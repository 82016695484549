import { Component, OnInit } from '@angular/core';
import {AuthService} from "@services/auth.service";
import {Router} from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import {IResetPasswordForm} from '@models/authentication.model';
import {ResetPasswordFormComponent} from '../../components/reset-password-form/reset-password-form.component';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss'],
})

export class ResetPasswordPage implements OnInit {


    constructor(
    	private auth: AuthService,
        private router: Router,
        private translate: TranslateService
    ) {  }

    ngOnInit() {
    }

    onResetPassword({email}: IResetPasswordForm): void {
               
        this.auth.getPasswordToken(email)
        .then(() => {
            this.router.navigate(['/code-view']);
        })
        .catch(error => {
            console.log('Error Reset password ', error);
        });

    }
}

import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthModule } from "./modules/auth/auth.module";

const routes: Routes = [
  {
    path: 'coach',
    loadChildren: () => import('./modules/coach/pages/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'athlete',
    loadChildren: () => import('./modules/athlete/pages/athlete-tabs/athlete-tabs.module').then(m => m.AthleteTabsPageModule)
  },
  {
    path: 'coach-athlete',
    loadChildren: () => import('./modules/coach-athlete/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: 'athlete-coach',
    loadChildren: () => import('./modules/athlete-coach/tabs/tabs.module').then(m => m.TabsPageModule)
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
    AuthModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, withLatestFrom } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { AppState } from '../app.states';
import { NutritionGoalsService } from '@services/nutrition-goals.service';
import { NutritionActions } from '../actions';

@Injectable()
export class NutritionEffects {
    constructor(
        private nutritionGoalsService: NutritionGoalsService,
        private actions$: Actions,
        private store$: Store<AppState>
    ) { }

    loadGoals$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NutritionActions.LOAD_GOALS),
            withLatestFrom(this.store$),
            filter(([action, storeState]) => {
                return !storeState?.nutrition?.goals?.loaded;
            }),
            exhaustMap((action) => {
                return this.nutritionGoalsService
                    .getAll()
                    .pipe(
                        map((res: any) => new NutritionActions.LoadGoalsSuccessAction(res)),
                        catchError((error) => of(new NutritionActions.LoadGoalsFailAction(error)))
                    )
            })
        )
    );
    addGoal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NutritionActions.ADD_GOAL),
            exhaustMap((action: any) =>
                this.nutritionGoalsService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new NutritionActions.AddGoalSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new NutritionActions.AddGoalFailAction(error)))
                    )
            )
        )
    );
    editGoal$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NutritionActions.EDIT_GOAL),
            exhaustMap((action: any) =>
                this.nutritionGoalsService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new NutritionActions.EditGoalSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new NutritionActions.EditGoalFailAction(error)))
                    )
            )
        )
    );
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-button {
  font-size: 32px;
  cursor: pointer;
}

app-training-plan-overview-sheet {
  display: block;
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 10px;
}
app-training-plan-overview-sheet:last-of-type {
  padding-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/training-plan-overview/training-plan-overview.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,eAAA;AACJ;;AACA;EACI,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;AAEJ;AAAI;EACI,oBAAA;AAER","sourcesContent":[".close-button {\n    font-size: 32px;\n    cursor: pointer;\n}\napp-training-plan-overview-sheet {\n    display: block;\n    padding-left: 20px;\n    padding-top: 20px;\n    padding-right: 10px;\n\n    &:last-of-type {\n        padding-bottom: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

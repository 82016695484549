import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PickerController } from '@ionic/angular';
import { PickerColumn, PickerColumnOption } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SliderComponent),
      multi: true
    }
  ]
})
export class SliderComponent implements ControlValueAccessor {
  @Input() _value = null;
  @Input() step: number = 1;
  @Input() min: number = 0;
  @Input() max: number = 100;
  @Input() dualKnobs: boolean = false;
  @Input() suffix: string = null;
  @Input() disabled: boolean = false;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  precision = 10;
  isShowRange: boolean = true;
  @Input() mode: string = 'popup'; // possible values: input, popup

  constructor(private pickerController: PickerController,
    private translate: TranslateService) { }

  get value() {
    return this._value;
  }

  set value(val) {
    if (this.dualKnobs) {
      let lower = this.round(val.lower) || 0;
      let upper = this.round(val.upper) || 0;

      this._value = {
        lower: lower < upper ? lower : upper,
        upper: upper > lower ? upper : lower
      }
    }
    else {
      this._value = this.round(val);
    }
    this.propagateChange(this._value);
  }

  round(val) {
    return Math.round(val * this.precision) / this.precision;
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.value = value;
    }
  }
  propagateChange = (_: any) => { };
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }


  calcSliderPercent(value) {
    let percent = (100 * value) / this.max;
    percent = percent < 0 ? 0 : percent;
    percent = percent > 100 ? 100 : percent;
    return percent + '%';
  }


  generateColumns(value): PickerColumn[] {
    let integers = [];
    for (let i = this.min; i <= this.max; i++) {
      let option: PickerColumnOption = {
        text: i + '',
        value: i
      };
      integers.push(option)
    }

    let decimals = [];
    for (let i = 0; i <= 9; i++) {
      let option: PickerColumnOption = {
        text: '.' + i,
        value: i / 10
      };
      decimals.push(option)
    }

    let integerSelectedIndex = Math.trunc(value) - (this.min ? this.min : 0);
    let columns: PickerColumn[] = [{
      name: `integer`,
      options: integers,
      selectedIndex: integerSelectedIndex
    }];

    if (this.step < 1) {
      let decimalSelectedIndex = Math.abs((+(value % 1).toFixed(1)) * 10);
      columns.push({
        name: `decimal`,
        options: decimals,
        selectedIndex: decimalSelectedIndex
      });
    }

    return columns;
  }


  async openPicker(value, dualKnobValue = 'lower') {
    if (this.disabled) {
      return;
    }
    const picker = await this.pickerController.create({
      columns: this.generateColumns(value),
      buttons: [
        {
          text: this.translate.instant("base.modal.cancel"),
          role: 'cancel'
        },
        {
          text: this.translate.instant("base.modal.confirm"),
          handler: (result) => {
            value = result.integer.value;
            if (result.decimal?.value != null) {
              value = value < 0 ? value - result.decimal?.value : value + result.decimal?.value;
            }
            if (this.dualKnobs) {
              this.value = {
                lower: dualKnobValue == 'lower' ? value : this.value.lower,
                upper: dualKnobValue == 'upper' ? value : this.value.upper
              };
            }
            else {
              this.value = value;
            }
            this.isShowRange = false;
            setTimeout(() => {
              this.isShowRange = true;
            }, 0);
          }
        }
      ]
    });

    await picker.present();
  }

  onRangeChange(event) {
    this.onChange.next(event);
  }

  _keyUp(event: any) {
    const pattern = /^[0-9]*$/;
    let val = event.target.value;
    val = +val;
    val = !val ? 0 : val;
    event.target.value = val;
  }
}

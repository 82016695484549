import { APP_INITIALIZER, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

import {
  HttpBackend,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { AuthEffects } from "./core/store/effects/auth.effects";
import { reducers } from "./core/store/app.states";
import { lineBreak } from './core/pipes/lineBreakPipe';
import { Autosize } from './core/directives/autosize';
import { CoreModule } from './core/core.module';
import { DocumentEffects } from './core/store/effects/document.effects';
import { TrainingEffects } from './core/store/effects/training.effects';
import { QuillModule } from 'ngx-quill'
import { MesocycleEffects } from './core/store/effects/mesocycle.effects';
import { ProgressEffects } from './core/store/effects/progress.effects';
import { AthleteEffects } from './core/store/effects/athlete.effects';
import { NutritionEffects } from './core/store/effects/nutrition.effects';
import { AnalyticsEffects } from './core/store/effects/analytics.effects';
import { Chooser } from '@ionic-native/chooser/ngx';
import { InvitationEffects } from "./core/store/effects/invitation.effects";
import { NoteEffects } from "./core/store/effects/note.effects";
import { NotificationEffects } from "./core/store/effects/notification.effects";
// import { PurchaseService } from "@services/purchase.service";
import { Deeplinks } from "@ionic-native/deeplinks/ngx";
import { BaseObjectEffects } from "./core/store/effects/base-objects.effects";

@NgModule({
    declarations: [
        AppComponent,
        lineBreak,
        Autosize
    ],
    imports: [
        HttpClientModule,
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        CoreModule,
        EffectsModule.forRoot([
            AuthEffects,
            DocumentEffects,
            TrainingEffects,
            MesocycleEffects,
            ProgressEffects,
            AthleteEffects,
            NutritionEffects,
            AnalyticsEffects,
            NoteEffects,
            InvitationEffects,
            NotificationEffects,
            BaseObjectEffects
        ]),
        StoreModule.forRoot(reducers),
        StoreDevtoolsModule.instrument(),
        QuillModule.forRoot({
            modules: {
                toolbar: [
                    ['bold', 'italic'],
                    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                    [{ 'indent': '-1' }, { 'indent': '+1' }],
                    ['link']
                ]
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpBackend],
            },
        }),
    ],
    providers: [
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        Chooser,
        Deeplinks,
        // PurchaseService
    ],
    bootstrap: [AppComponent],
    exports: [
        lineBreak,
        Autosize
    ]
})
export class AppModule { }

export function createTranslateLoader(http: HttpBackend) {
  return new TranslateHttpLoader(
    new HttpClient(http),
    "./assets/i18n/",
    ".json"
  );
}
import { Gender } from '../enums/gender.enum';

export interface ILoginForm {
    email: string,
    password: string
}

export enum UserRole {
    admin ='admin',
    coach = 'coach',
    athlete = 'athlete'
}

export interface ICoachRegistrationForm {
    name: string,
    email: string,
    gender: Gender,
    password: string,
    role: UserRole,
    city: string,
    street: string,
    zip: string
}

export interface IResetPasswordForm {
    email: string,
    password: string,
    passwordRepeat: string
}
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.invite {
  margin-bottom: 10px;
}

.next-button {
  margin-top: 0;
  --border-radius: 0 0 5px 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/athlete-invites/athlete-invites.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AAEA;EACI,aAAA;EACA,4BAAA;AACJ","sourcesContent":[".invite {\n    margin-bottom: 10px;\n}\n\n.next-button {\n    margin-top: 0;\n    --border-radius: 0 0 5px 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

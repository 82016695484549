import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { ProgressActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-macro-infinity-scroll',
  templateUrl: './macro-infinity-scroll.component.html',
  styleUrls: ['./macro-infinity-scroll.component.scss'],
})
export class MacroInfinityScrollComponent {
  selectPaging = AppReducers.getMacrosPaging;
  loadPageSuccessAction: string = ProgressActions.LOAD_MACROS_PAGE_SUCCESS;
  loadPageFailAction: string = ProgressActions.LOAD_MACROS_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  loadPageAction() {
    this.store.dispatch(new ProgressActions.LoadMacrosPageAction());
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ITechnique, ITrainingCategory } from '@models/training.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '@services/ui.service';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-techniq-modal',
  templateUrl: './edit-techniq-modal.component.html',
  styleUrls: ['./edit-techniq-modal.component.scss'],
})
export class EditTechniqModalComponent implements OnInit, OnDestroy {
  @Input() techniq: ITechnique;
  editingSub: Subscription;

  constructor(private modalController: ModalController,
    private uiService: UIService,
    private translate: TranslateService,
    private actions$: Actions,
    private store: Store<AppState>) { }

  ngOnInit() {
    this.editingSub = this.actions$.pipe(ofType(TrainingActions.EDIT_TECHNIQUE_SUCCESS))
      .subscribe(data => {
        this.uiService.showMessage(this.translate.instant('coach.techniques.edit.success'), 'success', 2000);
        this.modalController.dismiss();
      })
  }

  edit() {
    this.store.dispatch(new TrainingActions.EditTechniqueAction(this.techniq));
  }

  async onClickCloseModal() {
    await this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.editingSub.unsubscribe();
  }
}

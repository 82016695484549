import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { IMacro } from '@models/progress.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MacroUnit } from 'app/core/enums/macro-unit.enum';
import { ProgressActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-macro-form',
  templateUrl: './macro-form.component.html',
  styleUrls: ['./macro-form.component.scss'],
})
export class MacroFormComponent implements OnInit {
  macroForm: UntypedFormGroup;
  macroUnits = MacroUnit;
  subs: Subscription[] = [];

  @Input() macro: IMacro;
  @Input() isCopy: boolean = false;
  @Output() onSave = new EventEmitter();
  @Input() readonly: boolean = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private actions$: Actions,
  ) { }

  ngOnInit() {
    this.createForm();

    this.subscribeToMacroActions();
    this.subscribeToMacroCopyActions();
  }

  subscribeToMacroCopyActions() {
    this.subs[this.subs.length] = this.actions$.pipe(ofType(ProgressActions.EDIT_MACRO_COPY_SUCCESS))
      .subscribe((macro: any) => {
        this.onSave.emit(macro);
      })
  }

  subscribeToMacroActions() {
    this.subs[this.subs.length] = this.actions$.pipe(ofType(ProgressActions.EDIT_MACRO_SUCCESS))
      .subscribe((macro: any) => {
        this.onSave.emit(macro);
      })

    this.subs[this.subs.length] = this.actions$.pipe(ofType(ProgressActions.ADD_MACRO_SUCCESS))
      .subscribe((macro: any) => {
        this.onSave.emit(macro);
      })
  }
  createForm() {
    this.macroForm = this.formBuilder.group({
      id: [],
      name: ['', [Validators.required]],
      unit: [Object.keys(this.macroUnits)[0], [Validators.required]],
      range: [{ lower: 0, upper: 100 }, [Validators.required]],
      deviation: [{ lower: 0, upper: 100 }, [Validators.required]]
    });

    this.macroForm.get('range').valueChanges
      .subscribe(data => this.setDeviation(this.macroForm.get('deviation'), data))

    if (this.macro) {
      this.setFormData();
    }
  }

  setDeviation(deviationControl: AbstractControl, rangeValue: any) {
    let deviationValue = deviationControl.value;
    if (deviationValue.lower < rangeValue.lower) {
      deviationValue.lower = rangeValue.lower;
    }
    if (deviationValue.upper < rangeValue.lower) {
      deviationValue.upper = rangeValue.lower;
    }
    if (deviationValue.lower > rangeValue.upper) {
      deviationValue.lower = rangeValue.upper;
    }
    if (deviationValue.upper > rangeValue.upper) {
      deviationValue.upper = rangeValue.upper;
    }
    deviationControl.setValue(deviationValue);
  }

  /**
   * Fills the form with data given
   */
  setFormData() {
    this.macroForm.patchValue({
      id: this.macro.id,
      name: this.macro.name,
      unit: this.macro.unit,
      range: { lower: this.macro?.macro_detail?.range_from, upper: this.macro?.macro_detail?.range_to },
      deviation: { lower: this.macro?.macro_detail?.deviation.min, upper: this.macro?.macro_detail?.deviation.max }
    })

    this.setDeviation(this.macroForm.get('deviation'), this.macroForm.get('range').value);
  }

  /**
   * create Macro Object and emit it
   */
  onSubmit() {
    if (this.macroForm.valid) {
      let formValue = this.macroForm.value;

      const macro: IMacro = {
        ...formValue,
        deviation_min: undefined,
        deviation_max: undefined,
        range_from: formValue.range?.lower,
        range_to: formValue.range?.upper,
        macro_detail: {
          deviation: {
            min: formValue.deviation?.lower,
            max: formValue.deviation?.upper,
          },
        },
        range: undefined,
        detail_id: this.macro?.macro_detail?.id
      }

      if (!this.isCopy) {
        if (macro.id) {
          this.store.dispatch(new ProgressActions.EditMacroAction(macro));
        } else {
          this.store.dispatch(new ProgressActions.AddMacroAction(macro));
        }
      }
      else {
        this.store.dispatch(new ProgressActions.EditMacroCopyAction(macro));
      }
    }
  }

  /**
   * Set value for unit from segment
   */
  onUnitChanged(event: CustomEvent) {
    this.macroForm.get("unit").patchValue(event.detail.value);
  }

  onIncreaseNumber(field: string) {
    this.macroForm.get(field).patchValue(this.macroForm.get(field).value + 1);
  }

  onDecreaseNumber(field: string) {
    this.macroForm.get(field).patchValue(this.macroForm.get(field).value - 1);
  }

  get unit() { return this.macroForm.get('unit').value; }


}

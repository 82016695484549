import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from '@models/authentication.model';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { AppState } from 'app/core/store/app.states';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-coach-analytics-button',
  templateUrl: './coach-analytics-button.component.html',
  styleUrls: ['./coach-analytics-button.component.scss'],
})
export class CoachAnalyticsButtonComponent implements OnInit {
  role$: Observable<UserRole>;
  UserRole = UserRole;

  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit() {
    this.role$ = this.store.select(AppReducers.getUserRole);
  }

  gotoAnalitycsPage() {
    this.router.navigate(['/coach/analytics']);
  }
}

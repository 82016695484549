import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { DocumentActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-documents-infinity-scroll',
  templateUrl: './documents-infinity-scroll.component.html',
  styleUrls: ['./documents-infinity-scroll.component.scss'],
})
export class DocumentsInfinityScrollComponent {
  selectPaging = AppReducers.getDocumentPaging;
  loadPageSuccessAction: string = DocumentActions.LOAD_DOCUMENTS_PAGE_SUCCESS;
  loadPageFailAction: string = DocumentActions.LOAD_DOCUMENTS_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  loadPageAction() {
    this.store.dispatch(new DocumentActions.LoadDocumentsPageAction());
  }
}

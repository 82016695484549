import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-athlete-more-button',
  templateUrl: './athlete-more-button.component.html',
  styleUrls: ['./athlete-more-button.component.scss'],
})
export class AthleteMoreButtonComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit() { }

  gotoMorePage() {
    this.router.navigate(['/athlete/more']);
  }
}

import { IAssignedMesocycle, IMesocycle } from '@models/mesocycle.model';
import { INITIAL_PAGING, IPaging, IPagingResult } from '@models/paging.model';
import { AuthActions, MesocycleActions, ProgressActions, TrainingActions } from "../actions";

export interface State {
    mesocycles: MesocycleState;
    mesocycleCopies: MesocycleState;
    assigned: AssignedMesocycleState[];
}
export interface MesocycleState {
    loaded: boolean;
    loading: boolean;
    items: IMesocycle[];
    paging: IPaging;
}
export interface AssignedMesocycleState {
    loaded: boolean;
    loading: boolean;
    items: IAssignedMesocycle[];
    paging: IPaging;
}

const initialSubState = {
    loaded: false,
    loading: false,
    items: []
}
const initialState: State = {
    mesocycles: {
        ...initialSubState,
        paging: INITIAL_PAGING
    },
    mesocycleCopies: {
        ...initialSubState,
        paging: INITIAL_PAGING
    },
    assigned: []
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
        case MesocycleActions.LOAD_MESOCYCLES:
        case MesocycleActions.LOAD_MESOCYCLES_PAGE: {
            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    loading: true
                }
            });
        }
        case MesocycleActions.LOAD_MESOCYCLES_FAIL:
        case MesocycleActions.LOAD_MESOCYCLES_PAGE_FAIL: {
            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    loading: false
                }
            });
        }
        case MesocycleActions.LOAD_MESOCYCLES_SUCCESS: {
            let resp: IPagingResult<IMesocycle> = action.payload;

            return Object.assign({}, state, {
                mesocycles: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: resp.items,
                    paging: resp.paging
                }
            });
        }
        case MesocycleActions.LOAD_MESOCYCLES_PAGE_SUCCESS: {
            let resp: IPagingResult<IMesocycle> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.mesocycles.items, ...resp.items];
            newItems = newItems.filter(x => !x.is_frontend);

            return Object.assign({}, state, {
                mesocycles: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }
        case MesocycleActions.LOAD_MESOCYCLE_SUCCESS:
        case MesocycleActions.ADD_MESOCYCLE_SUCCESS:
        case MesocycleActions.COPY_TO_BASE_MESOCYCLE_SUCCESS: {
            let mesocycle = action.payload;
            mesocycle = { ...mesocycle };

            if (state.mesocycles.items.findIndex(x => x.id == mesocycle.id) > -1) {
                return state;
            }

            let items = [...state.mesocycles.items];
            switch (action.type) {
                case MesocycleActions.LOAD_MESOCYCLE_SUCCESS:
                    mesocycle.is_frontend = true;
                    items = [...items, mesocycle];
                default:
                    if (state.mesocycles?.paging?.nextPageUrl) {
                        items.pop();
                    }
                    items = [mesocycle, ...items];
            }

            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    items: items
                }
            });
        }
        case MesocycleActions.EDIT_MESOCYCLE_SUCCESS: {
            const mesocycle = action.payload;

            if (!state.mesocycles.items.some(x => x.id == mesocycle.id)) {
                return state;
            }

            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    items: state.mesocycles.items.map(obj => obj.id == mesocycle.id ? mesocycle : obj)
                }
            });
        }
        case MesocycleActions.REMOVE_MESOCYCLE_SUCCESS: {
            const removedMesocycle = action.payload;

            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    items: state.mesocycles.items.filter(mesocycle => mesocycle.id !== removedMesocycle.id)
                }
            });
        }


        case MesocycleActions.LOAD_MESOCYCLE_COPY_SUCCESS: {
            const mesocycleCopy = action.payload;

            if (state.mesocycleCopies.items.findIndex(x => x.id == mesocycleCopy.id) > -1) {
                return Object.assign({}, state, {
                    mesocycleCopies: {
                        ...state.mesocycleCopies,
                        items: state.mesocycleCopies.items.map(x => x.id == mesocycleCopy.id ? mesocycleCopy : x)
                    }
                });
            }

            return Object.assign({}, state, {
                mesocycleCopies: {
                    ...state.mesocycleCopies,
                    items: [mesocycleCopy, ...state.mesocycleCopies.items]
                }
            });
        }
        case MesocycleActions.EDIT_MESOCYCLE_COPY_SUCCESS: {
            const mesocycleCopy = action.payload;

            if (!state.mesocycleCopies.items.some(x => x.id == mesocycleCopy.id)) {
                return state;
            }


            let currentAssignedItems = Array.from(state.assigned);
            currentAssignedItems = currentAssignedItems.map(
                athleteArray =>
                    athleteArray?.items.some(x => x.mesocycle_id == mesocycleCopy.id) ?
                        Object.assign({}, athleteArray, {
                            items: athleteArray.items.map(assignedMesocycle =>
                                assignedMesocycle.mesocycle_id == mesocycleCopy.id ?
                                    Object.assign({}, assignedMesocycle, { mesocycle: mesocycleCopy }) :
                                    assignedMesocycle)
                        }) :
                        athleteArray);


            return Object.assign({}, state, {
                mesocycleCopies: {
                    ...state.mesocycleCopies,
                    items: state.mesocycleCopies.items.map(obj => obj.id == mesocycleCopy.id ? mesocycleCopy : obj)
                },
                assigned: currentAssignedItems
            });
        }



        case MesocycleActions.LOAD_ASSIGNED_MESOCYCLES_SUCCESS:
        case MesocycleActions.LOAD_ASSIGNED_MESOCYCLES_PAGE_SUCCESS: {
            const assignedMesocycles: IPagingResult<IAssignedMesocycle> = action.payload;

            let currentItems = Array.from(state.assigned || []);

            let newAMItems = assignedMesocycles.items;
            if (assignedMesocycles.paging.currentPage != 1) {
                newAMItems = [...state.assigned[action.athleteId]?.items, ...assignedMesocycles.items];
            }

            currentItems[action.athleteId] = {
                loaded: assignedMesocycles.paging.currentPage == assignedMesocycles.paging.lastPage,
                loading: false,
                items: newAMItems,
                paging: assignedMesocycles.paging
            }

            return Object.assign({}, state, {
                assigned: currentItems
            });
        }
        case MesocycleActions.ADD_ASSIGNED_MESOCYCLE_SUCCESS: {
            const assignedMesocycle: IAssignedMesocycle = action.payload;
            let athleteId = assignedMesocycle?.athlete_id;

            let currentItems = Array.from(state.assigned || []);
            if (state.assigned[athleteId]?.items.findIndex(x => x.id == assignedMesocycle.id) > -1) {
                return state;
            }

            currentItems[athleteId] = Object.assign({}, currentItems[athleteId], {
                items: [assignedMesocycle, ...Array.from(currentItems[athleteId]?.items || [])]
            });

            return Object.assign({}, state, {
                assigned: currentItems
            });
        }
        case MesocycleActions.ACTIVATE_ASSIGNED_MESOCYCLE_SUCCESS:
        case MesocycleActions.DEACTIVATE_ASSIGNED_MESOCYCLE_SUCCESS:
        case MesocycleActions.SET_INVISIBLE_ASSIGNED_MESOCYCLE_SUCCESS: {
            const assignedMesocycle: IAssignedMesocycle = action.payload;
            let athleteId = assignedMesocycle?.athlete_id;

            let currentItems = Array.from(state.assigned || []);
            if (state.assigned[athleteId]?.items.findIndex(x => x.id == assignedMesocycle.id) < 0) {
                return state;
            }
            currentItems[athleteId] = Object.assign({}, currentItems[athleteId],
                {
                    items: Array.from(currentItems[athleteId]?.items || []).map(obj => obj.id == assignedMesocycle.id ? assignedMesocycle : obj)
                });

            return Object.assign({}, state, {
                assigned: currentItems
            });
        }
        case MesocycleActions.REMOVE_ASSIGNED_MESOCYCLE_SUCCESS: {
            const assignedMesocycle: IAssignedMesocycle = action.payload;
            let athleteId = assignedMesocycle?.athlete_id;

            let currentItems = Array.from(state.assigned || []);
            currentItems[athleteId] = Object.assign({}, currentItems[athleteId], {
                items: Array.from(currentItems[athleteId]?.items || [])?.filter(x => x.id != assignedMesocycle.id)
            });

            return Object.assign({}, state, {
                assigned: currentItems
            });
        }



        case ProgressActions.EDIT_BODY_DATA_SUCCESS: {
            const actionBodyData = action.payload;

            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    items: state.mesocycles.items.map(obj => Object.assign({}, obj, {
                        bodyDatas: obj.bodyDatas.map(bodyData => bodyData.id == actionBodyData.id ? {
                            ...actionBodyData,
                            body_data_detail: bodyData.body_data_detail
                        } : bodyData)
                    }))
                },
                mesocycleCopies: {
                    ...state.mesocycleCopies,
                    items: state.mesocycleCopies.items.map(obj => Object.assign({}, obj, {
                        bodyDatas: obj.bodyDatas.map(bodyData => bodyData.id == actionBodyData.id ? {
                            ...actionBodyData,
                            body_data_detail: bodyData.body_data_detail
                        } : bodyData)
                    }))
                }
            });
        }
        case ProgressActions.EDIT_BODY_DATA_COPY_SUCCESS: {
            const bodyDataCopy = action.payload;

            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    items: state.mesocycles.items.map(obj => Object.assign({}, obj, {
                        bodyDatas: obj.bodyDatas.map(bodyData => bodyData.id == bodyDataCopy.id ? bodyDataCopy : bodyData)
                    }))
                },
                mesocycleCopies: {
                    ...state.mesocycleCopies,
                    items: state.mesocycleCopies.items.map(obj => Object.assign({}, obj, {
                        bodyDatas: obj.bodyDatas.map(bodyData => bodyData.id == bodyDataCopy.id ? bodyDataCopy : bodyData)
                    }))
                }
            });
        }
        case ProgressActions.REMOVE_BODY_DATA_COPY_SUCCESS: {
            const removedBodyDataCopy = action.payload;

            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    items: state.mesocycles.items.map(obj => Object.assign({}, obj, {
                        bodyDatas: obj.bodyDatas.filter(bodyData => bodyData.id !== removedBodyDataCopy.id)
                    }))
                },
                mesocycleCopies: {
                    ...state.mesocycleCopies,
                    items: state.mesocycleCopies.items.map(obj => Object.assign({}, obj, {
                        bodyDatas: obj.bodyDatas.filter(bodyData => bodyData.id !== removedBodyDataCopy.id)
                    }))
                }
            });
        }


        case ProgressActions.EDIT_MACRO_SUCCESS: {
            const actionMacro = action.payload;
            
            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    items: state.mesocycles.items.map(obj => Object.assign({}, obj, {
                        macros: obj.macros.map(macro => macro.id == actionMacro.id ? {
                            ...actionMacro,
                            macro_detail: macro.macro_detail
                        } : macro)
                    }))
                },
                mesocycleCopies: {
                    ...state.mesocycleCopies,
                    items: state.mesocycleCopies.items.map(obj => Object.assign({}, obj, {
                        macros: obj.macros.map(macro => macro.id == actionMacro.id ? {
                            ...actionMacro,
                            macro_detail: macro.macro_detail
                        } : macro)
                    }))
                }
            });
        }
        case ProgressActions.EDIT_MACRO_COPY_SUCCESS: {
            const macroCopy = action.payload;

            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    items: state.mesocycles.items.map(obj => Object.assign({}, obj, {
                        macros: obj.macros.map(macro => macro.id == macroCopy.id ? macroCopy : macro)
                    }))
                },
                mesocycleCopies: {
                    ...state.mesocycleCopies,
                    items: state.mesocycleCopies.items.map(obj => Object.assign({}, obj, {
                        macros: obj.macros.map(macro => macro.id == macroCopy.id ? macroCopy : macro)
                    }))
                }
            });
        }
        case ProgressActions.REMOVE_MACRO_COPY_SUCCESS: {
            const removedMacroCopy = action.payload;

            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    items: state.mesocycles.items.map(obj => Object.assign({}, obj, {
                        macros: obj.macros.filter(macro => macro.id !== removedMacroCopy.id)
                    }))
                },
                mesocycleCopies: {
                    ...state.mesocycleCopies,
                    items: state.mesocycleCopies.items.map(obj => Object.assign({}, obj, {
                        macros: obj.macros.filter(macro => macro.id !== removedMacroCopy.id)
                    }))
                }
            });
        }


        case TrainingActions.EDIT_PLAN_COPY_SUCCESS: {
            const planCopy = action.payload;

            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    items: state.mesocycles.items.map(obj => Object.assign({}, obj, {
                        trainingPlans: [...obj.trainingPlans].map(x => x.id == planCopy?.id ? planCopy : x)
                    }))
                },
                mesocycleCopies: {
                    ...state.mesocycleCopies,
                    items: state.mesocycleCopies.items.map(obj => Object.assign({}, obj, {
                        trainingPlans: [...obj.trainingPlans].map(x => x.id == planCopy?.id ? planCopy : x)
                    }))
                }
            });
        }
        case TrainingActions.REMOVE_PLAN_COPY_SUCCESS: {
            const removedPlanCopy = action.payload;

            return Object.assign({}, state, {
                mesocycles: {
                    ...state.mesocycles,
                    items: state.mesocycles.items.map(obj => Object.assign({}, obj, {
                        trainingPlans: [...obj.trainingPlans].filter(x => x.id != removedPlanCopy?.id)
                    }))
                },
                mesocycleCopies: {
                    ...state.mesocycleCopies,
                    items: state.mesocycleCopies.items.map(obj => Object.assign({}, obj, {
                        trainingPlans: [...obj.trainingPlans].filter(x => x.id != removedPlanCopy?.id)
                    }))
                }
            });
        }


        case AuthActions.LOGIN_SUCCESS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}


export const getMesocyclesLoaded = (state: State) => state.mesocycles.loaded;
export const getMesocyclesLoading = (state: State) => state.mesocycles.loading;
export const getMesocycles = (state: State) => state.mesocycles.items;
export const getMesocyclesPaging = (state: State) => state.mesocycles.paging;
export const getMesocycle = (state: State, props) => state.mesocycles.items.find(x => x.id == props.id);

export const getMesocycleCopy = (state: State, props) => state.mesocycleCopies.items.find(x => x.id == props.id);

export const getAssignedMesocycles = (state: State, props) => state.assigned[props.id]?.items;
export const getAssignedMesocyclesPaging = (state: State, props) => state.assigned[props.id]?.paging;
export const getActiveAssignedMesocycle = (state: State, props) => state.assigned[props.id]?.items?.find(x => x.is_active);
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { IPagingResult } from '@models/paging.model';
import { IBodyData, IMacro } from '@models/progress.model';
import { ITrainingPlan, ITrainingUnit } from '@models/training.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../helpers/http.helper';

@Injectable({
    providedIn: "root",
})
export class BaseObjectsService {
    constructor(private http: HttpClient) { }

    getBodyDatas(page: number = 1, withoutLoading = false): Observable<IPagingResult<IBodyData>> {
        let pageQuery = `page=${page}`;

        let http = withoutLoading ? this.http.disableLoading() : this.http;
        return http.get(`/base_body_data?${pageQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    getMacros(page: number = 1, withoutLoading = false): Observable<IPagingResult<IMacro>> {
        let pageQuery = `page=${page}`;

        let http = withoutLoading ? this.http.disableLoading() : this.http;
        return http.get(`/base_macros?${pageQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    getUnits(filter: string = '', page: number = 1, withoutLoading = false): Observable<IPagingResult<ITrainingUnit>> {
        let pageQuery = `page=${page}`;

        let filterQuery = '';
        if (filter) {
            filterQuery = `&filter[name]=${filter}`;
        }

        let http = withoutLoading ? this.http.disableLoading() : this.http;
        return http.get(`/base_training_units?${pageQuery}${filterQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: {
                            ...paging,
                            filter: filter
                        }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    getPlans(page: number = 1, withoutLoading = false): Observable<IPagingResult<ITrainingPlan>> {
        let pageQuery = `page=${page}`;

        let http = withoutLoading ? this.http.disableLoading() : this.http;
        return http.get(`/base_training_plans?${pageQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    importBodyDatas(ids: number[]) {
        return this.http.post(`/import_base_data`, { type: 'body_data', ref_ids: ids });
    }

    importMacros(ids: number[]) {
        return this.http.post(`/import_base_data`, { type: 'macro', ref_ids: ids });
    }

    importUnits(ids: number[]) {
        return this.http.post(`/import_base_data`, { type: 'training_unit', ref_ids: ids });
    }

    importPlans(ids: number[]) {
        return this.http.post(`/import_base_data`, { type: 'training_plan', ref_ids: ids });
    }
}
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSubscription } from '@models/app-subscription.model';
import { UserRole } from '@models/authentication.model';
import { IUser } from '@models/user.model';
import { Store } from '@ngrx/store';
import { AuthService } from '@services/auth.service';
import { IAPService } from '@services/iap.service';
import { AppReducers } from 'app/core/store';
import { AppState } from 'app/core/store/app.states';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.page.html',
  styleUrls: ['./subscription.page.scss'],
})
export class SubscriptionPage implements OnInit, OnDestroy {
  selectedSub: AppSubscription;
  subs: Subscription[] = [];
  isLongPullingActive: boolean = true;
  subLoaded: boolean = false;
  user$: Observable<IUser>;

  constructor(private iapService: IAPService,
    private router: Router,
    private store: Store<AppState>,
    private authService: AuthService) { }
  ngOnInit() {
    this.user$ = this.store.select(AppReducers.getUser);

    setInterval(() => {
      if (this.isLongPullingActive) {
        this.authService.getUserData().subscribe();
      }
    }, 5000);
  }

  ionViewWillEnter() {
    this.isLongPullingActive = true;
  }
  ionViewDidLeave() {
    this.isLongPullingActive = false;
  }

  getSuccessUrl() {
    return this.authService.getUserRole() == UserRole.coach ? 'coach/athletes' : 'athlete-info';
  }

  nextPage() {
    if (this.selectedSub?.id) {
      this.iapService.purchase(this.selectedSub, this.getSuccessUrl(), 'subscription')
        .subscribe(data => {
          if (this.iapService.isIOS) {
            this.router.navigateByUrl(this.getSuccessUrl());
          }
        });
    }
    else {
      this.router.navigateByUrl(this.getSuccessUrl());
    }
  }
  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

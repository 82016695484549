import { IPagingResult } from '@models/paging.model';
import { IBodyData, IMacro } from '@models/progress.model';
import { ITrainingPlan, ITrainingUnit } from '@models/training.model';
import { Action } from '@ngrx/store';

export const LOAD_BASE_UNITS = '[Base objects] Load Base Units';
export const LOAD_BASE_UNITS_SUCCESS = '[Base objects] Load Base Units Success';
export const LOAD_BASE_UNITS_FAIL = '[Base objects] Load Base Units Fail';
export const LOAD_BASE_UNITS_PAGE = '[Base objects] Load Base Units Page';
export const LOAD_BASE_UNITS_PAGE_SUCCESS = '[Base objects] Load Base Units Page Success';
export const LOAD_BASE_UNITS_PAGE_FAIL = '[Base objects] Load Base Units Page Fail';
export const IMPORT_BASE_UNIT = '[Base objects] Import Base Unit';
export const IMPORT_BASE_UNIT_SUCCESS = '[Base objects] Import Base Unit Success';
export const IMPORT_BASE_UNIT_FAIL = '[Base objects] Import Base Unit Fail';

export const LOAD_BASE_PLANS = '[Base objects] Load Base Plans';
export const LOAD_BASE_PLANS_SUCCESS = '[Base objects] Load Base Plans Success';
export const LOAD_BASE_PLANS_FAIL = '[Base objects] Load Base Plans Fail';
export const LOAD_BASE_PLANS_PAGE = '[Base objects] Load Base Plans Page';
export const LOAD_BASE_PLANS_PAGE_SUCCESS = '[Base objects] Load Base Plans Page Success';
export const LOAD_BASE_PLANS_PAGE_FAIL = '[Base objects] Load Base Plans Page Fail';
export const IMPORT_BASE_PLAN = '[Base objects] Import Base Plan';
export const IMPORT_BASE_PLAN_SUCCESS = '[Base objects] Import Base Plan Success';
export const IMPORT_BASE_PLAN_FAIL = '[Base objects] Import Base Plan Fail';

export const LOAD_BASE_BODY_DATAS = '[Base objects] Load Base Body Datas';
export const LOAD_BASE_BODY_DATAS_SUCCESS = '[Base objects] Load Base Body Datas Success';
export const LOAD_BASE_BODY_DATAS_FAIL = '[Base objects] Load Base Body Datas Fail';
export const LOAD_BASE_BODY_DATAS_PAGE = '[Base objects] Load Base Body Datas Page';
export const LOAD_BASE_BODY_DATAS_PAGE_SUCCESS = '[Base objects] Load Base Body Datas Page Success';
export const LOAD_BASE_BODY_DATAS_PAGE_FAIL = '[Base objects] Load Base Body Datas Page Fail';
export const IMPORT_BASE_BODY_DATA = '[Base objects] Import Base Body Datas';
export const IMPORT_BASE_BODY_DATA_SUCCESS = '[Base objects] Import Base Body Datas Success';
export const IMPORT_BASE_BODY_DATA_FAIL = '[Base objects] Import Base Body Datas Fail';

export const LOAD_BASE_MACROS = '[Base objects] Load Base Macros';
export const LOAD_BASE_MACROS_SUCCESS = '[Base objects] Load Base Macros Success';
export const LOAD_BASE_MACROS_FAIL = '[Base objects] Load Base Macros Fail';
export const LOAD_BASE_MACROS_PAGE = '[Base objects] Load Base Macros Page';
export const LOAD_BASE_MACROS_PAGE_SUCCESS = '[Base objects] Load Base Macros Page Success';
export const LOAD_BASE_MACROS_PAGE_FAIL = '[Base objects] Load Base Macros Page Fail';
export const IMPORT_BASE_MACRO = '[Base objects] Import Base Macro';
export const IMPORT_BASE_MACRO_SUCCESS = '[Base objects] Import Base Macro Success';
export const IMPORT_BASE_MACRO_FAIL = '[Base objects] Import Base Macro Fail';


/**
 * Load Base Units Actions
 */
export class LoadBaseUnitsAction implements Action {
    readonly type = LOAD_BASE_UNITS;
    constructor(public filter: string, public force: boolean = false, public withoutLoading = false) { }
}
export class LoadBaseUnitsSuccessAction implements Action {
    readonly type = LOAD_BASE_UNITS_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingUnit>) { }
}
export class LoadBaseUnitsFailAction implements Action {
    readonly type = LOAD_BASE_UNITS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Base Units Page Actions
 */
export class LoadBaseUnitsPageAction implements Action {
    readonly type = LOAD_BASE_UNITS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadBaseUnitsPageSuccessAction implements Action {
    readonly type = LOAD_BASE_UNITS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingUnit>) { }
}
export class LoadBaseUnitsPageFailAction implements Action {
    readonly type = LOAD_BASE_UNITS_PAGE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Import Base Unit Actions
 */
export class ImportBaseUnitAction implements Action {
    readonly type = IMPORT_BASE_UNIT;
    constructor(public ids: number[]) { }
}
export class ImportBaseUnitSuccessAction implements Action {
    readonly type = IMPORT_BASE_UNIT_SUCCESS;
    constructor(public ids: number[]) { }
}
export class ImportBaseUnitFailAction implements Action {
    readonly type = IMPORT_BASE_UNIT_FAIL;
    constructor(public payload: any) { }
}


/**
 * Load Base Plans Actions
 */
export class LoadBasePlansAction implements Action {
    readonly type = LOAD_BASE_PLANS;
    constructor(public force: boolean = false) { }
}
export class LoadBasePlansSuccessAction implements Action {
    readonly type = LOAD_BASE_PLANS_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingPlan>) { }
}
export class LoadBasePlansFailAction implements Action {
    readonly type = LOAD_BASE_PLANS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Base Plans Page Actions
 */
export class LoadBasePlansPageAction implements Action {
    readonly type = LOAD_BASE_PLANS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadBasePlansPageSuccessAction implements Action {
    readonly type = LOAD_BASE_PLANS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingPlan>) { }
}
export class LoadBasePlansPageFailAction implements Action {
    readonly type = LOAD_BASE_PLANS_PAGE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Import Base Plan Actions
 */
export class ImportBasePlanAction implements Action {
    readonly type = IMPORT_BASE_PLAN;
    constructor(public ids: number[]) { }
}
export class ImportBasePlanSuccessAction implements Action {
    readonly type = IMPORT_BASE_PLAN_SUCCESS;
    constructor() { }
}
export class ImportBasePlanFailAction implements Action {
    readonly type = IMPORT_BASE_PLAN_FAIL;
    constructor(public payload: any) { }
}



/**
 * Load Base Body Datas Actions
 */
export class LoadBaseBodyDatasAction implements Action {
    readonly type = LOAD_BASE_BODY_DATAS;
    constructor(public force: boolean = false) { }
}
export class LoadBaseBodyDatasSuccessAction implements Action {
    readonly type = LOAD_BASE_BODY_DATAS_SUCCESS;
    constructor(public payload: IPagingResult<IBodyData>) { }
}
export class LoadBaseBodyDatasFailAction implements Action {
    readonly type = LOAD_BASE_BODY_DATAS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Base Body Datas Page Actions
 */
export class LoadBaseBodyDatasPageAction implements Action {
    readonly type = LOAD_BASE_BODY_DATAS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadBaseBodyDatasPageSuccessAction implements Action {
    readonly type = LOAD_BASE_BODY_DATAS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<IBodyData>) { }
}
export class LoadBaseBodyDatasPageFailAction implements Action {
    readonly type = LOAD_BASE_BODY_DATAS_PAGE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Import Base Body Datas Actions
 */
export class ImportBaseBodyDataAction implements Action {
    readonly type = IMPORT_BASE_BODY_DATA;
    constructor(public ids: number[]) { }
}
export class ImportBaseBodyDataSuccessAction implements Action {
    readonly type = IMPORT_BASE_BODY_DATA_SUCCESS;
    constructor(public ids: number[]) { }
}
export class ImportBaseBodyDataFailAction implements Action {
    readonly type = IMPORT_BASE_BODY_DATA_FAIL;
    constructor(public payload: any) { }
}


/**
 * Load Base Macros Actions
 */
export class LoadBaseMacrosAction implements Action {
    readonly type = LOAD_BASE_MACROS;
    constructor(public force: boolean = false) { }
}
export class LoadBaseMacrosSuccessAction implements Action {
    readonly type = LOAD_BASE_MACROS_SUCCESS;
    constructor(public payload: IPagingResult<IMacro>) { }
}
export class LoadBaseMacrosFailAction implements Action {
    readonly type = LOAD_BASE_MACROS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Base Macros Page Actions
 */
export class LoadBaseMacrosPageAction implements Action {
    readonly type = LOAD_BASE_MACROS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadBaseMacrosPageSuccessAction implements Action {
    readonly type = LOAD_BASE_MACROS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<IMacro>) { }
}
export class LoadBaseMacrosPageFailAction implements Action {
    readonly type = LOAD_BASE_MACROS_PAGE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Import Base Macro Actions
 */
export class ImportBaseMacroAction implements Action {
    readonly type = IMPORT_BASE_MACRO;
    constructor(public ids: number[]) { }
}
export class ImportBaseMacroSuccessAction implements Action {
    readonly type = IMPORT_BASE_MACRO_SUCCESS;
    constructor(public ids: number[]) { }
}
export class ImportBaseMacroFailAction implements Action {
    readonly type = IMPORT_BASE_MACRO_FAIL;
    constructor(public payload: any) { }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';

@Component({
  selector: 'app-crop-avatar',
  templateUrl: './crop-avatar.component.html',
  styleUrls: ['./crop-avatar.component.scss'],
})
export class CropAvatarComponent implements OnInit {
  croppedImageBase64: string = "";
  @Input() aspectRatio: any = 1/1;
  @Input() maintainAspectRatio: boolean = true;
  @Input() readyToCropImage: any = '';
  @Input() imageFormat: string = 'png';
  @Output() imageCropDone: EventEmitter<any> = new EventEmitter<any>();
  @Output() imageCropCancel: EventEmitter<void> = new EventEmitter<void>();
  rotation = 0;
  transform: ImageTransform = {};

  constructor() { }

  ngOnInit() { }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImageBase64 = event.base64;
  }

  done() {
    this.imageCropDone.emit(this.croppedImageBase64);
  }

  cancel() {
    this.imageCropCancel.emit();
  }

  rotateRight() {
    this.rotation += 90;
    this.updateRotation();
  }

  updateRotation() {
    this.transform = {
      ...this.transform,
      rotate: this.rotation,
    };
  }
}

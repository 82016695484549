import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { UserRole } from '@models/authentication.model';
import { IPagingResult } from '@models/paging.model';
import { ITrainingTemplate } from '@models/training.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../helpers/http.helper';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: "root",
})
export class TrainingTemplateService {
    apiPath: string = 'training_templates';

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    getAll(page: number = 1, withoutLoading = false): Observable<IPagingResult<ITrainingTemplate>> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let authQuery = userRole == UserRole.athlete ? 'athlete_id' : 'coach_id';
        authQuery += `=${userId}`;
        let pageQuery = `&page=${page}`;

        let http = withoutLoading ? this.http.disableLoading() : this.http;
        return http.get(`/${this.apiPath}?${authQuery}&with_units=1${pageQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    add(model: ITrainingTemplate) {
        let userId = this.authService.getUserId();
        let template: any = {
            ...model,
            units: [...model.units.map(x => x.id)]
        };
        let userRole = this.authService.getUserRole();
        if (userRole == UserRole.athlete) {
            template.athlete_id = userId;
        }
        else {
            template.coach_id = userId;
        }

        return this.http.post(`/${this.apiPath}`, template);
    }


    edit(model: ITrainingTemplate) {
        let userId = this.authService.getUserId();
        let template: any = {
            ...model,
            units: undefined,
            unit_data: [...model.units.map(x => x.unit_data?.id)]
        };
        let userRole = this.authService.getUserRole();
        if (userRole == UserRole.athlete) {
            template.athlete_id = userId;
        }
        else {
            template.coach_id = userId;
        }
        return this.http.patch(`/${this.apiPath}/${template.id}`, template);
    }

    delete(id: number) {
        return this.http.delete(`/${this.apiPath}/${id}`);
    }
}
export class IPaging {
    currentPage: number;
    lastPage: number;

    prevPageUrl: string;
    nextPageUrl: string;

    perPage: number;
    total: number;
    filter?: string;
}

export interface IPagingResult<T> {
    items: T[],
    paging: IPaging,
}

export const INITIAL_PAGING: IPaging = {
    currentPage: 0,
    lastPage: 1,

    prevPageUrl: '',
    nextPageUrl: '',

    perPage: 15,
    total: 0,

    filter: ''
}
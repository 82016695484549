import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, withLatestFrom } from "rxjs/operators";
import { BaseObjectActions } from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.states';
import { ITrainingPlan, ITrainingUnit } from "@models/training.model";
import { IPagingResult } from "@models/paging.model";
import { BaseObjectsService } from "@services/base-objects.service";
import { IBodyData, IMacro } from "@models/progress.model";

@Injectable()
export class BaseObjectEffects {
    constructor(
        private baseObjectsService: BaseObjectsService,
        private store$: Store<AppState>,
        private actions$: Actions
    ) { }

    loadUnits$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseObjectActions.LOAD_BASE_UNITS),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [BaseObjectActions.LoadBaseUnitsAction, AppState]) => {
                let isNewFilter = storeState?.baseObjects?.units.paging?.filter != action.filter;
                return action.force || isNewFilter || !(storeState?.baseObjects?.units?.items?.length > 0);
            }),
            exhaustMap(([action, storeState]: [BaseObjectActions.LoadBaseUnitsAction, AppState]) => {
                return this.baseObjectsService
                    .getUnits(action.filter, 1, action.withoutLoading)
                    .pipe(
                        map((res: any) => new BaseObjectActions.LoadBaseUnitsSuccessAction(res)),
                        catchError((error) => of(new BaseObjectActions.LoadBaseUnitsFailAction(error)))
                    )
            }
            )
        )
    );
    loadUnitsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseObjectActions.LOAD_BASE_UNITS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [BaseObjectActions.LoadBaseUnitsPageAction, AppState]) => {
                return storeState?.baseObjects?.units?.paging?.currentPage != 0 && (!action.page || storeState?.baseObjects?.units?.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [BaseObjectActions.LoadBaseUnitsPageAction, AppState]) => {
                let page = !action.page ? storeState.baseObjects.units.paging.currentPage + 1 : action.page;
                let currentFilter = storeState.baseObjects.units.paging.filter;
                return this.baseObjectsService
                    .getUnits(currentFilter, page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<ITrainingUnit>) => new BaseObjectActions.LoadBaseUnitsPageSuccessAction(pagingResult)),
                        catchError((error) => of(new BaseObjectActions.LoadBaseUnitsPageFailAction(error)))
                    )
            })
        )
    );
    importUnit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseObjectActions.IMPORT_BASE_UNIT),
            exhaustMap((action: BaseObjectActions.ImportBaseUnitAction) =>
                this.baseObjectsService
                    .importUnits(action.ids)
                    .pipe(
                        map((res: any) => {
                            return new BaseObjectActions.ImportBaseUnitSuccessAction(action.ids);
                        }),
                        catchError((error) => of(new BaseObjectActions.ImportBaseUnitFailAction(error)))
                    )
            )
        )
    );



    loadPlans$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseObjectActions.LOAD_BASE_PLANS),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [BaseObjectActions.LoadBasePlansAction, AppState]) => {
                return action.force || !(storeState?.baseObjects?.plans?.items?.length > 0);
            }),
            exhaustMap(([action, storeState]: [BaseObjectActions.LoadBasePlansAction, AppState]) => {
                return this.baseObjectsService
                    .getPlans()
                    .pipe(
                        map((res: any) => new BaseObjectActions.LoadBasePlansSuccessAction(res)),
                        catchError((error) => of(new BaseObjectActions.LoadBasePlansFailAction(error)))
                    )
            }
            )
        )
    );
    loadPlansPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseObjectActions.LOAD_BASE_PLANS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [BaseObjectActions.LoadBasePlansPageAction, AppState]) => {
                return storeState?.baseObjects?.plans?.paging?.currentPage != 0 && (!action.page || storeState?.baseObjects?.plans?.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [BaseObjectActions.LoadBasePlansPageAction, AppState]) => {
                let page = !action.page ? storeState.baseObjects.plans.paging.currentPage + 1 : action.page;
                return this.baseObjectsService
                    .getPlans(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<ITrainingPlan>) => new BaseObjectActions.LoadBasePlansPageSuccessAction(pagingResult)),
                        catchError((error) => of(new BaseObjectActions.LoadBasePlansPageFailAction(error)))
                    )
            })
        )
    );
    importPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseObjectActions.IMPORT_BASE_PLAN),
            exhaustMap((action: BaseObjectActions.ImportBasePlanAction) =>
                this.baseObjectsService
                    .importPlans(action.ids)
                    .pipe(
                        map((res: any) => {
                            return new BaseObjectActions.ImportBasePlanSuccessAction();
                        }),
                        catchError((error) => of(new BaseObjectActions.ImportBasePlanFailAction(error)))
                    )
            )
        )
    );



    loadBodyDatas$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseObjectActions.LOAD_BASE_BODY_DATAS),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [BaseObjectActions.LoadBaseBodyDatasAction, AppState]) => {
                return action.force || !(storeState?.baseObjects?.bodyDatas?.items?.length > 0);
            }),
            exhaustMap(([action, storeState]: [BaseObjectActions.LoadBaseBodyDatasAction, AppState]) => {
                return this.baseObjectsService
                    .getBodyDatas()
                    .pipe(
                        map((res: any) => new BaseObjectActions.LoadBaseBodyDatasSuccessAction(res)),
                        catchError((error) => of(new BaseObjectActions.LoadBaseBodyDatasFailAction(error)))
                    )
            }
            )
        )
    );
    loadBodyDatasPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseObjectActions.LOAD_BASE_BODY_DATAS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [BaseObjectActions.LoadBaseBodyDatasPageAction, AppState]) => {
                return storeState?.baseObjects?.bodyDatas?.paging?.currentPage != 0 && (!action.page || storeState?.baseObjects?.bodyDatas?.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [BaseObjectActions.LoadBaseBodyDatasPageAction, AppState]) => {
                let page = !action.page ? storeState.baseObjects.bodyDatas.paging.currentPage + 1 : action.page;
                return this.baseObjectsService
                    .getBodyDatas(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<IBodyData>) => new BaseObjectActions.LoadBaseBodyDatasPageSuccessAction(pagingResult)),
                        catchError((error) => of(new BaseObjectActions.LoadBaseBodyDatasPageFailAction(error)))
                    )
            })
        )
    );
    importBodyData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseObjectActions.IMPORT_BASE_BODY_DATA),
            exhaustMap((action: BaseObjectActions.ImportBaseBodyDataAction) =>
                this.baseObjectsService
                    .importBodyDatas(action.ids)
                    .pipe(
                        map((res: any) => {
                            return new BaseObjectActions.ImportBaseBodyDataSuccessAction(action.ids);
                        }),
                        catchError((error) => of(new BaseObjectActions.ImportBaseBodyDataFailAction(error)))
                    )
            )
        )
    );



    loadMacros$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseObjectActions.LOAD_BASE_MACROS),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [BaseObjectActions.LoadBaseMacrosAction, AppState]) => {
                return action.force || !(storeState?.baseObjects?.macros?.items?.length > 0);
            }),
            exhaustMap(([action, storeState]: [BaseObjectActions.LoadBaseMacrosAction, AppState]) => {
                return this.baseObjectsService
                    .getMacros()
                    .pipe(
                        map((res: any) => new BaseObjectActions.LoadBaseMacrosSuccessAction(res)),
                        catchError((error) => of(new BaseObjectActions.LoadBaseMacrosFailAction(error)))
                    )
            }
            )
        )
    );
    loadMacrosPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseObjectActions.LOAD_BASE_MACROS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [BaseObjectActions.LoadBaseMacrosPageAction, AppState]) => {
                return storeState?.baseObjects?.macros?.paging?.currentPage != 0 && (!action.page || storeState?.baseObjects?.macros?.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [BaseObjectActions.LoadBaseMacrosPageAction, AppState]) => {
                let page = !action.page ? storeState.baseObjects.macros.paging.currentPage + 1 : action.page;
                return this.baseObjectsService
                    .getMacros(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<IMacro>) => new BaseObjectActions.LoadBaseMacrosPageSuccessAction(pagingResult)),
                        catchError((error) => of(new BaseObjectActions.LoadBaseMacrosPageFailAction(error)))
                    )
            })
        )
    );
    importMacro$ = createEffect(() =>
        this.actions$.pipe(
            ofType(BaseObjectActions.IMPORT_BASE_MACRO),
            exhaustMap((action: BaseObjectActions.ImportBaseMacroAction) =>
                this.baseObjectsService
                    .importMacros(action.ids)
                    .pipe(
                        map((res: any) => {
                            return new BaseObjectActions.ImportBaseMacroSuccessAction(action.ids);
                        }),
                        catchError((error) => of(new BaseObjectActions.ImportBaseMacroFailAction(error)))
                    )
            )
        )
    );



}

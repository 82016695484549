import { IDocument } from '@models/document.model';
import { IPagingResult } from '@models/paging.model';
import { Action } from '@ngrx/store';

export const S3_UPLOAD_DOCUMENT = '[Document] S3 Upload Document';
export const S3_UPLOAD_DOCUMENT_SUCCESS = '[Document] S3 Upload Document Success';
export const S3_UPLOAD_DOCUMENT_FAIL = '[Document] S3 Upload Document Fail';
export const ADD_DOCUMENT = '[Document] Add Document';
export const ADD_DOCUMENT_SUCCESS = '[Document] Add Document Success';
export const ADD_DOCUMENT_FAIL = '[Document] Add Document Fail';
export const REMOVE_DOCUMENT = '[Document] Remove Document';
export const REMOVE_DOCUMENT_SUCCESS = '[Document] Remove Document Success';
export const REMOVE_DOCUMENT_FAIL = '[Document] Remove Document Fail';
export const LOAD_DOCUMENTS = '[Document] Load Documents';
export const LOAD_DOCUMENTS_SUCCESS = '[Document] Load Documents Success';
export const LOAD_DOCUMENTS_FAIL = '[Document] Load Documents Fail';
export const LOAD_DOCUMENTS_PAGE = '[Document] Load Documents Page';
export const LOAD_DOCUMENTS_PAGE_SUCCESS = '[Document] Load Documents Page Success';
export const LOAD_DOCUMENTS_PAGE_FAIL = '[Document] Load Documents Page Fail';


/**
 * Interfaces 
 */
export interface IS3UploadDocumentModel {
    dataUrl: string,
    name: string
}

/**
 * S3 Upload Document Actions
 */
export class S3UploadDocumentAction implements Action {
    readonly type = S3_UPLOAD_DOCUMENT;
    constructor(public payload: IS3UploadDocumentModel) { }
}
export class S3UploadDocumentSuccessAction implements Action {
    readonly type = S3_UPLOAD_DOCUMENT_SUCCESS;
    constructor(public payload: any) { }
}
export class S3UploadDocumentFailAction implements Action {
    readonly type = S3_UPLOAD_DOCUMENT_FAIL;
    constructor(public payload: any) { }
}

/**
 * Add Document Actions
 */
export class AddDocumentAction implements Action {
    readonly type = ADD_DOCUMENT;
    constructor(public payload: IDocument) { }
}
export class AddDocumentSuccessAction implements Action {
    readonly type = ADD_DOCUMENT_SUCCESS;
    constructor(public payload: IDocument) { }
}
export class AddDocumentFailAction implements Action {
    readonly type = ADD_DOCUMENT_FAIL;
    constructor(public payload: any) { }
}

/**
 * Remove Document Actions
 */
export class RemoveDocumentAction implements Action {
    readonly type = REMOVE_DOCUMENT;
    constructor(public payload: IDocument) { }
}
export class RemoveDocumentSuccessAction implements Action {
    readonly type = REMOVE_DOCUMENT_SUCCESS;
    constructor(public payload: IDocument) { }
}
export class RemoveDocumentFailAction implements Action {
    readonly type = REMOVE_DOCUMENT_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load Documents Actions
 */
export class LoadDocumentsAction implements Action {
    readonly type = LOAD_DOCUMENTS;
}
export class LoadDocumentsSuccessAction implements Action {
    readonly type = LOAD_DOCUMENTS_SUCCESS;
    constructor(public payload: IPagingResult<IDocument>) { }
}
export class LoadDocumentsFailAction implements Action {
    readonly type = LOAD_DOCUMENTS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Documents Page Actions
 */
export class LoadDocumentsPageAction implements Action {
    readonly type = LOAD_DOCUMENTS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadDocumentsPageSuccessAction implements Action {
    readonly type = LOAD_DOCUMENTS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<IDocument>) { }
}
export class LoadDocumentsPageFailAction implements Action {
    readonly type = LOAD_DOCUMENTS_PAGE_FAIL;
    constructor(public payload: any) { }
}
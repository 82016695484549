import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ITag } from '@models/progress.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '@services/ui.service';
import { AppReducers } from 'app/core/store';
import { ProgressActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-tags-modal',
  templateUrl: './tags-modal.component.html',
  styleUrls: ['./tags-modal.component.scss'],
})
export class TagsModalComponent implements OnInit, OnDestroy {
  @Input() activeTags: Array<ITag> = [];

  tags$: Observable<Array<ITag>>;

  newTagForm: UntypedFormGroup;

  currentActiveTags: Array<ITag>;

  isAddForm = false;
  subs: Subscription[] = [];


  constructor(
    private modalController: ModalController,
    private uiService: UIService,
    private formBuilder: UntypedFormBuilder,
    private actions$: Actions,
    private translate: TranslateService,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.store.dispatch(new ProgressActions.LoadTagsAction());
    this.tags$ = this.store.select(AppReducers.getTagsCollection);

    this.createForm();

    this.currentActiveTags = [...this.activeTags || []];

    this.subscribeToTagActions();
  }

  subscribeToTagActions() {
    this.subs[this.subs.length] = this.actions$.pipe(ofType(ProgressActions.ADD_TAG_SUCCESS))
      .subscribe((newTag: any) => {
        this.currentActiveTags.push(newTag.payload)

        this.toggleForm();
      })
  }

  checkIfTagActive(tag) {
    return this.currentActiveTags.some(x => x.id == tag.id);
  }

  createForm() {
    this.newTagForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    })
  }

  onSubmit() {
    if (this.newTagForm.valid) {
      const newTag = {
        name: this.newTagForm.get("name").value
      }

      this.store.dispatch(new ProgressActions.AddTagAction(newTag));
    }
  }

  onSelectTag(event: CustomEvent) {
    const tag = event.detail.value;
    if (event.detail.checked) {
      this.currentActiveTags.push(tag);
    } else {
      this.currentActiveTags = this.currentActiveTags.filter(el => { return (el.id != tag.id); });
    }
  }

  onClickDeleteTag(id: number, name: string) {
    this.uiService.confirmModal(this.translate.instant("base.modal.confirm_title"), this.translate.instant("base.modal.delete_text", { name })).then(res => {
      if (res) {
        this.deleteTag(id);
      }
    })
  }

  async deleteTag(id: number) {
    this.store.dispatch(new ProgressActions.RemoveTagAction(id));
    await this.modalController.dismiss({ delete: id });
  }

  async onClickCloseModal() {
    await this.modalController.dismiss();
  }

  async onSaveTags() {
    await this.modalController.dismiss({ change: this.currentActiveTags });
  }

  toggleForm() {
    this.newTagForm.reset();
    this.isAddForm = !this.isAddForm;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

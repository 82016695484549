import { Component, EventEmitter, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { IResetPasswordForm } from '@models/authentication.model';
import { AppValidators } from "@validators/validators";

@Component({
    selector: 'reset-password-form',
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./reset-password-form.component.scss'],
})

export class ResetPasswordFormComponent {

    @Output() onSubmit = new EventEmitter<IResetPasswordForm>();

    showPassword: boolean;
    resetPasswordForm: UntypedFormGroup;

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    ngOnInit() {
        this.resetPasswordForm = this.formBuilder.group({
            email: ['', [Validators.required, AppValidators.Email]],
            // password: ['', [Validators.required, Validators.minLength(1)]],
            // passwordRepeat: ['', [Validators.required, Validators.minLength(1)]]
        });
    }

    onSubmitClicked(): void {
        if (this.resetPasswordForm.valid) {
            this.onSubmit.emit({ ...this.resetPasswordForm.value });
        }
    }

}

import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-training-plan-sheet-units',
  templateUrl: './training-plan-sheet-units.component.html',
  styleUrls: ['./training-plan-sheet-units.component.scss'],
})
export class TrainingPlanSheetUnitsComponent implements OnInit {
  selectedSegment = 0;

  constructor(private modalController: ModalController) { }

  ngOnInit() { }

  close() {
    this.modalController.dismiss();
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.next-button {
  margin-top: 10px;
  margin-bottom: 40px;
}

ion-item {
  max-width: 200px;
}

.invite-input-title {
  margin-top: 15px;
}

.invite-desc {
  margin-top: 20px;
  margin-bottom: 20px;
}

.invite-block {
  margin-bottom: 50px;
}

.back {
  margin-bottom: 15px;
  font-size: 14px;
}

app-coach-block {
  margin-bottom: 10px;
  display: flex;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/pages/invite-code/invite-code.page.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,mBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,mBAAA;EACA,eAAA;AACJ;;AAEA;EACI,mBAAA;EACA,aAAA;AACJ","sourcesContent":[".next-button {\n    margin-top: 10px;\n    margin-bottom: 40px;\n}\n\nion-item {\n    max-width: 200px;\n}\n\n.invite-input-title {\n    margin-top: 15px;\n}\n\n.invite-desc {\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n.invite-block {\n    margin-bottom: 50px;\n}\n\n.back {\n    margin-bottom: 15px;\n    font-size: 14px;\n}\n\napp-coach-block {\n    margin-bottom: 10px;\n    display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

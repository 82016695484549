import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { NoteActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-notes-infinity-scroll',
  templateUrl: './notes-infinity-scroll.component.html',
  styleUrls: ['./notes-infinity-scroll.component.scss'],
})
export class NotesInfinityScrollComponent {
  selectPaging = AppReducers.getNotePaging;
  loadPageSuccessAction: string = NoteActions.LOAD_NOTES_PAGE_SUCCESS;
  loadPageFailAction: string = NoteActions.LOAD_NOTES_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  loadPageAction() {
    this.store.dispatch(new NoteActions.LoadNotesPageAction());
  }
}

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-categories-infinity-scroll',
  templateUrl: './categories-infinity-scroll.component.html',
  styleUrls: ['./categories-infinity-scroll.component.scss'],
})
export class CategoriesInfinityScrollComponent {
  selectPaging = AppReducers.getTrainingCategoriesPaging;
  loadPageSuccessAction: string = TrainingActions.LOAD_CATEGORIES_PAGE_SUCCESS;
  loadPageFailAction: string = TrainingActions.LOAD_CATEGORIES_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  loadPageAction() {
    this.store.dispatch(new TrainingActions.LoadCategoriesPageAction());
  }
}

import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UIService } from '@services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { ModalController, NavController } from '@ionic/angular';
import { UserRole } from '@models/authentication.model';
import { Store } from '@ngrx/store';
import { AppState } from '../store/app.states';
import { AppReducers } from '../store';
import { LocalStorageService } from '@services/local-storage.service';
import { AuthService } from '@services/auth.service';
import { ILoginRes } from '@models/login.model';

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {
    private isRefreshing = false;
    private refreshTokenSubject = new BehaviorSubject<ILoginRes>(null);

    constructor(private uiService: UIService,
        private navCtrl: NavController,
        private modalController: ModalController,
        private store: Store<AppState>,
        private translate: TranslateService,
        private localStorageService: LocalStorageService,
        private injector: Injector,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => this.errorHandler(request, next, error)));
    }

    addToken(request: HttpRequest<any>, currentToken: string): HttpRequest<any> {
        let newHeaders = new HttpHeaders();
        for (const key of request.headers.keys()) {
            newHeaders = newHeaders.set(key, request.headers.getAll(key));
        }
        newHeaders = newHeaders.set('Authorization', `Bearer ${currentToken}`);
        return request.clone({ headers: newHeaders });
    }

    // Customize the default error handler here if needed
    private errorHandler(request: HttpRequest<any>, next: HttpHandler, response: HttpErrorResponse): Observable<HttpEvent<any>> {
        if (!environment.production) {
            // Do something with the error (only for DEV environment)
        }

        const auth = this.injector.get(AuthService);

        let urlSections = request.url.split('/');
        let lastUrlSection = urlSections[urlSections.length - 1];

        if (response.status == 0) {
            throw response;
        }

        if (lastUrlSection == 'refresh') {
            this.isRefreshing = false;
            auth.logout();

            // after refresh token, still get 401
            if(response.status == 401) {
                this.navCtrl.navigateRoot('/login', { animated: true, animationDirection: 'forward' });
                throw response;
            }
        } else if (response.status == 423) {
            this.handleHttpCode423();
        } else if (response.status != 401 || lastUrlSection == 'login') {
            this.showErrorMessage(response?.error);
            throw response;
        }

        if (this.isRefreshing) {
            return this.refreshTokenSubject
                .pipe(
                    filter(result => result != null),
                    take(1),
                    switchMap((resp: ILoginRes) => {
                        return next.handle(this.addToken(request, resp.access_token));
                    })
                );
        } else {
            let token = this.localStorageService.getRefreshToken();

            if (token) {
                this.isRefreshing = true;
                this.refreshTokenSubject.next(null);
                return auth
                    .refreshToken()
                    .pipe(
                        switchMap((resp: ILoginRes) => {
                            this.isRefreshing = false;
                            this.refreshTokenSubject.next(resp);
                            return next.handle(this.addToken(request, resp.access_token));
                        }),
                        catchError(error => {                            
                            if(error.status == 423) {
                                this.handleHttpCode423();
                                throw error;
                            } else {
                                auth.logout();
                                this.navCtrl.navigateRoot('/login', { animated: true, animationDirection: 'forward' });
                                throw error;
                            }
                        })
                    );
            } else {
                if (response.status != 401 && lastUrlSection == 'login') {
                    this.showErrorMessage(response?.error);
                }
                auth.logout();
                throw response;
            }
        }

    }

    showErrorMessage(error) {
        let message = error?.message;

        if (error?.error && Array.isArray(error?.error) && error?.error.length > 0) {
            error?.error.forEach(errorDetail => {
                message += '\r\n• ' + errorDetail.message;
            });
        }

        this.uiService.showMessage(message || this.translate.instant('messages.server_error'), 'danger', 5000);
    }

    handleHttpCode423() {
        let sub = this.store.select(AppReducers.getUserRole)
            .pipe(take(1))
            .subscribe(userRole => {
                let urlPrefix: string;
                switch (userRole) {
                    case UserRole.coach:
                        urlPrefix = '/coach/more';
                        break;
                    case UserRole.athlete:
                        urlPrefix = '/athlete/more';
                        break;
                }
                // TODO: does not close all modals
                // this.modalController.dismiss();

                this.navCtrl.navigateRoot(`${urlPrefix}/subscription`, { animated: true, animationDirection: 'forward' });
            });
    }
}

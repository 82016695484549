// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.right-icon {
  margin-left: 10px;
  font-size: 22px;
  position: relative;
  top: 1px;
}

ion-segment {
  --background: var(--ons-color-light-grey);
  border-radius: 40px;
  height: 40px;
}
ion-segment ion-segment-button {
  font-weight: 700;
  --border-radius: 40px;
  --indicator-box-shadow: none;
  --border-color: transparent;
}

.top-title p {
  margin: 0;
}

.input-item {
  --border-width: 0 0 1px 0;
}

ion-icon {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/body-data-form/body-data-form.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,QAAA;AACJ;;AACA;EACI,yCAAA;EACA,mBAAA;EACA,YAAA;AAEJ;AADI;EACI,gBAAA;EACA,qBAAA;EACA,4BAAA;EACA,2BAAA;AAGR;;AACI;EACI,SAAA;AAER;;AACA;EACI,yBAAA;AAEJ;;AACA;EACI,eAAA;AAEJ","sourcesContent":[".right-icon {\n    margin-left: 10px;\n    font-size: 22px;\n    position: relative;\n    top: 1px;\n}\nion-segment {\n    --background: var(--ons-color-light-grey);\n    border-radius: 40px;\n    height: 40px;\n    ion-segment-button {\n        font-weight: 700;\n        --border-radius: 40px;\n        --indicator-box-shadow: none;\n        --border-color: transparent;\n    }\n}\n.top-title {\n    p {\n        margin: 0;\n    }\n}\n.input-item {\n    --border-width: 0 0 1px 0;\n}\n\nion-icon {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

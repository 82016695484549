import { INITIAL_PAGING, IPaging, IPagingResult } from '@models/paging.model';
import { ITechnique, ITrainingCategory, ITrainingPlan, ITrainingPlanSheet, ITrainingTemplate, ITrainingUnit } from '@models/training.model';
import { AuthActions, BaseObjectActions, TrainingActions } from "../actions";

export interface State {
    plans: PlansState;
    planCopies: PlansState;
    units: UnitsState;
    unitCopies: UnitsState;
    categories: CategoriesState;
    techniques: TechniqueState;
    sheets: SheetState;
    templates: TemplateState;
}
export interface PlansState {
    loaded: boolean;
    loading: boolean;
    items: ITrainingPlan[];
    paging: IPaging;
}
export interface UnitsState {
    loaded: boolean;
    loading: boolean;
    items: ITrainingUnit[];
    paging: IPaging;
}
export interface CategoriesState {
    loaded: boolean;
    loading: boolean;
    items: ITrainingCategory[];
    paging: IPaging;
}
export interface TechniqueState {
    loaded: boolean;
    loading: boolean;
    items: ITechnique[];
    paging: IPaging;
}
export interface SheetState {
    loaded: boolean;
    loading: boolean;
    items: ITrainingPlanSheet[];
}
export interface TemplateState {
    loaded: boolean;
    loading: boolean;
    items: ITrainingTemplate[];
    paging: IPaging;
}

const initialSubState = {
    loaded: false,
    loading: false,
    items: [],
    paging: INITIAL_PAGING
}
const initialState: State = {
    plans: initialSubState,
    planCopies: initialSubState,
    units: initialSubState,
    unitCopies: initialSubState,
    categories: initialSubState,
    techniques: initialSubState,
    sheets: initialSubState,
    templates: initialSubState
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
        case TrainingActions.LOAD_PLANS:
        case TrainingActions.LOAD_PLANS_PAGE: {
            return Object.assign({}, state, {
                plans: {
                    ...state.plans,
                    loading: true
                }
            });
        }
        case TrainingActions.LOAD_PLANS_FAIL:
        case TrainingActions.LOAD_PLANS_PAGE_FAIL: {
            return Object.assign({}, state, {
                plans: {
                    ...state.plans,
                    loading: false
                }
            });
        }
        case TrainingActions.LOAD_PLANS_SUCCESS: {
            let resp: IPagingResult<ITrainingPlan> = action.payload;

            return Object.assign({}, state, {
                plans: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: resp.items,
                    paging: resp.paging
                }
            });
        }
        case TrainingActions.LOAD_PLANS_PAGE_SUCCESS: {
            let resp: IPagingResult<ITrainingPlan> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.plans.items, ...resp.items];

            return Object.assign({}, state, {
                plans: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }

        case TrainingActions.LOAD_PLAN_SUCCESS: {
            const plan = action.payload;

            if (state.plans.items.findIndex(x => x.id == plan.id) > -1) {
                return Object.assign({}, state, {
                    plans: {
                        ...state.plans,
                        items: state.plans.items.map(obj => obj.id == plan.id ? plan : obj)
                    }
                });
            }

            return Object.assign({}, state, {
                plans: {
                    ...state.plans,
                    items: [plan, ...state.plans.items]
                }
            });
        }
        case TrainingActions.ADD_PLAN_SUCCESS:
        case TrainingActions.COPY_PLAN_SUCCESS: {
            const plan = action.payload;

            if (state.plans.items.findIndex(x => x.id == plan.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                plans: {
                    ...state.plans,
                    items: [plan, ...state.plans.items]
                }
            });
        }
        case TrainingActions.EDIT_PLAN_SUCCESS: {
            const plan = action.payload;

            if (!state.plans.items.some(x => x.id == plan.id)) {
                return state;
            }

            return Object.assign({}, state, {
                plans: {
                    ...state.plans,
                    items: state.plans.items.map(obj => obj.id == plan.id ? plan : obj)
                }
            });
        }
        case TrainingActions.REMOVE_PLAN_SUCCESS: {
            const removedPlan = action.payload;

            return Object.assign({}, state, {
                plans: {
                    ...state.plans,
                    items: state.plans.items.filter(plan => plan.id !== removedPlan.id)
                }
            });
        }

        case TrainingActions.LOAD_PLAN_COPY_SUCCESS: {
            const planCopy = action.payload;

            if (state.planCopies.items.findIndex(x => x.id == planCopy.id) > -1) {
                return Object.assign({}, state, {
                    planCopies: {
                        ...state.planCopies,
                        items: state.planCopies.items.map(obj => obj.id == planCopy.id ? planCopy : obj)
                    }
                });
            }

            return Object.assign({}, state, {
                planCopies: {
                    ...state.planCopies,
                    items: [planCopy, ...state.planCopies.items]
                }
            });
        }
        case TrainingActions.EDIT_PLAN_COPY_SUCCESS: {
            const planCopy = action.payload;

            if (!state.planCopies.items.some(x => x.id == planCopy.id)) {
                return state;
            }

            return Object.assign({}, state, {
                planCopies: {
                    ...state.planCopies,
                    items: state.planCopies.items.map(obj => obj.id == planCopy.id ? planCopy : obj)
                }
            });
        }
        case TrainingActions.REMOVE_PLAN_COPY_SUCCESS: {
            const removedPlanCopy = action.payload;

            return Object.assign({}, state, {
                planCopies: {
                    ...state.planCopies,
                    items: state.planCopies.items.filter(obj => obj.id !== removedPlanCopy.id)
                }
            });
        }


        case TrainingActions.LOAD_UNITS:
        case TrainingActions.LOAD_UNITS_PAGE: {
            return Object.assign({}, state, {
                units: {
                    ...state.units,
                    loading: true
                }
            });
        }
        case TrainingActions.LOAD_UNITS_FAIL:
        case TrainingActions.LOAD_UNITS_PAGE_FAIL: {
            return Object.assign({}, state, {
                units: {
                    ...state.units,
                    loading: false
                }
            });
        }
        case TrainingActions.LOAD_UNITS_SUCCESS: {
            let resp: IPagingResult<ITrainingUnit> = action.payload;

            return Object.assign({}, state, {
                units: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: resp.items,
                    paging: resp.paging
                }
            });
        }
        case TrainingActions.LOAD_UNITS_PAGE_SUCCESS: {
            let resp: IPagingResult<ITrainingUnit> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.units.items, ...resp.items];

            return Object.assign({}, state, {
                units: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }
        case TrainingActions.ADD_UNIT_SUCCESS: {
            const unit = action.payload;

            if (state.units.items.findIndex(x => x.id == unit.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                units: {
                    ...state.units,
                    items: [unit, ...state.units.items]
                }
            });
        }
        case TrainingActions.EDIT_UNIT_SUCCESS: {
            const unit = action.payload;
            
            // if (!state.units.items.some(x => x.id == unit.id)) {
            //     return state;
            // }

            let updatedUnitCopies = [];
            state.unitCopies.items.forEach(unitCopy => {
                if (unitCopy.id == unit.id) {
                    unitCopy = {
                        ...unit,
                        unit_data: unitCopy.unit_data
                    };
                }
                updatedUnitCopies.push(unitCopy);
            });

            let updatedSheets = [];
            state.sheets.items.forEach(sheet => {
                sheet = {
                    ...sheet,
                    units: sheet.units.map(x => {
                            if(x.id == unit.id) {
                                if(x.unit_data.id == unit.unit_data.id) {
                                    return { ...x, name: unit.name, unit_data: unit.unit_data }    
                                }
                                return { ...x, name: unit.name }    
                            }
                            return x;
                        }
                    )
                };
                updatedSheets.push(sheet);
            });

            let updatedTemplates = [];
            state.templates.items.forEach(template => {
                template = {
                    ...template,
                    units: template.units.map(x => {
                            if(x.id == unit.id) {
                                if(x.unit_data.id == unit.unit_data.id) {
                                    return { ...x, name: unit.name, unit_data: unit.unit_data }    
                                }
                                return { ...x, name: unit.name }    
                            }
                            return x;
                        }
                    )
                };
                updatedTemplates.push(template);
            });

            return Object.assign({}, state, {
                units: {
                    ...state.units,
                    items: state.units.items.map(obj => obj.id == unit.id ? unit : obj)
                },
                unitCopies: {
                    ...state.unitCopies,
                    items: updatedUnitCopies
                },
                sheets: {
                    ...state.sheets,
                    items: updatedSheets
                },
                templates: {
                    ...state.templates,
                    items: updatedTemplates
                }
            });
        }
        case TrainingActions.REMOVE_UNIT_SUCCESS: {
            const removedUnit = action.payload;

            let updatedSheets = [];
            state.sheets.items.forEach(sheet => {
                sheet = {
                    ...sheet,
                    units: sheet.units.filter(x => x.id != removedUnit.id)
                };
                updatedSheets.push(sheet);
            });

            let updatedTemplates = [];
            state.templates.items.forEach(template => {
                template = {
                    ...template,
                    units: template.units.filter(x => x.id != removedUnit.id)
                };
                updatedTemplates.push(template);
            });

            return Object.assign({}, state, {
                units: {
                    ...state.units,
                    items: state.units.items.filter(unit => unit.id !== removedUnit.id)
                },
                unitCopies: {
                    ...state.unitCopies,
                    items: state.unitCopies.items.filter(x => x.id != removedUnit.id)
                },
                sheets: {
                    ...state.sheets,
                    items: updatedSheets
                },
                templates: {
                    ...state.templates,
                    items: updatedTemplates
                }
            });
        }

        case TrainingActions.LOAD_UNIT_COPY_SUCCESS: {
            const unitCopy = action.payload;

            if (state.unitCopies.items.findIndex(x => x.unit_data?.id == unitCopy.unit_data?.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                unitCopies: {
                    ...state.unitCopies,
                    items: [unitCopy, ...state.unitCopies.items]
                }
            });
        }
        case TrainingActions.EDIT_UNIT_COPY_SUCCESS: {
            const unitCopy = action.payload;
            
            // if (!state.unitCopies.items.some(x => x.id == unitCopy.id)) {
            //     return state;
            // }

            return Object.assign({}, state, {
                unitCopies: {
                    ...state.unitCopies,
                    items: state.unitCopies.items.map(obj => obj.unit_data?.id == unitCopy.unit_data?.id ? unitCopy : obj)
                },
                sheets: {
                    ...state.sheets,
                    items: state.sheets.items.map(obj => Object.assign({}, obj, {
                        units: obj.units.map(unit => unit.unit_data?.id == unitCopy.unit_data?.id ? unitCopy : unit)
                    }))
                },
                templates: {
                    ...state.templates,
                    items: state.templates.items.map(obj => Object.assign({}, obj, {
                        units: obj.units.map(unit => unit.unit_data?.id == unitCopy.unit_data?.id ? unitCopy : unit)
                    }))
                }
            });
        }
        case TrainingActions.REMOVE_UNIT_COPY_SUCCESS: {
            const removedUnitCopy = action.payload;

            return Object.assign({}, state, {
                unitCopies: {
                    ...state.unitCopies,
                    items: state.unitCopies.items.filter(unit => unit?.unit_data?.id !== removedUnitCopy?.unit_data?.id)
                },
                sheets: {
                    ...state.sheets,
                    items: state.sheets.items.map(obj => Object.assign({}, obj, {
                        units: obj.units.filter(unit => unit?.unit_data?.id !== removedUnitCopy?.unit_data?.id)
                    }))
                },
                templates: {
                    ...state.templates,
                    items: state.templates.items.map(obj => Object.assign({}, obj, {
                        units: obj.units.filter(unit => unit?.unit_data?.id !== removedUnitCopy?.unit_data?.id)
                    }))
                }
            });
        }

        case TrainingActions.LOAD_CATEGORIES:
        case TrainingActions.LOAD_CATEGORIES_PAGE: {
            return Object.assign({}, state, {
                categories: {
                    ...state.categories,
                    loading: true
                }
            });
        }
        case TrainingActions.LOAD_CATEGORIES_FAIL:
        case TrainingActions.LOAD_CATEGORIES_PAGE_FAIL: {
            return Object.assign({}, state, {
                categories: {
                    ...state.categories,
                    loading: false
                }
            });
        }
        case TrainingActions.LOAD_CATEGORIES_SUCCESS: {
            let resp: IPagingResult<ITrainingCategory> = action.payload;

            return Object.assign({}, state, {
                categories: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: resp.items,
                    paging: resp.paging
                }
            });
        }
        case TrainingActions.LOAD_CATEGORIES_PAGE_SUCCESS: {
            let resp: IPagingResult<ITrainingCategory> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.categories.items, ...resp.items];

            return Object.assign({}, state, {
                categories: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }

        case TrainingActions.ADD_CATEGORY_SUCCESS: {
            const category = action.payload;

            if (state.categories.items.findIndex(x => x.id == category.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                categories: {
                    ...state.categories,
                    items: [category, ...state.categories.items]
                }
            });
        }
        case TrainingActions.REMOVE_CATEGORY_SUCCESS: {
            const removedCategory = action.payload;

            let unitItems = state.units.items;
            if (state?.units?.loaded && state?.units?.items?.length > 0) {
                unitItems = state.units.items.map(x =>
                    Object.assign({}, x, {
                        categories: x.categories.filter(category => category.id !== removedCategory.id)
                    })
                )
            }

            return Object.assign({}, state, {
                units: {
                    ...state.units,
                    items: unitItems
                },
                categories: {
                    ...state.categories,
                    items: state.categories.items.filter(category => category.id !== removedCategory.id)
                }
            });
        }



        case TrainingActions.LOAD_TECHNIQUES:
        case TrainingActions.LOAD_TECHNIQUES_PAGE: {
            return Object.assign({}, state, {
                techniques: {
                    ...state.techniques,
                    loading: true
                }
            });
        }
        case TrainingActions.LOAD_TECHNIQUES_FAIL:
        case TrainingActions.LOAD_TECHNIQUES_PAGE_FAIL: {
            return Object.assign({}, state, {
                techniques: {
                    ...state.techniques,
                    loading: false
                }
            });
        }
        case TrainingActions.LOAD_TECHNIQUES_SUCCESS: {
            let resp: IPagingResult<ITechnique> = action.payload;

            return Object.assign({}, state, {
                techniques: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: resp.items,
                    paging: resp.paging
                }
            });
        }
        case TrainingActions.LOAD_TECHNIQUES_PAGE_SUCCESS: {
            let resp: IPagingResult<ITechnique> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.techniques.items, ...resp.items];

            return Object.assign({}, state, {
                techniques: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }
        case TrainingActions.ADD_TECHNIQUE_SUCCESS: {
            const technique = action.payload;

            if (state.techniques.items.findIndex(x => x.id == technique.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                techniques: {
                    ...state.techniques,
                    items: [technique, ...state.techniques.items]
                }
            });
        }
        case TrainingActions.EDIT_TECHNIQUE_SUCCESS: {
            const technique = action.payload;

            if (!state.techniques.items.some(x => x.id == technique.id)) {
                return state;
            }

            return Object.assign({}, state, {
                techniques: {
                    ...state.techniques,
                    items: state.techniques.items.map(obj => obj.id == technique.id ? technique : obj)
                }
            });
        }
        case TrainingActions.REMOVE_TECHNIQUE_SUCCESS: {
            const removedTechnique = action.payload;
            
            return Object.assign({}, state, {
                techniques: {
                    ...state.techniques,
                    items: state.techniques.items.filter(technique => technique.id !== removedTechnique.id)
                }
            });
        }

        case TrainingActions.LOAD_SHEETS: {
            return Object.assign({}, state, {
                sheets: {
                    ...state.sheets,
                    loading: true
                }
            });
        }
        case TrainingActions.LOAD_SHEETS_SUCCESS: {
            const sheets = action.payload;

            return Object.assign({}, state, {
                sheets: {
                    loaded: true,
                    loading: false,
                    items: sheets
                }
            });
        }
        case TrainingActions.LOAD_SHEET_SUCCESS: {
            const sheet = action.payload;

            if (state.sheets.items.findIndex(x => x.id == sheet.id) > -1) {
                return Object.assign({}, state, {
                    sheets: {
                        ...state.sheets,
                        items: state.sheets.items.map(obj => obj.id == sheet.id ? sheet : obj)
                    }
                });
            }

            return Object.assign({}, state, {
                sheets: {
                    ...state.sheets,
                    items: [sheet, ...state.sheets.items]
                }
            });
        }
        case TrainingActions.ADD_SHEET_SUCCESS: {
            const sheet = action.payload;

            if (state.sheets.items.findIndex(x => x.id == sheet.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                sheets: {
                    ...state.sheets,
                    items: [sheet, ...state.sheets.items]
                }
            });
        }
        case TrainingActions.EDIT_SHEET_SUCCESS: {
            const sheet = action.payload;

            if (!sheet) {
                return state;
            }

            let planItems = state.plans.items;

            if (state?.plans?.items?.length > 0) {
                planItems = state.plans.items.map(x =>
                    Object.assign({}, x, {
                        sheet_01: x.sheet_01?.id == sheet.id ? sheet : x.sheet_01,
                        sheet_02: x.sheet_02?.id == sheet.id ? sheet : x.sheet_02,
                        sheet_03: x.sheet_03?.id == sheet.id ? sheet : x.sheet_03,
                        sheet_04: x.sheet_04?.id == sheet.id ? sheet : x.sheet_04,
                        sheet_05: x.sheet_05?.id == sheet.id ? sheet : x.sheet_05,
                        sheet_06: x.sheet_06?.id == sheet.id ? sheet : x.sheet_06,
                        sheet_07: x.sheet_07?.id == sheet.id ? sheet : x.sheet_07,
                        sheet_08: x.sheet_08?.id == sheet.id ? sheet : x.sheet_08,
                        sheet_09: x.sheet_09?.id == sheet.id ? sheet : x.sheet_09,
                        sheet_10: x.sheet_10?.id == sheet.id ? sheet : x.sheet_10,
                    })
                )
            }
            let planCopiesItems = state.planCopies.items;
            if (state?.planCopies?.items?.length > 0) {
                planCopiesItems = state.planCopies.items.map(x =>
                    Object.assign({}, x, {
                        sheet_01: x.sheet_01?.id == sheet.id ? sheet : x.sheet_01,
                        sheet_02: x.sheet_02?.id == sheet.id ? sheet : x.sheet_02,
                        sheet_03: x.sheet_03?.id == sheet.id ? sheet : x.sheet_03,
                        sheet_04: x.sheet_04?.id == sheet.id ? sheet : x.sheet_04,
                        sheet_05: x.sheet_05?.id == sheet.id ? sheet : x.sheet_05,
                        sheet_06: x.sheet_06?.id == sheet.id ? sheet : x.sheet_06,
                        sheet_07: x.sheet_07?.id == sheet.id ? sheet : x.sheet_07,
                        sheet_08: x.sheet_08?.id == sheet.id ? sheet : x.sheet_08,
                        sheet_09: x.sheet_09?.id == sheet.id ? sheet : x.sheet_09,
                        sheet_10: x.sheet_10?.id == sheet.id ? sheet : x.sheet_10,
                    })
                )
            }

            return Object.assign({}, state, {
                plans: {
                    ...state.plans,
                    items: planItems
                },
                planCopies: {
                    ...state.planCopies,
                    items: planCopiesItems
                },
                sheets: {
                    ...state.sheets,
                    items: state.sheets.items.map(obj => obj.id == sheet.id ? sheet : obj)
                }
            });
        }
        case TrainingActions.REMOVE_SHEET_SUCCESS: {
            const removedSheetId = action.payload;

            let planItems = state.plans.items;
            if (state?.plans?.items?.length > 0) {
                planItems = state.plans.items.map(x =>
                    Object.assign({}, x, {
                        sheet_01: x.sheet_01?.id == removedSheetId ? null : x.sheet_01,
                        sheet_02: x.sheet_02?.id == removedSheetId ? null : x.sheet_02,
                        sheet_03: x.sheet_03?.id == removedSheetId ? null : x.sheet_03,
                        sheet_04: x.sheet_04?.id == removedSheetId ? null : x.sheet_04,
                        sheet_05: x.sheet_05?.id == removedSheetId ? null : x.sheet_05,
                        sheet_06: x.sheet_06?.id == removedSheetId ? null : x.sheet_06,
                        sheet_07: x.sheet_07?.id == removedSheetId ? null : x.sheet_07,
                        sheet_08: x.sheet_08?.id == removedSheetId ? null : x.sheet_08,
                        sheet_09: x.sheet_09?.id == removedSheetId ? null : x.sheet_09,
                        sheet_10: x.sheet_10?.id == removedSheetId ? null : x.sheet_10,
                    })
                )
            }
            let planCopiesItems = state.planCopies.items;
            if (state?.planCopies?.items?.length > 0) {
                planCopiesItems = state.planCopies.items.map(x =>
                    Object.assign({}, x, {
                        sheet_01: x.sheet_01?.id == removedSheetId ? null : x.sheet_01,
                        sheet_02: x.sheet_02?.id == removedSheetId ? null : x.sheet_02,
                        sheet_03: x.sheet_03?.id == removedSheetId ? null : x.sheet_03,
                        sheet_04: x.sheet_04?.id == removedSheetId ? null : x.sheet_04,
                        sheet_05: x.sheet_05?.id == removedSheetId ? null : x.sheet_05,
                        sheet_06: x.sheet_06?.id == removedSheetId ? null : x.sheet_06,
                        sheet_07: x.sheet_07?.id == removedSheetId ? null : x.sheet_07,
                        sheet_08: x.sheet_08?.id == removedSheetId ? null : x.sheet_08,
                        sheet_09: x.sheet_09?.id == removedSheetId ? null : x.sheet_09,
                        sheet_10: x.sheet_10?.id == removedSheetId ? null : x.sheet_10,
                    })
                )
            }

            return Object.assign({}, state, {
                plans: {
                    ...state.plans,
                    items: planItems
                },
                planCopies: {
                    ...state.planCopies,
                    items: planCopiesItems
                },
                sheets: {
                    ...state.sheets,
                    items: state.sheets.items.filter(sheet => sheet.id !== removedSheetId)
                }
            });
        }

        case TrainingActions.COPY_SHEET_AS_TEMPLATE_SUCCESS: {
            //debugger;
            const template = action.payload;

            //const a = state.templates;

            if (state.units.items.findIndex(x => x.id == template.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                templates: {
                    ...state.templates,
                    items: [template, ...state.templates.items]
                }
            });
        }


        case TrainingActions.LOAD_TEMPLATES:
        case TrainingActions.LOAD_TEMPLATES_PAGE: {
            return Object.assign({}, state, {
                templates: {
                    ...state.templates,
                    loading: true
                }
            });
        }
        case TrainingActions.LOAD_TEMPLATES_FAIL:
        case TrainingActions.LOAD_TEMPLATES_PAGE_FAIL: {
            return Object.assign({}, state, {
                templates: {
                    ...state.templates,
                    loading: false
                }
            });
        }
        case TrainingActions.LOAD_TEMPLATES_SUCCESS: {
            let resp: IPagingResult<ITrainingTemplate> = action.payload;

            return Object.assign({}, state, {
                templates: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: resp.items,
                    paging: resp.paging
                }
            });
        }
        case TrainingActions.LOAD_TEMPLATES_PAGE_SUCCESS: {
            let resp: IPagingResult<ITrainingTemplate> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.templates.items, ...resp.items];

            return Object.assign({}, state, {
                templates: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }
        case TrainingActions.ADD_TEMPLATE_SUCCESS: {
            const template = action.payload;

            if (state.units.items.findIndex(x => x.id == template.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                templates: {
                    ...state.templates,
                    items: [template, ...state.templates.items]
                }
            });
        }
        case TrainingActions.EDIT_TEMPLATE_SUCCESS: {
            const template = action.payload;

            if (!state.templates.items.some(x => x.id == template.id)) {
                return state;
            }

            return Object.assign({}, state, {
                templates: {
                    ...state.templates,
                    items: state.templates.items.map(obj => obj.id == template.id ? template : obj)
                }
            });
        }
        case TrainingActions.REMOVE_TEMPLATE_SUCCESS: {
            const removedTemplateId = action.payload;

            return Object.assign({}, state, {
                templates: {
                    ...state.templates,
                    items: state.templates.items.filter(template => template.id !== removedTemplateId)
                }
            });
        }

        case AuthActions.LOGIN_SUCCESS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}


export const getPlansLoaded = (state: State) => state.plans.loaded;
export const getPlansLoading = (state: State) => state.plans.loading;
export const getPlans = (state: State) => state.plans.items;
export const getPlansPaging = (state: State) => state.plans.paging;
export const getPlan = (state: State, props) => state.plans.items.find(x => x.id == props.id);

export const getPlanCopy = (state: State, props) => state.planCopies.items.find(x => x.id == props.id);

export const getUnitsLoaded = (state: State) => state.units.loaded;
export const getUnitsLoading = (state: State) => state.units.loading;
export const getUnits = (state: State) => state.units.items;
export const getUnitsPaging = (state: State) => state.units.paging;
export const getUnit = (state: State, props) => state.units.items.find(x => x.id == props.id);

export const getUnitCopy = (state: State, props) => state.unitCopies.items.find(x => x.unit_data.id == props.unitDataId);

export const getCategoriesLoaded = (state: State) => state.categories.loaded;
export const getCategoriesLoading = (state: State) => state.categories.loading;
export const getCategories = (state: State) => state.categories.items;
export const getCategoriesPaging = (state: State) => state.categories.paging;

export const getTechniquesLoaded = (state: State) => state.techniques.loaded;
export const getTechniquesLoading = (state: State) => state.techniques.loading;
export const getTechniques = (state: State) => state.techniques.items;
export const getTechniquesPaging = (state: State) => state.techniques.paging;

export const getSheetsLoaded = (state: State) => state.sheets.loaded;
export const getSheetsLoading = (state: State) => state.sheets.loading;
export const getSheets = (state: State) => state.sheets.items;

export const getTemplatesLoaded = (state: State) => state.templates.loaded;
export const getTemplatesLoading = (state: State) => state.templates.loading;
export const getTemplates = (state: State) => state.templates.items;
export const getTemplatesPaging = (state: State) => state.templates.paging;
export const getTemplate = (state: State, props) => state.templates.items.find(x => x.id == props.id);
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalController } from "@ionic/angular";
import { ILoginForm } from "@models/authentication.model";
import { Store } from "@ngrx/store";
import { LocalStorageService } from "@services/local-storage.service";
import { AuthActions } from "app/core/store/actions";
import { AppState } from "app/core/store/app.states";
import { OverviewPoliciesModalComponent } from "app/modules/shared/overview-policies-modal/overview-policies-modal.component";

@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnInit {

  constructor(
    private router: Router,
    private lsService: LocalStorageService,
    private store: Store<AppState>,
    private modalController: ModalController
  ) { }

  ngOnInit() { }

  onLogin({ email, password }: ILoginForm): void {
    if (this.checkPolicies()) {
      this.store.dispatch(
        AuthActions.login({ credentials: { email, password } })
      );
    }
  }

  onForgetPassword() {
    if (this.checkPolicies()) {
      this.router.navigate(["/reset-password"]);
    }
  }

  onSignUp() {
    if (this.checkPolicies()) {
      this.router.navigate(["/select-role"]);
    }
  }

  checkPolicies(): boolean {
    let isEULAAccepted = this.lsService.getEULA();
    let isPolicyAccepted = this.lsService.getPolicy();
    if (!isEULAAccepted || !isPolicyAccepted) {
      this.modalController.create({
        component: OverviewPoliciesModalComponent,
        cssClass: "modal-shadow"
      }).then(modal => {
        modal.present();
      });
      return false;
    }
    else {
      return true;
    }
  }
}

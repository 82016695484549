import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UserRole } from '@models/authentication.model';
import { AuthService } from '@services/auth.service';
import { IAPService } from '@services/iap.service';
import { SUBSCRIPTION_ICONS } from 'app/core/consts/subscription-icons.const';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { IUser } from '@models/user.model';
import { ActivatedRoute } from '@angular/router';
import { UIService } from '@services/ui.service';
import { TranslateService } from '@ngx-translate/core';
import { LoadingController } from '@ionic/angular';
import { AppSubscription } from '@models/app-subscription.model';

@Component({
  selector: 'app-subscription-select',
  templateUrl: './subscription-select.component.html',
  styleUrls: ['./subscription-select.component.scss'],
})
export class SubscriptionSelectComponent implements OnInit, OnDestroy {
  manualSwitched: boolean = false;
  selectedSubIndex: number = 0;
  appSubs: AppSubscription[];
  SUBSCRIPTION_ICONS = SUBSCRIPTION_ICONS;
  subs: Subscription[] = [];
  activeSubId: string;
  activeUntil: string;
  @Output() onSubLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onSelect: EventEmitter<AppSubscription> = new EventEmitter<AppSubscription>();
  loading: HTMLIonLoadingElement;
  isSubscriptionCancelled: boolean = false;

  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private uiService: UIService,
    private translate: TranslateService,
    private loadingController: LoadingController,
    private iapService: IAPService) { }

  ngOnInit() {
    this.route.queryParamMap.subscribe((paramMap) => {
      let type = paramMap.get('type');
      switch (type) {
        case 'success':
          this.uiService.showMessage(this.translate.instant('messages.sub_success'), 'success', 2000);
          break;
        case 'error':
          this.uiService.showMessage(this.translate.instant('messages.sub_error'), 'danger', 2000);
          break;
      }
    })


    let messagePath = 'base.loading';
    let message = this.translate.instant(messagePath);
    this.loadingController.create({
      message: message
    })
      .then(loading => {
        this.loading = loading;
        this.loading.present();
      });

    this.subs[this.subs.length] = this.authService.currentUser$.subscribe(user => {
      if (user) {
        this.activeSubId = user.subscription;
        this.isSubscriptionCancelled = user.is_subscription_cancelled;
        if (user.subscription && user.subscription_until && moment(user.subscription_until).isSameOrAfter(moment(), 'day')) {
          this.activeUntil = moment(user.subscription_until).format('DD.MM.YYYY');
        }
        else {
          this.activeUntil = null;
        }

        switch (user?.role) {
          case UserRole.coach:
            this.subs[this.subs.length] = this.iapService.trainerSubs$.subscribe(data => this.hadleSubsData(user, data));
            break;
          case UserRole.athlete:
            this.subs[this.subs.length] = this.iapService.athleteSubs$.subscribe(data => this.hadleSubsData(user, data));
            break;
        }
      }
    })
  }

  hadleSubsData(user: IUser, subs: AppSubscription[]) {
    if (user.had_trial) {
      subs = this.filterFreeSub(subs);
    }
    this.appSubs = subs;
    if (this.appSubs?.length > 0) {
      this.onSubLoaded.emit(true);
    }
    if (this.loading) {
      this.loading.dismiss();
    }

    if (!this.manualSwitched) {
      this.selectedSubIndex = this.activeSubId ? this.appSubs.findIndex(x => x.id == this.activeSubId) : 0;
      this.selectedSubIndex = this.selectedSubIndex < 0 ? 0 : this.selectedSubIndex;
    }
    this.select();
  }

  selectNext() {
    this.manualSwitched = true;
    this.selectedSubIndex++;
    this.select();
  }
  selectPrev() {
    this.manualSwitched = true;
    this.selectedSubIndex--;
    this.select();
  }
  select() {
    if (this.appSubs.length > this.selectedSubIndex) {
      let sub = this.appSubs[this.selectedSubIndex];
      if (sub.id == this.activeSubId) {
        this.onSelect.emit(null);
      }
      else {
        this.onSelect.emit(sub);
      }
    }
  }

  filterFreeSub(data: AppSubscription[]) {
    if (this.activeSubId != this.iapService.freeSubKey) {
      data = data.filter(x => x.id != this.iapService.freeSubKey);
    }
    return data;
  }

  reInitIAP() {
    this.iapService.refresh();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IPaging } from '@models/paging.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-infinity-scroll',
  templateUrl: './infinity-scroll.component.html',
  styleUrls: ['./infinity-scroll.component.scss'],
})
export class InfinityScrollComponent implements OnInit, OnDestroy {
  @Input() selectPaging;
  @Input() selectPagingVar: any = {};
  @Input() loadPageAction: Function;
  @Input() loadPageSuccesAction: string;
  @Input() loadPageFailAction: string;

  infScrollDisabled: boolean = false;
  subs: Subscription[] = [];

  constructor(private actions$: Actions,
    private store: Store<AppState>) { }

  ngOnInit() {
    this.subs[this.subs.length] = this.store.select(this.selectPaging, this.selectPagingVar)
      .subscribe((paging: IPaging) => {
        if (paging && paging.currentPage != 0) {
          this.infScrollDisabled = paging.currentPage >= paging.lastPage;
        }
      })
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  loadMore(event) {
    let subSuccess = this.actions$.pipe(ofType(this.loadPageSuccesAction))
      .subscribe((data: any) => {
        subSuccess.unsubscribe();
        subFail.unsubscribe();

        event.target.complete();
      })
    let subFail = this.actions$.pipe(ofType(this.loadPageFailAction))
      .subscribe((data: any) => {
        subSuccess.unsubscribe();
        subFail.unsubscribe();

        event.target.complete();
      })

    this.loadPageAction();
  }
}

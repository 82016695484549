import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, withLatestFrom } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { AppState } from '../app.states';
import { BodyDataService } from '@services/body-data.service';
import { ProgressActions } from '../actions';
import { TagService } from '@services/tag.service';
import { MacroService } from '@services/macro.service';
import { IPagingResult } from "@models/paging.model";
import { IBodyData, IMacro, ITag } from "@models/progress.model";

@Injectable()
export class ProgressEffects {
    constructor(
        private bodyDataService: BodyDataService,
        private tagService: TagService,
        private actions$: Actions,
        private macroService: MacroService,
        private store$: Store<AppState>
    ) { }

    //#region Body Data
    loadBodyDatas$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.LOAD_BODY_DATAS),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [ProgressActions.LoadBodyDatasAction, AppState]) => {
                return action.force || !storeState?.progress?.bodyDatas?.paging.currentPage;
            }),
            exhaustMap(([action, storeState]: [ProgressActions.LoadBodyDatasAction, AppState]) => {
                return this.bodyDataService
                    .getAll()
                    .pipe(
                        map((res: any) => new ProgressActions.LoadBodyDatasSuccessAction(res)),
                        catchError((error) => of(new ProgressActions.LoadBodyDatasFailAction(error)))
                    )
            })
        )
    );
    loadBodyDatasPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.LOAD_BODY_DATAS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [ProgressActions.LoadBodyDatasPageAction, AppState]) => {
                return storeState?.progress?.bodyDatas?.paging?.currentPage != 0 && (!action.page || storeState?.progress?.bodyDatas.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [ProgressActions.LoadBodyDatasPageAction, AppState]) => {
                let page = !action.page ? storeState.progress.bodyDatas.paging.currentPage + 1 : action.page;
                return this.bodyDataService
                    .getAll(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<IBodyData>) => new ProgressActions.LoadBodyDatasPageSuccessAction(pagingResult)),
                        catchError((error) => of(new ProgressActions.LoadBodyDatasPageFailAction(error)))
                    )
            })
        )
    );
    loadBodyData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.LOAD_BODY_DATA),
            withLatestFrom(this.store$),
            filter((data: any) => {
                return !data[1]?.progress?.bodyDatas?.items.some(x => x.id == data[0].id);
            }),
            exhaustMap(([action, store]: [ProgressActions.LoadBodyDataAction, any]) => {
                return this.bodyDataService
                    .get(action.id, null)
                    .pipe(
                        map((res: any) => new ProgressActions.LoadBodyDataSuccessAction(res)),
                        catchError((error) => of(new ProgressActions.LoadBodyDataFailAction(error)))
                    )
            })
        )
    );
    addBodyData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.ADD_BODY_DATA),
            exhaustMap((action: any) =>
                this.bodyDataService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new ProgressActions.AddBodyDataSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new ProgressActions.AddBodyDataFailAction(error)))
                    )
            )
        )
    );
    editBodyData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.EDIT_BODY_DATA),
            exhaustMap((action: any) =>
                this.bodyDataService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new ProgressActions.EditBodyDataSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new ProgressActions.EditBodyDataFailAction(error)))
                    )
            )
        )
    );
    removeBodyData$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.REMOVE_BODY_DATA),
            exhaustMap((action: ProgressActions.RemoveBodyDataAction) =>
                this.bodyDataService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new ProgressActions.RemoveBodyDataSuccessAction(action.payload);
                        }),
                        catchError((error) => of(new ProgressActions.RemoveBodyDataFailAction(error)))
                    )
            )
        )
    );
    //#endregion

    //#region Body Data Copy
    loadBodyDataCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.LOAD_BODY_DATA_COPY),
            withLatestFrom(this.store$),
            filter((data: any) => {
                return !data[1]?.progress?.bodyDataCopies?.items.some(x => x.id == data[0].id);
            }),
            exhaustMap(([action, store]: [ProgressActions.LoadBodyDataCopyAction, any]) => {
                return this.bodyDataService
                    .get(action.id, action.detailId)
                    .pipe(
                        map((res: any) => new ProgressActions.LoadBodyDataCopySuccessAction(res)),
                        catchError((error) => of(new ProgressActions.LoadBodyDataCopyFailAction(error)))
                    )
            })
        )
    );
    editBodyDataCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.EDIT_BODY_DATA_COPY),
            exhaustMap((action: any) =>
                this.bodyDataService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new ProgressActions.EditBodyDataCopySuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new ProgressActions.EditBodyDataCopyFailAction(error)))
                    )
            )
        )
    );
    removeBodyDataCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.REMOVE_BODY_DATA_COPY),
            exhaustMap((action: ProgressActions.RemoveBodyDataCopyAction) =>
                this.bodyDataService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new ProgressActions.RemoveBodyDataCopySuccessAction(action.payload);
                        }),
                        catchError((error) => of(new ProgressActions.RemoveBodyDataCopyFailAction(error)))
                    )
            )
        )
    );
    //#endregion

    //#region Tag
    loadTags$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.LOAD_TAGS),
            withLatestFrom(this.store$),
            filter(([action, storeState]) => {
                return !storeState?.progress?.tags?.paging.currentPage;
            }),
            exhaustMap((action) => {
                return this.tagService
                    .getAll()
                    .pipe(
                        map((res: any) => new ProgressActions.LoadTagsSuccessAction(res)),
                        catchError((error) => of(new ProgressActions.LoadTagsFailAction(error)))
                    )
            })
        )
    );
    loadTagsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.LOAD_TAGS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [ProgressActions.LoadTagsPageAction, AppState]) => {
                return storeState?.progress?.tags?.paging?.currentPage != 0 && (!action.page || storeState?.progress?.tags.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [ProgressActions.LoadTagsPageAction, AppState]) => {
                let page = !action.page ? storeState.progress.tags.paging.currentPage + 1 : action.page;
                return this.tagService
                    .getAll(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<ITag>) => new ProgressActions.LoadTagsPageSuccessAction(pagingResult)),
                        catchError((error) => of(new ProgressActions.LoadTagsPageFailAction(error)))
                    )
            })
        )
    );

    addTag$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.ADD_TAG),
            exhaustMap((action: any) =>
                this.tagService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new ProgressActions.AddTagSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new ProgressActions.AddTagFailAction(error)))
                    )
            )
        )
    );
    removeTag$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.REMOVE_TAG),
            exhaustMap((action: ProgressActions.RemoveTagAction) =>
                this.tagService
                    .delete(action.id)
                    .pipe(
                        map((res: any) => {
                            return new ProgressActions.RemoveTagSuccessAction(action.id);
                        }),
                        catchError((error) => of(new ProgressActions.RemoveTagFailAction(error)))
                    )
            )
        )
    );
    //#endregion

    //#region Macro
    loadMacros$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.LOAD_MACROS),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [ProgressActions.LoadMacrosAction, AppState]) => {
                return action.force || !storeState?.progress?.macros?.paging.currentPage;
            }),
            exhaustMap(([action, storeState]: [ProgressActions.LoadMacrosAction, AppState]) => {
                return this.macroService
                    .getAll()
                    .pipe(
                        map((res: any) => new ProgressActions.LoadMacrosSuccessAction(res)),
                        catchError((error) => of(new ProgressActions.LoadMacrosFailAction(error)))
                    )
            })
        )
    );
    loadMacrosPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.LOAD_MACROS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [ProgressActions.LoadMacrosPageAction, AppState]) => {
                return storeState?.progress?.macros?.paging?.currentPage != 0 && (!action.page || storeState?.progress?.macros.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [ProgressActions.LoadMacrosPageAction, AppState]) => {
                let page = !action.page ? storeState.progress.macros.paging.currentPage + 1 : action.page;
                return this.macroService
                    .getAll(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<IMacro>) => new ProgressActions.LoadMacrosPageSuccessAction(pagingResult)),
                        catchError((error) => of(new ProgressActions.LoadMacrosPageFailAction(error)))
                    )
            })
        )
    );
    loadMacro$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.LOAD_MACRO),
            withLatestFrom(this.store$),
            filter((data: any) => {
                return !data[1]?.progress?.macros?.items.some(x => x.id == data[0].id);
            }),
            exhaustMap((action: ProgressActions.LoadMacroAction) => {
                return this.macroService
                    .get(action[0].id, null)
                    .pipe(
                        map((res: any) => new ProgressActions.LoadMacroSuccessAction(res)),
                        catchError((error) => of(new ProgressActions.LoadMacroFailAction(error)))
                    )
            })
        )
    );
    addMacro$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.ADD_MACRO),
            exhaustMap((action: any) =>
                this.macroService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new ProgressActions.AddMacroSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new ProgressActions.AddMacroFailAction(error)))
                    )
            )
        )
    );
    editMacro$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.EDIT_MACRO),
            exhaustMap((action: any) =>
                this.macroService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new ProgressActions.EditMacroSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new ProgressActions.EditMacroFailAction(error)))
                    )
            )
        )
    );
    removeMacro$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.REMOVE_MACRO),
            exhaustMap((action: ProgressActions.RemoveMacroAction) =>
                this.macroService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new ProgressActions.RemoveMacroSuccessAction(action.payload);
                        }),
                        catchError((error) => of(new ProgressActions.RemoveMacroFailAction(error)))
                    )
            )
        )
    );
    //#endregion

    //#region Macro copy
    loadMacroCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.LOAD_MACRO_COPY),
            withLatestFrom(this.store$),
            filter((data: any) => {
                return !data[1]?.progress?.macroCopies?.items.some(x => x.id == data[0].id);
            }),
            exhaustMap((action: ProgressActions.LoadMacroCopyAction) => {
                return this.macroService
                    .get(action[0].id, action[0].detailId)
                    .pipe(
                        map((res: any) => new ProgressActions.LoadMacroCopySuccessAction(res)),
                        catchError((error) => of(new ProgressActions.LoadMacroCopyFailAction(error)))
                    )
            })
        )
    );
    editMacroCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.EDIT_MACRO_COPY),
            exhaustMap((action: any) =>
                this.macroService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new ProgressActions.EditMacroCopySuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new ProgressActions.EditMacroCopyFailAction(error)))
                    )
            )
        )
    );
    removeMacroCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(ProgressActions.REMOVE_MACRO_COPY),
            exhaustMap((action: ProgressActions.RemoveMacroCopyAction) =>
                this.macroService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new ProgressActions.RemoveMacroCopySuccessAction(action.payload);
                        }),
                        catchError((error) => of(new ProgressActions.RemoveMacroCopyFailAction(error)))
                    )
            )
        )
    );
    //#endregion
}
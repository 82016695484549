import { IDocument } from '@models/document.model';
import { INITIAL_PAGING, IPaging, IPagingResult } from '@models/paging.model';
import { AuthActions, DocumentActions } from "../actions";

export interface State {
  loaded: boolean;
  loading: boolean;
  documents: IDocument[];
  paging: IPaging;
}

const initialState: State = {
  loaded: false,
  loading: false,
  documents: [],
  paging: INITIAL_PAGING
};

export function reducer(state = initialState, action): State {
  switch (action.type) {
    case DocumentActions.LOAD_DOCUMENTS:
    case DocumentActions.LOAD_DOCUMENTS_PAGE: {
      return Object.assign({}, state, {
        ...state,
        loading: true
      });
    }
    case DocumentActions.LOAD_DOCUMENTS_FAIL:
    case DocumentActions.LOAD_DOCUMENTS_PAGE_FAIL: {
      return Object.assign({}, state, {
        ...state,
        loading: false
      });
    }
    case DocumentActions.LOAD_DOCUMENTS_SUCCESS:
    case DocumentActions.LOAD_DOCUMENTS_PAGE_SUCCESS: {
      let resp: IPagingResult<IDocument> = action.payload;

      let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.documents, ...resp.items];

      return Object.assign({}, state, {
        loaded: resp.paging.currentPage == resp.paging.lastPage,
        loading: false,
        documents: newItems,
        paging: resp.paging
      });
    }

    case DocumentActions.ADD_DOCUMENT_SUCCESS: {
      const document = action.payload;

      if (state.documents.indexOf(document.id) > -1) {
        return state;
      }

      return Object.assign({}, state, {
        documents: [document, ...state.documents]
      });
    }

    case DocumentActions.REMOVE_DOCUMENT_SUCCESS: {
      const removeddocument = action.payload;

      return Object.assign({}, state, {
        documents: state.documents.filter(document => document.id !== removeddocument.id)
      });
    }

    case AuthActions.LOGIN_SUCCESS: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}


export const getLoaded = (state: State) => state.loaded;
export const getLoading = (state: State) => state.loading;
export const getDocuments = (state: State) => state.documents;
export const getDocumentsPaging = (state: State) => state.paging;
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor() { }


  get(name: string): any {
    let stringValue = localStorage.getItem(name);
    return stringValue ? JSON.parse(stringValue) : stringValue;
  }
  set(name: string, value: any) {
    if (value != null) {
      let stringValue = JSON.stringify(value);
      localStorage.setItem(name, stringValue);
    }
    else {
      localStorage.removeItem(name);
    }
  }

  getAccessToken() {
    return localStorage.getItem('onspot_access_token');
  }

  setAccessToken(accessToken: string) {
    localStorage.setItem('onspot_access_token', accessToken);
  }

  getRefreshToken() {
    return localStorage.getItem('onspot_refresh_token');
  }

  setRefreshToken(refreshToken: string) {
    localStorage.setItem('onspot_refresh_token', refreshToken);
  }

  removeRefreshToken(): void {
    localStorage.removeItem('onspot_refresh_token');
  }

  getExpiryDate() {
    const expiresAt = localStorage.getItem('onspot_expires_date');
    return moment(expiresAt);
  }
  setExpiryDate(expiresDate) {
    localStorage.setItem('onspot_expires_date', expiresDate);
  }

  getEULA(): boolean {
    const accepted = localStorage.getItem('eula_accepted');
    return !!accepted;
  }

  setEULA(accepted: boolean) {
    if (accepted) {
      localStorage.setItem('eula_accepted', accepted + '');
    }
    else {
      localStorage.removeItem('eula_accepted');
    }
  }

  getPolicy(): boolean {
    const accepted = localStorage.getItem('policy_accepted');
    return !!accepted;
  }
  setPolicy(accepted: boolean) {
    if (accepted) {
      localStorage.setItem('policy_accepted', accepted + '');
    }
    else {
      localStorage.removeItem('policy_accepted');
    }
  }

  getOnBoarding(): boolean {
    const completed = localStorage.getItem('onboarding_done');
    return !!completed;
  }

  completedOnBoarding() {
      localStorage.setItem('onboarding_done', "true");
  }
}

import { HttpResponse } from "@angular/common/http";
import { IPaging } from "@models/paging.model";

export abstract class HttpHelper {
    public static getPaging(response: HttpResponse<any>): IPaging {
        return {
            currentPage: +this.getHeader(response, 'current_page'),
            lastPage: +this.getHeader(response, 'last_page'),

            prevPageUrl: this.getHeader(response, 'prev_page_url'),
            nextPageUrl: this.getHeader(response, 'next_page_url'),

            perPage: +this.getHeader(response, 'per_page'),
            total: +this.getHeader(response, 'total')
        };
    }

    public static getHeader(response: HttpResponse<any>, key: string): string {
        return response.headers.get(key);
    }
}
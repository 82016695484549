import { IAnalytics, IBodyDataGroup, IMacroGroup } from '@models/analytics.model';
import { IPagingResult } from '@models/paging.model';
import { Action } from '@ngrx/store';
import { IGetAnalyticsRequest } from '@services/analytics.service';

export const LOAD_ANALYTICS = '[Analytic] Load Analytics';
export const LOAD_ANALYTICS_SUCCESS = '[Analytic] Load Analytics Success';
export const LOAD_ANALYTICS_FAIL = '[Analytic] Load Analytics Fail';

export const LOAD_BODY_DATA_GROUPS = '[Analytic] Load Body Data Groups';
export const LOAD_BODY_DATA_GROUPS_SUCCESS = '[Analytic] Load Body Data Groups Success';
export const LOAD_BODY_DATA_GROUPS_FAIL = '[Analytic] Load Body Data Groups Fail';
export const LOAD_BODY_DATA_GROUPS_PAGE = '[Analytic] Load Body Data Groups Page';
export const LOAD_BODY_DATA_GROUPS_PAGE_SUCCESS = '[Analytic] Load Body Data Groups Page Success';
export const LOAD_BODY_DATA_GROUPS_PAGE_FAIL = '[Analytic] Load Body Data Groups Page Fail';

export const LOAD_MACRO_GROUPS = '[Analytic] Load Macro Groups';
export const LOAD_MACRO_GROUPS_SUCCESS = '[Analytic] Load Macro Groups Success';
export const LOAD_MACRO_GROUPS_FAIL = '[Analytic] Load Macro Groups Fail';
export const LOAD_MACRO_GROUPS_PAGE = '[Analytic] Load Macro Groups Page';
export const LOAD_MACRO_GROUPS_PAGE_SUCCESS = '[Analytic] Load Macro Groups Page Success';
export const LOAD_MACRO_GROUPS_PAGE_FAIL = '[Analytic] Load Macro Groups Page Fail';


/**
 * Load Analytics Actions
 */
export class LoadAnalyticsAction implements Action {
    readonly type = LOAD_ANALYTICS;
    constructor(public payload: IGetAnalyticsRequest) { }
}
export class LoadAnalyticsSuccessAction implements Action {
    readonly type = LOAD_ANALYTICS_SUCCESS;
    constructor(public payload: IAnalytics) { }
}
export class LoadAnalyticsFailAction implements Action {
    readonly type = LOAD_ANALYTICS_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load Body Data Groups Actions
 */
export class LoadBodyDataGroupsAction implements Action {
    readonly type = LOAD_BODY_DATA_GROUPS;
}
export class LoadBodyDataGroupsSuccessAction implements Action {
    readonly type = LOAD_BODY_DATA_GROUPS_SUCCESS;
    constructor(public payload: IPagingResult<IBodyDataGroup>) { }
}
export class LoadBodyDataGroupsFailAction implements Action {
    readonly type = LOAD_BODY_DATA_GROUPS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Body Data Groups Page Actions
 */
export class LoadBodyDataGroupsPageAction implements Action {
    readonly type = LOAD_BODY_DATA_GROUPS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadBodyDataGroupsPageSuccessAction implements Action {
    readonly type = LOAD_BODY_DATA_GROUPS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<IBodyDataGroup>) { }
}
export class LoadBodyDataGroupsPageFailAction implements Action {
    readonly type = LOAD_BODY_DATA_GROUPS_PAGE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load Macro Groups Actions
 */
export class LoadMacroGroupsAction implements Action {
    readonly type = LOAD_MACRO_GROUPS;
}
export class LoadMacroGroupsSuccessAction implements Action {
    readonly type = LOAD_MACRO_GROUPS_SUCCESS;
    constructor(public payload: IPagingResult<IMacroGroup>) { }
}
export class LoadMacroGroupsFailAction implements Action {
    readonly type = LOAD_MACRO_GROUPS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Macro Groups Page Actions
 */
export class LoadMacroGroupsPageAction implements Action {
    readonly type = LOAD_MACRO_GROUPS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadMacroGroupsPageSuccessAction implements Action {
    readonly type = LOAD_MACRO_GROUPS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<IMacroGroup>) { }
}
export class LoadMacroGroupsPageFailAction implements Action {
    readonly type = LOAD_MACRO_GROUPS_PAGE_FAIL;
    constructor(public payload: any) { }
}
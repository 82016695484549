import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { IAnalytics } from '@models/analytics.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

export interface IGetAnalyticsRequest {
    athlete_ids?: string[],
    body_datas?: string[],
    macros?: string[],
    start?: string,
    end?: string,
    phase?: string,
    coach_id?: string,
    calories?: string,
    fats?: string,
    proteins?: string,
    carbs?: string
}

@Injectable({
    providedIn: "root",
})
export class AnalyticsService {
    apiPath: string = 'analytics';

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    getAnalytics(model: IGetAnalyticsRequest): Observable<IAnalytics> {
        let reqModel: any = {
            athlete_ids: model.athlete_ids,
            coach_id: this.authService.getUserId()
        };
        if (model.phase != null) {
            reqModel.phase = model.phase;
        }
        if (model.start != null && model.end != null) {
            reqModel.start = model.start;
            reqModel.end = model.end;
        }

        reqModel.body_datas = [];
        (model.body_datas || []).forEach((bodyDataGroup, i) => {
            const obj: { ids: string[], reference_ids: string[] } = JSON.parse(bodyDataGroup);
            reqModel.body_datas.push(obj);
        });
        reqModel.macros = [];
        (model.macros || []).forEach((macrosGroup, i) => {
            const obj: { ids: string[], reference_ids: string[] } = JSON.parse(macrosGroup);
            reqModel.macros.push(obj);
        });

        if (model.calories) {
            reqModel.calories = model.calories;
        }
        if (model.fats) {
            reqModel.fats = model.fats;
        }
        if (model.proteins) {
            reqModel.proteins = model.proteins;
        }
        if (model.carbs) {
            reqModel.carbs = model.carbs;
        }

        return this.http.post(`/${this.apiPath}`, reqModel).pipe(
            map((response: any) => {
                if (response?.status === "success" && response.data?.length > 0) {
                    return response.data[0];
                } else {
                    throw response.message;
                }
            })
        );
    }
}
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-data {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px;
}
.no-data img {
  width: 80%;
}
.no-data .no-data-text {
  padding-top: 30px;
  font-family: var(--ons-font-medium);
  font-size: 18px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/no-data/no-data.component.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,aAAA;AAAJ;AAEI;EACI,UAAA;AAAR;AAGI;EACI,iBAAA;EACA,mCAAA;EACA,eAAA;EACA,kBAAA;AADR","sourcesContent":["// No data block\n.no-data {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    padding: 30px;\n\n    img {\n        width: 80%;\n    }\n\n    .no-data-text {\n        padding-top: 30px;\n        font-family: var(--ons-font-medium);\n        font-size: 18px;\n        text-align: center;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

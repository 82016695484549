import { Injectable } from "@angular/core";
import { IAthlete } from '@models/athlete.model';
import { ACTIVITY_LEVELS } from '../consts/activity-level.const';
import { HB_CONSTS } from '../consts/harris-benedict.const';
import { NUTRIENT_KCAL } from '../consts/nutrient.const';
import { Gender } from '../enums/gender.enum';
import * as moment from 'moment';

@Injectable({
    providedIn: "root",
})
export class NutritionService {

    constructor() { }

    calcBasicCalories(athlete: IAthlete) {
        let size = athlete.size || 170;
        let weight = athlete.weight || 83;
        let age = moment().diff(athlete.dob, 'years');
        age = age || 24;
        let gender = athlete.gender || Gender.male;
        let activityLevel = athlete.activity_level ? ACTIVITY_LEVELS[athlete.activity_level] : ACTIVITY_LEVELS[3];
        let hbConsts = HB_CONSTS[gender];

        let calories = hbConsts.basic +
            (size * hbConsts.sizeCoeff) +
            (weight * hbConsts.weightCoeff) -
            (age * hbConsts.ageCoeff);
        return Math.round(calories * activityLevel);
    }

    calcGrams(gkg, weight) {
        return gkg != null ? gkg * weight : null;
    }
    calcCal(grams, Kcal) {
        return grams != null ? grams * Kcal : null;
    }

    calcNutritionByGKG(weight: number, calories: number, protGKG: number = 0, fatsGKG: number = 0, carbGKG: number = 0) {
        weight = weight || 83;

        let protGrams = this.calcGrams(protGKG, weight);
        let fatsGrams = this.calcGrams(fatsGKG, weight);
        let carbGrams = this.calcGrams(carbGKG, weight);

        let protCal = this.calcCal(protGrams, NUTRIENT_KCAL.prot);
        let fatsCal = this.calcCal(fatsGrams, NUTRIENT_KCAL.fat);
        let carbCal = this.calcCal(carbGrams, NUTRIENT_KCAL.carb);

        if (protCal == null) {
            protCal = calories - fatsCal - carbCal;
            protCal = protCal < 0 ? 0 : protCal;
            protGrams = protCal / NUTRIENT_KCAL.prot;
            protGKG = protGrams / weight;
        }
        if (fatsCal == null) {
            fatsCal = calories - protCal - carbCal;
            fatsCal = fatsCal < 0 ? 0 : fatsCal;
            fatsGrams = fatsCal / NUTRIENT_KCAL.fat;
            fatsGKG = fatsGrams / weight;
        }
        if (carbCal == null) {
            carbCal = calories - fatsCal - protCal;
            carbCal = carbCal < 0 ? 0 : carbCal;
            carbGrams = carbCal / NUTRIENT_KCAL.carb;
            carbGKG = carbGrams / weight;
        }

        let totalCalories = protCal + fatsCal + carbCal;

        let protPercent = (protCal * 100) / calories;
        let fatsPercent = (fatsCal * 100) / calories;
        let carbPercent = (carbCal * 100) / calories;

        let totalPercent = protPercent + fatsPercent + carbPercent;

        return {
            calories: Math.round(totalCalories),
            totalPercent: Math.round(totalPercent),
            prot: {
                gkg: this.round(protGKG, 1),
                g: Math.round(protGrams),
                percent: Math.round(protPercent)
            },
            fats: {
                gkg: this.round(fatsGKG, 1),
                g: Math.round(fatsGrams),
                percent: Math.round(fatsPercent)
            },
            carb: {
                gkg: this.round(carbGKG, 1),
                g: Math.round(carbGrams),
                percent: Math.round(carbPercent)
            }
        };
    }

    calcNutritionByGrams(weight: number, calories: number, protGrams: number = 0, fatsGrams: number = 0, carbGrams: number = 0) {
        weight = weight || 83;

        let protCal = this.calcCal(protGrams, NUTRIENT_KCAL.prot);
        let fatsCal = this.calcCal(fatsGrams, NUTRIENT_KCAL.fat);
        let carbCal = this.calcCal(carbGrams, NUTRIENT_KCAL.carb);

        if (protCal == null) {
            protCal = calories - fatsCal - carbCal;
            protCal = protCal < 0 ? 0 : protCal;
            protGrams = protCal / NUTRIENT_KCAL.prot;
        }
        if (fatsCal == null) {
            fatsCal = calories - protCal - carbCal;
            fatsCal = fatsCal < 0 ? 0 : fatsCal;
            fatsGrams = fatsCal / NUTRIENT_KCAL.fat;
        }
        if (carbCal == null) {
            carbCal = calories - fatsCal - protCal;
            carbCal = carbCal < 0 ? 0 : carbCal;
            carbGrams = carbCal / NUTRIENT_KCAL.carb;
        }

        let protGKG = protGrams / weight;
        let fatsGKG = fatsGrams / weight;
        let carbGKG = carbGrams / weight;

        let totalCalories = protCal + fatsCal + carbCal;

        let protPercent = (protCal * 100) / calories;
        let fatsPercent = (fatsCal * 100) / calories;
        let carbPercent = (carbCal * 100) / calories;

        let totalPercent = protPercent + fatsPercent + carbPercent;

        return {
            calories: Math.round(totalCalories),
            totalPercent: Math.round(totalPercent),
            prot: {
                gkg: this.round(protGKG, 1),
                g: Math.round(protGrams),
                percent: Math.round(protPercent)
            },
            fats: {
                gkg: this.round(fatsGKG, 1),
                g: Math.round(fatsGrams),
                percent: Math.round(fatsPercent)
            },
            carb: {
                gkg: this.round(carbGKG, 1),
                g: Math.round(carbGrams),
                percent: Math.round(carbPercent)
            }
        };
    }

    calcNutritionByPercent(weight: number, calories: number, protPercent: number = 0, fatsPercent: number = 0, carbPercent: number = 0) {
        weight = weight || 83;

        protPercent = protPercent == null ? 100 - fatsPercent - carbPercent : protPercent;
        protPercent = protPercent < 0 ? 0 : protPercent;
        fatsPercent = fatsPercent == null ? 100 - protPercent - carbPercent : fatsPercent;
        fatsPercent = fatsPercent < 0 ? 0 : fatsPercent;
        carbPercent = carbPercent == null ? 100 - fatsPercent - protPercent : carbPercent;
        carbPercent = carbPercent < 0 ? 0 : carbPercent;

        let protCal = calories * (protPercent / 100);
        let fatsCal = calories * (fatsPercent / 100);
        let carbCal = calories * (carbPercent / 100);

        let protGrams = protCal / NUTRIENT_KCAL.prot;
        let fatsGrams = fatsCal / NUTRIENT_KCAL.fat;
        let carbGrams = carbCal / NUTRIENT_KCAL.carb;

        let protGKG = protGrams / weight;
        let fatsGKG = fatsGrams / weight;
        let carbGKG = carbGrams / weight;

        let totalCalories = protCal + fatsCal + carbCal;

        let totalPercent = protPercent + fatsPercent + carbPercent;

        return {
            calories: Math.round(totalCalories),
            totalPercent: Math.round(totalPercent),
            prot: {
                gkg: this.round(protGKG, 1),
                g: Math.round(protGrams),
                percent: Math.round(protPercent)
            },
            fats: {
                gkg: this.round(fatsGKG, 1),
                g: Math.round(fatsGrams),
                percent: Math.round(fatsPercent)
            },
            carb: {
                gkg: this.round(carbGKG, 1),
                g: Math.round(carbGrams),
                percent: Math.round(carbPercent)
            }
        };
    }

    round(value, precision) {
        let multiplier = Math.pow(10, precision || 0);
        return Math.round(value * multiplier) / multiplier;
    }
}
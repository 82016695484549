import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from "@angular/core";
import { IAddTrackingValue, IAthleteTracking, IUpdateTrackingValue } from '@models/athlete-tracking.model';
import { UserRole } from '@models/authentication.model';
import { IPagingResult } from '@models/paging.model';
import { ITrackingMesocycleOverview } from '@models/tracking-overview.model';
import { ITrainingMesocycleOverview } from '@models/training-overview.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../helpers/http.helper';
import { AuthService } from './auth.service';
import { CoachAthleteViewService } from './coach-athlete-view.service';

@Injectable({
    providedIn: "root",
})
export class AthleteTrackingService {
    apiPath: string = 'athlete_trackings';

    openTrackingsMenuIntent: EventEmitter<boolean> = new EventEmitter<boolean>(false);

    constructor(private http: HttpClient,
        private coachAthleteViewService: CoachAthleteViewService,
        private authService: AuthService) { }

    getOverview(page: number = 1, disableLoading = false): Observable<IPagingResult<ITrackingMesocycleOverview>> {
        let athleteId = null;

        let userRole = this.authService.getUserRole();
        athleteId = userRole == UserRole.athlete ? +this.authService.getUserId() : this.coachAthleteViewService.athleteId;

        let url = `/trackings_overview/${athleteId}`;
        let pageQuery = `page=${page}`;

        let req = this.http;
        if (disableLoading) {
            req = req.disableLoading();
        }
        return req.get(`${url}?${pageQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    getTrainingOverview(page: number = 1, disableLoading = false): Observable<IPagingResult<ITrainingMesocycleOverview>> {
        let athleteId = null;

        let userRole = this.authService.getUserRole();
        athleteId = userRole == UserRole.athlete ? +this.authService.getUserId() : this.coachAthleteViewService.athleteId;

        let url = `/trainings_overview/${athleteId}`;
        let pageQuery = `page=${page}`;

        let req = this.http;
        if (disableLoading) {
            req = req.disableLoading();
        }
        return req.get(`${url}?${pageQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    get(date: Date = null, disableLoading = true): Observable<IAthleteTracking> {
        let athleteId = null;

        let userRole = this.authService.getUserRole();
        athleteId = userRole == UserRole.athlete ? +this.authService.getUserId() : this.coachAthleteViewService.athleteId;

        let url = `/users/${athleteId}/trackings`;
        url = date ? url + `?filter[day]=${moment(date).format('YYYY-MM-DD')}` : url;

        let req = this.http;
        if (disableLoading) {
            req = req.disableLoading();
        }
        return req.get(url).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data[0];
                } else {
                    throw response.message;
                }
            })
        );
    }

    addValue(model: IAddTrackingValue): Observable<IAthleteTracking> {
        model = { ...model };

        let userRole = this.authService.getUserRole();
        model.athlete_id = userRole == UserRole.athlete ? +this.authService.getUserId() : this.coachAthleteViewService.athleteId;

        model.day = model.day ? model.day : new Date();

        return this.http.post(`/${this.apiPath}`, model)
            .pipe(
                map((response: any) => {
                    if (response?.status === "success") {
                        return response.data[0];
                    } else {
                        throw response.message;
                    }
                })
            );
    }


    updateValue(model: IUpdateTrackingValue): Observable<IAthleteTracking> {
        model = { ...model };

        return this.http.patch(`/${this.apiPath}/${model.id}`, model)
            .pipe(
                map((response: any) => {
                    if (response?.status === "success") {
                        return response.data[0];
                    } else {
                        throw response.message;
                    }
                })
            );
    }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CameraSource } from '@capacitor/camera';
import { ActionSheetController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CameraService } from '@services/camera.service';
import { FileService } from '@services/file.service';
import { UIService } from '@services/ui.service';

@Component({
  selector: 'app-update-avatar',
  templateUrl: './update-avatar.component.html',
  styleUrls: ['./update-avatar.component.scss'],
})
export class UpdateAvatarComponent implements OnInit {
  @Input() croppedImagePath: string = "";
  @Input() textVariant: number = 1;
  croppedImageBase64: string = "";
  readyToCropImage: any = '';
  @Output() imageUpdated: EventEmitter<any> = new EventEmitter<any>();

  constructor(private cameraService: CameraService,
    private uiService: UIService,
    private translate: TranslateService,
    private fileService: FileService,
    private actionSheetController: ActionSheetController) { }

  ngOnInit() { }


  imageCropDone($event) {
    this.readyToCropImage = '';
    this.croppedImageBase64 = $event;

    this.imageUpdated.emit(this.croppedImageBase64);
  }

  openCamera(sourceType: CameraSource) {
    this.cameraService.pickImage(sourceType).then((imageData: any) => {
      this.readyToCropImage = imageData.dataUrl;
    }).catch((err) => {
      console.log("Error :", err);
      this.uiService.showMessage(err, 'danger', 5000);
    });
  }

  async presentActionSheet(input) {
    if (this.fileService.ifMobile()) {
      const actionSheet = await this.actionSheetController.create({
        header: this.translate.instant('auth.upload.select_image'),
        cssClass: 'logo-pick-action-sheet',
        mode: 'ios',
        buttons: [
          {
            text: this.translate.instant('auth.upload.photo_library'),
            icon: 'images',
            cssClass: 'as-icon',
            handler: () => {
              this.openCamera(CameraSource.Photos);
            }
          },
          {
            text: this.translate.instant('auth.upload.camera'),
            icon: 'camera',
            cssClass: 'as-icon',
            handler: () => {
              this.openCamera(CameraSource.Camera);
            }
          }]
      });
      await actionSheet.present();
    }
    else {
      input.click();
    }
  }

  fileChangeEvent(files: File[]) {
    let file = files[0];
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      this.readyToCropImage = fileReader.result as string;
    }
    fileReader.readAsDataURL(file);
  }
}

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { ProgressActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-tag-infinity-scroll',
  templateUrl: './tag-infinity-scroll.component.html',
  styleUrls: ['./tag-infinity-scroll.component.scss'],
})
export class TagInfinityScrollComponent {
  selectPaging = AppReducers.getTagsPaging;
  loadPageSuccessAction: string = ProgressActions.LOAD_TAGS_PAGE_SUCCESS;
  loadPageFailAction: string = ProgressActions.LOAD_TAGS_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  loadPageAction() {
    this.store.dispatch(new ProgressActions.LoadTagsPageAction());
  }
}

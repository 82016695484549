import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { ISideNote } from '@models/note.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';


@Injectable({
    providedIn: "root",
})
export class SideNoteService {
    apiPath: string = 'side_notes';

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    getAll(): Observable<ISideNote[]> {
        let userId = this.authService.getUserId();
        let query = `coach_id=${userId}`;

        return this.http.get(`/${this.apiPath}?${query}`).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data;
                } else {
                    throw response.message;
                }
            })
        );
    }

    add(req: ISideNote) {
        let model: any = { ...req };
        let userId = this.authService.getUserId();
        model.coach_id = userId;

        return this.http.post(`/${this.apiPath}`, model);
    }

    edit(req: ISideNote) {
        let model: any = { ...req };
        return this.http.patch(`/${this.apiPath}/${model.id}`, model);
    }

    delete(id: number) {
        return this.http.delete(`/${this.apiPath}/${id}`)
    }
}
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { IMesocycle } from '@models/mesocycle.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MesocycleActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-copy-mesocycle-modal',
  templateUrl: './copy-mesocycle-modal.component.html',
  styleUrls: ['./copy-mesocycle-modal.component.scss']
})
export class CopyMesocycleModalComponent implements OnInit, OnDestroy {
  @Input() mesocycle: IMesocycle;
  @Input() isAssigned: boolean = false;
  form: UntypedFormGroup;
  subSuccess: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private actions$: Actions,
    private modalController: ModalController) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      value: [this.mesocycle?.name, Validators.required]
    });

    this.subSuccess = this.actions$.pipe(ofType(MesocycleActions.COPY_TO_BASE_MESOCYCLE_SUCCESS))
      .subscribe((data: any) => {
        this.modalController.dismiss();
      })
  }

  closeModal() {
    this.modalController.dismiss();
  }

  submit() {
    this.store.dispatch(new MesocycleActions.CopyToBaseMesocycleAction(this.mesocycle?.id, this.form?.value?.value));
  }

  ngOnDestroy(): void {
    this.subSuccess.unsubscribe();
  }
}

import { Pipe, PipeTransform } from "@angular/core";
import { IInvitation } from "@models/invitation.model";


@Pipe({ name: 'filterActiveInvites' })
export class FilterActiveInvitesPipe implements PipeTransform {
    transform(list: IInvitation[]): any[] {
        if (list) {
            return list.filter((item: IInvitation) => !item.has_accepted && !item.has_cancelled);
        }
    }
}
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { from } from 'rxjs';
import { finalize, switchMap, tap } from 'rxjs/operators';
import { LoadingController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';

/**
 * Adds a default error handler to all requests.
 */
@Injectable()
export class ShowLoadingInterceptor implements HttpInterceptor {
  constructor(private loadingController: LoadingController,
    private translate: TranslateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (request.url.indexOf('http') !== 0) {
      return next.handle(request);
    }

    let messagePath = 'base.loading';
    let message = this.translate.instant(messagePath);
    message = message == messagePath ? 'Bitte warten...' : message;
    return from(this.loadingController.create({
      message: message
    }))
      .pipe(
        tap((loading) => {
          return loading.present();
        }),
        switchMap((loading) => {
          return next.handle(request).pipe(
            finalize(() => {
              loading.dismiss();
            })
          );
        })
      );
  }

}

import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";

export abstract class AppValidators {
    public static Email = Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
    public static Number = Validators.pattern('[0-9]*[,.]*[0-9]+');

    public static MustMatch(controlName: string, matchingControlName: string) {
        return (formGroup: UntypedFormGroup) => {
            const control = formGroup.controls[controlName];
            const matchingControl = formGroup.controls[matchingControlName];

            if (matchingControl.errors && !matchingControl.errors.mustMatch) {
                // return if another validator has already found an error on the matchingControl
                return;
            }

            // set error on matchingControl if validation fails
            if (control.value !== matchingControl.value) {
                matchingControl.setErrors({ mustMatch: true });
            } else {
                matchingControl.setErrors(null);
            }
        }
    }

    public static TextMinValue(minValue: number): ValidatorFn {
        return (formControl: AbstractControl): ValidationErrors | null => {
            let value = +(formControl.value?.replace(',', '.'));
            value = value || null;
            if (value < minValue) {
                return { textMinValue: true };
            } else {
                return null;
            }
        }
    }

    public static TextMaxValue(maxValue: number): ValidatorFn {
        return (formControl: AbstractControl): ValidationErrors | null => {
            let value = +(formControl.value?.replace(',', '.'));
            value = value || null;
            if (value > maxValue) {
                return { textMaxValue: true };
            } else {
                return null;
            }
        }
    }
}
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ITechnique, ITrainingUnit } from '@models/training.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '@services/ui.service';
import { AppReducers } from 'app/core/store';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';
import { CategoriesModalComponent } from './categories-modal/categories-modal.component';
import { TechniquesModalComponent } from './techniques-modal/techniques-modal.component';

@Component({
  selector: 'app-training-unit',
  templateUrl: './training-unit.component.html',
  styleUrls: ['./training-unit.component.scss'],
})
export class TrainingUnitComponent implements OnInit, OnDestroy {
  @Input() unitId: number;
  @Input() unitDataId: number;
  @Input() isCopyUnit: boolean = false;
  form: UntypedFormGroup;
  subs: Subscription[] = [];
  repsMin = 1;
  repsMax = 30;
  isCreating = false;
  isReference = false;

  intensityTechniques: ITechnique[] = [];

  constructor(private modalController: ModalController,
    private store: Store<AppState>,
    private actions$: Actions,
    private translate: TranslateService,
    private uiService: UIService,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    if (!this.unitId) {
      let unit = {
        id: null,
        name: '',
        categories: [],
        coach_id: null,
        unit_data: {
          id: null,
          sets: 1,
          rirs: 1,
          reps_from: this.repsMin,
          reps_to: this.repsMax,
          info_title: '',
          info_description: '',
        }
      }
      this.isCreating = true;

      this.initForm(unit);
    }
    else if (!this.isCopyUnit) {
      this.selectTrainingUnit();
    }
    else {
      this.selectTrainingUnitCopy();
    }

    this.subscribeToActions();
  }

  subscribeToActions() {
    this.subscribeToUnitActions();
    this.subscribeToTechniquesActions();
    this.subscribeToCategoryActions();
  }

  subscribeToTechniquesActions() {
    this.subs[this.subs.length] = this.actions$.pipe(ofType(TrainingActions.REMOVE_TECHNIQUE_SUCCESS))
      .subscribe((data: any) => {
        let activeTechniques = this.form.get('intensity_techniques').value;
        this.intensityTechniques = activeTechniques.filter(x => x.id != data.payload.id);
      })
  }

  subscribeToCategoryActions() {
    this.subs[this.subs.length] = this.actions$.pipe(ofType(TrainingActions.REMOVE_CATEGORY_SUCCESS))
      .subscribe((data: any) => {
        let activeCategories = this.form.get('categories').value;
        this.form.get('categories').setValue(activeCategories.filter(x => x.id != data.payload.id));
      })
  }

  subscribeToUnitActions() {
    this.subs[this.subs.length] = this.actions$.pipe(ofType(TrainingActions.ADD_UNIT_SUCCESS))
      .subscribe(data => {
        this.uiService.showMessage(this.translate.instant('coach.training.units.add.unit.success_add'), 'success', 2000);
        this.modalController.dismiss();
      })
    this.subs[this.subs.length] = this.actions$.pipe(ofType(TrainingActions.EDIT_UNIT_SUCCESS))
      .subscribe(data => {
        this.uiService.showMessage(this.translate.instant('coach.training.units.add.unit.success_edit'), 'success', 2000);
        this.modalController.dismiss();
      })
    this.subs[this.subs.length] = this.actions$.pipe(ofType(TrainingActions.EDIT_UNIT_COPY_SUCCESS))
      .subscribe(data => {
        this.uiService.showMessage(this.translate.instant('coach.training.units.add.unit.success_edit'), 'success', 2000);
        this.modalController.dismiss();
      })
  }

  selectTrainingUnit() {
    this.subs[this.subs.length] = this.store.select(AppReducers.getTrainingUnit, { id: this.unitId })
      .subscribe(unit => {
        if (unit) {
          this.initForm(unit);
        }
      });
  }
  selectTrainingUnitCopy() {
    this.subs[this.subs.length] = this.store.select(AppReducers.getTrainingUnitCopy, { unitDataId: this.unitDataId })
      .subscribe(unit => {
        if (unit) {
          this.initForm(unit);
        }
        else {
          this.store.dispatch(new TrainingActions.LoadUnitCopyAction(this.unitId, this.unitDataId));
        }
      });
  }

  initForm(unit: ITrainingUnit) {
    this.form = this.formBuilder.group({
      id: [unit.id],
      name: [unit.name, Validators.required],
      sets: [unit.unit_data?.sets, Validators.required],
      rirs: [unit.unit_data?.rirs, Validators.required],
      reps: [{ lower: unit.unit_data?.reps_from, upper: unit.unit_data?.reps_to, }],
      reps_from: [unit.unit_data?.reps_from, Validators.required],
      reps_to: [unit.unit_data?.reps_to, Validators.required],
      categories: [unit.categories || []],
      unit_data_id: [unit.unit_data?.id],
      info_title: unit.unit_data.info_title,
      info_description: unit.unit_data.info_description,
    });

    this.form.get('reps').valueChanges.subscribe(value => {
      this.form.get('reps_from').setValue(value?.lower);
      this.form.get('reps_to').setValue(value?.upper);
    });

    this.isReference = !!unit.reference_id;
  }

  close() {
    this.modalController.dismiss();
  }

  save() {
    let unit = { ...this.form.value };
    unit.categories = unit.categories.map(x => x.id);

    if (this.isCopyUnit) {
      this.store.dispatch(new TrainingActions.EditUnitCopyAction(unit));
    }
    else {
      this.store.dispatch(
        this.isCreating ?
          new TrainingActions.AddUnitAction(unit) :
          new TrainingActions.EditUnitAction(unit)
      );
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }


  onClickEditCategories() {
    this.modalController.create({
      component: CategoriesModalComponent,
      swipeToClose: true,
      componentProps: {
        'activeCategories': [...this.form.get('categories').value]
      }
    })
      .then(modal => {
        modal.present();

        modal.onDidDismiss().then(data => {
          if (data?.data) {
            this.form.get('categories').setValue(data?.data);
          }
        })
      })
  }

  onClickRemoveCategory(category) {
    let value = this.form.get('categories').value;
    value = value.filter(x => x.id != category.id);
    this.form.get('categories').setValue(value);
  }

  onClickAddDescription() {
    this.modalController.create({
      component: TechniquesModalComponent,
      swipeToClose: true,
      componentProps: {
        'activeTechniques': [...this.intensityTechniques]
      }
    })
      .then(modal => {
        modal.present();
        modal.onDidDismiss().then(data => {
          let infoCollection = data?.data as ITechnique[];
          if (infoCollection?.length > 0 ) {
            const currentDescription = this.form.get('info_description').value;
            const newDescriptions = infoCollection.map(x => x.description);
            const result = [currentDescription, ...newDescriptions].join("\n");
            this.form.get('info_description').setValue(result);
          }
        })
      })
  }
}

import { Component, OnInit } from '@angular/core';
import { AuthService } from "@services/auth.service";
import { UserRole } from "@models/authentication.model";

import { Router } from '@angular/router';

@Component({
  selector: 'app-select-role',
  templateUrl: './select-role.page.html',
  styleUrls: ['./select-role.page.scss'],
})
export class SelectRolePage implements OnInit {

  constructor(
    private auth: AuthService,
    private router: Router

  ) { }

  ngOnInit() {
  }

  gotoCoachReg() {
    this.auth.logout();
    this.router.navigate(["/registration"]);
  }

  gotoAthleteReg() {
    this.auth.logout();
    this.router.navigate(['/athlete-reg']);
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { MesocycleActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-assigned-mesocycles-infinity-scroll',
  templateUrl: './assigned-mesocycles-infinity-scroll.component.html',
  styleUrls: ['./assigned-mesocycles-infinity-scroll.component.scss'],
})
export class AssignedMesocyclesInfinityScrollComponent implements OnInit {
  @Input() athleteId: number;
  selectPagingVar: any = {};
  selectPaging = AppReducers.getAssignedMesocyclesPaging;
  loadPageSuccessAction: string = MesocycleActions.LOAD_ASSIGNED_MESOCYCLES_PAGE_SUCCESS;
  loadPageFailAction: string = MesocycleActions.LOAD_ASSIGNED_MESOCYCLES_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  ngOnInit(): void {
    this.selectPagingVar = { id: this.athleteId };
  }

  loadPageAction() {
    this.store.dispatch(new MesocycleActions.LoadAssignedMesocyclesPageAction(this.selectPagingVar.id));
  }
}

import { IPagingResult } from '@models/paging.model';
import { ITechnique, ITrainingCategory, ITrainingPlan, ITrainingPlanSheet, ITrainingTemplate, ITrainingUnit, IUnitFilter } from '@models/training.model';
import { Action } from '@ngrx/store';
import { DEFAULT_UNIT_FILTER } from 'app/core/consts/default-unit-filter.const';

export const LOAD_PLANS = '[Training] Load Plans';
export const LOAD_PLANS_SUCCESS = '[Training] Load Plans Success';
export const LOAD_PLANS_FAIL = '[Training] Load Plans Fail';
export const LOAD_PLANS_PAGE = '[Training] Load Plans Page';
export const LOAD_PLANS_PAGE_SUCCESS = '[Training] Load Plans Page Success';
export const LOAD_PLANS_PAGE_FAIL = '[Training] Load Plans Page Fail';
export const LOAD_PLAN = '[Training] Load Plan';
export const LOAD_PLAN_SUCCESS = '[Training] Load Plan Success';
export const LOAD_PLAN_FAIL = '[Training] Load Plan Fail';
export const ADD_PLAN = '[Training] Add Plan';
export const ADD_PLAN_SUCCESS = '[Training] Add Plan Success';
export const ADD_PLAN_FAIL = '[Training] Add Plan Fail';
export const EDIT_PLAN = '[Training] Edit Plan';
export const EDIT_PLAN_SUCCESS = '[Training] Edit Plan Success';
export const EDIT_PLAN_FAIL = '[Training] Edit Plan Fail';
export const REMOVE_PLAN = '[Training] Remove Plan';
export const REMOVE_PLAN_SUCCESS = '[Training] Remove Plan Success';
export const REMOVE_PLAN_FAIL = '[Training] Remove Plan Fail';

export const LOAD_PLAN_COPY = '[Training] Load Plan Copy';
export const LOAD_PLAN_COPY_SUCCESS = '[Training] Load Plan Copy Success';
export const LOAD_PLAN_COPY_FAIL = '[Training] Load Plan Copy Fail';
export const ADD_PLAN_COPY = '[Training] Add Plan Copy';
export const ADD_PLAN_COPY_SUCCESS = '[Training] Add Plan Copy Success';
export const ADD_PLAN_COPY_FAIL = '[Training] Add Plan Copy Fail';
export const EDIT_PLAN_COPY = '[Training] Edit Plan Copy';
export const EDIT_PLAN_COPY_SUCCESS = '[Training] Edit Plan Copy Success';
export const EDIT_PLAN_COPY_FAIL = '[Training] Edit Plan Copy Fail';
export const REMOVE_PLAN_COPY = '[Training] Remove Plan Copy';
export const REMOVE_PLAN_COPY_SUCCESS = '[Training] Remove Plan Copy Success';
export const REMOVE_PLAN_COPY_FAIL = '[Training] Remove Plan Copy Fail';

export const LOAD_UNITS = '[Training] Load Units';
export const LOAD_UNITS_SUCCESS = '[Training] Load Units Success';
export const LOAD_UNITS_FAIL = '[Training] Load Units Fail';
export const LOAD_UNITS_PAGE = '[Training] Load Units Page';
export const LOAD_UNITS_PAGE_SUCCESS = '[Training] Load Units Page Success';
export const LOAD_UNITS_PAGE_FAIL = '[Training] Load Units Page Fail';
export const ADD_UNIT = '[Training] Add Unit';
export const ADD_UNIT_SUCCESS = '[Training] Add Unit Success';
export const ADD_UNIT_FAIL = '[Training] Add Unit Fail';
export const EDIT_UNIT = '[Training] Edit Unit';
export const EDIT_UNIT_SUCCESS = '[Training] Edit Unit Success';
export const EDIT_UNIT_FAIL = '[Training] Edit Unit Fail';
export const REMOVE_UNIT = '[Training] Remove Unit';
export const REMOVE_UNIT_SUCCESS = '[Training] Remove Unit Success';
export const REMOVE_UNIT_FAIL = '[Training] Remove Unit Fail';

export const LOAD_UNIT_COPY = '[Training] Load Unit copy';
export const LOAD_UNIT_COPY_SUCCESS = '[Training] Load Unit copy Success';
export const LOAD_UNIT_COPY_FAIL = '[Training] Load Unit copy Fail';
export const EDIT_UNIT_COPY = '[Training] Edit Unit copy';
export const EDIT_UNIT_COPY_SUCCESS = '[Training] Edit Unit copy Success';
export const EDIT_UNIT_COPY_FAIL = '[Training] Edit Unit copy Fail';
export const REMOVE_UNIT_COPY = '[Training] Remove Unit copy';
export const REMOVE_UNIT_COPY_SUCCESS = '[Training] Remove Unit copy Success';
export const REMOVE_UNIT_COPY_FAIL = '[Training] Remove Unit copy Fail';

export const LOAD_CATEGORIES = '[Training] Load Categories';
export const LOAD_CATEGORIES_SUCCESS = '[Training] Load Categories Success';
export const LOAD_CATEGORIES_FAIL = '[Training] Load Categories Fail';
export const LOAD_CATEGORIES_PAGE = '[Training] Load Categories Page';
export const LOAD_CATEGORIES_PAGE_SUCCESS = '[Training] Load Categories Page Success';
export const LOAD_CATEGORIES_PAGE_FAIL = '[Training] Load Categories Page Fail';
export const ADD_CATEGORY = '[Training] Add Category';
export const ADD_CATEGORY_SUCCESS = '[Training] Add Category Success';
export const ADD_CATEGORY_FAIL = '[Training] Add Category Fail';
export const REMOVE_CATEGORY = '[Training] Remove Category';
export const REMOVE_CATEGORY_SUCCESS = '[Training] Remove Category Success';
export const REMOVE_CATEGORY_FAIL = '[Training] Remove Category Fail';

export const LOAD_TECHNIQUES = '[Training] Load Techniques';
export const LOAD_TECHNIQUES_SUCCESS = '[Training] Load Techniques Success';
export const LOAD_TECHNIQUES_FAIL = '[Training] Load Techniques Fail';
export const LOAD_TECHNIQUES_PAGE = '[Training] Load Techniques Page';
export const LOAD_TECHNIQUES_PAGE_SUCCESS = '[Training] Load Techniques Page Success';
export const LOAD_TECHNIQUES_PAGE_FAIL = '[Training] Load Techniques Page Fail';
export const ADD_TECHNIQUE = '[Training] Add Technique';
export const ADD_TECHNIQUE_SUCCESS = '[Training] Add Technique Success';
export const ADD_TECHNIQUE_FAIL = '[Training] Add Technique Fail';
export const EDIT_TECHNIQUE = '[Training] Edit Technique';
export const EDIT_TECHNIQUE_SUCCESS = '[Training] Edit Technique Success';
export const EDIT_TECHNIQUE_FAIL = '[Training] Edit Technique Fail';
export const REMOVE_TECHNIQUE = '[Training] Remove Technique';
export const REMOVE_TECHNIQUE_SUCCESS = '[Training] Remove Technique Success';
export const REMOVE_TECHNIQUE_FAIL = '[Training] Remove Technique Fail';

export const LOAD_SHEETS = '[Training] Load Sheets';
export const LOAD_SHEETS_SUCCESS = '[Training] Load Sheets Success';
export const LOAD_SHEETS_FAIL = '[Training] Load Sheets Fail';
export const LOAD_SHEET = '[Training] Load Sheet';
export const LOAD_SHEET_SUCCESS = '[Training] Load Sheet Success';
export const LOAD_SHEET_FAIL = '[Training] Load Sheet Fail';
export const ADD_SHEET = '[Training] Add Sheet';
export const ADD_SHEET_SUCCESS = '[Training] Add Sheet Success';
export const ADD_SHEET_FAIL = '[Training] Add Sheet Fail';
export const EDIT_SHEET = '[Training] Edit Sheet';
export const EDIT_SHEET_SUCCESS = '[Training] Edit Sheet Success';
export const EDIT_SHEET_FAIL = '[Training] Edit Sheet Fail';

export const REMOVE_SHEET = '[Training] Remove Sheet';
export const REMOVE_SHEET_SUCCESS = '[Training] Remove Sheet Success';
export const REMOVE_SHEET_FAIL = '[Training] Remove Sheet Fail';

export const COPY_SHEET_AS_TEMPLATE = '[Training] Copy Sheet As Template';
export const COPY_SHEET_AS_TEMPLATE_SUCCESS = '[Training] Copy Sheet As Template Success';
export const COPY_SHEET_AS_TEMPLATE_FAIL = '[Training] Copy Sheet As Template Fail';

export const LOAD_TEMPLATES = '[Training] Load Templates';
export const LOAD_TEMPLATES_SUCCESS = '[Training] Load Templates Success';
export const LOAD_TEMPLATES_FAIL = '[Training] Load Templates Fail';
export const LOAD_TEMPLATES_PAGE = '[Training] Load Templates Page';
export const LOAD_TEMPLATES_PAGE_SUCCESS = '[Training] Load Templates Page Success';
export const LOAD_TEMPLATES_PAGE_FAIL = '[Training] Load Templates Page Fail';
export const ADD_TEMPLATE = '[Training] Add Template';
export const ADD_TEMPLATE_SUCCESS = '[Training] Add Template Success';
export const ADD_TEMPLATE_FAIL = '[Training] Add Template Fail';
export const EDIT_TEMPLATE = '[Training] Edit Template';
export const EDIT_TEMPLATE_SUCCESS = '[Training] Edit Template Success';
export const EDIT_TEMPLATE_FAIL = '[Training] Edit Template Fail';
export const REMOVE_TEMPLATE = '[Training] Remove Template';
export const REMOVE_TEMPLATE_SUCCESS = '[Training] Remove Template Success';
export const REMOVE_TEMPLATE_FAIL = '[Training] Remove Template Fail';

export const COPY_PLAN = '[Training] Copy Training Plan';
export const COPY_PLAN_SUCCESS = '[Training] Copy Training Plan Success';
export const COPY_PLAN_FAIL = '[Training] Copy Training Plan Fail';

/**
 * Load Plans Actions
 */
export class LoadPlansAction implements Action {
    readonly type = LOAD_PLANS;
    constructor(public force: boolean = false) { }
}
export class LoadPlansSuccessAction implements Action {
    readonly type = LOAD_PLANS_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingPlan>) { }
}
export class LoadPlansFailAction implements Action {
    readonly type = LOAD_PLANS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Plans Page Actions
 */
export class LoadPlansPageAction implements Action {
    readonly type = LOAD_PLANS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadPlansPageSuccessAction implements Action {
    readonly type = LOAD_PLANS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingPlan>) { }
}
export class LoadPlansPageFailAction implements Action {
    readonly type = LOAD_PLANS_PAGE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Plan Actions
 */
export class LoadPlanAction implements Action {
    readonly type = LOAD_PLAN;
    constructor(public id: number) { }
}
export class LoadPlanSuccessAction implements Action {
    readonly type = LOAD_PLAN_SUCCESS;
    constructor(public payload: ITrainingPlan) { }
}
export class LoadPlanFailAction implements Action {
    readonly type = LOAD_PLAN_FAIL;
    constructor(public payload: any) { }
}
/**
 * Add Plan Actions
 */
export class AddPlanAction implements Action {
    readonly type = ADD_PLAN;
    constructor(public payload: ITrainingPlan) { }
}
export class AddPlanSuccessAction implements Action {
    readonly type = ADD_PLAN_SUCCESS;
    constructor(public payload: ITrainingPlan) { }
}
export class AddPlanFailAction implements Action {
    readonly type = ADD_PLAN_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Plan Actions
 */
export class EditPlanAction implements Action {
    readonly type = EDIT_PLAN;
    constructor(public payload: ITrainingPlan,
        public withLoading: boolean = true,
        public withResult: boolean = true) { }
}
export class EditPlanSuccessAction implements Action {
    readonly type = EDIT_PLAN_SUCCESS;
    constructor(public payload: ITrainingPlan,
        public withLoading: boolean = true,
        public withResult: boolean = true) { }
}
export class EditPlanFailAction implements Action {
    readonly type = EDIT_PLAN_FAIL;
    constructor(public payload: any) { }
}
/**
 * Remove Plan Actions
 */
export class RemovePlanAction implements Action {
    readonly type = REMOVE_PLAN;
    constructor(public payload: ITrainingPlan) { }
}
export class RemovePlanSuccessAction implements Action {
    readonly type = REMOVE_PLAN_SUCCESS;
    constructor(public payload: ITrainingPlan) { }
}
export class RemovePlanFailAction implements Action {
    readonly type = REMOVE_PLAN_FAIL;
    constructor(public payload: any) { }
}


/**
 * Load Plan Copy Actions
 */
export class LoadPlanCopyAction implements Action {
    readonly type = LOAD_PLAN_COPY;
    constructor(public id: number) { }
}
export class LoadPlanCopySuccessAction implements Action {
    readonly type = LOAD_PLAN_COPY_SUCCESS;
    constructor(public payload: ITrainingPlan) { }
}
export class LoadPlanCopyFailAction implements Action {
    readonly type = LOAD_PLAN_COPY_FAIL;
    constructor(public payload: any) { }
}

/**
 * Add Plan Copy Actions
 */
export class AddPlanCopyAction implements Action {
    readonly type = ADD_PLAN_COPY;
    constructor(public payload: ITrainingPlan) { }
}
export class AddPlanCopySuccessAction implements Action {
    readonly type = ADD_PLAN_COPY_SUCCESS;
    constructor(public payload: ITrainingPlan) { }
}
export class AddPlanCopyFailAction implements Action {
    readonly type = ADD_PLAN_COPY_FAIL;
    constructor(public payload: any) { }
}

/**
 * Edit Plan Copy Actions
 */
export class EditPlanCopyAction implements Action {
    readonly type = EDIT_PLAN_COPY;
    constructor(public payload: ITrainingPlan,
        public withLoading: boolean = true,
        public withResult: boolean = true) { }
}
export class EditPlanCopySuccessAction implements Action {
    readonly type = EDIT_PLAN_COPY_SUCCESS;
    constructor(public payload: ITrainingPlan,
        public withLoading: boolean = true,
        public withResult: boolean = true) { }
}
export class EditPlanCopyFailAction implements Action {
    readonly type = EDIT_PLAN_COPY_FAIL;
    constructor(public payload: any) { }
}


/**
 * Remove Plan Copy Actions
 */
export class RemovePlanCopyAction implements Action {
    readonly type = REMOVE_PLAN_COPY;
    constructor(public payload: ITrainingPlan) { }
}
export class RemovePlanCopySuccessAction implements Action {
    readonly type = REMOVE_PLAN_COPY_SUCCESS;
    constructor(public payload: ITrainingPlan) { }
}
export class RemovePlanCopyFailAction implements Action {
    readonly type = REMOVE_PLAN_COPY_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load Units Actions
 */
export class LoadUnitsAction implements Action {
    readonly type = LOAD_UNITS;
    constructor(public filter: IUnitFilter = DEFAULT_UNIT_FILTER, public force: boolean = false, public withoutLoading = false) { }
}
export class LoadUnitsSuccessAction implements Action {
    readonly type = LOAD_UNITS_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingUnit>) { }
}
export class LoadUnitsFailAction implements Action {
    readonly type = LOAD_UNITS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Units Page Actions
 */
export class LoadUnitsPageAction implements Action {
    readonly type = LOAD_UNITS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadUnitsPageSuccessAction implements Action {
    readonly type = LOAD_UNITS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingUnit>) { }
}
export class LoadUnitsPageFailAction implements Action {
    readonly type = LOAD_UNITS_PAGE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Add Unit Actions
 */
export class AddUnitAction implements Action {
    readonly type = ADD_UNIT;
    constructor(public payload: ITrainingUnit) { }
}
export class AddUnitSuccessAction implements Action {
    readonly type = ADD_UNIT_SUCCESS;
    constructor(public payload: ITrainingUnit) { }
}
export class AddUnitFailAction implements Action {
    readonly type = ADD_UNIT_FAIL;
    constructor(public payload: any) { }
}

/**
 * Edit Unit Actions
 */
export class EditUnitAction implements Action {
    readonly type = EDIT_UNIT;
    constructor(public payload: ITrainingUnit) { }
}
export class EditUnitSuccessAction implements Action {
    readonly type = EDIT_UNIT_SUCCESS;
    constructor(public payload: ITrainingUnit) { }
}
export class EditUnitFailAction implements Action {
    readonly type = EDIT_UNIT_FAIL;
    constructor(public payload: any) { }
}

/**
 * Remove Unit Actions
 */
export class RemoveUnitAction implements Action {
    readonly type = REMOVE_UNIT;
    constructor(public payload: ITrainingUnit) { }
}
export class RemoveUnitSuccessAction implements Action {
    readonly type = REMOVE_UNIT_SUCCESS;
    constructor(public payload: ITrainingUnit) { }
}
export class RemoveUnitFailAction implements Action {
    readonly type = REMOVE_UNIT_FAIL;
    constructor(public payload: any) { }
}


/**
 * Load Unit copy Actions
 */
export class LoadUnitCopyAction implements Action {
    readonly type = LOAD_UNIT_COPY;
    constructor(public payload: number, public unitDataId: number) { }
}
export class LoadUnitCopySuccessAction implements Action {
    readonly type = LOAD_UNIT_COPY_SUCCESS;
    constructor(public payload: ITrainingUnit) { }
}
export class LoadUnitCopyFailAction implements Action {
    readonly type = LOAD_UNIT_COPY_FAIL;
    constructor(public payload: any) { }
}

/**
 * Edit Unit copy Actions
 */
export class EditUnitCopyAction implements Action {
    readonly type = EDIT_UNIT_COPY;
    constructor(public payload: ITrainingUnit) { }
}
export class EditUnitCopySuccessAction implements Action {
    readonly type = EDIT_UNIT_COPY_SUCCESS;
    constructor(public payload: ITrainingUnit) { }
}
export class EditUnitCopyFailAction implements Action {
    readonly type = EDIT_UNIT_COPY_FAIL;
    constructor(public payload: any) { }
}

/**
 * Remove Unit copy Actions
 */
export class RemoveUnitCopyAction implements Action {
    readonly type = REMOVE_UNIT_COPY;
    constructor(public payload: ITrainingUnit) { }
}
export class RemoveUnitCopySuccessAction implements Action {
    readonly type = REMOVE_UNIT_COPY_SUCCESS;
    constructor(public payload: ITrainingUnit) { }
}
export class RemoveUnitCopyFailAction implements Action {
    readonly type = REMOVE_UNIT_COPY_FAIL;
    constructor(public payload: any) { }
}


/**
 * Load Categories Actions
 */
export class LoadCategoriesAction implements Action {
    readonly type = LOAD_CATEGORIES;
}
export class LoadCategoriesSuccessAction implements Action {
    readonly type = LOAD_CATEGORIES_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingCategory>) { }
}
export class LoadCategoriesFailAction implements Action {
    readonly type = LOAD_CATEGORIES_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Categories Page Actions
 */
export class LoadCategoriesPageAction implements Action {
    readonly type = LOAD_CATEGORIES_PAGE;
    constructor(public page: number = null) { }
}
export class LoadCategoriesPageSuccessAction implements Action {
    readonly type = LOAD_CATEGORIES_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingCategory>) { }
}
export class LoadCategoriesPageFailAction implements Action {
    readonly type = LOAD_CATEGORIES_PAGE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Add Category Actions
 */
export class AddCategoryAction implements Action {
    readonly type = ADD_CATEGORY;
    constructor(public payload: ITrainingCategory) { }
}
export class AddCategorySuccessAction implements Action {
    readonly type = ADD_CATEGORY_SUCCESS;
    constructor(public payload: ITrainingCategory) { }
}
export class AddCategoryFailAction implements Action {
    readonly type = ADD_CATEGORY_FAIL;
    constructor(public payload: any) { }
}

/**
 * Remove Category Actions
 */
export class RemoveCategoryAction implements Action {
    readonly type = REMOVE_CATEGORY;
    constructor(public payload: ITrainingCategory) { }
}
export class RemoveCategorySuccessAction implements Action {
    readonly type = REMOVE_CATEGORY_SUCCESS;
    constructor(public payload: ITrainingCategory) { }
}
export class RemoveCategoryFailAction implements Action {
    readonly type = REMOVE_CATEGORY_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load Techniques Actions
 */
export class LoadTechniquesAction implements Action {
    readonly type = LOAD_TECHNIQUES;
}
export class LoadTechniquesSuccessAction implements Action {
    readonly type = LOAD_TECHNIQUES_SUCCESS;
    constructor(public payload: IPagingResult<ITechnique>) { }
}
export class LoadTechniquesFailAction implements Action {
    readonly type = LOAD_TECHNIQUES_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Techniques Page Actions
 */
export class LoadTechniquesPageAction implements Action {
    readonly type = LOAD_TECHNIQUES_PAGE;
    constructor(public page: number = null) { }
}
export class LoadTechniquesPageSuccessAction implements Action {
    readonly type = LOAD_TECHNIQUES_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<ITechnique>) { }
}
export class LoadTechniquesPageFailAction implements Action {
    readonly type = LOAD_TECHNIQUES_PAGE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Add Technique Actions
 */
export class AddTechniqueAction implements Action {
    readonly type = ADD_TECHNIQUE;
    constructor(public payload: ITechnique) { }
}
export class AddTechniqueSuccessAction implements Action {
    readonly type = ADD_TECHNIQUE_SUCCESS;
    constructor(public payload: ITechnique) { }
}
export class AddTechniqueFailAction implements Action {
    readonly type = ADD_TECHNIQUE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Edit Technique Actions
 */
export class EditTechniqueAction implements Action {
    readonly type = EDIT_TECHNIQUE;
    constructor(public payload: ITechnique) { }
}
export class EditTechniqueSuccessAction implements Action {
    readonly type = EDIT_TECHNIQUE_SUCCESS;
    constructor(public payload: ITechnique) { }
}
export class EditTechniqueFailAction implements Action {
    readonly type = EDIT_TECHNIQUE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Remove Technique Actions
 */
export class RemoveTechniqueAction implements Action {
    readonly type = REMOVE_TECHNIQUE;
    constructor(public payload: ITechnique) { }
}
export class RemoveTechniqueSuccessAction implements Action {
    readonly type = REMOVE_TECHNIQUE_SUCCESS;
    constructor(public payload: ITechnique) { }
}
export class RemoveTechniqueFailAction implements Action {
    readonly type = REMOVE_TECHNIQUE_FAIL;
    constructor(public payload: any) { }
}


/**
 * Load Sheets Actions
 */
export class LoadSheetsAction implements Action {
    readonly type = LOAD_SHEETS;
}
export class LoadSheetsSuccessAction implements Action {
    readonly type = LOAD_SHEETS_SUCCESS;
    constructor(public payload: ITrainingPlanSheet[]) { }
}
export class LoadSheetsFailAction implements Action {
    readonly type = LOAD_SHEETS_FAIL;
    constructor(public payload: any) { }
}


/**
 * Load Sheet Actions
 */
export class LoadSheetAction implements Action {
    readonly type = LOAD_SHEET;
    constructor(public id: number) { }
}
export class LoadSheetSuccessAction implements Action {
    readonly type = LOAD_SHEET_SUCCESS;
    constructor(public payload: ITrainingPlanSheet) { }
}
export class LoadSheetFailAction implements Action {
    readonly type = LOAD_SHEET_FAIL;
    constructor(public payload: any) { }
}

/**
 * Add Sheet Actions
 */
export class AddSheetAction implements Action {
    readonly type = ADD_SHEET;
    constructor(public payload: ITrainingPlanSheet) { }
}
export class AddSheetSuccessAction implements Action {
    readonly type = ADD_SHEET_SUCCESS;
    constructor(public payload: ITrainingPlanSheet) { }
}
export class AddSheetFailAction implements Action {
    readonly type = ADD_SHEET_FAIL;
    constructor(public payload: any) { }
}

/**
 * Edit Sheet Actions
 */
export class EditSheetAction implements Action {
    readonly type = EDIT_SHEET;
    constructor(public payload: ITrainingPlanSheet, public skipLoading: boolean = false) { }
}
export class EditSheetSuccessAction implements Action {
    readonly type = EDIT_SHEET_SUCCESS;
    constructor(public payload: ITrainingPlanSheet) { }
}
export class EditSheetFailAction implements Action {
    readonly type = EDIT_SHEET_FAIL;
    constructor(public payload: any) { }
}

/**
 * Remove Sheet Actions
 */
export class RemoveSheetAction implements Action {
    readonly type = REMOVE_SHEET;
    constructor(public payload: number) { }
}
export class RemoveSheetSuccessAction implements Action {
    readonly type = REMOVE_SHEET_SUCCESS;
    constructor(public payload: number) { }
}
export class RemoveSheetFailAction implements Action {
    readonly type = REMOVE_SHEET_FAIL;
    constructor(public payload: any) { }
}

/**
 * Copy Sheet As Template Actions
 */
 export class CopySheetAsTemlateAction implements Action {
    readonly type = COPY_SHEET_AS_TEMPLATE;
    constructor(public payload: number) { }
}
export class CopySheetAsTemlateSuccessAction implements Action {
    readonly type = COPY_SHEET_AS_TEMPLATE_SUCCESS;
    constructor(public payload: number) { }
}
export class CopySheetAsTemlateFailAction implements Action {
    readonly type = COPY_SHEET_AS_TEMPLATE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load Templates Actions
 */
export class LoadTemplatesAction implements Action {
    readonly type = LOAD_TEMPLATES;
}
export class LoadTemplatesSuccessAction implements Action {
    readonly type = LOAD_TEMPLATES_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingTemplate>) { }
}
export class LoadTemplatesFailAction implements Action {
    readonly type = LOAD_TEMPLATES_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Templates Page Actions
 */
export class LoadTemplatesPageAction implements Action {
    readonly type = LOAD_TEMPLATES_PAGE;
    constructor(public page: number = null) { }
}
export class LoadTemplatesPageSuccessAction implements Action {
    readonly type = LOAD_TEMPLATES_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingTemplate>) { }
}
export class LoadTemplatesPageFailAction implements Action {
    readonly type = LOAD_TEMPLATES_PAGE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Add Template Actions
 */
export class AddTemplateAction implements Action {
    readonly type = ADD_TEMPLATE;
    constructor(public payload: ITrainingTemplate) { }
}
export class AddTemplateSuccessAction implements Action {
    readonly type = ADD_TEMPLATE_SUCCESS;
    constructor(public payload: ITrainingTemplate) { }
}
export class AddTemplateFailAction implements Action {
    readonly type = ADD_TEMPLATE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Template Actions
 */
export class EditTemplateAction implements Action {
    readonly type = EDIT_TEMPLATE;
    constructor(public payload: ITrainingTemplate) { }
}
export class EditTemplateSuccessAction implements Action {
    readonly type = EDIT_TEMPLATE_SUCCESS;
    constructor(public payload: ITrainingTemplate) { }
}
export class EditTemplateFailAction implements Action {
    readonly type = EDIT_TEMPLATE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Remove Template Actions
 */
export class RemoveTemplateAction implements Action {
    readonly type = REMOVE_TEMPLATE;
    constructor(public payload: number) { }
}
export class RemoveTemplateSuccessAction implements Action {
    readonly type = REMOVE_TEMPLATE_SUCCESS;
    constructor(public payload: number) { }
}
export class RemoveTemplateFailAction implements Action {
    readonly type = REMOVE_TEMPLATE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Copy Training Plan Actions
 */
export class CopyTrainingPlanAction implements Action {
    readonly type = COPY_PLAN;
    constructor(public planId: number, public name: string) { }
}
export class CopyTrainingPlanSuccessAction implements Action {
    readonly type = COPY_PLAN_SUCCESS;
    constructor(public payload: ITrainingPlan) { }
}
export class CopyTrainingPlanFailAction implements Action {
    readonly type = COPY_PLAN_FAIL;
    constructor(public payload: any) { }
}
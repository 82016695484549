import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UserRole } from '@models/authentication.model';
import { IUser } from '@models/user.model';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/store/app.states';
import { selectUser } from 'app/core/store/reducers/auth.reducers';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss'],
})
export class NoDataComponent implements OnInit, OnDestroy {
  @Input() text: string;
  coachLogo: string;
  subs: Subscription[] = [];

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.subs[this.subs.length] = this.store
      .select((state) => selectUser(state.auth))
      .subscribe((user: IUser) => {
        if (user) {
          if (user.role == UserRole.coach) {
            this.coachLogo = user.image;
          }
          else if (user.role == UserRole.athlete && (user.coach || user.assigned_coach?.coach)) {
            this.coachLogo = user.coach ? user.coach.image : user.assigned_coach.coach?.image;
          }
        }
        else {
          this.coachLogo = null;
        }
      });
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

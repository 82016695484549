import { Component, EventEmitter, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ILoginForm } from '@models/authentication.model';
import { AppValidators } from "@validators/validators";
import { environment } from "environments/environment";
import { Subscription } from "rxjs";

@Component({
    selector: 'login-form',
    templateUrl: './login-form.component.html',
    styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {

    @Output() onSubmit = new EventEmitter<ILoginForm>();
    @Output() onForgetPassword = new EventEmitter();
    @Output() onSignUp = new EventEmitter();

    showPassword: boolean;
    loginForm: UntypedFormGroup;
    hasMailFocus = false;
    hasMailValue = false;
    hasPasswordFocus = false;
    hasPasswordValue = false;
    mailSub: Subscription;
    passwordSub: Subscription;

    constructor(private formBuilder: UntypedFormBuilder) {
    }

    ngOnInit() {
        if (!environment.production) {
            this.loginForm = this.formBuilder.group({
                email: ['', [Validators.required, AppValidators.Email]],
                password: ['', [Validators.required, Validators.minLength(1)]],
            });
        }
        else {
            this.loginForm = this.formBuilder.group({
                email: ['', [Validators.required, AppValidators.Email]],
                password: ['', [Validators.required, Validators.minLength(1)]],
            });
        }

        this.mailSub = this.loginForm.get("email").valueChanges.subscribe(x => {
            this.hasMailValue = x !== "" ? true : false;
        })
        this.passwordSub = this.loginForm.get("password").valueChanges.subscribe(x => {
            this.hasPasswordValue = x !== "" ? true : false;
        })
    }

    onSubmitClicked(): void {
        if (this.loginForm.valid) {
            this.onSubmit.emit(this.loginForm.value);
        }
    }

    onForgetClicked() {
        this.onForgetPassword.emit();
    }

    onSignupClicked() {
        this.onSignUp.emit();
    }

    onFocusMail() {
        this.hasMailFocus = true;
    }
    onLoseFocusMail() {
        this.hasMailFocus = false;
    }

    onFocusPassword() {
        this.hasPasswordFocus = true;
    }
    onLoseFocusPassword() {
        this.hasPasswordFocus = false;
    }

    ngOnDestroy() {
        this.mailSub.unsubscribe();
        this.passwordSub.unsubscribe();
    }

}

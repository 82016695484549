/*
 *ngFor="let c of oneDimArray | sortBy:SortDirection.ASC"
 *ngFor="let c of arrayOfObjects | sortBy:SortDirection.ASC:'propertyName'"
*/
import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';
import { SortDirection } from '../enums/sort-direction.enum';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

    transform(value: any[], order = '', column: string = ''): any[] {
        if (!value || order === '' || !order) { return value; } // no array
        if (value.length <= 1) { return value; } // array with only one item
        if (!column || column === '') {
            if (order === SortDirection.ASC) { return value.sort() }
            else { return value.sort().reverse(); }
        } // sort 1d array
        return orderBy(value, [column], [order]);
    }
}
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-area {
  width: 100%;
  height: calc(100vw - 2 * var(--ion-padding));
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  border-radius: 4px;
  border: 1px solid var(--ons-color-light-grey);
  max-width: 500px;
  max-height: 500px;
}
.avatar-area .instructions {
  margin-top: 10px;
  max-width: 60vw;
  font-size: 12px;
  font-family: var(--ons-font-medium);
  color: var(--ons-color-primary-black);
  opacity: 0.2;
  text-align: center;
  margin-bottom: 0;
}
.avatar-area .add-your-logo {
  font-family: var(--ons-font-bold);
  font-size: 16px;
  display: flex;
}
.avatar-area .add-your-logo .part-1 {
  color: var(--ons-color-primary-black);
  margin-right: 4px;
}
.avatar-area .add-your-logo .part-2 {
  color: var(--ons-color-primary);
}

input[type=file] {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  height: 1px;
  width: 1px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/update-avatar/update-avatar.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,4CAAA;EACA,uBAAA;EACA,4BAAA;EACA,sBAAA;EACA,2BAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;EACA,kBAAA;EACA,6CAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACI;EACI,gBAAA;EACA,eAAA;EACA,eAAA;EACA,mCAAA;EACA,qCAAA;EACA,YAAA;EACA,kBAAA;EACA,gBAAA;AACR;AAEI;EACI,iCAAA;EACA,eAAA;EACA,aAAA;AAAR;AAEQ;EACI,qCAAA;EACA,iBAAA;AAAZ;AAGQ;EACI,+BAAA;AADZ;;AAMA;EACI,UAAA;EACA,oBAAA;EACA,kBAAA;EACA,WAAA;EACA,UAAA;AAHJ","sourcesContent":[".avatar-area {\n    width: 100%;\n    height: calc(100vw - 2 * var(--ion-padding));\n    background-color: white;\n    background-repeat: no-repeat;\n    background-size: cover;\n    background-position: center;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    margin: auto;\n    border-radius: 4px;\n    border: 1px solid var(--ons-color-light-grey);\n    max-width: 500px;\n    max-height: 500px;\n\n    .instructions {\n        margin-top: 10px;\n        max-width: 60vw;\n        font-size: 12px;\n        font-family: var(--ons-font-medium);\n        color: var(--ons-color-primary-black);\n        opacity: 0.2;\n        text-align: center;\n        margin-bottom: 0;\n    }\n\n    .add-your-logo {\n        font-family: var(--ons-font-bold);\n        font-size: 16px;\n        display: flex;\n\n        .part-1 {\n            color: var(--ons-color-primary-black);\n            margin-right: 4px;\n        }\n\n        .part-2 {\n            color: var(--ons-color-primary);\n        }\n    }\n}\n\ninput[type=\"file\"] {\n    opacity: 0;\n    pointer-events: none;\n    position: absolute;\n    height: 1px;\n    width: 1px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

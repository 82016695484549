import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

/**
 * Prefixes all requests not starting with `http[s]` with `environment.serverUrl`.
 */

@Injectable()
export class LocaleInterceptor implements HttpInterceptor {

  constructor(@Inject(PLATFORM_ID) private platformId: Object,
    private translateService: TranslateService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let lang = this.translateService.currentLang;
    if (lang) {
      request = request.clone({ headers: request.headers.set('Accept-Language', lang) });
    }

    return next.handle(request);
  }
}

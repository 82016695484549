import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { UserRole } from '@models/authentication.model';
import { IMesocycle } from '@models/mesocycle.model';
import { IPagingResult } from '@models/paging.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../helpers/http.helper';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: "root",
})
export class MesocycleService {
    apiPath: string = 'mesocycles';

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    getAll(page: number = 1, withoutLoading = false): Observable<IPagingResult<IMesocycle>> {
        let userId = this.authService.getUserId();
        let pageQuery = `page=${page}`;

        let userRole = this.authService.getUserRole();
        let authQuery = userRole == UserRole.athlete ? 'athlete_id' : 'coach_id';
        authQuery += `=${userId}`;

        let http = this.http;
        if (withoutLoading) {
            http = http.disableLoading();
        }

        return http.get(`/${this.apiPath}?${authQuery}&withMacros=1&withBodyDatas=1&withTrainingPlan=1&${pageQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response.message;
                }
            })
        );
    }
    get(id: number): Observable<IMesocycle> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let query = userRole == UserRole.coach ?
            `coach_id=${userId}` :
            `athlete_id=${userId}`;
        return this.http.get(`/${this.apiPath}/${id}?${query}&withMacros=1&withBodyDatas=1&withTrainingPlan=1`).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data[0];
                } else {
                    throw response.message;
                }
            })
        );
    }

    add(model: IMesocycle) {
        let mesocycle = { ...model };
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        if (userRole == UserRole.athlete) {
            mesocycle.athlete_id = userId;
        }
        else {
            mesocycle.coach_id = userId;
        }

        return this.http.post(`/${this.apiPath}`, mesocycle);
    }

    edit(model: IMesocycle) {
        let mesocycle = {
            ...model,
            bodyDatas: undefined,
            body_data_details: model.bodyDatas?.map(x => x.body_data_detail?.id),
            macro_details: model.macros?.map(x => x.macro_detail?.id),
            training_plans: model.trainingPlans?.map(x => x.id),
            trainingPlans: undefined
        };

        return this.http.patch(`/${this.apiPath}/${mesocycle.id}?withMacros=1&withBodyDatas=1&withTrainingPlan=1`, mesocycle);
    }

    delete(id: number) {
        return this.http.delete(`/${this.apiPath}/${id}`)
    }
}
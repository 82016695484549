// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ons-button {
  margin-top: 30px;
}

ion-fab {
  bottom: 10px !important;
  display: flex;
}
ion-fab ion-fab-button {
  --background: var(--ons-color-primary);
  --color: var(--ons-color-primary-black);
  width: 60px;
  height: 60px;
  --box-shadow: none;
  font-size: 36px !important;
  margin-left: 10px;
}
ion-fab ion-fab-button ion-icon {
  font-size: 24px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/training-unit/categories-modal/categories-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,uBAAA;EACA,aAAA;AACJ;AACI;EACI,sCAAA;EACA,uCAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,0BAAA;EACA,iBAAA;AACR;AACQ;EACI,eAAA;AACZ","sourcesContent":[".ons-button {\n    margin-top: 30px;\n}\n\nion-fab {\n    bottom: 10px !important;\n    display: flex;\n\n    ion-fab-button {\n        --background: var(--ons-color-primary);\n        --color: var(--ons-color-primary-black);\n        width: 60px;\n        height: 60px;\n        --box-shadow: none;\n        font-size: 36px !important;\n        margin-left: 10px;\n\n        ion-icon {\n            font-size: 24px;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

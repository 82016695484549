import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ITrainingCategory } from '@models/training.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '@services/ui.service';
import { AppReducers } from 'app/core/store';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';
import { AddCategoryModalComponent } from './add-category-modal/add-category-modal.component';

@Component({
  selector: 'app-categories-modal',
  templateUrl: './categories-modal.component.html',
  styleUrls: ['./categories-modal.component.scss'],
})
export class CategoriesModalComponent implements OnInit, OnDestroy {
  @Input() categories: Array<ITrainingCategory>;
  @Input() activeCategories: Array<ITrainingCategory>;
  catSub: Subscription;
  checkCatLoadedSub: Subscription;
  deletingSub: Subscription;
  showButtons: boolean = false;

  constructor(private modalController: ModalController,
    private store: Store<AppState>,
    private uiService: UIService,
    private actions$: Actions,
    private translate: TranslateService) { }

  ngOnInit() {
    this.checkCatLoadedSub = this.store.select(AppReducers.getTrainingCategoriesLoaded)
      .subscribe(hasLoaded => {
        if (!hasLoaded) this.store.dispatch(new TrainingActions.LoadCategoriesAction());
      });
    this.catSub = this.store.select(AppReducers.getTrainingCategoriesCollection)
      .subscribe(data => {
        this.categories = data;
      });
    this.deletingSub = this.actions$.pipe(ofType(TrainingActions.REMOVE_CATEGORY_SUCCESS))
      .subscribe((data: any) => {
        this.activeCategories = this.activeCategories.filter(x => x.id != data.payload.id);
      })

      setTimeout(()=>{
        this.showButtons = true;
      }, 1000);
  }

  checkIfCategoryActive(category) {
    return this.activeCategories.some(x => x.id == category.id);
  }

  onSelectCategory($event) {
    let checked = $event.detail.checked;
    let value = $event.detail.value;

    if (checked) {
      this.activeCategories.push(value);
    }
    else {
      this.activeCategories = this.activeCategories.filter(x => x.id != value.id);
    }
  }

  onClickAddCategory() {
    this.modalController.create({
      component: AddCategoryModalComponent,
      swipeToClose: true
    })
      .then(modal => {
        modal.present();
      })
  }

  onClickDeleteCategory(category) {
    let name = category.name;
    this.uiService.confirmModal(this.translate.instant("base.modal.confirm_title"), this.translate.instant("base.modal.delete_text", { name })).then(res => {
      if (res) {
        this.store.dispatch(new TrainingActions.RemoveCategoryAction(category))
      }
    })
  }

  async onSave() {
    await this.modalController.dismiss(this.activeCategories);
  }

  async onClickCloseModal() {
    await this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.checkCatLoadedSub.unsubscribe();
    this.catSub.unsubscribe();
    this.deletingSub.unsubscribe();
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ITechnique, ITrainingCategory } from '@models/training.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '@services/ui.service';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-techniq-modal',
  templateUrl: './add-techniq-modal.component.html',
  styleUrls: ['./add-techniq-modal.component.scss'],
})
export class AddTechniqModalComponent implements OnInit, OnDestroy {
  techniq: ITechnique = {
    name: '',
    description: '',
    coach_id: null
  };
  creatingSub: Subscription;

  constructor(private modalController: ModalController,
    private uiService: UIService,
    private translate: TranslateService,
    private actions$: Actions,
    private store: Store<AppState>) { }

  ngOnInit() {
    this.creatingSub = this.actions$.pipe(ofType(TrainingActions.ADD_TECHNIQUE_SUCCESS))
      .subscribe(data => {
        this.uiService.showMessage(this.translate.instant('coach.techniques.add.success'), 'success', 2000);
        this.modalController.dismiss();
      })
  }

  add() {
    this.store.dispatch(new TrainingActions.AddTechniqueAction(this.techniq));
  }

  async onClickCloseModal() {
    await this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.creatingSub.unsubscribe();
  }
}

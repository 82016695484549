// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  font-size: 12px;
}
ion-content p {
  text-align: justify;
}
ion-content p.top-title {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/policy/policy.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;AACI;EACI,mBAAA;AACR;AACQ;EACI,kBAAA;AACZ","sourcesContent":["ion-content {\n    font-size: 12px;\n\n    p {\n        text-align: justify;\n\n        &.top-title {\n            text-align: center;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

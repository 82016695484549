import { INotification } from '@models/notificaton.model';
import { IPagingResult } from '@models/paging.model';
import { Action } from '@ngrx/store';

export const LOAD_NOTIFICATIONS = '[Notification] Load Notifications';
export const LOAD_NOTIFICATIONS_SUCCESS = '[Notification] Load Notifications Success';
export const LOAD_NOTIFICATIONS_FAIL = '[Notification] Load Notifications Fail';
export const LOAD_NOTIFICATIONS_PAGE = '[Notification] Load Notifications Page';
export const LOAD_NOTIFICATIONS_PAGE_SUCCESS = '[Notification] Load Notifications Page Success';
export const LOAD_NOTIFICATIONS_PAGE_FAIL = '[Notification] Load Notifications Page Fail';

export const READ_NOTIFICATIONS = '[Notification] Read Notifications';
export const READ_NOTIFICATIONS_SUCCESS = '[Notification] Read Notifications Success';
export const READ_NOTIFICATIONS_FAIL = '[Notification] Read Notifications Fail';

/**
 * Load Notifications Actions
 */
export class LoadNotificationsAction implements Action {
    readonly type = LOAD_NOTIFICATIONS;
    constructor(public force: boolean = false, public withoutLoading: boolean = false) { }
}
export class LoadNotificationsSuccessAction implements Action {
    readonly type = LOAD_NOTIFICATIONS_SUCCESS;
    constructor(public payload: IPagingResult<INotification>) { }
}
export class LoadNotificationsFailAction implements Action {
    readonly type = LOAD_NOTIFICATIONS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Notifications Page Actions
 */
export class LoadNotificationsPageAction implements Action {
    readonly type = LOAD_NOTIFICATIONS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadNotificationsPageSuccessAction implements Action {
    readonly type = LOAD_NOTIFICATIONS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<INotification>) { }
}
export class LoadNotificationsPageFailAction implements Action {
    readonly type = LOAD_NOTIFICATIONS_PAGE_FAIL;
    constructor(public payload: any) { }
}


/**
 * Read Notifications Actions
 */
export class ReadNotificationsAction implements Action {
    readonly type = READ_NOTIFICATIONS;
    constructor(public ids: number[]) { }
}
export class ReadNotificationsSuccessAction implements Action {
    readonly type = READ_NOTIFICATIONS_SUCCESS;
    constructor(public ids: number[]) { }
}
export class ReadNotificationsFailAction implements Action {
    readonly type = READ_NOTIFICATIONS_FAIL;
    constructor(public payload: any) { }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { UserRole } from '@models/authentication.model';
import { ITrainingPlanSheet } from '@models/training.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: "root",
})
export class TrainingPlanSheetService {
    apiSheetsPath: string = 'training_plan_sheets';

    apiTemplatesPath: string = 'copy_training_template';

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    getAll(): Observable<ITrainingPlanSheet[]> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let authQuery = userRole == UserRole.athlete ? 'athlete_id' : 'coach_id';
        authQuery += `=${userId}`;
        return this.http.get(`/${this.apiSheetsPath}?${authQuery}&with_units=1`).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data;
                } else {
                    throw response.message;
                }
            })
        );
    }

    get(id: number): Observable<ITrainingPlanSheet> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let query = userRole == UserRole.coach ?
            `coach_id=${userId}` :
            `athlete_id=${userId}`;
        return this.http.get(`/${this.apiSheetsPath}/${id}?${query}&with_units=1`).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data[0];
                } else {
                    throw response.message;
                }
            })
        );
    }

    add(model: ITrainingPlanSheet) {
        let userId = this.authService.getUserId();
        let sheet: any = {
            title: model.title,
            unit_data: [...model.units.map(x => x.unit_data?.id).filter(x => !!x)],
            units: undefined
        };
        let userRole = this.authService.getUserRole();
        if (userRole == UserRole.athlete) {
            sheet.athlete_id = userId;
        }
        else {
            sheet.coach_id = userId;
        }

        return this.http.post(`/${this.apiSheetsPath}`, sheet);
    }


    edit(model: ITrainingPlanSheet, skipLoading: boolean = false) {
        let userId = this.authService.getUserId();
        let sheet: any = {
            ...model,
            unit_data: [...model.units.map(x => x.unit_data?.id).filter(x => !!x)],
            units: undefined
        };
        let userRole = this.authService.getUserRole();
        if (userRole == UserRole.athlete) {
            sheet.athlete_id = userId;
        }
        else {
            sheet.coach_id = userId;
        }

        const http = skipLoading ? this.http.disableLoading() : this.http;
        return http.patch(`/${this.apiSheetsPath}/${sheet.id}`, sheet);
    }

    delete(id: number) {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let authQuery = userRole == UserRole.athlete ? 'athlete_id' : 'coach_id';
        authQuery += `=${userId}`;
        return this.http.delete(`/${this.apiSheetsPath}/${id}?${authQuery}`);
    }

    copyAsTemplate(id: number) {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let authQuery = userRole == UserRole.athlete ? 'athlete_id' : 'coach_id';
        authQuery += `=${userId}`;
        return this.http.post(`/${this.apiTemplatesPath}?${authQuery}&with_units=1`, {sheet_id: id});
    }
}
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon {
  cursor: pointer;
}

.follow-up {
  display: flex;
  align-items: center;
  font-size: 14px;
  cursor: pointer;
  margin-top: 5px;
  color: var(--ons-color-lighter-grey);
  width: max-content;
}
.follow-up ion-icon {
  margin: 0 5px;
  font-size: 16px;
}

ion-checkbox {
  z-index: 0;
}

ion-label,
ion-avatar,
ion-icon {
  z-index: 1;
}

ion-radio {
  z-index: 2;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/user-list/user-list.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;AACJ;;AACA;EACI,aAAA;EACA,mBAAA;EACA,eAAA;EACA,eAAA;EACA,eAAA;EACA,oCAAA;EACA,kBAAA;AAEJ;AAAI;EACI,aAAA;EACA,eAAA;AAER;;AAEA;EACI,UAAA;AACJ;;AAEA;;;EAGI,UAAA;AACJ;;AAEA;EACI,UAAA;AACJ","sourcesContent":["ion-icon {\n    cursor: pointer;\n}\n.follow-up {\n    display: flex;\n    align-items: center;\n    font-size: 14px;\n    cursor: pointer;\n    margin-top: 5px;\n    color: var(--ons-color-lighter-grey);\n    width: max-content;\n\n    ion-icon {\n        margin: 0 5px;\n        font-size: 16px;\n    }\n}\n\nion-checkbox{\n    z-index: 0;\n}\n\nion-label,\nion-avatar,\nion-icon {\n    z-index: 1;\n}\n\nion-radio{\n    z-index: 2;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

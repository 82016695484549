import { INotification, INotificationPagingResult } from '@models/notificaton.model';
import { INITIAL_PAGING, IPaging } from '@models/paging.model';
import { AuthActions, NotificationActions } from "../actions";

export interface State {
    loaded: boolean;
    loading: boolean;
    items: INotification[];
    unreadCount: number;
    paging: IPaging;
}

const initialState: State = {
    loaded: false,
    loading: false,
    items: [],
    unreadCount: null,
    paging: INITIAL_PAGING
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
        case NotificationActions.LOAD_NOTIFICATIONS:
        case NotificationActions.LOAD_NOTIFICATIONS_PAGE: {
            return Object.assign({}, state, {
                ...state,
                loading: true
            });
        }
        case NotificationActions.LOAD_NOTIFICATIONS_FAIL:
        case NotificationActions.LOAD_NOTIFICATIONS_PAGE_FAIL: {
            return Object.assign({}, state, {
                ...state,
                loading: false
            });
        }
        case NotificationActions.LOAD_NOTIFICATIONS_SUCCESS: {
            let resp: INotificationPagingResult = action.payload;

            return Object.assign({}, state, {
                loaded: resp.paging.currentPage == resp.paging.lastPage,
                loading: false,
                items: resp.items,
                unreadCount: resp.unread_count,
                paging: resp.paging
            });
        }
        case NotificationActions.LOAD_NOTIFICATIONS_PAGE_SUCCESS: {
            let resp: INotificationPagingResult = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.items, ...resp.items];

            return Object.assign({}, state, {
                loaded: resp.paging.currentPage == resp.paging.lastPage,
                loading: false,
                items: newItems,
                unreadCount: resp.unread_count,
                paging: resp.paging
            });
        }

        case NotificationActions.READ_NOTIFICATIONS_SUCCESS: {
            let ids: number[] = action.ids;

            let newItems = state.items.map(x => ids.includes(x.id) ? Object.assign({}, x, { is_read: true }) : x);

            return Object.assign({}, state, {
                items: newItems,
                unreadCount: state.unreadCount - ids.length
            });
        }

        case AuthActions.LOGIN_SUCCESS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}


export const getNotificationsLoaded = (state: State) => state.loaded;
export const getNotificationsLoading = (state: State) => state.loading;
export const getNotifications = (state: State) => state.items;
export const getNotificationsPaging = (state: State) => state.paging;
export const getNotificationsUnreadCount = (state: State) => state.unreadCount;
export const getNotification = (state: State, props) => state.items.find(x => x.id == props.id);
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.follow-up {
  padding: 20px;
}
.follow-up .manage-date {
  display: flex;
  align-items: center;
}
.follow-up .manage-date ion-icon {
  margin-right: 10px;
}
.follow-up .recurring-header {
  font-family: var(--ons-font-bold);
  font-size: 15px;
  margin-top: 15px;
}
.follow-up .recurring-block {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.follow-up .recurring-block .recurring {
  margin-left: 0;
}
.follow-up .recurring-block .recurring ion-item {
  font-size: 13px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/user-list/followup/followup.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,aAAA;EACA,mBAAA;AACR;AACQ;EACI,kBAAA;AACZ;AAGI;EACI,iCAAA;EACA,eAAA;EACA,gBAAA;AADR;AAII;EACI,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;AAFR;AAIQ;EACI,cAAA;AAFZ;AAIY;EACI,eAAA;AAFhB","sourcesContent":[".follow-up {\n    padding: 20px;\n\n    .manage-date {\n        display: flex;\n        align-items: center;\n\n        ion-icon {\n            margin-right: 10px;\n        }\n    }\n\n    .recurring-header {\n        font-family: var(--ons-font-bold);\n        font-size: 15px;\n        margin-top: 15px;\n    }\n\n    .recurring-block {\n        margin-top: 15px;\n        display: flex;\n        align-items: center;\n        justify-content: space-between;\n\n        .recurring {\n            margin-left: 0;\n\n            ion-item {\n                font-size: 13px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

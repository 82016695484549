import { Injectable } from "@angular/core";
import { act, Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { AthleteActions } from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.states';
import { AthleteService } from '@services/athlete.service';
import { AthleteTrackingService } from '@services/athlete-tracking.service';
import * as moment from 'moment';
import { S3FileUploadService } from '@services/s3.upload.service';
import { AthleteTrainingTrackingService } from '@services/athlete-training-tracking.service';
import { IPagingResult } from "@models/paging.model";
import { IGetUsersResponse } from "@models/user.model";
import { IAthleteTraining } from "@models/athlete-training.model";
import { ITrackingMesocycleOverview } from "@models/tracking-overview.model";
import { ITrainingMesocycleOverview } from "@models/training-overview.model";

@Injectable()
export class AthleteEffects {
    constructor(
        private athleteService: AthleteService,
        private athleteTrackingService: AthleteTrackingService,
        private athleteTrainingService: AthleteTrainingTrackingService,
        private s3Service: S3FileUploadService,
        private actions$: Actions,
        private store$: Store<AppState>
    ) { }

    loadAthletes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.LOAD_ATHLETES),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [AthleteActions.LoadAthletesAction, AppState]) => {
                return action.force || !storeState?.athlete?.athletes?.paging.currentPage;
            }),
            exhaustMap((action) => {
                return this.athleteService
                    .getAll()
                    .pipe(
                        map(([paging, payload]: [IPagingResult<any>, IGetUsersResponse]) => new AthleteActions.LoadAthletesSuccessAction(paging, payload)),
                        catchError((error) => of(new AthleteActions.LoadAthletesFailAction(error)))
                    )
            })
        )
    );
    loadAthletesPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.LOAD_ATHLETES_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [AthleteActions.LoadAthletesPageAction, AppState]) => {
                return storeState?.athlete?.athletes?.paging?.currentPage != 0 && (!action.page || storeState?.athlete?.athletes.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [AthleteActions.LoadAthletesPageAction, AppState]) => {
                let page = !action.page ? storeState.athlete.athletes.paging.currentPage + 1 : action.page;
                return this.athleteService
                    .getAll(page, true)
                    .pipe(
                        map(([paging, payload]: [IPagingResult<any>, IGetUsersResponse]) => new AthleteActions.LoadAthletesPageSuccessAction(paging, payload)),
                        catchError((error) => of(new AthleteActions.LoadAthletesPageFailAction(error)))
                    )
            })
        )
    );

    loadAthlete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.LOAD_ATHLETE),
            withLatestFrom(this.store$),
            filter(([action, storeState]) => {
                return !storeState?.athlete?.athletes?.loaded;
            }),
            exhaustMap(([action, storeState]: [AthleteActions.LoadAthleteAction, AppState]) => {
                return this.athleteService
                    .get(action.id)
                    .pipe(
                        map((res: any) => new AthleteActions.LoadAthleteSuccessAction(res)),
                        catchError((error) => of(new AthleteActions.LoadAthleteFailAction(error)))
                    )
            })
        )
    );

    cancelAssignment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.CANCEL_ASSIGNMENT),
            exhaustMap((action: AthleteActions.CancelAssignmentAction) =>
                this.athleteService
                    .cancelAssignment(action.athleteId)
                    .pipe(
                        map((res: any) => new AthleteActions.CancelAssignmentSuccessAction(action.athleteId)),
                        catchError((error) => of(new AthleteActions.CancelAssignmentFailAction(error)))
                    )
            )
        )
    );

    assignDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.ASSIGN_DOCUMENT),
            filter((action: AthleteActions.AssignDocumentAction) => {
                return action.docIds?.length > 0;
            }),
            exhaustMap((action: AthleteActions.AssignDocumentAction) =>
                this.athleteService
                    .assignDocument(action.docIds, action.athleteId)
                    .pipe(
                        map((res: any) => new AthleteActions.AssignDocumentSuccessAction()),
                        catchError((error) => of(new AthleteActions.AssignDocumentFailAction(error)))
                    )
            )
        )
    );

    unassignDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.UNASSIGN_DOCUMENT),
            exhaustMap((action: AthleteActions.UnassignDocumentAction) =>
                this.athleteService
                    .unassignDocument(action.id)
                    .pipe(
                        map((res: any) => new AthleteActions.UnassignDocumentSuccessAction()),
                        catchError((error) => of(new AthleteActions.UnassignDocumentFailAction(error)))
                    )
            )
        )
    );

    loadAthleteTracking$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.LOAD_TRACKING),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [AthleteActions.LoadTrackingAction, AppState]) => {
                let formattedDate = moment(action.payload ? action.payload : new Date()).format('YYYY-MM-DD');
                return action.force || !(storeState?.athlete.trackings[formattedDate]);
            }),
            exhaustMap(([action, storeState]: [AthleteActions.LoadTrackingAction, AppState]) => {
                return this.athleteTrackingService
                    .get(action.payload, action.disableLoading)
                    .pipe(
                        map((res: any) => new AthleteActions.LoadTrackingSuccessAction(res)),
                        catchError((error) => of(new AthleteActions.LoadTrackingFailAction(error)))
                    )
            })
        )
    );
    addTrackingValue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.ADD_TRACKING_VALUE),
            exhaustMap((action: AthleteActions.AddTrackingValueAction) =>
                this.athleteTrackingService
                    .addValue(action.payload)
                    .pipe(
                        map((res: any) => new AthleteActions.AddTrackingValueSuccessAction(res)),
                        catchError((error) => of(new AthleteActions.AddTrackingValueFailAction(error)))
                    )
            )
        )
    );
    editTrackingValue$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.EDIT_TRACKING_VALUE),
            exhaustMap((action: AthleteActions.EditTrackingValueAction) =>
                this.athleteTrackingService
                    .updateValue(action.payload)
                    .pipe(
                        map((res: any) => new AthleteActions.EditTrackingValueSuccessAction(res)),
                        catchError((error) => of(new AthleteActions.EditTrackingValueFailAction(error)))
                    )
            )
        )
    );


    s3UploadPhoto$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.S3_UPLOAD_PHOTO),
            exhaustMap((action: AthleteActions.S3UploadPhotoAction) =>
                this.s3Service
                    .uploadDocument(action.dataUrl)
                    .pipe(
                        map((res: any) => new AthleteActions.S3UploadPhotoSuccessAction(res.uri, action)),
                        catchError((error) => of(new AthleteActions.S3UploadPhotoFailAction(error)))
                    )
            )
        )
    );

    updatePhoto$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.S3_UPLOAD_PHOTO_SUCCESS),
            exhaustMap((action: AthleteActions.S3UploadPhotoSuccessAction) => {

                if (action.model.isEdit) {
                    let model = { ...action.model.editModel };
                    model.value = action.url;
                    return this.athleteTrackingService.updateValue(model)
                        .pipe(
                            map((res: any) => {
                                return new AthleteActions.EditTrackingValueSuccessAction(res);
                            }),
                            catchError((error) => of(new AthleteActions.EditTrackingValueFailAction(error)))
                        );
                }
                else {
                    let model = { ...action.model.addModel };
                    model.value = action.url;

                    return this.athleteTrackingService.addValue(model)
                        .pipe(
                            map((res: any) => {
                                return new AthleteActions.AddTrackingValueSuccessAction(res);
                            }),
                            catchError((error) => of(new AthleteActions.AddTrackingValueFailAction(error)))
                        );
                }
            })
        )
    );



    loadAthleteTraining$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.LOAD_TRAINING),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [AthleteActions.LoadTrainingAction, AppState]) => {
                if (action.force) {
                    return true;
                }
                let date = action.payload ? moment(action.payload) : moment();
                date = moment(date.format('YYYY-MM-DD'));

                if (storeState?.athlete?.trainings) {
                    let hasValues: boolean = false;
                    Object.values(storeState.athlete.trainings).forEach((training: IAthleteTraining) => {
                        if (training.week_range?.end && training.week_range.start) {
                            if (date.isSameOrBefore(moment(training.week_range.end)) && date.isSameOrAfter(moment(training.week_range.start))) {
                                hasValues = true;
                            }
                        }
                    })

                    return !hasValues;
                }

                return true;
            }),
            exhaustMap(([action, storeState]: [AthleteActions.LoadTrainingAction, AppState]) => {
                return this.athleteTrainingService
                    .get(action.payload)
                    .pipe(
                        map((res: any) => new AthleteActions.LoadTrainingSuccessAction(res)),
                        catchError((error) => of(new AthleteActions.LoadTrainingFailAction(error)))
                    )
            })
        )
    );
    addTrainingSet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.ADD_TRAINING_SET),
            exhaustMap((action: AthleteActions.AddTrainingSetAction) =>
                this.athleteTrainingService
                    .addSet(action.payload)
                    .pipe(
                        map((res: any) => new AthleteActions.AddTrainingSetSuccessAction(res)),
                        catchError((error) => of(new AthleteActions.AddTrainingSetFailAction(error)))
                    )
            )
        )
    );
    editTrainingSet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.EDIT_TRAINING_SET),
            exhaustMap((action: AthleteActions.EditTrainingSetAction) =>
                this.athleteTrainingService
                    .editSet(action.id, action.payload)
                    .pipe(
                        map((res: any) => new AthleteActions.EditTrainingSetSuccessAction(res)),
                        catchError((error) => of(new AthleteActions.EditTrainingSetFailAction(error)))
                    )
            )
        )
    );

    updateAssignedAthlete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.UPDATE_ASSIGNED_ATHLETE),
            mergeMap((action: AthleteActions.UpdateAssignedAthleteAction) =>
                this.athleteService
                    .update(action.athleteId, action.model)
                    .pipe(
                        map((res) => new AthleteActions.UpdateAssignedAthleteSuccessAction(action.athleteId, action.model)),
                        catchError((error) => of(new AthleteActions.UpdateAssignedAthleteFailAction(error)))
                    )
            )
        )
    );

    manageAthleteDate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.MANAGE_ATHLETE_DATE),
            exhaustMap((action: AthleteActions.ManageAthleteDateAction) =>
                this.athleteService
                    .setManageDate(action.model)
                    .pipe(
                        map((res) => new AthleteActions.ManageAthleteDateSuccessAction(action.model)),
                        catchError((error) => of(new AthleteActions.ManageAthleteDateFailAction(error)))
                    )
            )
        )
    );

    addTrainingNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.ADD_TRAINING_NOTE),
            exhaustMap((action: AthleteActions.AddTrainingNoteAction) =>
                this.athleteTrainingService
                    .addNote(action.payload)
                    .pipe(
                        map((res: any) => new AthleteActions.AddTrainingNoteSuccessAction(res, action.updateStore)),
                        catchError((error) => of(new AthleteActions.AddTrainingNoteFailAction(error)))
                    )
            )
        )
    );
    editTrainingNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.EDIT_TRAINING_NOTE),
            exhaustMap((action: AthleteActions.EditTrainingNoteAction) =>
                this.athleteTrainingService
                    .editNote(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new AthleteActions.EditTrainingNoteSuccessAction(res, action.updateStore)
                        }),
                        catchError((error) => of(new AthleteActions.EditTrainingNoteFailAction(error)))
                    )
            )
        )
    );
    deleteTrainingNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.DELETE_TRAINING_NOTE),
            exhaustMap((action: AthleteActions.DeleteTrainingNoteAction) =>
                this.athleteTrainingService
                    .deleteNote(action.id)
                    .pipe(
                        map((res: any) => new AthleteActions.DeleteTrainingNoteSuccessAction(action.id)),
                        catchError((error) => of(new AthleteActions.DeleteTrainingNoteFailAction(error)))
                    )
            )
        )
    );

    addTrainingUnitNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.ADD_TRAINING_UNIT_NOTE),
            exhaustMap((action: AthleteActions.AddTrainingUnitNoteAction) =>
                this.athleteTrainingService
                    .addUnitNote(action.payload)
                    .pipe(
                        map((res: any) => new AthleteActions.AddTrainingUnitNoteSuccessAction(res)),
                        catchError((error) => of(new AthleteActions.AddTrainingUnitNoteFailAction(error)))
                    )
            )
        )
    );
    editTrainingUnitNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.EDIT_TRAINING_UNIT_NOTE),
            exhaustMap((action: AthleteActions.EditTrainingUnitNoteAction) =>
                this.athleteTrainingService
                    .editUnitNote(action.payload)
                    .pipe(
                        map((res: any) => new AthleteActions.EditTrainingUnitNoteSuccessAction(res)),
                        catchError((error) => of(new AthleteActions.EditTrainingUnitNoteFailAction(error)))
                    )
            )
        )
    );
    deleteTrainingUnitNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.DELETE_TRAINING_UNIT_NOTE),
            exhaustMap((action: AthleteActions.DeleteTrainingUnitNoteAction) =>
                this.athleteTrainingService
                    .deleteUnitNote(action.id)
                    .pipe(
                        map((res: any) => new AthleteActions.DeleteTrainingUnitNoteSuccessAction(action.id)),
                        catchError((error) => of(new AthleteActions.DeleteTrainingUnitNoteFailAction(error)))
                    )
            )
        )
    );


    loadOverview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.LOAD_OVERVIEW),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [AthleteActions.LoadOverviewAction, AppState]) => {
                return action.force || !storeState?.athlete?.overview?.paging.currentPage;
            }),
            exhaustMap(([action, storeState]: [AthleteActions.LoadOverviewAction, AppState]) => {
                return this.athleteTrackingService
                    .getOverview()
                    .pipe(
                        map((res: any) => new AthleteActions.LoadOverviewSuccessAction(res)),
                        catchError((error) => of(new AthleteActions.LoadOverviewFailAction(error)))
                    )
            })
        )
    );
    loadOverviewPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.LOAD_OVERVIEW_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [AthleteActions.LoadOverviewPageAction, AppState]) => {
                return storeState?.athlete?.overview?.paging?.currentPage != 0 && (!action.page || storeState?.athlete?.overview.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [AthleteActions.LoadOverviewPageAction, AppState]) => {
                let page = !action.page ? storeState.athlete?.overview.paging.currentPage + 1 : action.page;
                return this.athleteTrackingService
                    .getOverview(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<ITrackingMesocycleOverview>) => new AthleteActions.LoadOverviewPageSuccessAction(pagingResult)),
                        catchError((error) => of(new AthleteActions.LoadOverviewPageFailAction(error)))
                    )
            })
        )
    );


    loadTrainingOverview$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.LOAD_TRAINING_OVERVIEW),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [AthleteActions.LoadTrainingOverviewAction, AppState]) => {
                return action.force || !storeState?.athlete?.trainingOverview?.paging.currentPage;
            }),
            exhaustMap(([action, storeState]: [AthleteActions.LoadTrainingOverviewAction, AppState]) => {
                return this.athleteTrackingService
                    .getTrainingOverview()
                    .pipe(
                        map((res: any) => new AthleteActions.LoadTrainingOverviewSuccessAction(res)),
                        catchError((error) => of(new AthleteActions.LoadTrainingOverviewFailAction(error)))
                    )
            })
        )
    );
    loadTrainingOverviewPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AthleteActions.LOAD_TRAINING_OVERVIEW_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [AthleteActions.LoadTrainingOverviewPageAction, AppState]) => {
                return storeState?.athlete?.trainingOverview?.paging?.currentPage != 0 && (!action.page || storeState?.athlete?.trainingOverview.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [AthleteActions.LoadTrainingOverviewPageAction, AppState]) => {
                let page = !action.page ? storeState.athlete?.trainingOverview.paging.currentPage + 1 : action.page;
                return this.athleteTrackingService
                    .getTrainingOverview(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<ITrainingMesocycleOverview>) => new AthleteActions.LoadTrainingOverviewPageSuccessAction(pagingResult)),
                        catchError((error) => of(new AthleteActions.LoadTrainingOverviewPageFailAction(error)))
                    )
            })
        )
    );
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { IInvitation } from '@models/invitation.model';
import { IUser } from '@models/user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../helpers/http.helper';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: "root",
})
export class CoachInvitationsService {
    apiPath: string = 'coach_invitations';

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    get(): Observable<IInvitation[]> {
        return this.http.get(`/${this.apiPath}`).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data;
                } else {
                    throw response.message;
                }
            })
        );
    }

    getByCode(code: string): Observable<IUser> {
        return this.http.get(`/${this.apiPath}?code=${code}`).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data[0];
                } else {
                    throw response.message;
                }
            })
        );
    }

    accept(coachId: number, code: string): Observable<IUser> {
        return this.http.post(`/coach_assignment`, { coach_id: coachId, code: code }).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    let user = response.data[0];
                    this.authService.setUserData(user);
                    return user;
                } else {
                    throw response.message;
                }
            })
        );
    }

    cancel(invitationId: number): Observable<any> {
        return this.http.patch(`/${this.apiPath}/${invitationId}`, { is_cancel: 1 }).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data[0];
                } else {
                    throw response.message;
                }
            })
        );
    }
}
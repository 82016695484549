import { AnalyticsActions, AuthActions, ProgressActions } from '../actions';
import { IBodyDataGroup, IMacroGroup } from '@models/analytics.model';
import { INITIAL_PAGING, IPaging, IPagingResult } from '@models/paging.model';

export interface State {
    bodyDataGroups: BodyDataGroupState;
    macroGroups: MacroGroupState;
}
export interface BodyDataGroupState {
    loaded: boolean;
    loading: boolean;
    items: IBodyDataGroup[];
    paging: IPaging;
}
export interface MacroGroupState {
    loaded: boolean;
    loading: boolean;
    items: IMacroGroup[];
    paging: IPaging;
}

const initialSubState = {
    loaded: false,
    loading: false,
    items: [],
    paging: INITIAL_PAGING
}
const initialState: State = {
    bodyDataGroups: initialSubState,
    macroGroups: initialSubState
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
        case AnalyticsActions.LOAD_BODY_DATA_GROUPS:
        case AnalyticsActions.LOAD_BODY_DATA_GROUPS_PAGE: {
            return Object.assign({}, state, {
                bodyDataGroups: {
                    ...state.bodyDataGroups,
                    loading: true
                }
            });
        }
        case AnalyticsActions.LOAD_BODY_DATA_GROUPS_FAIL:
        case AnalyticsActions.LOAD_BODY_DATA_GROUPS_PAGE_FAIL: {
            return Object.assign({}, state, {
                bodyDataGroups: {
                    ...state.bodyDataGroups,
                    loading: false
                }
            });
        }
        case AnalyticsActions.LOAD_BODY_DATA_GROUPS_SUCCESS: {
            let payload: IPagingResult<IBodyDataGroup> = action.payload;

            return Object.assign({}, state, {
                bodyDataGroups: {
                    loaded: payload.paging.currentPage == payload.paging.lastPage,
                    loading: false,
                    items: payload.items,
                    paging: payload.paging
                }
            });
        }
        case AnalyticsActions.LOAD_BODY_DATA_GROUPS_PAGE_SUCCESS: {
            let payload: IPagingResult<IBodyDataGroup> = action.payload;
            let newBodyDatas = payload.paging.currentPage == 1 ? payload.items : [...state.bodyDataGroups.items, ...payload.items];

            return Object.assign({}, state, {
                bodyDataGroups: {
                    loaded: payload.paging.currentPage == payload.paging.lastPage,
                    loading: false,
                    items: newBodyDatas,
                    paging: payload.paging
                }
            });
        }


        case ProgressActions.ADD_BODY_DATA_SUCCESS:
        case ProgressActions.EDIT_BODY_DATA_SUCCESS:
        case ProgressActions.REMOVE_BODY_DATA_SUCCESS:
        case ProgressActions.EDIT_BODY_DATA_COPY_SUCCESS:
        case ProgressActions.REMOVE_BODY_DATA_COPY_SUCCESS: {
            return Object.assign({}, state, {
                bodyDataGroups: initialSubState
            });
        }




        case AnalyticsActions.LOAD_MACRO_GROUPS:
        case AnalyticsActions.LOAD_MACRO_GROUPS_PAGE: {
            return Object.assign({}, state, {
                macroGroups: {
                    ...state.macroGroups,
                    loading: true
                }
            });
        }
        case AnalyticsActions.LOAD_MACRO_GROUPS_FAIL:
        case AnalyticsActions.LOAD_MACRO_GROUPS_PAGE_FAIL: {
            return Object.assign({}, state, {
                macroGroups: {
                    ...state.macroGroups,
                    loading: false
                }
            });
        }
        case AnalyticsActions.LOAD_MACRO_GROUPS_SUCCESS: {
            let payload: IPagingResult<IMacroGroup> = action.payload;

            return Object.assign({}, state, {
                macroGroups: {
                    loaded: payload.paging.currentPage == payload.paging.lastPage,
                    loading: false,
                    items: payload.items,
                    paging: payload.paging
                }
            });
        }
        case AnalyticsActions.LOAD_MACRO_GROUPS_PAGE_SUCCESS: {
            let payload: IPagingResult<IMacroGroup> = action.payload;
            let newMacros = payload.paging.currentPage == 1 ? payload.items : [...state.macroGroups.items, ...payload.items];

            return Object.assign({}, state, {
                macroGroups: {
                    loaded: payload.paging.currentPage == payload.paging.lastPage,
                    loading: false,
                    items: newMacros,
                    paging: payload.paging
                }
            });
        }


        case ProgressActions.ADD_MACRO_SUCCESS:
        case ProgressActions.EDIT_MACRO_SUCCESS:
        case ProgressActions.REMOVE_MACRO_SUCCESS:
        case ProgressActions.EDIT_MACRO_COPY_SUCCESS:
        case ProgressActions.REMOVE_MACRO_COPY_SUCCESS: {
            return Object.assign({}, state, {
                macroGroups: initialSubState
            });
        }

        case AuthActions.LOGIN_SUCCESS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}


export const getBodyDataGroupsLoaded = (state: State) => state.bodyDataGroups.loaded;
export const getBodyDataGroupsLoading = (state: State) => state.bodyDataGroups.loading;
export const getBodyDataGroups = (state: State) => state.bodyDataGroups.items;
export const getBodyDataGroupsPaging = (state: State) => state.bodyDataGroups.paging;
export const getBodyDataGroup = (state: State, props) => state.bodyDataGroups.items.find(x => x.name == props.groupName);
export const getMacroGroups = (state: State) => state.macroGroups.items;
export const getMacroGroupsPaging = (state: State) => state.macroGroups.paging;
export const getMacroGroup = (state: State, props) => state.macroGroups.items.find(x => x.name == props.groupName);
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { LoadingController } from '@ionic/angular';
import { UserRole } from '@models/authentication.model';
import { IAssignedMesocycle, IMesocycle } from '@models/mesocycle.model';
import { IPagingResult } from '@models/paging.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { from, interval, Observable, Subject } from 'rxjs';
import { finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpHelper } from '../helpers/http.helper';
import { AppReducers } from '../store';
import { MesocycleActions } from '../store/actions';
import { AppState } from '../store/app.states';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: "root",
})
export class AssignedMesocycleService {
    apiPath: string = 'assigned_mesocycles';

    constructor(private http: HttpClient,
        private store: Store<AppState>,
        private loadingController: LoadingController,
        private translate: TranslateService,
        private authService: AuthService) { }

    detectExpiredMesocycle(): Observable<boolean> {
        let athleteId = +this.authService.getUserId();
        this.store.dispatch(new MesocycleActions.LoadAssignedMesocyclesAction(athleteId, true));
        return this.store.select(AppReducers.getAssignedMesocycles, { id: athleteId })
            .pipe(
                map((mesocycles: IAssignedMesocycle[]) => {
                    if (mesocycles != undefined) {
                        let activeMesocycle = mesocycles.find(x => x.is_active);
                        if (activeMesocycle) {
                            let documentEndDay = (new Date(activeMesocycle.date_to)).setHours(0, 0, 0, 0);
                            let today = (new Date()).setHours(0, 0, 0, 0);
                            if (today > documentEndDay) {
                                return true;
                            }
                        }
                        return false;
                    }
                })
            )
    }

    getAthleteMesocycles(athleteId: number, withoutLoading: boolean = false, page: number = 1): Observable<IPagingResult<IAssignedMesocycle>> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let query = userRole == UserRole.coach ?
            `filter[athlete]=${athleteId}&coach_id=${userId}` :
            `athlete_id=${userId}`;
        let pageQuery = `&page=${page}`;

        let httpClient = this.http;
        if (withoutLoading) {
            httpClient = httpClient.disableLoading();
        }

        return httpClient.get(`/${this.apiPath}?${query}${pageQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response.message;
                }
            })
        );
    }

    add(model: IAssignedMesocycle) {
        let assignedMesocycle = { ...model };
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        if (userRole == UserRole.athlete) {
            assignedMesocycle.athlete_id = userId;
        }
        else {
            assignedMesocycle.coach_id = userId;
        }

        return this.http.post(`/${this.apiPath}`, assignedMesocycle);
    }

    activate(id: number, athleteId: number|boolean, isActive = true) {
        
        let userId = !athleteId ? this.authService.getUserId() : athleteId;
        let assignedMesocycle = {
            athlete_id: userId,
            is_active: isActive
        };

        return this.http.patch(`/${this.apiPath}/${id}`, assignedMesocycle);
    }

    delete(id: number) {
        return this.http.delete(`/${this.apiPath}/${id}`)
    }

    setInvisible(id: number, is_invisible: boolean, date: moment.Moment) {
        let assignedMesocycle = {
            is_invisible: is_invisible,
            invisible_until: date ? date.format('YYYY-MM-DD HH:mm:ss') : null
        };
        return this.http.patch(`/${this.apiPath}/${id}`, assignedMesocycle)
    }

    copyToBaseMesocycle(name: string, mesocycleId: number) {
        const timer = interval(2000);
        const event = new Subject();

        let messagePath = 'base.loading';
        let message = this.translate.instant(messagePath);
        message = message == messagePath ? 'Bitte warten...' : message;

        return from(this.loadingController.create({
            message: message
        }))
            .pipe(
                tap((loading) => {
                    timer.pipe(takeUntil(event))
                        .subscribe(index => {
                            let textPath = 'fake_loading.mesocycle.msg' + index;
                            let text = this.translate.instant(textPath);
                            if (textPath != text) {
                                loading.message = `<div class="loading-icon-text"><ion-icon name="checkmark-circle-outline" class="loading-icon"></ion-icon>${text}</div>`;
                            }
                        });
                    return loading.present();
                }),
                switchMap((loading) => {
                    return this.http.disableLoading().post(`/copy_mesocycle?withMacros=1&withBodyDatas=1&withTrainingPlan=1`, { id: mesocycleId, name: name, option: 'assigned_mesocycle' })
                        .pipe(finalize(() => {
                            event.next({});
                            loading.dismiss();
                        }));
                })
            );
    }
}
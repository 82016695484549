// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content.activity-level-view {
  --background: var(--ons-color-primary-background);
}
ion-content.activity-level-view main.inner-scroll {
  padding: 10px !important;
}
ion-content.activity-level-view ion-row {
  justify-content: center;
}

ion-item {
  --transition: none;
  --background: none;
  --border-color: var(--ons-color-primary-background);
}

ion-radio-group {
  display: block;
  margin: 0 -30px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/pages/athlete-level/athlete-level.page.scss"],"names":[],"mappings":"AAAA;EACE,iDAAA;AACF;AAAE;EACE,wBAAA;AAEJ;AACE;EACE,uBAAA;AACJ;;AAGA;EACE,kBAAA;EACA,kBAAA;EACA,mDAAA;AAAF;;AAGA;EACE,cAAA;EACA,eAAA;AAAF","sourcesContent":["ion-content.activity-level-view {\n  --background: var(--ons-color-primary-background);\n  main.inner-scroll {\n    padding: 10px !important;\n  }\n\n  ion-row {\n    justify-content: center;\n  }\n}\n\nion-item {\n  --transition: none;\n  --background: none;\n  --border-color: var(--ons-color-primary-background);\n}\n\nion-radio-group {\n  display: block;\n  margin: 0 -30px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

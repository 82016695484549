import { IAddTrackingValue, IAthleteTracking, IUpdateTrackingValue } from '@models/athlete-tracking.model';
import { IAthleteTraining, IAddSetRequest, IEditSetRequest, ITrainingNote } from '@models/athlete-training.model';
import { IManageDateRequest, IUpdateAssignedAthleteRequest } from '@models/athlete.model';
import { IPagingResult } from '@models/paging.model';
import { ITrackingMesocycleOverview } from '@models/tracking-overview.model';
import { ITrainingMesocycleOverview } from '@models/training-overview.model';
import { IGetUsersResponse, IUser } from '@models/user.model';
import { Action } from '@ngrx/store';
import { AthleteActions } from '.';
import { ITrainingUnitNote } from '@models/training.model';

export const LOAD_ATHLETES = '[Athlete] Load Athletes';
export const LOAD_ATHLETES_SUCCESS = '[Athlete] Load Athletes Success';
export const LOAD_ATHLETES_FAIL = '[Athlete] Load Athletes Fail';
export const LOAD_ATHLETES_PAGE = '[Athlete] Load Athletes Page';
export const LOAD_ATHLETES_PAGE_SUCCESS = '[Athlete] Load Athletes Page Success';
export const LOAD_ATHLETES_PAGE_FAIL = '[Athlete] Load Athletes Page Fail';

export const LOAD_ATHLETE = '[Athlete] Load Athlete';
export const LOAD_ATHLETE_SUCCESS = '[Athlete] Load Athlete Success';
export const LOAD_ATHLETE_FAIL = '[Athlete] Load Athlete Fail';

export const CANCEL_ASSIGNMENT = '[Athlete] Cancel Assignment';
export const CANCEL_ASSIGNMENT_SUCCESS = '[Athlete] Cancel Assignment Success';
export const CANCEL_ASSIGNMENT_FAIL = '[Athlete] Cancel Assignment Fail';

export const ASSIGN_DOCUMENT = '[Athlete] Assign Document';
export const ASSIGN_DOCUMENT_SUCCESS = '[Athlete] Assign Document Success';
export const ASSIGN_DOCUMENT_FAIL = '[Athlete] Assign Document Fail';
export const UNASSIGN_DOCUMENT = '[Athlete] Unassign Document';
export const UNASSIGN_DOCUMENT_SUCCESS = '[Athlete] Unassign Document Success';
export const UNASSIGN_DOCUMENT_FAIL = '[Athlete] Unassign Document Fail';

export const LOAD_TRACKING = '[Athlete] Load Tracking';
export const LOAD_TRACKING_SUCCESS = '[Athlete] Load Tracking Success';
export const LOAD_TRACKING_FAIL = '[Athlete] Load Tracking Fail';
export const ADD_TRACKING_VALUE = '[Athlete] Add Tracking Value';
export const ADD_TRACKING_VALUE_SUCCESS = '[Athlete] Add Tracking Value Success';
export const ADD_TRACKING_VALUE_FAIL = '[Athlete] Add Tracking Value Fail';
export const EDIT_TRACKING_VALUE = '[Athlete] Edit Tracking Value';
export const EDIT_TRACKING_VALUE_SUCCESS = '[Athlete] Edit Tracking Value Success';
export const EDIT_TRACKING_VALUE_FAIL = '[Athlete] Edit Tracking Value Fail';

export const S3_UPLOAD_PHOTO = '[Athlete] S3 Upload Photo';
export const S3_UPLOAD_PHOTO_SUCCESS = '[Athlete] S3 Upload Photo Success';
export const S3_UPLOAD_PHOTO_FAIL = '[Athlete] S3 Upload Photo Fail';

export const LOAD_TRAINING = '[Athlete] Load Training';
export const LOAD_TRAINING_SUCCESS = '[Athlete] Load Training Success';
export const LOAD_TRAINING_FAIL = '[Athlete] Load Training Fail';
export const ADD_TRAINING_SET = '[Athlete] Add Training Set';
export const ADD_TRAINING_SET_SUCCESS = '[Athlete] Add Training Set Success';
export const ADD_TRAINING_SET_FAIL = '[Athlete] Add Training Set Fail';
export const EDIT_TRAINING_SET = '[Athlete] Edit Training Set';
export const EDIT_TRAINING_SET_SUCCESS = '[Athlete] Edit Training Set Success';
export const EDIT_TRAINING_SET_FAIL = '[Athlete] Edit Training Set Fail';

export const UPDATE_ASSIGNED_ATHLETE = '[Athlete] Update assigned athlete';
export const UPDATE_ASSIGNED_ATHLETE_SUCCESS = '[Athlete] Update assigned athlete Success';
export const UPDATE_ASSIGNED_ATHLETE_FAIL = '[Athlete] Update assigned athlete Fail';

export const MANAGE_ATHLETE_DATE = '[Athlete] Manage athlete date';
export const MANAGE_ATHLETE_DATE_SUCCESS = '[Athlete] Manage athlete date Success';
export const MANAGE_ATHLETE_DATE_FAIL = '[Athlete] Manage athlete date Fail';

export const ADD_TRAINING_NOTE = '[Athlete] Add Training Note';
export const ADD_TRAINING_NOTE_SUCCESS = '[Athlete] Add Training Note Success';
export const ADD_TRAINING_NOTE_FAIL = '[Athlete] Add Training Note Fail';
export const EDIT_TRAINING_NOTE = '[Athlete] Edit Training Note';
export const EDIT_TRAINING_NOTE_SUCCESS = '[Athlete] Edit Training Note Success';
export const EDIT_TRAINING_NOTE_FAIL = '[Athlete] Edit Training Note Fail';
export const DELETE_TRAINING_NOTE = '[Athlete] Delete Training Note';
export const DELETE_TRAINING_NOTE_SUCCESS = '[Athlete] Delete Training Note Success';
export const DELETE_TRAINING_NOTE_FAIL = '[Athlete] Delete Training Note Fail';

export const ADD_TRAINING_UNIT_NOTE = '[Athlete] Add Training Unit Note';
export const ADD_TRAINING_UNIT_NOTE_SUCCESS = '[Athlete] Add Training Unit Note Success';
export const ADD_TRAINING_UNIT_NOTE_FAIL = '[Athlete] Add Training Unit Note Fail';
export const EDIT_TRAINING_UNIT_NOTE = '[Athlete] Edit Training Unit Note';
export const EDIT_TRAINING_UNIT_NOTE_SUCCESS = '[Athlete] Edit Training Unit Note Success';
export const EDIT_TRAINING_UNIT_NOTE_FAIL = '[Athlete] Edit Training Unit Note Fail';
export const DELETE_TRAINING_UNIT_NOTE = '[Athlete] Delete Training Unit Note';
export const DELETE_TRAINING_UNIT_NOTE_SUCCESS = '[Athlete] Delete Training Unit Note Success';
export const DELETE_TRAINING_UNIT_NOTE_FAIL = '[Athlete] Delete Training Unit Note Fail';

export const LOAD_OVERVIEW = '[Athlete] Load Overview';
export const LOAD_OVERVIEW_SUCCESS = '[Athlete] Load Overview Success';
export const LOAD_OVERVIEW_FAIL = '[Athlete] Load Overview Fail';
export const LOAD_OVERVIEW_PAGE = '[Athlete] Load Overview Page';
export const LOAD_OVERVIEW_PAGE_SUCCESS = '[Athlete] Load Overview Page Success';
export const LOAD_OVERVIEW_PAGE_FAIL = '[Athlete] Load Overview Page Fail';

export const LOAD_TRAINING_OVERVIEW = '[Athlete] Load Training Overview';
export const LOAD_TRAINING_OVERVIEW_SUCCESS = '[Athlete] Load Training Overview Success';
export const LOAD_TRAINING_OVERVIEW_FAIL = '[Athlete] Load Training Overview Fail';
export const LOAD_TRAINING_OVERVIEW_PAGE = '[Athlete] Load Training Overview Page';
export const LOAD_TRAINING_OVERVIEW_PAGE_SUCCESS = '[Athlete] Load Training Overview Page Success';
export const LOAD_TRAINING_OVERVIEW_PAGE_FAIL = '[Athlete] Load Training Overview Page Fail';

export const SET_INITIAL = '[Athlete] Set initial state';


/**
 * Set initial
 */
export class SetInitialAction implements Action {
    readonly type = SET_INITIAL;
}

/**
 * Load Athletes Actions
 */
export class LoadAthletesAction implements Action {
    readonly type = LOAD_ATHLETES;
    constructor(public force: boolean = false) { }
}
export class LoadAthletesSuccessAction implements Action {
    readonly type = LOAD_ATHLETES_SUCCESS;
    constructor(public paging: IPagingResult<any>, public payload: IGetUsersResponse) { }
}
export class LoadAthletesFailAction implements Action {
    readonly type = LOAD_ATHLETES_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Athletes Page Actions
 */
export class LoadAthletesPageAction implements Action {
    readonly type = LOAD_ATHLETES_PAGE;
    constructor(public page: number = null) { }
}
export class LoadAthletesPageSuccessAction implements Action {
    readonly type = LOAD_ATHLETES_PAGE_SUCCESS;
    constructor(public paging: IPagingResult<any>, public payload: IGetUsersResponse) { }
}
export class LoadAthletesPageFailAction implements Action {
    readonly type = LOAD_ATHLETES_PAGE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Athlete Actions
 */
export class LoadAthleteAction implements Action {
    readonly type = LOAD_ATHLETE;
    constructor(public id: number) { }
}
export class LoadAthleteSuccessAction implements Action {
    readonly type = LOAD_ATHLETE_SUCCESS;
    constructor(public payload: IUser) { }
}
export class LoadAthleteFailAction implements Action {
    readonly type = LOAD_ATHLETE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Cancel Assignment Actions
 */
export class CancelAssignmentAction implements Action {
    readonly type = CANCEL_ASSIGNMENT;
    constructor(public athleteId: number = null) { }
}
export class CancelAssignmentSuccessAction implements Action {
    readonly type = CANCEL_ASSIGNMENT_SUCCESS;
    constructor(public athleteId: number = null) { }
}
export class CancelAssignmentFailAction implements Action {
    readonly type = CANCEL_ASSIGNMENT_FAIL;
    constructor(public payload: any) { }
}


/**
 * Assign Document Actions
 */
export class AssignDocumentAction implements Action {
    readonly type = ASSIGN_DOCUMENT;
    constructor(public docIds: number[], public athleteId: number) { }
}
export class AssignDocumentSuccessAction implements Action {
    readonly type = ASSIGN_DOCUMENT_SUCCESS;
}
export class AssignDocumentFailAction implements Action {
    readonly type = ASSIGN_DOCUMENT_FAIL;
    constructor(public payload: any) { }
}


/**
 * Unassign Document Actions
 */
export class UnassignDocumentAction implements Action {
    readonly type = UNASSIGN_DOCUMENT;
    constructor(public id: number) { }
}
export class UnassignDocumentSuccessAction implements Action {
    readonly type = UNASSIGN_DOCUMENT_SUCCESS;
}
export class UnassignDocumentFailAction implements Action {
    readonly type = UNASSIGN_DOCUMENT_FAIL;
    constructor(public payload: any) { }
}


/**
 * Load Tracking Actions
 */
export class LoadTrackingAction implements Action {
    readonly type = LOAD_TRACKING;
    constructor(public payload: Date = null, public disableLoading: boolean = true, public force: boolean = false) { }
}
export class LoadTrackingSuccessAction implements Action {
    readonly type = LOAD_TRACKING_SUCCESS;
    constructor(public payload: IAthleteTracking) { }
}
export class LoadTrackingFailAction implements Action {
    readonly type = LOAD_TRACKING_FAIL;
    constructor(public payload: any) { }
}
/**
 * Add Tracking Value Actions
 */
export class AddTrackingValueAction implements Action {
    readonly type = ADD_TRACKING_VALUE;
    constructor(public payload: IAddTrackingValue) { }
}
export class AddTrackingValueSuccessAction implements Action {
    readonly type = ADD_TRACKING_VALUE_SUCCESS;
    constructor(public payload: IAthleteTracking) { }
}
export class AddTrackingValueFailAction implements Action {
    readonly type = ADD_TRACKING_VALUE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Tracking Value Actions
 */
export class EditTrackingValueAction implements Action {
    readonly type = EDIT_TRACKING_VALUE;
    constructor(public payload: IUpdateTrackingValue) { }
}
export class EditTrackingValueSuccessAction implements Action {
    readonly type = EDIT_TRACKING_VALUE_SUCCESS;
    constructor(public payload: IAthleteTracking) { }
}
export class EditTrackingValueFailAction implements Action {
    readonly type = EDIT_TRACKING_VALUE_FAIL;
    constructor(public payload: any) { }
}


/**
 * S3 Upload Photo Actions
 */
export class S3UploadPhotoAction implements Action {
    readonly type = S3_UPLOAD_PHOTO;
    constructor(public dataUrl: string, public isEdit: boolean = false, public editModel: IUpdateTrackingValue = null, public addModel: IAddTrackingValue = null) { }
}
export class S3UploadPhotoSuccessAction implements Action {
    readonly type = S3_UPLOAD_PHOTO_SUCCESS;
    constructor(public url: string, public model: AthleteActions.S3UploadPhotoAction) { }
}
export class S3UploadPhotoFailAction implements Action {
    readonly type = S3_UPLOAD_PHOTO_FAIL;
    constructor(public payload: any) { }
}



/**
 * Load Training Actions
 */
export class LoadTrainingAction implements Action {
    readonly type = LOAD_TRAINING;
    constructor(public payload: Date = null, public force: boolean = false) { }
}
export class LoadTrainingSuccessAction implements Action {
    readonly type = LOAD_TRAINING_SUCCESS;
    constructor(public payload: IAthleteTraining) { }
}
export class LoadTrainingFailAction implements Action {
    readonly type = LOAD_TRAINING_FAIL;
    constructor(public payload: any) { }
}
/**
 * Add Training Set Actions
 */
export class AddTrainingSetAction implements Action {
    readonly type = ADD_TRAINING_SET;
    constructor(public payload: IAddSetRequest) { }
}
export class AddTrainingSetSuccessAction implements Action {
    readonly type = ADD_TRAINING_SET_SUCCESS;
    constructor(public payload: IAthleteTraining) { }
}
export class AddTrainingSetFailAction implements Action {
    readonly type = ADD_TRAINING_SET_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Training Set Actions
 */
export class EditTrainingSetAction implements Action {
    readonly type = EDIT_TRAINING_SET;
    constructor(public id: number, public payload: IEditSetRequest) { }
}
export class EditTrainingSetSuccessAction implements Action {
    readonly type = EDIT_TRAINING_SET_SUCCESS;
    constructor(public payload: IAthleteTraining) { }
}
export class EditTrainingSetFailAction implements Action {
    readonly type = EDIT_TRAINING_SET_FAIL;
    constructor(public payload: any) { }
}

/**
 * Update assigned athlete Actions
 */
export class UpdateAssignedAthleteAction implements Action {
    readonly type = UPDATE_ASSIGNED_ATHLETE;
    constructor(public athleteId: number, public model: IUpdateAssignedAthleteRequest) { }
}
export class UpdateAssignedAthleteSuccessAction implements Action {
    readonly type = UPDATE_ASSIGNED_ATHLETE_SUCCESS;
    constructor(public athleteId: number, public model: IUpdateAssignedAthleteRequest) { }
}
export class UpdateAssignedAthleteFailAction implements Action {
    readonly type = UPDATE_ASSIGNED_ATHLETE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Manage athlete date Actions
 */
export class ManageAthleteDateAction implements Action {
    readonly type = MANAGE_ATHLETE_DATE;
    constructor(public model: IManageDateRequest) { }
}
export class ManageAthleteDateSuccessAction implements Action {
    readonly type = MANAGE_ATHLETE_DATE_SUCCESS;
    constructor(public payload: any) { }
}
export class ManageAthleteDateFailAction implements Action {
    readonly type = MANAGE_ATHLETE_DATE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Add Training Note Actions
 */
export class AddTrainingNoteAction implements Action {
    readonly type = ADD_TRAINING_NOTE;
    constructor(public payload: ITrainingNote, public updateStore: boolean = true) { }
}
export class AddTrainingNoteSuccessAction implements Action {
    readonly type = ADD_TRAINING_NOTE_SUCCESS;
    constructor(public payload: ITrainingNote, public updateStore: boolean = true) { }
}
export class AddTrainingNoteFailAction implements Action {
    readonly type = ADD_TRAINING_NOTE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Training Note Actions
 */
export class EditTrainingNoteAction implements Action {
    readonly type = EDIT_TRAINING_NOTE;
    constructor(public payload: ITrainingNote, public updateStore: boolean = true) { }
}
export class EditTrainingNoteSuccessAction implements Action {
    readonly type = EDIT_TRAINING_NOTE_SUCCESS;
    constructor(public payload: ITrainingNote, public updateStore: boolean = true) { }
}
export class EditTrainingNoteFailAction implements Action {
    readonly type = EDIT_TRAINING_NOTE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Delete Training Note Actions
 */
export class DeleteTrainingNoteAction implements Action {
    readonly type = DELETE_TRAINING_NOTE;
    constructor(public id: number) { }
}
export class DeleteTrainingNoteSuccessAction implements Action {
    readonly type = DELETE_TRAINING_NOTE_SUCCESS;
    constructor(public id: number) { }
}
export class DeleteTrainingNoteFailAction implements Action {
    readonly type = DELETE_TRAINING_NOTE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Add Training Unit Note Actions
 */
export class AddTrainingUnitNoteAction implements Action {
    readonly type = ADD_TRAINING_UNIT_NOTE;
    constructor(public payload: ITrainingUnitNote) { }
}
export class AddTrainingUnitNoteSuccessAction implements Action {
    readonly type = ADD_TRAINING_UNIT_NOTE_SUCCESS;
    constructor(public payload: ITrainingUnitNote) { }
}
export class AddTrainingUnitNoteFailAction implements Action {
    readonly type = ADD_TRAINING_UNIT_NOTE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Edit Training Unit Note Actions
 */
export class EditTrainingUnitNoteAction implements Action {
    readonly type = EDIT_TRAINING_UNIT_NOTE;
    constructor(public payload: ITrainingUnitNote) { }
}
export class EditTrainingUnitNoteSuccessAction implements Action {
    readonly type = EDIT_TRAINING_UNIT_NOTE_SUCCESS;
    constructor(public payload: ITrainingUnitNote) { }
}
export class EditTrainingUnitNoteFailAction implements Action {
    readonly type = EDIT_TRAINING_UNIT_NOTE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Delete Training Note Actions
 */
export class DeleteTrainingUnitNoteAction implements Action {
    readonly type = DELETE_TRAINING_UNIT_NOTE;
    constructor(public id: number) { }
}
export class DeleteTrainingUnitNoteSuccessAction implements Action {
    readonly type = DELETE_TRAINING_UNIT_NOTE_SUCCESS;
    constructor(public id: number) { }
}
export class DeleteTrainingUnitNoteFailAction implements Action {
    readonly type = DELETE_TRAINING_UNIT_NOTE_FAIL;
    constructor(public payload: any) { }
}


/**
 * Load Overview Actions
 */
export class LoadOverviewAction implements Action {
    readonly type = LOAD_OVERVIEW;
    constructor(public force: boolean = false) { }
}
export class LoadOverviewSuccessAction implements Action {
    readonly type = LOAD_OVERVIEW_SUCCESS;
    constructor(public payload: IPagingResult<ITrackingMesocycleOverview>) { }
}
export class LoadOverviewFailAction implements Action {
    readonly type = LOAD_OVERVIEW_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Overview Page Actions
 */
export class LoadOverviewPageAction implements Action {
    readonly type = LOAD_OVERVIEW_PAGE;
    constructor(public page: number = null) { }
}
export class LoadOverviewPageSuccessAction implements Action {
    readonly type = LOAD_OVERVIEW_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<ITrackingMesocycleOverview>) { }
}
export class LoadOverviewPageFailAction implements Action {
    readonly type = LOAD_OVERVIEW_PAGE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load Training Overview Actions
 */
 export class LoadTrainingOverviewAction implements Action {
    readonly type = LOAD_TRAINING_OVERVIEW;
    constructor(public force: boolean = false) { }
}
export class LoadTrainingOverviewSuccessAction implements Action {
    readonly type = LOAD_TRAINING_OVERVIEW_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingMesocycleOverview>) { }
}
export class LoadTrainingOverviewFailAction implements Action {
    readonly type = LOAD_TRAINING_OVERVIEW_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Training Overview Page Actions
 */
export class LoadTrainingOverviewPageAction implements Action {
    readonly type = LOAD_TRAINING_OVERVIEW_PAGE;
    constructor(public page: number = null) { }
}
export class LoadTrainingOverviewPageSuccessAction implements Action {
    readonly type = LOAD_TRAINING_OVERVIEW_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<ITrainingMesocycleOverview>) { }
}
export class LoadTrainingOverviewPageFailAction implements Action {
    readonly type = LOAD_TRAINING_OVERVIEW_PAGE_FAIL;
    constructor(public payload: any) { }
}
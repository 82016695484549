// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: var(--ons-color-primary-black);
}

.background-image {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.logo-container {
  margin-top: 14vh;
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}

login-form {
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/pages/login/login.page.scss"],"names":[],"mappings":"AAAA;EACE,4CAAA;AACF;;AAEA;EACE,kBAAA;EACA,OAAA;EACA,MAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;EACA,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;;AAGA;EACE,gBAAA;AAAF","sourcesContent":["ion-content {\n  --background: var(--ons-color-primary-black);\n}\n\n.background-image {\n  position: absolute;\n  left: 0;\n  top: 0;\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n  z-index: -1;\n}\n\n.logo-container {\n  margin-top: 14vh;\n  display: flex;\n  justify-content: center;\n  margin-bottom: 40px;\n}\n\n\nlogin-form {\n  margin-top: 30px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

export const HB_CONSTS = {
    male: {
        basic: 66.47,
        weightCoeff: 13.7,
        sizeCoeff: 5,
        ageCoeff: 6.8
    },
    female: {
        basic: 655.1,
        weightCoeff: 9.6,
        sizeCoeff: 1.8,
        ageCoeff: 4.7
    }
}
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { IAddAthlete, IAssignDocumentRequest, IAthlete, IManageDateRequest, IUpdateAssignedAthleteRequest } from '@models/athlete.model';
import { IGetUsersResponse, IUser } from '@models/user.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import * as moment from 'moment';
import { IPagingResult } from '@models/paging.model';
import { HttpHelper } from '../helpers/http.helper';
import { IInvitation } from '@models/invitation.model';

@Injectable({
  providedIn: "root",
})
export class AthleteService {
  apiPath: string = 'assigned_athletes';

  constructor(private http: HttpClient,
    private authService: AuthService) { }

  get(id: number): Observable<IUser> {
    return this.http.get(`/users/${id}`).pipe(
      map((response: any) => {
        if (response?.status === "success" && response.data[0]) {
          return response.data[0];
        } else {
          throw response.message;
        }
      })
    );
  }

  getAll(page: number = 1, withoutLoading = false): Observable<[IPagingResult<any>, IGetUsersResponse]> {
    let userId = this.authService.getUserId();
    let pageQuery = `&page=${page}`;

    let http = this.http;
    if (withoutLoading) {
      http = http.disableLoading();
    }

    return http.get(`/users?filter[assigned_coach]=${userId}&with_invitations=1${pageQuery}`, { observe: 'response' }).pipe(
      map((response: any) => {
        if (response?.body?.status === "success") {
          let paging = HttpHelper.getPaging(response);
          return [
            {
              items: [],
              paging: { ...paging }
            },
            response?.body?.data[0]
          ];
        } else {
          throw response.message;
        }
      })
    );
  }

  add(model: IAddAthlete): Observable<IInvitation> {
    let athlete = { ...model };
    let userId = this.authService.getUserId();
    athlete.coach_id = userId;
    athlete.coach_management_from = athlete.coach_management_from ? moment(athlete.coach_management_from).format('YYYY-MM-DD') : athlete.coach_management_from;
    athlete.coach_management_to = athlete.coach_management_to ? moment(athlete.coach_management_to).format('YYYY-MM-DD') : athlete.coach_management_to;

    return this.http.post(`/${this.apiPath}`, athlete)
      .pipe(
        map((response: any) => {
          if (response?.status === "success") {
            return response.data[0];
          } else {
            throw response.message;
          }
        })
      );
  }

  assignDocument(docIds: number[], athleteId: number) {
    let model: IAssignDocumentRequest = {
      athlete_id: athleteId,
      documents: docIds
    };

    return this.http.post("/athlete_documents", model);
  }

  unassignDocument(id: number) {
    return this.http.delete(`/athlete_documents/${id}`);
  }

  cancelAssignment(athlete_id: number = null): Observable<any> {
    let query = athlete_id ? `?athlete_id=${athlete_id}` : '';
    return this.http.delete(`/${this.apiPath}${query}`)
      .pipe(
        map((response: any) => {
          if (response?.status === "success") {
            return response.data[0];
          } else {
            throw response.message;
          }
        })
      );
  }

  update(athleteId: number, model: IUpdateAssignedAthleteRequest): Observable<any> {
    return this.http.patch(`/${this.apiPath}/${athleteId}`, model)
      .pipe(
        map((response: any) => {
          if (response?.status === "success") {
            return response.data[0];
          } else {
            throw response.message;
          }
        })
      );
  }

  setManageDate(model: IManageDateRequest): Observable<any> {
    return this.http.patch(`/coach_athlete_management`, model)
      .pipe(
        map((response: any) => {
          if (response?.status === "success") {
            return response.data[0];
          } else {
            throw response.message;
          }
        })
      );
  }
}
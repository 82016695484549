// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: var(--ons-color-primary-background);
}

.button-style {
  width: 100%;
  margin-left: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/pages/upload-avatar/upload-avatar.page.scss"],"names":[],"mappings":"AAAA;EACE,iDAAA;AACF;;AAEA;EACE,WAAA;EACA,cAAA;AACF","sourcesContent":["ion-content {\n  --background: var(--ons-color-primary-background);\n}\n\n.button-style {\n  width: 100%;\n  margin-left: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

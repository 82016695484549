import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UIService } from "@services/ui.service";
import { AuthService } from "@services/auth.service";
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";

@Component({
  selector: 'app-code-view',
  templateUrl: './code-view.page.html',
  styleUrls: ['./code-view.page.scss'],
})

export class CodeViewPage implements OnInit {

    tokenForm: UntypedFormGroup;


  	constructor(
  		private formBuilder: UntypedFormBuilder,
    	private router: Router,
  		private uiService: UIService,
  		private auth: AuthService,
  	) { }

  	ngOnInit() {
        this.tokenForm = this.formBuilder.group({
            token: ['', [Validators.required, Validators.minLength(4)]]
        });
  	}

    onToken(): void {
        let code = this.tokenForm.value.token;
        
        this.auth.checkPasswordToken(code)
        .then(() => {
            this.router.navigate(['/new-password']);
        })
        .catch(error => {
            console.log('Error New Password Set ', error);
        });
        
    }

}


import { Component, OnInit } from '@angular/core';
import { UIService } from "@services/ui.service";
import { AuthService } from "@services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
	selector: 'app-athlete-info',
	templateUrl: './athlete-info.page.html',
	styleUrls: ['./athlete-info.page.scss'],
})
export class AthleteInfoPage implements OnInit {
	currentDate: string = moment().format('YYYY-MM-DD');
	dob: Date = null;
	size: number = 180;
	weight: number = 85;

	constructor(
		private translate: TranslateService,
		private uiService: UIService,
		private auth: AuthService,
		private router: Router
	) { }

	ngOnInit() {
	}

	nextPage() {

		this.auth.updateAthleteInfo(this.size, this.dob, this.weight)
			.then((res) => {
				this.uiService.showMessage("Your data has been saved!", 'success', 2000);
				this.router.navigate(['/athlete-level']);
			})
			.catch(error => {
				console.log('Error Athlete Update ', error);
			});


	}
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-logo {
  height: 38px;
  margin-right: 10px;
  margin-top: -5px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/header-logo/header-logo.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,kBAAA;EACA,gBAAA;AACJ","sourcesContent":[".header-logo {\n    height: 38px;\n    margin-right: 10px;\n    margin-top: -5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

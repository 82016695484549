import { INITIAL_PAGING, IPaging, IPagingResult } from "@models/paging.model";
import { IBodyData, IMacro } from "@models/progress.model";
import { ITrainingPlan, ITrainingUnit } from "@models/training.model";
import { AuthActions, BaseObjectActions, ProgressActions, TrainingActions } from "../actions";

export interface State {
    units: UnitsState;
    bodyDatas: BodyDatasState;
    macros: MacrosState;
    plans: PlansState;
}
export interface UnitsState {
    loaded: boolean;
    loading: boolean;
    items: ITrainingUnit[];
    paging: IPaging;
}
export interface PlansState {
    loaded: boolean;
    loading: boolean;
    items: ITrainingPlan[];
    paging: IPaging;
}
export interface BodyDatasState {
    loaded: boolean;
    loading: boolean;
    items: IBodyData[];
    paging: IPaging;
}
export interface MacrosState {
    loaded: boolean;
    loading: boolean;
    items: IMacro[];
    paging: IPaging;
}

const initialSubState = {
    loaded: false,
    loading: false,
    items: [],
    paging: INITIAL_PAGING
}
const initialState: State = {
    units: initialSubState,
    bodyDatas: initialSubState,
    macros: initialSubState,
    plans: initialSubState,
};


export function reducer(state = initialState, action): State {
    switch (action.type) {
        case BaseObjectActions.LOAD_BASE_UNITS:
        case BaseObjectActions.LOAD_BASE_UNITS_PAGE: {
            return Object.assign({}, state, {
                units: {
                    ...state.units,
                    loading: true
                }
            });
        }
        case BaseObjectActions.LOAD_BASE_UNITS_FAIL:
        case BaseObjectActions.LOAD_BASE_UNITS_PAGE_FAIL: {
            return Object.assign({}, state, {
                units: {
                    ...state.units,
                    loading: false
                }
            });
        }
        case BaseObjectActions.LOAD_BASE_UNITS_SUCCESS: {
            let resp: IPagingResult<ITrainingUnit> = action.payload;

            return Object.assign({}, state, {
                units: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: resp.items,
                    paging: resp.paging
                }
            });
        }
        case BaseObjectActions.LOAD_BASE_UNITS_PAGE_SUCCESS: {
            let resp: IPagingResult<ITrainingUnit> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.units.items, ...resp.items];

            return Object.assign({}, state, {
                units: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }
        case BaseObjectActions.IMPORT_BASE_UNIT_SUCCESS: {
            const ids: number[] = action.ids;

            return Object.assign({}, state, {
                units: {
                    ...state.units,
                    items: state.units.items.map(x => x.is_reference && ids.includes(x.id) ? { ...x, is_imported: true } : x)
                }
            });
        }
        case TrainingActions.REMOVE_UNIT_SUCCESS: {
            const unit: ITrainingUnit = action.payload;

            if (unit.reference_id) {
                return Object.assign({}, state, {
                    units: {
                        ...state.units,
                        items: state.units.items.map(x => x.is_reference && x.id == unit.reference_id ? { ...x, is_imported: false } : x)
                    }
                });
            }
        }


        case BaseObjectActions.LOAD_BASE_PLANS:
        case BaseObjectActions.LOAD_BASE_PLANS_PAGE: {
            return Object.assign({}, state, {
                plans: {
                    ...state.plans,
                    loading: true
                }
            });
        }
        case BaseObjectActions.LOAD_BASE_PLANS_FAIL:
        case BaseObjectActions.LOAD_BASE_PLANS_PAGE_FAIL: {
            return Object.assign({}, state, {
                plans: {
                    ...state.plans,
                    loading: false
                }
            });
        }
        case BaseObjectActions.LOAD_BASE_PLANS_SUCCESS: {
            let resp: IPagingResult<ITrainingPlan> = action.payload;

            return Object.assign({}, state, {
                plans: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: resp.items,
                    paging: resp.paging
                }
            });
        }
        case BaseObjectActions.LOAD_BASE_PLANS_PAGE_SUCCESS: {
            let resp: IPagingResult<ITrainingPlan> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.plans.items, ...resp.items];

            return Object.assign({}, state, {
                plans: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }


        case BaseObjectActions.LOAD_BASE_BODY_DATAS:
        case BaseObjectActions.LOAD_BASE_BODY_DATAS_PAGE: {
            return Object.assign({}, state, {
                bodyDatas: {
                    ...state.bodyDatas,
                    loading: true
                }
            });
        }
        case BaseObjectActions.LOAD_BASE_BODY_DATAS_FAIL:
        case BaseObjectActions.LOAD_BASE_BODY_DATAS_PAGE_FAIL: {
            return Object.assign({}, state, {
                bodyDatas: {
                    ...state.bodyDatas,
                    loading: false
                }
            });
        }
        case BaseObjectActions.LOAD_BASE_BODY_DATAS_SUCCESS: {
            let resp: IPagingResult<ITrainingUnit> = action.payload;

            return Object.assign({}, state, {
                bodyDatas: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: resp.items,
                    paging: resp.paging
                }
            });
        }
        case BaseObjectActions.LOAD_BASE_BODY_DATAS_PAGE_SUCCESS: {
            let resp: IPagingResult<IBodyData> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.bodyDatas.items, ...resp.items];

            return Object.assign({}, state, {
                bodyDatas: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }
        case BaseObjectActions.IMPORT_BASE_BODY_DATA_SUCCESS: {
            const ids: number[] = action.ids;

            return Object.assign({}, state, {
                bodyDatas: {
                    ...state.bodyDatas,
                    items: state.bodyDatas.items.map(x => x.is_reference && ids.includes(x.id) ? { ...x, is_imported: true } : x)
                }
            });
        }
        case ProgressActions.REMOVE_BODY_DATA_SUCCESS: {
            const bodyData: IBodyData = action.payload;

            if (bodyData.reference_id) {
                return Object.assign({}, state, {
                    bodyDatas: {
                        ...state.bodyDatas,
                        items: state.bodyDatas.items.map(x => x.is_reference && x.id == bodyData.reference_id ? { ...x, is_imported: false } : x)
                    }
                });
            }
        }


        case BaseObjectActions.LOAD_BASE_MACROS:
        case BaseObjectActions.LOAD_BASE_MACROS_PAGE: {
            return Object.assign({}, state, {
                macros: {
                    ...state.macros,
                    loading: true
                }
            });
        }
        case BaseObjectActions.LOAD_BASE_MACROS_FAIL:
        case BaseObjectActions.LOAD_BASE_MACROS_PAGE_FAIL: {
            return Object.assign({}, state, {
                macros: {
                    ...state.macros,
                    loading: false
                }
            });
        }
        case BaseObjectActions.LOAD_BASE_MACROS_SUCCESS: {
            let resp: IPagingResult<ITrainingUnit> = action.payload;

            return Object.assign({}, state, {
                macros: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: resp.items,
                    paging: resp.paging
                }
            });
        }
        case BaseObjectActions.LOAD_BASE_MACROS_PAGE_SUCCESS: {
            let resp: IPagingResult<IMacro> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.macros.items, ...resp.items];

            return Object.assign({}, state, {
                macros: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }
        case BaseObjectActions.IMPORT_BASE_MACRO_SUCCESS: {
            const ids: number[] = action.ids;

            return Object.assign({}, state, {
                macros: {
                    ...state.macros,
                    items: state.macros.items.map(x => x.is_reference && ids.includes(x.id) ? { ...x, is_imported: true } : x)
                }
            });
        }
        case ProgressActions.REMOVE_MACRO_SUCCESS: {
            const macro: IMacro = action.payload;

            if (macro.reference_id) {
                return Object.assign({}, state, {
                    macros: {
                        ...state.macros,
                        items: state.macros.items.map(x => x.is_reference && x.id == macro.reference_id ? { ...x, is_imported: false } : x)
                    }
                });
            }
        }


        case AuthActions.LOGIN_SUCCESS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}

export const getUnitsLoaded = (state: State) => state.units.loaded;
export const getUnitsLoading = (state: State) => state.units.loading;
export const getUnits = (state: State) => state.units.items;
export const getUnitsPaging = (state: State) => state.units.paging;
export const getUnit = (state: State, props) => state.units.items.find(x => x.id == props.id);

export const getPlansLoaded = (state: State) => state.plans.loaded;
export const getPlansLoading = (state: State) => state.plans.loading;
export const getPlans = (state: State) => state.plans.items;
export const getPlansPaging = (state: State) => state.plans.paging;
export const getPlan = (state: State, props) => state.plans.items.find(x => x.id == props.id);

export const getBodyDatasLoaded = (state: State) => state.bodyDatas.loaded;
export const getBodyDatasLoading = (state: State) => state.bodyDatas.loading;
export const getBodyDatas = (state: State) => state.bodyDatas.items;
export const getBodyDatasPaging = (state: State) => state.bodyDatas.paging;
export const getBodyData = (state: State, props) => state.bodyDatas.items.find(x => x.id == props.id);

export const getMacrosLoaded = (state: State) => state.macros.loaded;
export const getMacrosLoading = (state: State) => state.macros.loading;
export const getMacros = (state: State) => state.macros.items;
export const getMacrosPaging = (state: State) => state.macros.paging;
export const getMacro = (state: State, props) => state.macros.items.find(x => x.id == props.id);
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ITrainingUnit } from '@models/training.model';
import { TranslateService } from '@ngx-translate/core';
import { TrainingActions } from 'app/core/store/actions';
import { Store } from '@ngrx/store';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-training-plan-overview-unit-change-modal',
  templateUrl: './training-plan-overview-unit-change-modal.component.html',
  styleUrls: ['./training-plan-overview-unit-change-modal.component.scss']
})
export class TrainingPlanOverviewUnitChangeModalComponent implements OnInit {
  @Input() unit: ITrainingUnit;
  @Input() section: String;

  sets: number;
  rirs: number;
  reps_from: number;
  reps_to: number;

  constructor(private translate: TranslateService,
    private modalCtrl: ModalController,
    private store: Store<AppState>) { }

  ngOnInit() {
    this.sets = this.unit.unit_data.sets;
    this.reps_from = this.unit.unit_data.reps_from;
    this.reps_to = this.unit.unit_data.reps_to;
    this.rirs = this.unit.unit_data.rirs;
  }

  closeModal(success = false) {
    this.modalCtrl.dismiss(success);
  }

  saveSection() {
    let unitObj: any = {
      id: this.unit.id,
      name: this.unit.name,
      sets: this.sets,
      rirs: this.rirs,
      reps_from: this.reps_from,
      reps_to: this.reps_to,
      categories: this.unit.categories?.map(x => x.id) ?? [],
      unit_data_id: this.unit.unit_data.id,
      // info_title: unit.unit_data.info_title,
      // info_description: unit.unit_data.info_description,
    }

    this.store.dispatch(new TrainingActions.EditUnitCopyAction(unitObj));

    this.closeModal(unitObj);
  }
}

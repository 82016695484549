import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { INote } from '@models/note.model';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { AppState } from 'app/core/store/app.states';
import * as moment from 'moment';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notes-list',
  templateUrl: './notes-list.component.html',
  styleUrls: ['./notes-list.component.scss'],
})
export class NotesListComponent implements OnInit {
  @Input() notes: INote[];
  @Input() withoutHeader: boolean = false;
  @Input() coachMode: boolean = false;
  hasNew$: Observable<boolean>;

  @Output() onEditClick: EventEmitter<number> = new EventEmitter();

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.hasNew$ = this.store.select(AppReducers.getNoteHasNewData);
  }

  editClick(noteId: number) {
    this.onEditClick.emit(noteId);
  }

  getDate(date) {
    return moment(date).toDate();
  }

  isNew(date) {
    const yesterday = moment().subtract(1, 'days');
    const noteDay = moment(date);
    return noteDay.isSameOrAfter(yesterday, 'd');
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { IBodyDataGroup, IMacroGroup } from '@models/analytics.model';
import { IPagingResult } from '@models/paging.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../helpers/http.helper';

@Injectable({
    providedIn: "root",
})
export class AnalyticsOptionsService {
    apiPath: string = 'analytic_options';

    constructor(private http: HttpClient) { }

    getBodyDatas(page: number = 1, withoutLoading = false): Observable<IPagingResult<IBodyDataGroup>> {
        let pageQuery = `&page=${page}`;

        let http = this.http;
        if (withoutLoading) {
            http = http.disableLoading();
        }

        return http.get(`/${this.apiPath}?type=body_data${pageQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response?.body?.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response.message;
                }
            })
        );
    }

    getMacros(page: number = 1, withoutLoading = false): Observable<IPagingResult<IMacroGroup>> {
        let pageQuery = `&page=${page}`;

        let http = this.http;
        if (withoutLoading) {
            http = http.disableLoading();
        }

        return http.get(`/${this.apiPath}?type=macros${pageQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response?.body?.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response.message;
                }
            })
        );
    }
}
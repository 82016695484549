import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { Chooser } from '@ionic-native/chooser/ngx';

export interface IFile {
    dataUrl: string,
    name: string
}

@Injectable({
    providedIn: 'root'
})
export class FileService {

    public constructor(private chooser: Chooser,
        private platform: Platform) { }

    public ifMobile() {
        return !this.platform.is('mobileweb') && (this.platform.is('android') || this.platform.is('ios'));
    }

    public async chooseFile(accept = ''): Promise<IFile> {
        if (this.ifMobile()) {
            const file = await this.chooser.getFile(accept);
            const dataUrl = file.dataURI;
            const fileName = file.name;

            return {
                dataUrl: dataUrl,
                name: fileName
            };
        }
    }
}

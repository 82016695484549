// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar-operation-buttons {
  display: inline-flex;
  width: 100%;
  place-content: space-evenly;
  font-size: 32px;
}
.avatar-operation-buttons .rotate-button {
  cursor: pointer;
}

.crop-avatar-buttons {
  display: flex;
  justify-content: space-evenly;
  padding-top: 10px;
}
.crop-avatar-buttons div {
  padding: 5px;
  border-radius: 4px;
}
.crop-avatar-buttons div.confirm {
  background: var(--ons-color-primary-black);
  color: white;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/crop-avatar/crop-avatar.component.scss"],"names":[],"mappings":"AAAA;EACI,oBAAA;EACA,WAAA;EACA,2BAAA;EACA,eAAA;AACJ;AACI;EACI,eAAA;AACR;;AAGA;EACI,aAAA;EACA,6BAAA;EACA,iBAAA;AAAJ;AAEI;EACI,YAAA;EACA,kBAAA;AAAR;AAEQ;EACI,0CAAA;EACA,YAAA;AAAZ","sourcesContent":[".avatar-operation-buttons{\n    display: inline-flex;\n    width: 100%;\n    place-content: space-evenly;\n    font-size: 32px;\n\n    .rotate-button {\n        cursor: pointer;\n    }\n}\n\n.crop-avatar-buttons {\n    display: flex;\n    justify-content: space-evenly;\n    padding-top: 10px;\n\n    div {\n        padding: 5px;\n        border-radius: 4px;\n\n        &.confirm {\n            background: var(--ons-color-primary-black);\n            color: white;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { PickerController } from '@ionic/angular';
import { PickerColumn, PickerColumnOption } from '@ionic/core';
import { TranslateService } from '@ngx-translate/core';
import * as uuid from 'uuid';

@Component({
  selector: 'app-plus-minus-input',
  templateUrl: './plus-minus-input.component.html',
  styleUrls: ['./plus-minus-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlusMinusInputComponent),
      multi: true
    }
  ]
})
export class PlusMinusInputComponent implements ControlValueAccessor, OnInit {
  id;

  @Input()
  _counterValue = 0;
  @Input() min: number = null;
  @Input() max: number = null;
  @Input() step: number = 1;
  @Input() theme: string = 'default';
  @Input() prefix: string = '';
  @Input() readonly: boolean = false;
  @Input() mode: string; // possible values: input, popup

  @Output() onUserInput: EventEmitter<number> = new EventEmitter<number>();

  constructor(private pickerController: PickerController,
    private translate: TranslateService) { }

  ngOnInit(): void {
    this.id = uuid.v4()
  }

  get counterValue(): number {
    return this._counterValue;
  }

  set counterValue(val: number | string) {

    val = val == "-" ? 0 : val;

    const pattern = /^-?[0-9]*([.][0-9]*)?$/;
    val = (val + '').replace(',', '.');

    if(val.slice(-1) != ".") {
      val = !val || val == '.' || !pattern.test(val) ? 0 : parseFloat(val);
      if (this.min != null && this.min >= val) {
        val = this.min;
      }
      if (this.max != null && this.max <= val) {
        val = this.max;
      }

      this._counterValue = val;
    
      this.propagateChange(this._counterValue);
      this.onUserInput.next(this._counterValue);
    }


    let input = document.getElementById(this.id) as HTMLInputElement;
    if(input){
      input.value = val.toString();
      let valueLength = input.value.length;
      input.setSelectionRange(valueLength, valueLength);
    }
  }

  increment() {
    if (!this.readonly) {
      this.counterValue = (this.counterValue + this.step).toFixed(10);
    }
  }

  decrement() {
    if (!this.readonly) {
      this.counterValue = (this.counterValue - this.step).toFixed(10);
    }
  }

  writeValue(value: any) {
    if (value !== undefined) {
      this.counterValue = value;
    }
  }
  propagateChange = (_: any) => { };
  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched() { }


  generateColumns(): PickerColumn[] {
    let integers = [];
    for (let i = this.min; i <= this.max; i++) {
      if (i == 0 && (!this.min || this.min < 0)) {
        let option: PickerColumnOption = { text: '-' + i + '', value: i };
        integers.push(option)
      }

      let option: PickerColumnOption = { text: i + '', value: i };
      integers.push(option)
    }

    let decimals = [];
    for (let i = 0; i <= 9; i++) {
      if (i % (this.step * 10) == 0) {
        let option: PickerColumnOption = {
          text: '.' + i,
          value: i / 10
        };
        decimals.push(option)
      }
    }

    let integerSelectedIndex = Math.trunc(this.counterValue) - (this.min ? this.min : 0);
    let columns: PickerColumn[] = [{
      name: `integer`,
      options: integers,
      selectedIndex: integerSelectedIndex
    }];

    if (this.step < 1) {
      let decimalSelectedIndex = Math.abs((+(this.counterValue % 1).toFixed(1)) * 10);
      columns.push({
        name: `decimal`,
        options: decimals,
        selectedIndex: decimalSelectedIndex
      });
    }

    return columns;
  }


  async openPicker() {
    if (this.readonly) {
      return false;
    }
    const picker = await this.pickerController.create({
      columns: this.generateColumns(),
      buttons: [
        {
          text: this.translate.instant("base.modal.cancel"),
          role: 'cancel'
        },
        {
          text: this.translate.instant("base.modal.confirm"),
          handler: (result) => {
            let value = result.integer.value;
            if (result.decimal?.value != null) {
              if (result.integer.text == '-0') {
                value = 0 - result.decimal?.value;
              }
              else {
                value = value < 0 ? value - result.decimal?.value : value + result.decimal?.value;
              }
            }
            this.counterValue = value;
          }
        }
      ]
    });

    await picker.present();
  }
}

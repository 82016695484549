import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from '@models/authentication.model';
import { AuthService } from '@services/auth.service';
import { CoachAthleteViewService } from '@services/coach-athlete-view.service';

@Component({
  selector: 'app-athlete-overview-button',
  templateUrl: './athlete-overview-button.component.html',
  styleUrls: ['./athlete-overview-button.component.scss'],
})
export class AthleteOverviewButtonComponent implements OnInit {
  constructor(private router: Router,
    private authService: AuthService,
    private coachAthleteViewService: CoachAthleteViewService) { }

  @Input() type: string = "overview";

  ngOnInit() {
  }

  gotoOverviewPage() {
    let userRole = this.authService.getUserRole();
    let isAthlete = userRole == UserRole.athlete ? true : false;

    if (isAthlete) {
      this.router.navigate([`/athlete/${this.type}`]);
    }
    else {
      let athleteId = this.coachAthleteViewService.athleteId;
      this.router.navigate(['coach-athlete', athleteId, this.type]);
    }
  }
}

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-techniques-infinity-scroll',
  templateUrl: './techniques-infinity-scroll.component.html',
  styleUrls: ['./techniques-infinity-scroll.component.scss'],
})
export class TechniquesInfinityScrollComponent {
  selectPaging = AppReducers.getTrainingTechniquesPaging;
  loadPageSuccessAction: string = TrainingActions.LOAD_TECHNIQUES_PAGE_SUCCESS;
  loadPageFailAction: string = TrainingActions.LOAD_TECHNIQUES_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  loadPageAction() {
    this.store.dispatch(new TrainingActions.LoadTechniquesPageAction());
  }
}

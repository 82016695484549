import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ITrainingUnit } from '@models/training.model';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '@services/ui.service';
import { TrainingUnitComponent } from 'app/modules/shared/training-unit/training-unit.component';
import { PopoverController } from '@ionic/angular';
import { ContentPopoverComponent } from 'app/modules/shared/content-popover/content-popover.component';
import { TrainingPlanOverviewUnitChangeModalComponent } from './training-plan-overview-unit-change-modal/training-plan-overview-unit-change-modal.component';

@Component({
  selector: 'app-training-plan-overview-unit',
  templateUrl: './training-plan-overview-unit.component.html',
  styleUrls: ['./training-plan-overview-unit.component.scss']
})
export class TrainingPlanOverviewUnitComponent implements OnInit {
  @Input() unit: ITrainingUnit;
  @Output() onDelete: EventEmitter<ITrainingUnit> = new EventEmitter<ITrainingUnit>();

  constructor(private uiService: UIService,
    private modalController: ModalController,
    private popoverController: PopoverController,
    private translate: TranslateService) { }

  ngOnInit() {
  }

  delete() {
    let name = this.unit.name;
    this.uiService.confirmModal(this.translate.instant("base.modal.confirm_title"), this.translate.instant("base.modal.delete_text", { name })).then(res => {
      if (res) {
        this.onDelete.emit(this.unit);
      }
    });
    return false;
  }

  edit() {
    this.modalController.create({
      component: TrainingUnitComponent,
      componentProps: {
        'unitId': this.unit.id,
        'unitDataId': this.unit.unit_data?.id,
        'isCopyUnit': true
      },
      cssClass: 'modal-floating shadow-modal',
      swipeToClose: true
    })
      .then(modal => {
        modal.present();
      });
    return false;
  }

  async openInfoDescription(content) {
    if (content) {
      const modal = await this.popoverController.create({
        component: ContentPopoverComponent,
        componentProps: {
          content: content,
        },
      });
      modal.present();
    }
  }

  updateUnitSection(unit: ITrainingUnit, section: String) {
    this.modalController.create({
      component: TrainingPlanOverviewUnitChangeModalComponent,
      componentProps: {
        'unit': unit,
        'section': section,
      },
      cssClass: 'modal-auto-height modal-shadow edit-unit-section-modal',
      swipeToClose: true
    })
      .then(modal => {
        modal.present();
      });
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { IAthleteCoach } from '@models/user.model';
import { DEFAULT_AVATAR_URL } from 'app/core/consts/default-avatar.const';

@Component({
  selector: 'app-content-popover',
  templateUrl: './content-popover.component.html',
  styleUrls: ['./content-popover.component.scss'],
})
export class ContentPopoverComponent implements OnInit {
  @Input() content: any;

  constructor() { }

  ngOnInit() { }

  replaceURLs(message) {
  
    if(!message) return;
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
  }

}

import { EventEmitter, Injectable } from "@angular/core";
import { AlertController, LoadingController, ToastController } from "@ionic/angular";
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class UIService {
  loader = 0;
  loaderEl: HTMLIonLoadingElement;
  loaderInit$: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public toastController: ToastController,
    public loadingController: LoadingController,
    public alertController: AlertController,
    private translate: TranslateService
  ) {

  }

  public async showMessage(message: string, color = 'medium', duration = 1500) {
    const toast = await this.toastController.create({
      message,
      duration,
      color
    })
    await toast.present()
  }

  showLoading() {
    this.loader++;

    if (this.loader == 1) {
      this.loadingController.create({
        message: this.translate.instant('base.loading')
      }).then((res) => {
        res.present().then(data => {
          this.loaderEl = res;
          this.loaderInit$.next(true);
        });
      });
    }
  }

  hideLoading() {
    if (this.loader > 0) {
      this.loader--;

      if (this.loader == 0) {
        if (this.loaderEl) {
          this.loaderEl.dismiss();
          this.loaderEl = null;
        }
        else {
          let sub = this.loaderInit$.subscribe(data => {
            sub.unsubscribe();
            this.loaderEl.dismiss();
            this.loaderEl = null;
          })
        }
      }
    }
  }

  async confirmModal(title: string, message: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: title,
      message: message,
      buttons: [
        {
          text: this.translate.instant("base.modal.cancel"),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => resolveFunction(false)
        }, {
          text: this.translate.instant("base.modal.confirm"),
          handler: () => resolveFunction(true)
        }
      ]
    });

    await alert.present();
    return promise;
  }

  async alertModal(title: string, message: string): Promise<boolean> {
    let resolveFunction: (confirm: boolean) => void;
    const promise = new Promise<boolean>(resolve => {
      resolveFunction = resolve;
    });

    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: title,
      message: message,
      buttons: [
        {
          text: this.translate.instant("base.modal.confirm"),
          handler: () => resolveFunction(true)
        }
      ]
    });

    await alert.present();
    return promise;
  }

  timeout(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
}

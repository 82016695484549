// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  font-size: 16px;
  line-height: 1.5;
}
ion-content p {
  text-align: justify;
}
ion-content p.top-title {
  text-align: center;
}
ion-content a {
  margin-left: 10px;
}

.eula {
  padding-top: 20px;
  font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/imprint/imprint.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;AACJ;AACI;EACI,mBAAA;AACR;AACQ;EACI,kBAAA;AACZ;AAEI;EACI,iBAAA;AAAR;;AAIA;EACI,iBAAA;EACA,eAAA;AADJ","sourcesContent":["ion-content {\n    font-size: 16px;\n    line-height: 1.5;\n\n    p {\n        text-align: justify;\n\n        &.top-title {\n            text-align: center;\n        }\n    }\n    a {\n        margin-left: 10px;\n    }\n}\n\n.eula {\n    padding-top: 20px;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

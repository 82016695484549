// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.coach-block {
  display: flex;
}
.coach-block img {
  width: 100px;
  height: 100px;
  border-radius: 6px;
  z-index: 1;
}
.coach-block-content {
  border-radius: 10px 3px 3px 10px;
  padding: 15px;
  background: var(--ons-color-light-grey);
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: -100px;
  padding-left: 115px;
  width: 100%;
}
.coach-block-content-name {
  color: var(--ons-color-black-2);
  font-family: var(--ons-font-bold);
  padding-bottom: 9px;
  font-size: 13px;
}
.coach-block-content-message {
  font-size: 16px;
  font-family: var(--ons-font-medium);
  font-size: 12px;
  color: rgb(93, 92, 113);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/coach-block/coach-block.component.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;AACJ;AACI;EACI,YAAA;EACA,aAAA;EACA,kBAAA;EACA,UAAA;AACR;AAEI;EACI,gCAAA;EACA,aAAA;EACA,uCAAA;EACA,aAAA;EACA,uBAAA;EACA,sBAAA;EACA,mBAAA;EACA,mBAAA;EACA,WAAA;AAAR;AAEQ;EACI,+BAAA;EACA,iCAAA;EACA,mBAAA;EACA,eAAA;AAAZ;AAEQ;EACI,eAAA;EACA,mCAAA;EACA,eAAA;EACA,uBAAA;AAAZ","sourcesContent":[".coach-block {\n    display: flex;\n\n    img {\n        width: 100px;\n        height: 100px;\n        border-radius: 6px;\n        z-index: 1;\n    }\n\n    &-content {\n        border-radius: 10px 3px 3px 10px;\n        padding: 15px;\n        background: var(--ons-color-light-grey);\n        display: flex;\n        justify-content: center;\n        flex-direction: column;\n        margin-left: -100px;\n        padding-left: 115px;\n        width: 100%;\n\n        &-name {\n            color: var(--ons-color-black-2);\n            font-family: var(--ons-font-bold);\n            padding-bottom: 9px;\n            font-size: 13px;\n        }\n        &-message {\n            font-size: 16px;\n            font-family: var(--ons-font-medium);\n            font-size: 12px;\n            color: rgb(93, 92, 113);\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IInvitation } from '@models/invitation.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '@services/ui.service';
import { InvitationActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-invite-details',
  templateUrl: './invite-details.component.html',
  styleUrls: ['./invite-details.component.scss'],
})
export class InviteDetailsComponent implements OnInit {
  @Input() invitation: IInvitation;

  constructor(private modalController: ModalController,
    private translate: TranslateService,
    private uiService: UIService,
    private store: Store<AppState>) { }

  ngOnInit() { }

  closeModal() {
    this.modalController.dismiss();
  }

  cancel() {
    this.uiService.confirmModal(this.translate.instant("base.modal.confirm_title"), this.translate.instant("coach.athletes.inv_details.cancel_confirmation")).then(res => {
      if (res) {
        this.store.dispatch(new InvitationActions.CancelInviteAction(this.invitation.id));
        this.closeModal();
      }
    })
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-segment {
  --background: var(--ons-color-light-grey);
  border-radius: 40px;
  height: 40px;
}
ion-segment ion-segment-button {
  font-weight: 700;
  --border-radius: 40px;
  --indicator-box-shadow: none;
  --border-color: transparent;
}

ion-item.overflow-visible {
  overflow: visible;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/macro-form/macro-form.component.scss"],"names":[],"mappings":"AAAA;EACI,yCAAA;EACA,mBAAA;EACA,YAAA;AACJ;AAAI;EACI,gBAAA;EACA,qBAAA;EACA,4BAAA;EACA,2BAAA;AAER;;AACA;EACI,iBAAA;AAEJ","sourcesContent":["ion-segment {\n    --background: var(--ons-color-light-grey);\n    border-radius: 40px;\n    height: 40px;\n    ion-segment-button {\n        font-weight: 700;\n        --border-radius: 40px;\n        --indicator-box-shadow: none;\n        --border-color: transparent;\n    }\n}\nion-item.overflow-visible {\n    overflow: visible;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content.color-schema-view {
  --background: var(--ons-color-primary-background);
}
ion-content.color-schema-view main.inner-scroll {
  padding: 10px !important;
}
ion-content.color-schema-view ion-row {
  justify-content: flex-start;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/pages/color-schema/color-schema.page.scss"],"names":[],"mappings":"AAAA;EACE,iDAAA;AACF;AAAE;EACE,wBAAA;AAEJ;AACE;EACE,2BAAA;AACJ","sourcesContent":["ion-content.color-schema-view {\n  --background: var(--ons-color-primary-background);\n  main.inner-scroll {\n    padding : 10px !important;\n  }\n\n  ion-row {\n    justify-content: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

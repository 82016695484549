import { Component, OnInit } from '@angular/core';
import { UIService } from "@services/ui.service";
import { AuthService } from "@services/auth.service";
import { Router } from '@angular/router';
import { IColorSchema } from '@models/user.model';
import { THEME_LIST } from 'app/core/consts/theme-list.const';

@Component({
	selector: 'app-color-schema',
	templateUrl: './color-schema.page.html',
	styleUrls: ['./color-schema.page.scss'],
})

export class ColorSchemaPage implements OnInit {

	currentTheme: string = "black";

	themeList: Array<IColorSchema> = THEME_LIST;

	constructor(
		private uiService: UIService,
		private auth: AuthService,
		private router: Router

	) { }

	ngOnInit() {
	}

	switchTheme(themeName) {
		let self = this;

		this.themeList.forEach(function (element) {
			if (themeName == element.name) {
				element.checked = true;
				self.currentTheme = element.name;
				self.storeColorSchema();
			}
			else element.checked = false;
		});
	}

	getColorSchema() {
		/*
		this.auth.getColorSchema()
		.then((res) => {
			console.log(res);
		})
		.catch(error => {
			console.log('Error color schema ', error);
		});
		*/
	}

	storeColorSchema() {

		this.auth.updateColorSchema(this.currentTheme)
			.then((res) => {
				this.uiService.showMessage("Your color schema has been saved!", 'success', 2000);
			})
			.catch(error => {
				console.log('Error color schema ', error);
			});
	}

	nextPage() {
		this.router.navigate(['/subscription']);
	}



}

import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { UserRole } from '@models/authentication.model';
import { IDocument } from '@models/document.model';
import { IPagingResult } from '@models/paging.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../helpers/http.helper';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: "root",
})
export class DocumentService {
    apiPath: string = 'documents';

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    getAll(page: number = 1, withoutLoading = false): Observable<IPagingResult<IDocument>> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();

        let authQuery = userRole == UserRole.athlete ? 'athlete_id' : 'coach_id';
        let pageQuery = `&page=${page}`;

        let http = withoutLoading ? this.http.disableLoading() : this.http;
        return http.get(`/${this.apiPath}?${authQuery}=${userId}&filter[sort]=desc${pageQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    add(name, url) {
        let coachId = this.authService.getUserId();

        return this.http.post(`/${this.apiPath}`, {
            coach_id: coachId,
            name: name,
            url: url
        })
    }

    delete(id: number) {
        return this.http.delete(`/${this.apiPath}/${id}`)
    }
}
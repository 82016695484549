import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { TrainingPlanOverviewComponent } from '../training-plan-overview.component';

@Component({
  selector: 'app-training-plan-overview-button',
  templateUrl: './training-plan-overview-button.component.html',
  styleUrls: ['./training-plan-overview-button.component.scss']
})
export class TrainingPlanOverviewButtonComponent {
  @Input() planId: number;
  @Input() isCopy: boolean = false;

  constructor(private modalCtrl: ModalController) { }

  async openPlanOverview() {
    if (this.planId) {
      const modal = await this.modalCtrl.create({
        component: TrainingPlanOverviewComponent,
        componentProps: {
          planId: this.planId,
          isCopy: this.isCopy
        },
        cssClass: 'full-size-modal'
      });
      modal.present();
    }
  }
}

import { IUser } from "@models/user.model";
import { createReducer, on } from "@ngrx/store";
import { AuthActions } from "../actions";

export interface State {
  user: IUser | null;
  isAuthenticated: boolean;
  token: string;
}

const initialState: State = {
  user: null,
  isAuthenticated: false,
  token: "",
};

export const reducer = createReducer(
  initialState,
  on(
    AuthActions.loadUserSuccess,
    (state, { user }) => {
      return {
        ...state,
        user,
      };
    }
  ),
  on(
    AuthActions.checkAuthSuccess,
    AuthActions.setNotAuthenticated,
    (state, { isAuthenticated }) => {
      return {
        ...state,
        isAuthenticated,
      };
    }
  )
);


export const selectUser = (state: State) => state.user;
export const selectUserRole = (state: State) => state.user?.role;
export const selectIsAuthenticated = (state: State) => state.isAuthenticated;
export const selectAccessToken = (state: State) => state.token;

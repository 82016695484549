import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IAthlete, IManageDateRequest } from '@models/athlete.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RecurringType } from 'app/core/enums/recurring-type.enum';
import { AthleteActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-followup',
  templateUrl: './followup.component.html',
  styleUrls: ['./followup.component.scss'],
})
export class FollowupComponent implements OnInit {
  @Input() athlete: IAthlete;
  currentDate: string = moment().format('YYYY-MM-DD');
  maxDate: string = moment().add(5, 'years').format('YYYY-MM-DD');
  isRecurringEnabled: boolean = false;
  sub: Subscription;

  constructor(private store: Store<AppState>,
    private actions: Actions,
    private modalController: ModalController) { }

  ngOnInit() {
    this.athlete = { ...this.athlete };
    this.onRecurringChange(this.athlete.recurring);


    this.sub = this.actions.pipe(ofType(AthleteActions.MANAGE_ATHLETE_DATE_SUCCESS))
      .subscribe((data: any) => {
        this.store.dispatch(new AthleteActions.LoadAthletesAction());
        this.modalController.dismiss();
      });

  }

  closeModal() {
    this.modalController.dismiss();
  }

  onIsRecurringEnabledChange(event: boolean) {
    if (event) {
      this.athlete.recurring = this.athlete.recurring ? this.athlete.recurring : RecurringType.Daily;
    }
    else {
      this.athlete.recurring = RecurringType.None;
    }
  }

  onRecurringChange(event: RecurringType) {
    this.isRecurringEnabled = !!event;
  }

  updateAthlete() {
    let model: IManageDateRequest = {
      athlete_id: this.athlete.id,
      recurring: this.athlete.recurring,
      manage_date: moment(this.athlete.manage_date).format('YYYY-MM-DD')
    };
    this.store.dispatch(new AthleteActions.ManageAthleteDateAction(model));
  }
}

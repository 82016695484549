import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import * as moment from 'moment';

export const SELECT_MODES = {
  day: 'day',
  week: 'week',
  custom: 'custom'
};

@Component({
  selector: 'app-tracking-calendar',
  templateUrl: './tracking-calendar.component.html',
  styleUrls: ['./tracking-calendar.component.scss'],
})
export class TrackingCalendarComponent implements OnInit {
  @ViewChild('trackingCalendarMonth') trackingCalendarMonth: ElementRef;
  @Output() onInit: EventEmitter<moment.Moment> = new EventEmitter();
  @Output() onChange: EventEmitter<moment.Moment> = new EventEmitter();
  currentDate: moment.Moment;
  todayDate: moment.Moment = moment();
  days: moment.Moment[];
  @Input() selectMode: string = SELECT_MODES.day;
  @Input() disableFutureDates: boolean = true;
  @Input() highlighSelectedDay: boolean = false;
  @Input() customColor: string;

  firstDayOfWeek: number;
  lastDayOfWeek: number;

  _customSelectionStart: string;
  _customSelectionEnd: string;
  @Input()
  set customSelectionStart(val: string) {
    this._customSelectionStart = val;
    this.setWeekDays();
  }
  get customSelectionStart() { return this._customSelectionStart; }
  @Input()
  set customSelectionEnd(val: string) {
    this._customSelectionEnd = val;
    this.setWeekDays();
  }
  get customSelectionEnd() { return this._customSelectionEnd; }

  constructor() { }

  ngOnInit() {
    this.currentDate = moment();
    this.onInit.next(this.currentDate);

    this.generateDays();

    this.setWeekDays();
  }

  isMonthDisabled(): boolean {
    return (this.currentDate.clone().add(1, 'month').startOf('month')).isAfter(moment().startOf('month'));
  }
  isDayDisabled(day: moment.Moment) {
    return day.isAfter(moment());
  }

  setWeekDays() {
    this.currentDate = this.currentDate ? this.currentDate : moment();
    switch (this.selectMode) {
      case SELECT_MODES.custom:
        let firstDayOfWeek = this.customSelectionStart ? moment(this.customSelectionStart) : this.currentDate;
        firstDayOfWeek = firstDayOfWeek ? firstDayOfWeek : moment();
        let lastDayOfWeek = this.customSelectionEnd ? moment(this.customSelectionEnd) : this.currentDate;
        lastDayOfWeek = lastDayOfWeek ? lastDayOfWeek : moment();
      case SELECT_MODES.week:
        firstDayOfWeek = firstDayOfWeek ? firstDayOfWeek : this.currentDate.clone().weekday(0);
        lastDayOfWeek = lastDayOfWeek ? lastDayOfWeek : this.currentDate.clone().weekday(6);

        this.firstDayOfWeek = +firstDayOfWeek.format('D');
        this.lastDayOfWeek = +lastDayOfWeek.format('D');

        this.firstDayOfWeek =
          this.firstDayOfWeek > this.lastDayOfWeek &&
            firstDayOfWeek.format('M') != this.currentDate.format('M') ?
            1 :
            this.firstDayOfWeek;
        this.lastDayOfWeek =
          this.firstDayOfWeek > this.lastDayOfWeek &&
            lastDayOfWeek.format('M') != this.currentDate.format('M') ?
            +this.currentDate.clone().endOf('month').format('D') :
            this.lastDayOfWeek;
        break;
      case SELECT_MODES.day:
        this.firstDayOfWeek = +this.currentDate.format('D');
        this.lastDayOfWeek = +this.currentDate.format('D');
    }
  }

  getMonth(diff: number = 0) {
    let date: moment.Moment = moment(this.currentDate).add(diff, 'M');
    return date.format('MMMM');
  }

  setMonth(diff: number = 0) {
    this.currentDate.add(diff, 'M');
    if (diff > 0) {
      this.currentDate.set('date', 1);
    }
    if (diff < 0) {
      this.currentDate = this.currentDate.clone().endOf('month');
    }
    this.onChange.next(this.currentDate);

    this.generateDays();

    this.setWeekDays();
  }

  generateDays() {
    let daysInMonth: number = this.currentDate.daysInMonth();

    this.days = [];
    for (let i = 0; i < daysInMonth; i++) {
      let date: moment.Moment = moment(this.currentDate).set('date', i + 1).set('hour', 0).set('minute', 0).set('second', 1);
      
      this.days.push(date);
    }
    setTimeout(() => {
      this.scrollToCurrentDay();
    }, 100)
  }

  setDate(day) {
    this.currentDate = day;
    this.onChange.next(this.currentDate);
    this.scrollToCurrentDay();

    this.setWeekDays();
  }

  scrollToCurrentDay() {
    let dayNo: number = +this.currentDate.format('D');

    let el = this.trackingCalendarMonth.nativeElement;
    let dayWidth = (el.clientWidth - 10) / 7;
    el.scrollLeft = dayWidth * (dayNo - 4) - 5;
  }

  slideLeft() {
    let el = this.trackingCalendarMonth.nativeElement;
    el.scrollLeft = el.scrollLeft - el.clientWidth + 10;
  }
  slideRight() {
    let el = this.trackingCalendarMonth.nativeElement;
    el.scrollLeft = el.scrollLeft + el.clientWidth - 10;
  }

  isCurrentDay(day: moment.Moment) {
    return day.format('D') == this.currentDate.format('D');
  }

  ifDayActive(day: moment.Moment) {
    switch (this.selectMode) {
      case SELECT_MODES.day:
        return day.format('D') == this.currentDate.format('D');
      case SELECT_MODES.week:
      case SELECT_MODES.custom:
        let currentDay = +day.format('D');
        return currentDay <= this.lastDayOfWeek && currentDay >= this.firstDayOfWeek;
    }
  }

  ifDayFirstActive(day: moment.Moment) {
    switch (this.selectMode) {
      case SELECT_MODES.day:
        return day.format('D') == this.currentDate.format('D');
      case SELECT_MODES.week:
      case SELECT_MODES.custom:
        let currentDay = +day.format('D');
        return currentDay == this.firstDayOfWeek;
    }
  }

  ifDayLastActive(day: moment.Moment) {
    switch (this.selectMode) {
      case SELECT_MODES.day:
        return day.format('D') == this.currentDate.format('D');
      case SELECT_MODES.week:
      case SELECT_MODES.custom:
        let currentDay = +day.format('D');
        return currentDay == this.lastDayOfWeek;
    }
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h5 {
  padding-right: 25px;
}

ion-input {
  max-width: 100%;
}

.modal-header-roll-up-icon {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/copy-mesocycle-modal/copy-mesocycle-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;AACJ;;AACA;EACI,eAAA;AAEJ;;AAAA;EACI,eAAA;AAGJ","sourcesContent":["h5 {\n    padding-right: 25px;\n}\nion-input {\n    max-width: 100%;\n}\n.modal-header-roll-up-icon {\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

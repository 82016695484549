import { Component, OnInit } from '@angular/core';
import { UIService } from "@services/ui.service";
import { AuthService } from "@services/auth.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { NavController } from '@ionic/angular';
import { AppValidators } from '@validators/validators';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.page.html',
  styleUrls: ['./new-password.page.scss'],
})

export class NewPasswordPage implements OnInit {

  showPassword1: boolean;
  showPassword2: boolean;
  newPasswordForm: UntypedFormGroup;


  constructor(
    private formBuilder: UntypedFormBuilder,
    private navCtrl: NavController,
    private uiService: UIService,
    private auth: AuthService,
  ) { }

  ngOnInit() {
    this.newPasswordForm = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(1)]],
      passwordRepeat: ['', [Validators.required, Validators.minLength(1)]]
    }, {
      validator: AppValidators.MustMatch('password', 'passwordRepeat')
    });
  }

  onNewPassword(): void {
    let newPassword = this.newPasswordForm.value.password;

    this.auth.setNewPassword(newPassword)
      .then(() => {
        this.uiService.showMessage("Reset Password Success!", 'success', 2000);
        this.navCtrl.navigateRoot('/login', { animated: true, animationDirection: 'forward' });
      })
      .catch(error => {
        console.log('Error New Password Set ', error);
      });

  }

}

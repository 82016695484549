import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ITag, IBodyData } from '@models/progress.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BodyDataUnit } from 'app/core/enums/body-data-unit.enum';
import { ProgressActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';
import { TagsModalComponent } from './tags-modal/tags-modal.component';

@Component({
  selector: 'app-body-data-form',
  templateUrl: './body-data-form.component.html',
  styleUrls: ['./body-data-form.component.scss'],
})
export class BodyDataFormComponent implements OnInit, OnDestroy {

  bodyDataForm: UntypedFormGroup;
  availableUnits = BodyDataUnit;
  isUpdate = false;
  currentTags: Array<ITag> = [];
  tagModal: HTMLIonModalElement;
  plusMinusStep: number = 0.1;
  subs: Subscription[] = [];

  @Input() bodyData: IBodyData;
  @Input() isCopy: boolean = false;
  @Input() readonly: boolean = false;

  @Output() onSave = new EventEmitter();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private actions$: Actions,
    private modalController: ModalController,
    private store: Store<AppState>
  ) { }

  ngOnInit() {
    this.createForm();
    this.subscribeToBodyDataActions();
    this.subscribeToBodyDataCopyActions();
  }

  subscribeToBodyDataCopyActions() {
    this.subs[this.subs.length] = this.actions$.pipe(ofType(ProgressActions.EDIT_BODY_DATA_COPY_SUCCESS))
      .subscribe((bodyData: any) => {
        this.onSave.emit(bodyData);
      })
  }

  subscribeToBodyDataActions() {
    this.subs[this.subs.length] = this.actions$.pipe(ofType(ProgressActions.EDIT_BODY_DATA_SUCCESS))
      .subscribe((bodyData: any) => {
        this.onSave.emit(bodyData);
      })

    this.subs[this.subs.length] = this.actions$.pipe(ofType(ProgressActions.ADD_BODY_DATA_SUCCESS))
      .subscribe((bodyData: any) => {
        this.onSave.emit(bodyData);
      })
  }

  createForm() {

    this.bodyDataForm = this.formBuilder.group({
      id: [''],
      name: ['', [Validators.required]],
      unit: [Object.keys(this.availableUnits)[0], [Validators.required]],
      deviation_maintain_min: [{ value: 1, disabled: false }, [Validators.required]],
      deviation_maintain_max: [{ value: 5, disabled: false }, [Validators.required]],

      deviation_gain_min: [{ value: 1, disabled: false }, [Validators.required]],
      deviation_gain_max: [{ value: 5, disabled: false }, [Validators.required]],

      deviation_diet_min: [{ value: 1, disabled: false }, [Validators.required]],
      deviation_diet_max: [{ value: 5, disabled: false }, [Validators.required]],

      tags: []
    });

    if (this.bodyData) {
      this.isUpdate = true;
      this.setFormData();
    }
  }

  /**
   * Fills the form with data given
   */
  setFormData() {
    this.bodyDataForm.patchValue({
      id: this.bodyData.id,
      name: this.bodyData.name,
      unit: this.bodyData.unit,

      deviation_maintain_min: this.bodyData?.body_data_detail?.deviation_maintain.min,
      deviation_maintain_max: this.bodyData?.body_data_detail?.deviation_maintain.max,

      deviation_gain_min: this.bodyData?.body_data_detail?.deviation_gain.min,
      deviation_gain_max: this.bodyData?.body_data_detail?.deviation_gain.max,

      deviation_diet_min: this.bodyData?.body_data_detail?.deviation_diet.min,
      deviation_diet_max: this.bodyData?.body_data_detail?.deviation_diet.max,
    });

    this.currentTags = this.bodyData.tags;
  }

  /**
   * create BodyData Object and emit it
   */
  onSubmit() {
    if (this.bodyDataForm.valid) {
      let formValue = this.bodyDataForm.value;
      const bodyData: IBodyData = {
        id: formValue.id,
        name: formValue.name,
        unit: formValue.unit,
        body_data_detail: {
          deviation_maintain: {
            "min": formValue.deviation_maintain_min,
            "max": formValue.deviation_maintain_max,
          },
          deviation_gain: {
            "min": formValue.deviation_gain_min,
            "max": formValue.deviation_gain_max,
          },
          deviation_diet: {
            "min": formValue.deviation_diet_min,
            "max": formValue.deviation_diet_max,
          },
        },
        tags: this.currentTags,
        detail_id: this.bodyData?.body_data_detail?.id
      }

      this.onSaveBodyData(bodyData);
    }
  }

  /**
   * Set value for unit from segment
   */
  onUnitChanged(event: CustomEvent) {
    let value = event.detail.value;
    this.plusMinusStep = (value == "kg") ? 0.1 : 0.5;
  }

  onIncreaseNumber(field: string) {
    this.bodyDataForm.get(field).patchValue(this.bodyDataForm.get(field).value + 1);
  }

  onDecreaseNumber(field: string) {
    this.bodyDataForm.get(field).patchValue(this.bodyDataForm.get(field).value - 1);
  }

  updateTags() {
    let tagArray = this.currentTags.map(el => { return el.id });
    this.bodyDataForm.get('tags').setValue(tagArray);
  }

  async onClickAddTag() {
    this.tagModal = await this.modalController.create({
      component: TagsModalComponent,
      cssClass: 'ons-tags-modal',
      swipeToClose: true,
      componentProps: {
        'activeTags': this.currentTags
      }
    });
    this.tagModal.present();
    this.onWatchModalDismiss();
  }

  /**
   * Watches on even onDidDismiss of tagModal
   */
  onWatchModalDismiss() {
    this.tagModal.onDidDismiss().then((data: any) => {

      if (data?.data?.change) {
        this.currentTags = data?.data?.change;
        this.updateTags();
      } else if (data?.data?.delete) {
        this.removeTag(data?.data?.delete);
      }
    });
  }


  removeTag(id: number) {
    this.currentTags = this.currentTags.filter(el => { return (el.id !== id); })
    this.updateTags();
  }


  onSaveBodyData(bodyData: IBodyData) {
    const requestBodyData = { ...bodyData };

    this.handleBodyData(bodyData);
  }

  handleBodyData(bodyData: IBodyData) {
    if (!this.isCopy) {
      if (bodyData.id) {
        this.store.dispatch(new ProgressActions.EditBodyDataAction(bodyData));
      } else {
        this.store.dispatch(new ProgressActions.AddBodyDataAction(bodyData));
      }
    }
    else {
      this.store.dispatch(new ProgressActions.EditBodyDataCopyAction(bodyData));
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}
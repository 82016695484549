import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { EndpointInterceptor } from './interceptors/endpoint.interceptor';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HttpService } from '@services/http.service';
import { ErrorHandlerInterceptor } from './interceptors/error-handler.interceptor';
import { ShowLoadingInterceptor } from './interceptors/show-loading.interceptor';
import { LocaleInterceptor } from './interceptors/locale.interceptor';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule
  ],
  providers: [
    EndpointInterceptor,
    ErrorHandlerInterceptor,
    AuthInterceptor,
    LocaleInterceptor,
    ShowLoadingInterceptor,
    {
      provide: HttpClient,
      useClass: HttpService
    }
  ],
  declarations: []
})
export class CoreModule {

  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    // Import guard
    if (parentModule) {
      throw new Error(`${parentModule} has already been loaded. Import Core module in the AppModule only.`);
    }
  }

}

import { Injectable } from "@angular/core";
import { IUser } from "@models/user.model";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AuthService } from "@services/auth.service";
import { of } from "rxjs";
import { catchError, exhaustMap, map, tap } from "rxjs/operators";
import { AddError } from "../actions/errors.actions";
import { AuthActions } from "../actions";
import { UserService } from '@services/user.service';

@Injectable()
export class AuthEffects {
  constructor(
    private authService: AuthService,
    private actions$: Actions,
    private userService: UserService
  ) { }

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.login),
      exhaustMap((action) =>
        this.authService
          .login(action.credentials.email, action.credentials.password)
          .pipe(
            map((res: any) => AuthActions.loginSuccess()),
            catchError((error) => of(new AddError(error)))
          )
      )
    )
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateUser),
      exhaustMap((action) =>
        this.userService
          .updateUser(action.user)
          .pipe(
            map((user: IUser) => {
              return AuthActions.loadUserSuccess({ user });
            }),
            catchError((error) => of(new AddError(error)))
          )
      )
    )
  );

  updateAthleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActions.updateAthleteUser),
      exhaustMap((action) =>
        this.userService
          .updateAthleteUser(action.user)
          .pipe(
            map((user: IUser) => {
              return AuthActions.loadUserSuccess({ user });
            }),
            catchError((error) => of(new AddError(error)))
          )
      )
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.logOut),
        tap(() => {
          this.authService.logout();
        })
      ),
    { dispatch: false }
  );
}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, withLatestFrom } from "rxjs/operators";
import { NotificationActions } from "../actions";
import { AppState } from '../app.states';
import { Store } from '@ngrx/store';
import { IPagingResult } from "@models/paging.model";
import { NotificationService } from "@services/notification.service";
import { INotification } from "@models/notificaton.model";

@Injectable()
export class NotificationEffects {
    constructor(
        private notificationService: NotificationService,
        private actions$: Actions,
        private store$: Store<AppState>
    ) { }

    loadNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.LOAD_NOTIFICATIONS),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [NotificationActions.LoadNotificationsAction, AppState]) => {
                return action.force || storeState.notification.paging.currentPage == 0;
            }),
            exhaustMap(([action, storeState]: [NotificationActions.LoadNotificationsAction, AppState]) => {
                return this.notificationService
                    .get(1, action.withoutLoading)
                    .pipe(
                        map((pagingResult: IPagingResult<INotification>) => new NotificationActions.LoadNotificationsSuccessAction(pagingResult)),
                        catchError((error) => of(new NotificationActions.LoadNotificationsFailAction(error)))
                    )
            })
        )
    );
    loadNotificationsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.LOAD_NOTIFICATIONS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [NotificationActions.LoadNotificationsPageAction, AppState]) => {
                return storeState?.notification?.paging?.currentPage != 0 && (!action.page || storeState?.notification?.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [NotificationActions.LoadNotificationsPageAction, AppState]) => {
                let page = !action.page ? storeState.notification.paging.currentPage + 1 : action.page;
                return this.notificationService
                    .get(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<INotification>) => new NotificationActions.LoadNotificationsPageSuccessAction(pagingResult)),
                        catchError((error) => of(new NotificationActions.LoadNotificationsPageFailAction(error)))
                    )
            })
        )
    );

    readNotifications$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NotificationActions.READ_NOTIFICATIONS),
            exhaustMap((action: NotificationActions.ReadNotificationsAction) => {
                return this.notificationService
                    .markAsRead(action.ids)
                    .pipe(
                        map((result: any) => new NotificationActions.ReadNotificationsSuccessAction(action.ids)),
                        catchError((error) => of(new NotificationActions.ReadNotificationsFailAction(error)))
                    )
            })
        )
    );
}

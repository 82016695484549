import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { NotificationActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-notifications-infinity-scroll',
  templateUrl: './notifications-infinity-scroll.component.html',
  styleUrls: ['./notifications-infinity-scroll.component.scss'],
})
export class NotificationsInfinityScrollComponent {
  selectPaging = AppReducers.getNotificationPaging;
  loadPageSuccessAction: string = NotificationActions.LOAD_NOTIFICATIONS_PAGE_SUCCESS;
  loadPageFailAction: string = NotificationActions.LOAD_NOTIFICATIONS_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  loadPageAction() {
    this.store.dispatch(new NotificationActions.LoadNotificationsPageAction());
  }
}

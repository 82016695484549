import * as AuthReducers from './auth.reducers';
import * as DocumentReducers from './document.reducers';
import * as ErrorsReducers from './errors.reducers';
import * as TrainingReducers from './training.reducers';
import * as MesocycleReducers from './mesocycle.reducers';
import * as ProgressReducers from './progress.reducers';
import * as AthleteReducers from './athlete.reducers';
import * as NutritionReducers from './nutrition.reducers';
import * as AnalyticsReducers from './analytics.reducers';
import * as NoteReducers from './note.reducers';
import * as NotificationReducers from './notification.reducers';
import * as AthleteInvitationsReducers from './athlete-invitations.reducers';
import * as BaseObjectReducers from './base-objects.reducers';

export { AuthReducers };
export { DocumentReducers };
export { ErrorsReducers };
export { TrainingReducers };
export { MesocycleReducers };
export { ProgressReducers };
export { AthleteReducers };
export { NutritionReducers };
export { AnalyticsReducers };
export { NoteReducers };
export { NotificationReducers };
export { AthleteInvitationsReducers };
export { BaseObjectReducers };
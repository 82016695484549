import { AuthActions, InvitationActions } from '../actions';
import { IInvitation } from '@models/invitation.model';

export interface State {
    loaded: boolean;
    loading: boolean;
    items: IInvitation[];
}

const initialState: State = {
    loaded: false,
    loading: false,
    items: []
};

export function reducer(state = initialState, action): State {
    switch (action.type) {

        case InvitationActions.GET: {
            return Object.assign({}, state, {
                ...state,
                loading: true
            });
        }
        case InvitationActions.GET_FAIL: {
            return Object.assign({}, state, {
                ...state,
                loading: false
            });
        }
        case InvitationActions.GET_SUCCESS: {
            let result: IInvitation[] = action.result;

            return Object.assign({}, state, {
                loaded: true,
                loading: false,
                items: result
            });
        }
        case InvitationActions.CANCEL_INVITE_SUCCESS: {
            const inviteId = action.id;

            if (state.items.findIndex(x => x.id == inviteId) < 0) {
                return state;
            }

            let items = [...state.items].filter(x => x.id != inviteId);

            return Object.assign({}, state, {
                ...state,
                items: items
            });
        }
        case InvitationActions.ACCEPT_INVITE_SUCCESS: {
            const code = action.code;

            if (state.items.findIndex(x => x.code == code) < 0) {
                return state;
            }

            let items = [...state.items].filter(x => x.code != code);

            return Object.assign({}, state, {
                ...state,
                items: items
            });
        }

        case AuthActions.LOGIN_SUCCESS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}

export const getItems = (state: State) => state.items;
export const getPendingItems = (state: State) => state.items?.filter(x => !x.has_accepted && !x.has_cancelled);
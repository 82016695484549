import { IPagingResult } from '@models/paging.model';
import { IBodyData, IMacro, ITag } from '@models/progress.model';
import { Action } from '@ngrx/store';

//#region Body Data const
export const LOAD_BODY_DATAS = '[Progress] Load BodyDatas';
export const LOAD_BODY_DATAS_SUCCESS = '[Progress] Load BodyDatas Success';
export const LOAD_BODY_DATAS_FAIL = '[Progress] Load BodyDatas Fail';
export const LOAD_BODY_DATAS_PAGE = '[Progress] Load BodyDatas Page';
export const LOAD_BODY_DATAS_PAGE_SUCCESS = '[Progress] Load BodyDatas Page Success';
export const LOAD_BODY_DATAS_PAGE_FAIL = '[Progress] Load BodyDatas Page Fail';
export const LOAD_BODY_DATA = '[Progress] Load Body Data';
export const LOAD_BODY_DATA_SUCCESS = '[Progress] Load Body Data Success';
export const LOAD_BODY_DATA_FAIL = '[Progress] Load Body Data Fail';
export const ADD_BODY_DATA = '[Progress] Add BodyData';
export const ADD_BODY_DATA_SUCCESS = '[Progress] Add BodyData Success';
export const ADD_BODY_DATA_FAIL = '[Progress] Add BodyData Fail';
export const EDIT_BODY_DATA = '[Progress] Edit BodyData';
export const EDIT_BODY_DATA_SUCCESS = '[Progress] Edit BodyData Success';
export const EDIT_BODY_DATA_FAIL = '[Progress] Edit BodyData Fail';
export const REMOVE_BODY_DATA = '[Progress] Remove BodyData';
export const REMOVE_BODY_DATA_SUCCESS = '[Progress] Remove BodyData Success';
export const REMOVE_BODY_DATA_FAIL = '[Progress] Remove BodyData Fail';
//#endregion

//#region Body Data copy const
export const LOAD_BODY_DATA_COPY = '[Progress] Load Body Data copy';
export const LOAD_BODY_DATA_COPY_SUCCESS = '[Progress] Load Body Data copy Success';
export const LOAD_BODY_DATA_COPY_FAIL = '[Progress] Load Body Data copy Fail';
export const EDIT_BODY_DATA_COPY = '[Progress] Edit Body Data copy';
export const EDIT_BODY_DATA_COPY_SUCCESS = '[Progress] Edit Body Data copy Success';
export const EDIT_BODY_DATA_COPY_FAIL = '[Progress] Edit Body Data copy Fail';
export const REMOVE_BODY_DATA_COPY = '[Progress] Remove Body Data copy';
export const REMOVE_BODY_DATA_COPY_SUCCESS = '[Progress] Remove Body Data copy Success';
export const REMOVE_BODY_DATA_COPY_FAIL = '[Progress] Remove Body Data copy Fail';
//#endregion

//#region Tag const
export const LOAD_TAGS = '[Progress] Load Tags';
export const LOAD_TAGS_SUCCESS = '[Progress] Load Tags Success';
export const LOAD_TAGS_FAIL = '[Progress] Load Tags Fail';
export const LOAD_TAGS_PAGE = '[Progress] Load Tags Page';
export const LOAD_TAGS_PAGE_SUCCESS = '[Progress] Load Tags Page Success';
export const LOAD_TAGS_PAGE_FAIL = '[Progress] Load Tags Page Fail';
export const ADD_TAG = '[Progress] Add Tag';
export const ADD_TAG_SUCCESS = '[Progress] Add Tag Success';
export const ADD_TAG_FAIL = '[Progress] Add Tag Fail';
export const REMOVE_TAG = '[Progress] Remove Tag';
export const REMOVE_TAG_SUCCESS = '[Progress] Remove Tag Success';
export const REMOVE_TAG_FAIL = '[Progress] Remove Tag Fail';
//#endregion

//#region Macro const
export const LOAD_MACROS = '[Progress] Load Macros';
export const LOAD_MACROS_SUCCESS = '[Progress] Load Macros Success';
export const LOAD_MACROS_FAIL = '[Progress] Load Macros Fail';
export const LOAD_MACROS_PAGE = '[Progress] Load Macros Page';
export const LOAD_MACROS_PAGE_SUCCESS = '[Progress] Load Macros Page Success';
export const LOAD_MACROS_PAGE_FAIL = '[Progress] Load Macros Page Fail';
export const LOAD_MACRO = '[Progress] Load Macro ';
export const LOAD_MACRO_SUCCESS = '[Progress] Load Macro Success';
export const LOAD_MACRO_FAIL = '[Progress] Load Macro Fail';
export const ADD_MACRO = '[Progress] Add Macro';
export const ADD_MACRO_SUCCESS = '[Progress] Add Macro Success';
export const ADD_MACRO_FAIL = '[Progress] Add Macro Fail';
export const EDIT_MACRO = '[Progress] Edit Macro';
export const EDIT_MACRO_SUCCESS = '[Progress] Edit Macro Success';
export const EDIT_MACRO_FAIL = '[Progress] Edit Macro Fail';
export const REMOVE_MACRO = '[Progress] Remove Macro';
export const REMOVE_MACRO_SUCCESS = '[Progress] Remove Macro Success';
export const REMOVE_MACRO_FAIL = '[Progress] Remove Macro Fail';
//#endregion

//#region Macro copy const
export const LOAD_MACRO_COPY = '[Progress] Load Macro copy';
export const LOAD_MACRO_COPY_SUCCESS = '[Progress] Load Macro copy Success';
export const LOAD_MACRO_COPY_FAIL = '[Progress] Load Macro copy Fail';
export const EDIT_MACRO_COPY = '[Progress] Edit Macro copy';
export const EDIT_MACRO_COPY_SUCCESS = '[Progress] Edit Macro copy Success';
export const EDIT_MACRO_COPY_FAIL = '[Progress] Edit Macro copy Fail';
export const REMOVE_MACRO_COPY = '[Progress] Remove Macro copy';
export const REMOVE_MACRO_COPY_SUCCESS = '[Progress] Remove Macro copy Success';
export const REMOVE_MACRO_COPY_FAIL = '[Progress] Remove Macro copy Fail';
//#endregion

//#region Body Data
/**
 * Load Body Datas Actions
 */
export class LoadBodyDatasAction implements Action {
    readonly type = LOAD_BODY_DATAS;
    constructor(public force: boolean = false) { }
}
export class LoadBodyDatasSuccessAction implements Action {
    readonly type = LOAD_BODY_DATAS_SUCCESS;
    constructor(public payload: IPagingResult<IBodyData>) { }
}
export class LoadBodyDatasFailAction implements Action {
    readonly type = LOAD_BODY_DATAS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Body Datas Page Actions
 */
export class LoadBodyDatasPageAction implements Action {
    readonly type = LOAD_BODY_DATAS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadBodyDatasPageSuccessAction implements Action {
    readonly type = LOAD_BODY_DATAS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<IBodyData>) { }
}
export class LoadBodyDatasPageFailAction implements Action {
    readonly type = LOAD_BODY_DATAS_PAGE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Body Data Actions
 */
export class LoadBodyDataAction implements Action {
    readonly type = LOAD_BODY_DATA;
    constructor(public id: number) { }
}
export class LoadBodyDataSuccessAction implements Action {
    readonly type = LOAD_BODY_DATA_SUCCESS;
    constructor(public payload: IBodyData) { }
}
export class LoadBodyDataFailAction implements Action {
    readonly type = LOAD_BODY_DATA_FAIL;
    constructor(public payload: any) { }
}
/**
 * Add Body Data Actions
 */
export class AddBodyDataAction implements Action {
    readonly type = ADD_BODY_DATA;
    constructor(public payload: IBodyData) { }
}
export class AddBodyDataSuccessAction implements Action {
    readonly type = ADD_BODY_DATA_SUCCESS;
    constructor(public payload: IBodyData) { }
}
export class AddBodyDataFailAction implements Action {
    readonly type = ADD_BODY_DATA_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Body Data Actions
 */
export class EditBodyDataAction implements Action {
    readonly type = EDIT_BODY_DATA;
    constructor(public payload: IBodyData) { }
}
export class EditBodyDataSuccessAction implements Action {
    readonly type = EDIT_BODY_DATA_SUCCESS;
    constructor(public payload: IBodyData) { }
}
export class EditBodyDataFailAction implements Action {
    readonly type = EDIT_BODY_DATA_FAIL;
    constructor(public payload: any) { }
}
/**
 * Remove Body Data Actions
 */
export class RemoveBodyDataAction implements Action {
    readonly type = REMOVE_BODY_DATA;
    constructor(public payload: IBodyData) { }
}
export class RemoveBodyDataSuccessAction implements Action {
    readonly type = REMOVE_BODY_DATA_SUCCESS;
    constructor(public payload: IBodyData) { }
}
export class RemoveBodyDataFailAction implements Action {
    readonly type = REMOVE_BODY_DATA_FAIL;
    constructor(public payload: any) { }
}
//#endregion

//#region Body Data Copy
/**
 * Load Body Data copy Actions
 */
export class LoadBodyDataCopyAction implements Action {
    readonly type = LOAD_BODY_DATA_COPY;
    constructor(public id: number, public detailId: number) { }
}
export class LoadBodyDataCopySuccessAction implements Action {
    readonly type = LOAD_BODY_DATA_COPY_SUCCESS;
    constructor(public payload: IBodyData) { }
}
export class LoadBodyDataCopyFailAction implements Action {
    readonly type = LOAD_BODY_DATA_COPY_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Body Data copy Actions
 */
export class EditBodyDataCopyAction implements Action {
    readonly type = EDIT_BODY_DATA_COPY;
    constructor(public payload: IBodyData) { }
}
export class EditBodyDataCopySuccessAction implements Action {
    readonly type = EDIT_BODY_DATA_COPY_SUCCESS;
    constructor(public payload: IBodyData) { }
}
export class EditBodyDataCopyFailAction implements Action {
    readonly type = EDIT_BODY_DATA_COPY_FAIL;
    constructor(public payload: any) { }
}
/**
 * Remove Body Data copy Actions
 */
export class RemoveBodyDataCopyAction implements Action {
    readonly type = REMOVE_BODY_DATA_COPY;
    constructor(public payload: IBodyData) { }
}
export class RemoveBodyDataCopySuccessAction implements Action {
    readonly type = REMOVE_BODY_DATA_COPY_SUCCESS;
    constructor(public payload: IBodyData) { }
}
export class RemoveBodyDataCopyFailAction implements Action {
    readonly type = REMOVE_BODY_DATA_COPY_FAIL;
    constructor(public payload: any) { }
}
//#endregion

//#region Tag
/**
 * Load Tags Actions
 */
export class LoadTagsAction implements Action {
    readonly type = LOAD_TAGS;
}
export class LoadTagsSuccessAction implements Action {
    readonly type = LOAD_TAGS_SUCCESS;
    constructor(public payload: IPagingResult<ITag>) { }
}
export class LoadTagsFailAction implements Action {
    readonly type = LOAD_TAGS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Tags Page Actions
 */
export class LoadTagsPageAction implements Action {
    readonly type = LOAD_TAGS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadTagsPageSuccessAction implements Action {
    readonly type = LOAD_TAGS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<ITag>) { }
}
export class LoadTagsPageFailAction implements Action {
    readonly type = LOAD_TAGS_PAGE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Add Tag Actions
 */
export class AddTagAction implements Action {
    readonly type = ADD_TAG;
    constructor(public payload: ITag) { }
}
export class AddTagSuccessAction implements Action {
    readonly type = ADD_TAG_SUCCESS;
    constructor(public payload: ITag) { }
}
export class AddTagFailAction implements Action {
    readonly type = ADD_TAG_FAIL;
    constructor(public payload: any) { }
}
/**
 * Remove Tag Actions
 */
export class RemoveTagAction implements Action {
    readonly type = REMOVE_TAG;
    constructor(public id: number) { }
}
export class RemoveTagSuccessAction implements Action {
    readonly type = REMOVE_TAG_SUCCESS;
    constructor(public id: number) { }
}
export class RemoveTagFailAction implements Action {
    readonly type = REMOVE_TAG_FAIL;
    constructor(public payload: any) { }
}
//#endregion

//#region Macro
/**
 * Load Macros Actions
 */
export class LoadMacrosAction implements Action {
    readonly type = LOAD_MACROS;
    constructor(public force: boolean = false) { }
}
export class LoadMacrosSuccessAction implements Action {
    readonly type = LOAD_MACROS_SUCCESS;
    constructor(public payload: IPagingResult<IMacro>) { }
}
export class LoadMacrosFailAction implements Action {
    readonly type = LOAD_MACROS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Macros Page Actions
 */
export class LoadMacrosPageAction implements Action {
    readonly type = LOAD_MACROS_PAGE;
    constructor(public page: number = null) { }
}
export class LoadMacrosPageSuccessAction implements Action {
    readonly type = LOAD_MACROS_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<IMacro>) { }
}
export class LoadMacrosPageFailAction implements Action {
    readonly type = LOAD_MACROS_PAGE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Macro Actions
 */
export class LoadMacroAction implements Action {
    readonly type = LOAD_MACRO;
    constructor(public id: number) { }
}
export class LoadMacroSuccessAction implements Action {
    readonly type = LOAD_MACRO_SUCCESS;
    constructor(public payload: IMacro) { }
}
export class LoadMacroFailAction implements Action {
    readonly type = LOAD_MACRO_FAIL;
    constructor(public payload: any) { }
}
/**
 * Add Macro Actions
 */
export class AddMacroAction implements Action {
    readonly type = ADD_MACRO;
    constructor(public payload: IMacro) { }
}
export class AddMacroSuccessAction implements Action {
    readonly type = ADD_MACRO_SUCCESS;
    constructor(public payload: IMacro) { }
}
export class AddMacroFailAction implements Action {
    readonly type = ADD_MACRO_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Macro Actions
 */
export class EditMacroAction implements Action {
    readonly type = EDIT_MACRO;
    constructor(public payload: IMacro) { }
}
export class EditMacroSuccessAction implements Action {
    readonly type = EDIT_MACRO_SUCCESS;
    constructor(public payload: IMacro) { }
}
export class EditMacroFailAction implements Action {
    readonly type = EDIT_MACRO_FAIL;
    constructor(public payload: any) { }
}
/**
 * Remove Macro Actions
 */
export class RemoveMacroAction implements Action {
    readonly type = REMOVE_MACRO;
    constructor(public payload: IMacro) { }
}
export class RemoveMacroSuccessAction implements Action {
    readonly type = REMOVE_MACRO_SUCCESS;
    constructor(public payload: IMacro) { }
}
export class RemoveMacroFailAction implements Action {
    readonly type = REMOVE_MACRO_FAIL;
    constructor(public payload: any) { }
}
//#endregion

//#region Macro copy
/**
 * Load Macro copy Actions
 */
export class LoadMacroCopyAction implements Action {
    readonly type = LOAD_MACRO_COPY;
    constructor(public id: number, public detailId: number) { }
}
export class LoadMacroCopySuccessAction implements Action {
    readonly type = LOAD_MACRO_COPY_SUCCESS;
    constructor(public payload: IMacro) { }
}
export class LoadMacroCopyFailAction implements Action {
    readonly type = LOAD_MACRO_COPY_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Macro copy Actions
 */
export class EditMacroCopyAction implements Action {
    readonly type = EDIT_MACRO_COPY;
    constructor(public payload: IMacro) { }
}
export class EditMacroCopySuccessAction implements Action {
    readonly type = EDIT_MACRO_COPY_SUCCESS;
    constructor(public payload: IMacro) { }
}
export class EditMacroCopyFailAction implements Action {
    readonly type = EDIT_MACRO_COPY_FAIL;
    constructor(public payload: any) { }
}
/**
 * Remove Macro copy Actions
 */
export class RemoveMacroCopyAction implements Action {
    readonly type = REMOVE_MACRO_COPY;
    constructor(public payload: IMacro) { }
}
export class RemoveMacroCopySuccessAction implements Action {
    readonly type = REMOVE_MACRO_COPY_SUCCESS;
    constructor(public payload: IMacro) { }
}
export class RemoveMacroCopyFailAction implements Action {
    readonly type = REMOVE_MACRO_COPY_FAIL;
    constructor(public payload: any) { }
}
//#endregion
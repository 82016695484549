import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { MesocycleActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-mesocycles-infinity-scroll',
  templateUrl: './mesocycles-infinity-scroll.component.html',
  styleUrls: ['./mesocycles-infinity-scroll.component.scss'],
})
export class MesocyclesInfinityScrollComponent {
  selectPaging = AppReducers.getMesocyclePaging;
  loadPageSuccessAction: string = MesocycleActions.LOAD_MESOCYCLES_PAGE_SUCCESS;
  loadPageFailAction: string = MesocycleActions.LOAD_MESOCYCLES_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  loadPageAction() {
    this.store.dispatch(new MesocycleActions.LoadMesocyclesPageAction());
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { INote, INoteFilter, INoteRequest } from '@models/note.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { NoteFilterShowOnDashboardTypes } from '../enums/note-filter-show-on-dashboard.enum';
import { AuthService } from './auth.service';
import * as moment from 'moment';
import { UserRole } from '@models/authentication.model';
import { HttpHelper } from '../helpers/http.helper';
import { IPagingResult } from '@models/paging.model';
import { CoachAthleteViewService } from './coach-athlete-view.service';


@Injectable({
    providedIn: "root",
})
export class NoteService {
    apiPath: string = 'notes';

    constructor(private http: HttpClient,
        private coachAthleteViewService: CoachAthleteViewService,
        private authService: AuthService) { }

    authQuery(): string {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        userRole = userRole ? userRole : UserRole.athlete;

        if (userRole == UserRole.athlete) {
            return `athlete_id=${userId}&show_on_dashboard=1`;
        }
        else {
            let query = `coach_id=${userId}`;
            let athleteId = this.coachAthleteViewService.athleteId;
            if (athleteId) {
                query += `&filter[athletes]=${this.coachAthleteViewService.athleteId}`;
            }
            return query;
        }
    }

    getAll(filter: INoteFilter, page: number = 1, withoutLoading = false): Observable<IPagingResult<INote>> {
        let query = `${this.authQuery()}&filter[sort]=desc`;

        switch (filter?.show_on_dashboard) {
            case NoteFilterShowOnDashboardTypes.onDashboard:
                query += `&show_on_dashboard=1`;
                break;
            case NoteFilterShowOnDashboardTypes.notOnDashboard:
                query += `&show_on_dashboard=0`;
                break;
        }
        if (filter?.date_from && filter.date_to) {
            let from = moment(filter.date_from).format('DD-MM-YYYY');
            let to = moment(filter.date_to).format('DD-MM-YYYY');
            query += `&filter[created_between]=${from},${to}`
        }
        if (filter?.athletes?.length > 0) {
            const athleteArr = filter.athletes.map((athlete) => athlete.id);
            query += `&filter[athletes]=${athleteArr}`;
        }

        let pageQuery = (query ? '&' : '') + `page=${page}`;

        let http = this.http;
        if (withoutLoading) {
            http = http.disableLoading();
        }

        return http.get(`/${this.apiPath}?${query}${pageQuery}`, { observe: 'response' }).pipe(
            map((response: HttpResponse<any>): IPagingResult<INote> => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: {
                            ...paging,
                            filter: JSON.stringify(filter)
                        }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    get(id: number): Observable<INote> {
        return this.http.get(`/${this.apiPath}/${id}`).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data[0];
                } else {
                    throw response.message;
                }
            })
        );
    }

    add(req: INoteRequest) {
        let model: any = { ...req };
        let userId = this.authService.getUserId();
        model.coach_id = userId;

        return this.http.post(`/${this.apiPath}`, model);
    }

    edit(req: INoteRequest) {
        let model: any = { ...req };
        let userId = this.authService.getUserId();
        model.coach_id = userId;
        return this.http.patch(`/${this.apiPath}/${model.id}`, model);
    }

    delete(id: number) {
        return this.http.delete(`/${this.apiPath}/${id}`)
    }
}
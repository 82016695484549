// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-button {
  position: relative;
}
ion-button .unread-count {
  position: absolute;
  padding: 5px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ons-color-white);
  background: var(--ons-color-red);
  font-family: var(--ons-font-bold);
  top: 0;
  right: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/athlete-notifications-button/athlete-notifications-button.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;EACA,eAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,6BAAA;EACA,gCAAA;EACA,iCAAA;EACA,MAAA;EACA,QAAA;AACR","sourcesContent":["ion-button {\n    position: relative;\n\n    .unread-count {\n        position: absolute;\n        padding: 5px;\n        border-radius: 50%;\n        width: 18px;\n        height: 18px;\n        font-size: 10px;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        color: var(--ons-color-white);\n        background: var(--ons-color-red);\n        font-family: var(--ons-font-bold);\n        top: 0;\n        right: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component, NgZone, OnInit } from "@angular/core";

import { ModalController, Platform } from "@ionic/angular";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { LanguageService } from "@services/language.service";
import { AthleteActions, InvitationActions, NotificationActions } from "./core/store/actions";
import { AppState } from "./core/store/app.states";
import { Store } from "@ngrx/store";
import { NavigationEnd, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AppReducers } from "./core/store";
import { INotification, NotificationType } from "@models/notificaton.model";
import { AuthService } from "@services/auth.service";
import { LocalStorageService } from "@services/local-storage.service";
import { OverviewPoliciesModalComponent } from "./modules/shared/overview-policies-modal/overview-policies-modal.component";
import { Plugins } from "@capacitor/core";
const { App } = Plugins;

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
  notificationSub: Subscription;
  loadedNotificationIds: number[] = null;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private languageService: LanguageService,
    private lsService: LocalStorageService,
    private store: Store<AppState>,
    private router: Router,
    private zone: NgZone,
    private authService: AuthService,
    private modalController: ModalController
  ) {
    this.initializeApp();
  }

  ngOnInit(): void { }

  async initializeApp() {
    await this.platform.ready();
    this.splashScreen.show();

    this.languageService.initLocalization();

    App?.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        const slug = data.url.split(".de").pop();
        if (slug) {
          this.router.navigateByUrl(slug);
        }
      });
    });

    this.notificationSub = this.store.select(AppReducers.getNotificationCollection)
      .subscribe(notifications => {
        if (notifications?.length > 0) {
          if (this.loadedNotificationIds) {
            let mesocycleUpdated = this.wasUpdated(notifications, NotificationType.mesocycle);
            if (mesocycleUpdated) {
              this.store.dispatch(new AthleteActions.LoadTrackingAction(null, true, true));
              this.store.dispatch(new AthleteActions.LoadTrainingAction(null, true));
            }

            let planUpdated = this.wasUpdated(notifications, NotificationType.plan);
            if (planUpdated) {
              this.store.dispatch(new AthleteActions.LoadTrainingAction(null, true));
            }

            let invitationUpdated = this.wasUpdated(notifications, NotificationType.invite);
            if (invitationUpdated) {
              this.store.dispatch(new InvitationActions.GetAction())
            }

            let assignedAthletesUpdated = this.wasUpdated(notifications, NotificationType.assignment);
            if (assignedAthletesUpdated) {
              this.store.dispatch(new AthleteActions.LoadAthletesAction(true))
            }
          }
          this.loadedNotificationIds = notifications.filter(x => !x.is_read).map(x => x.id);
        }
      })
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.authService.isLoggedIn()) {
          this.store.dispatch(new NotificationActions.LoadNotificationsAction(true, true));
        }
      }
    });

    let isEULAAccepted = this.lsService.getEULA();
    let isPolicyAccepted = this.lsService.getPolicy();
    if (!isEULAAccepted || !isPolicyAccepted) {
      setTimeout(() => {
        this.modalController.create({
          component: OverviewPoliciesModalComponent,
          cssClass: "modal-shadow"
        }).then(modal => {
          modal.present();
        });
      }, 2000);
    }
  }

  wasUpdated(notifications: INotification[], type: NotificationType): boolean {
    let events: INotification[] = notifications.filter(x => !x.is_read && x.type == type);
    let isUpdated: boolean = false;
    events.forEach(ev => {
      if (!this.loadedNotificationIds.includes(ev.id)) {
        isUpdated = true;
      }
    });
    return isUpdated;
  }
}

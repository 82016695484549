import { ILoginForm } from '@models/authentication.model';
import { IUser, IUpdateCoachUser, IUpdateAthleteUser } from '@models/user.model';
import { createAction, props } from '@ngrx/store';

export const LOGIN = '[Auth] Login';
export const LOGIN_SUCCESS = '[Auth] Login success';

export const initializeAuth = createAction('[Auth] Initialize auth');

export const login = createAction(
  LOGIN,
  props<{ credentials: ILoginForm }>()
);
export const loginSuccess = createAction(
  LOGIN_SUCCESS
);

export const updateUser = createAction(
  '[Coach profile] Update Coach User',
  props<{ user: IUpdateCoachUser }>()
);

export const updateAthleteUser = createAction(
  '[Athlete profile] Update Athlete User',
  props<{ user: IUpdateAthleteUser }>()
);

export const logOut = createAction(
  '[Auth] Log out'
);

export const loadUser = createAction('[Auth] Load user');
export const loadUserSuccess = createAction(
  '[Auth] Load user success',
  props<{ user: IUser }>()
);


export const checkAuth = createAction('[Auth] Check auth');
export const checkAuthSuccess = createAction(
  '[Auth] Check auth success',
  props<{ isAuthenticated: boolean }>()
);
export const setNotAuthenticated = createAction(
  '[Auth] Not authenticated',
  props<{ isAuthenticated: boolean }>()
);
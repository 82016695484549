import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { NotificationActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-athlete-notifications-button',
  templateUrl: './athlete-notifications-button.component.html',
  styleUrls: ['./athlete-notifications-button.component.scss'],
})
export class AthleteNotificationsButtonComponent implements OnInit {
  unreadCount$: Observable<number>;

  constructor(private router: Router,
    private store: Store<AppState>) { }

  ngOnInit() {
    this.store.dispatch(new NotificationActions.LoadNotificationsAction());
    this.unreadCount$ = this.store.select(AppReducers.getNotificationUnreadCount);
  }

  gotoPage() {
    this.router.navigate(['/athlete/notifications']);
  }
}

import { Injectable } from '@angular/core';
import { environment } from "../../../environments/environment";
import { UIService } from "@services/ui.service";

import * as AWS from 'aws-sdk';
import { Buffer } from 'buffer';
import { AuthService } from './auth.service';
import { from, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class S3FileUploadService {
    s3: AWS.S3 = null;
    baseUrl;

    constructor(
        private uiService: UIService,
        private httpClient: HttpClient,
        private authService: AuthService
    ) {
        this.configureAWS();
    }

    configureAWS() {
        this.baseUrl = environment.S3_BASE_URL;
        
        AWS.config.update({
            region: environment.S3_BUCKET_REGION,
            credentials: {
                accessKeyId: environment.AWS_ACCESS_KEY_ID,
                secretAccessKey: environment.AWS_SECRET_ACCESS_KEY
            }
        });

        AWS.config.signatureVersion = environment.AWS_SIGNATURE_VERSION;

        this.s3 = new AWS.S3({
            apiVersion: environment.AWS_API_VERSION,
            params: { Bucket: environment.S3_BUCKET_NAME }
        });
    }

    uploadAvatar(dataUrl) {
        let metaInfo = dataUrl.split(';')[0];
        let mimeType = metaInfo.split(':')[1];
        let fileExt = mimeType.split('/')[1];

        let filename = `avatar_${Date.now()}.${fileExt}`;

        let bucket = 'avatars';

        return new Promise((resolve, reject) => {
            this.uploadToS3(dataUrl, bucket, filename)
                .then((result) => {
                    resolve(result);
                }).catch((err) => {
                    reject(err);
                });
        });
    }

    uploadDocument(dataUrl, filename = ''): Observable<any> {
        let metaInfo = dataUrl.split(';')[0];
        let mimeType = metaInfo.split(':')[1];
        let fileExt = mimeType.split('/')[1];

        let s3filename = `document_${Date.now()}.${fileExt}`;
        if (!filename) {
            filename = s3filename;
        }

        let bucket = 'documents';

        let promise = new Promise((resolve, reject) => {
            this.uploadToS3(dataUrl, bucket, s3filename)
                .then((result) => {
                    resolve({ uri: result, filename: filename });
                }).catch((err) => {
                    reject(err);
                });
        });

        return from(promise);
    }

    uploadToS3(dataUrl, bucketName, filename) {
        const base64Buffer = Buffer.from(dataUrl.replace(/^data:([a-zA-Z]*)\/([a-zA-Z]*);base64,/, ''), 'base64');
        let metaInfo = dataUrl.split(';')[0];
        let mimeType = metaInfo.split(':')[1];

        let mainBucket = `users/user_${this.authService.getUserId()}/`;

        this.uiService.showLoading();
        return new Promise((resolve, reject) => {

            const params = {
                Bucket: environment.S3_BUCKET_NAME + '/' + mainBucket + bucketName,
                Body: base64Buffer,
                Key: filename,
                ACL: "public-read",
                ContentEncoding: "base64",
                ContentType: mimeType
            };

            this.s3.putObject(params, (err, res) => {
                this.uiService.hideLoading();
                if (err) {
                    reject(err);
                } else {
                    let url = this.baseUrl + mainBucket + bucketName + '/' + filename;
                    resolve(url);
                }
            });

        });
    }

    getFileAsDataUrl(fileUrl: string): Promise<string> {
        return new Promise(resolve => {
            let mainPart = fileUrl.substring(fileUrl.indexOf('.com') + 5);
            let bucket = environment.S3_BUCKET_NAME + '/' + mainPart.substr(0, mainPart.lastIndexOf('/'));
            let filename = mainPart.substr(mainPart.lastIndexOf('/') + 1);
            const params: AWS.S3.GetObjectRequest = {
                Bucket: bucket,
                Key: filename
            };
            this.s3.getObject(params, (err, res) => {
                if (err) {
                } else {
                    resolve("data:image/" + filename.substr(filename.lastIndexOf('.') + 1) + ";base64," + this.encode(res.Body));
                }
            });
        });
    }

    private encode(data) {
        let str = data.reduce(function (a, b) { return a + String.fromCharCode(b) }, '');
        return btoa(str).replace(/.{76}(?=.)/g, '$&\n');
    }

    private getFileReader(): FileReader {
        const fileReader = new FileReader();
        const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
        return zoneOriginalInstance || fileReader;
    }
}

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginPage } from './pages/login/login.page';
import { RegistrationPage } from './pages/registration/registration.page';
import { UploadAvatarPage } from './pages/upload-avatar/upload-avatar.page';
import { ColorSchemaPage } from './pages/color-schema/color-schema.page';
import { ResetPasswordPage } from './pages/reset-password/reset-password.page';
import { CodeViewPage } from './pages/code-view/code-view.page';
import { NewPasswordPage } from './pages/new-password/new-password.page';
import { SelectRolePage } from './pages/select-role/select-role.page';
import { AthleteRegPage } from './pages/athlete-reg/athlete-reg.page';
import { AthleteInfoPage } from './pages/athlete-info/athlete-info.page';
import { AthleteLevelPage } from './pages/athlete-level/athlete-level.page';
import { InviteCodePage } from './pages/invite-code/invite-code.page';
import { SubscriptionPage } from './pages/subscription/subscription.page';


const routes: Routes = [
    {
        path: 'login',
        component: LoginPage
    },
    {
        path: 'registration',
        component: RegistrationPage
    },
    {
        path: 'upload-avatar',
        component: UploadAvatarPage
    },
    {
        path: 'color-schema',
        component: ColorSchemaPage
    },
    {
        path: 'reset-password',
        component: ResetPasswordPage
    },
    {
        path: 'code-view',
        component: CodeViewPage
    },
    {
        path: 'new-password',
        component: NewPasswordPage
    },
    {
        path: 'select-role',
        component: SelectRolePage
    },
    {
        path: 'athlete-reg',
        component: AthleteRegPage
    },
    {
        path: 'athlete-info',
        component: AthleteInfoPage
    },
    {
        path: 'athlete-level',
        component: AthleteLevelPage
    },
    {
        path: 'invite-code',
        component: InviteCodePage
    },
    {
        path: 'subscription',
        component: SubscriptionPage
    },


];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})

export class AuthRoutingModule {

}

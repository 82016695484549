import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { AuthRoutingModule } from './auth-routing.module';
import { TranslateModule } from '@ngx-translate/core';

import { LoginFormComponent } from './components/login-form/login-form.component';
import { CoachRegistrationFormComponent } from './components/coach-registration-form/coach-registration-form.component';
import { ResetPasswordFormComponent } from './components/reset-password-form/reset-password-form.component';

import { LoginPage } from './pages/login/login.page';
import { UploadAvatarPage } from './pages/upload-avatar/upload-avatar.page';
import { RegistrationPage } from './pages/registration/registration.page';
import { ColorSchemaPage } from './pages/color-schema/color-schema.page';
import { ResetPasswordPage } from './pages/reset-password/reset-password.page';
import { CodeViewPage } from './pages/code-view/code-view.page';
import { NewPasswordPage } from './pages/new-password/new-password.page';
import { SelectRolePage } from './pages/select-role/select-role.page';
import { AthleteRegPage } from './pages/athlete-reg/athlete-reg.page';
import { AthleteInfoPage } from './pages/athlete-info/athlete-info.page';
import { AthleteLevelPage } from './pages/athlete-level/athlete-level.page';
import { SharedModule } from '../shared/shared.module';
import { InviteCodePage } from './pages/invite-code/invite-code.page';
import { SubscriptionPage } from './pages/subscription/subscription.page';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        AuthRoutingModule,
        ReactiveFormsModule,
        TranslateModule.forChild(),
        SharedModule
    ],
    declarations: [
        LoginFormComponent,
        CoachRegistrationFormComponent,
        ResetPasswordFormComponent,
        LoginPage,
        RegistrationPage,
        UploadAvatarPage,
        ColorSchemaPage,
        ResetPasswordPage,
        CodeViewPage,
        NewPasswordPage,
        SelectRolePage,
        AthleteRegPage,
        AthleteInfoPage,
        AthleteLevelPage,
        InviteCodePage,
        SubscriptionPage
    ],

})

export class AuthModule {
}

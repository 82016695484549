// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
  padding: 60px 30px;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/overview-policies-modal/overview-policies-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;AACJ","sourcesContent":[".text {\n    padding: 60px 30px;\n    text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

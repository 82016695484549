// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-fab ion-fab-button {
  --background: var(--ons-color-primary);
  --color: var(--ons-color-primary-black);
  width: 60px;
  height: 60px;
  --box-shadow: none;
  font-size: 36px !important;
}
ion-fab ion-fab-button ion-icon {
  font-size: 24px;
}

.search {
  margin-bottom: 10px;
  --padding-start: 0;
}

ion-list-header {
  padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/filter-units/filter-units.component.scss"],"names":[],"mappings":"AACI;EACI,sCAAA;EACA,uCAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;EACA,0BAAA;AAAR;AAEQ;EACI,eAAA;AAAZ;;AAKA;EACI,mBAAA;EACA,kBAAA;AAFJ;;AAKA;EACI,UAAA;AAFJ","sourcesContent":["ion-fab {\n    ion-fab-button {\n        --background: var(--ons-color-primary);\n        --color: var(--ons-color-primary-black);\n        width: 60px;\n        height: 60px;\n        --box-shadow: none;\n        font-size: 36px !important;\n\n        ion-icon {\n            font-size: 24px;\n        }\n    }\n}\n\n.search {\n    margin-bottom: 10px;\n    --padding-start: 0;\n}\n\nion-list-header {\n    padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

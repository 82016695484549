// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content.select-role-view {
  --background: var(--ons-color-primary-background);
}
ion-content.select-role-view main.inner-scroll {
  padding: 10px !important;
}
ion-content.select-role-view ion-row {
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/pages/select-role/select-role.page.scss"],"names":[],"mappings":"AAAA;EACE,iDAAA;AACF;AAAE;EACE,wBAAA;AAEJ;AACE;EACE,uBAAA;AACJ","sourcesContent":["ion-content.select-role-view {\n  --background: var(--ons-color-primary-background);\n  main.inner-scroll {\n    padding : 10px !important;\n  }\n\n  ion-row {\n    justify-content: center;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

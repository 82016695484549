import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { TrainingPlanService } from '@services/training-plan.service';
import { TrainingActions } from '../actions';
import { TrainingUnitService } from '@services/training-unit.service';
import { TrainingCategoryService } from '@services/training-category.service';
import { IntensityTechniquesService } from '@services/intensity-techniques.service';
import { TrainingPlanSheetService } from '@services/training-sheet.service';
import { TrainingTemplateService } from '@services/training-template.service';
import { Store } from '@ngrx/store';
import { AppState } from '../app.states';
import { ITechnique, ITrainingCategory, ITrainingPlan, ITrainingTemplate, ITrainingUnit, IUnitFilter } from "@models/training.model";
import { IPagingResult } from "@models/paging.model";
import { DEFAULT_UNIT_FILTER } from "app/core/consts/default-unit-filter.const";

@Injectable()
export class TrainingEffects {
    constructor(
        private trainingPlanService: TrainingPlanService,
        private trainingUnitService: TrainingUnitService,
        private trainingCategoryService: TrainingCategoryService,
        private techniquesService: IntensityTechniquesService,
        private trainingSheetService: TrainingPlanSheetService,
        private trainingTemplateService: TrainingTemplateService,
        private store$: Store<AppState>,
        private actions$: Actions
    ) { }

    loadPlans$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_PLANS),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [TrainingActions.LoadPlansAction, AppState]) => {
                return action.force || !storeState?.training?.plans?.paging.currentPage;
            }),
            exhaustMap(([action, storeState]: [TrainingActions.LoadPlansAction, AppState]) => {
                return this.trainingPlanService
                    .getAll()
                    .pipe(
                        map((res: any) => new TrainingActions.LoadPlansSuccessAction(res)),
                        catchError((error) => of(new TrainingActions.LoadPlansFailAction(error)))
                    )
            })
        )
    );
    loadPlansPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_PLANS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [TrainingActions.LoadPlansPageAction, AppState]) => {
                return storeState?.training?.plans?.paging?.currentPage != 0 && (!action.page || storeState?.training?.plans.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [TrainingActions.LoadPlansPageAction, AppState]) => {
                let page = !action.page ? storeState.training.plans.paging.currentPage + 1 : action.page;
                return this.trainingPlanService
                    .getAll(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<ITrainingPlan>) => new TrainingActions.LoadPlansPageSuccessAction(pagingResult)),
                        catchError((error) => of(new TrainingActions.LoadPlansPageFailAction(error)))
                    )
            })
        )
    );

    loadPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_PLAN),
            withLatestFrom(this.store$),
            filter((data: any) => {
                return !data[1]?.training?.plans?.items.some(x => x.id == data[0].id);
            }),
            exhaustMap((action: TrainingActions.LoadPlanAction) => {
                return this.trainingPlanService
                    .get(action[0].id)
                    .pipe(
                        map((res: any) => new TrainingActions.LoadPlanSuccessAction(res)),
                        catchError((error) => of(new TrainingActions.LoadPlanFailAction(error)))
                    )
            })
        )
    );
    addPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.ADD_PLAN),
            exhaustMap((action: any) =>
                this.trainingPlanService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.AddPlanSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.AddPlanFailAction(error)))
                    )
            )
        )
    );
    editPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.EDIT_PLAN),
            exhaustMap((action: any) =>
                this.trainingPlanService
                    .edit(action.payload, action.withLoading)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.EditPlanSuccessAction(res.data[0], action.withLoading, action.withResult);
                        }),
                        catchError((error) => of(new TrainingActions.EditPlanFailAction(error)))
                    ))
        )
    );
    removePlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.REMOVE_PLAN),
            exhaustMap((action: TrainingActions.RemovePlanAction) =>
                this.trainingPlanService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.RemovePlanSuccessAction(action.payload);
                        }),
                        catchError((error) => of(new TrainingActions.RemovePlanFailAction(error)))
                    )
            )
        )
    );

    loadPlanCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_PLAN_COPY),
            withLatestFrom(this.store$),
            filter((data: any) => {
                return !data[1]?.training?.planCopies?.items.some(x => x.id == data[0].id);
            }),
            exhaustMap((action: TrainingActions.LoadPlanCopyAction) => {
                return this.trainingPlanService
                    .get(action[0].id)
                    .pipe(
                        map((res: any) => new TrainingActions.LoadPlanCopySuccessAction(res)),
                        catchError((error) => of(new TrainingActions.LoadPlanCopyFailAction(error)))
                    )
            })
        )
    );
    editPlanCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.EDIT_PLAN_COPY),
            exhaustMap((action: any) =>
                this.trainingPlanService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.EditPlanCopySuccessAction(res.data[0], action.withLoading, action.withResult);
                        }),
                        catchError((error) => of(new TrainingActions.EditPlanCopyFailAction(error)))
                    )
            )
        )
    );
    removePlanCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.REMOVE_PLAN_COPY),
            exhaustMap((action: TrainingActions.RemovePlanCopyAction) =>
                this.trainingPlanService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.RemovePlanCopySuccessAction(action.payload);
                        }),
                        catchError((error) => of(new TrainingActions.RemovePlanCopyFailAction(error)))
                    )
            )
        )
    );


    loadUnits$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_UNITS),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [TrainingActions.LoadUnitsAction, AppState]) => {
                let isNewFilter = storeState?.training?.units.paging?.filter != JSON.stringify(action.filter);
                return action.force || isNewFilter;
            }),
            exhaustMap(([action, storeState]: [TrainingActions.LoadUnitsAction, AppState]) => {
                return this.trainingUnitService
                    .getAll(action.filter, 1, action.withoutLoading)
                    .pipe(
                        map((res: any) => new TrainingActions.LoadUnitsSuccessAction(res)),
                        catchError((error) => of(new TrainingActions.LoadUnitsFailAction(error)))
                    )
            }
            )
        )
    );
    loadUnitsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_UNITS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [TrainingActions.LoadUnitsPageAction, AppState]) => {
                return storeState?.training?.units?.paging?.currentPage != 0 && (!action.page || storeState?.training?.units.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [TrainingActions.LoadUnitsPageAction, AppState]) => {
                let page = !action.page ? storeState.training.units.paging.currentPage + 1 : action.page;
                let currentFilterJSON = storeState.training.units.paging.filter;
                let filter: IUnitFilter = currentFilterJSON ? JSON.parse(currentFilterJSON) : DEFAULT_UNIT_FILTER;

                return this.trainingUnitService
                    .getAll(filter, page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<ITrainingUnit>) => new TrainingActions.LoadUnitsPageSuccessAction(pagingResult)),
                        catchError((error) => of(new TrainingActions.LoadUnitsPageFailAction(error)))
                    )
            })
        )
    );
    addUnit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.ADD_UNIT),
            exhaustMap((action: any) =>
                this.trainingUnitService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.AddUnitSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.AddUnitFailAction(error)))
                    )
            )
        )
    );
    editUnit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.EDIT_UNIT),
            exhaustMap((action: any) =>
                this.trainingUnitService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.EditUnitSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.EditUnitFailAction(error)))
                    )
            )
        )
    );
    removeUnit$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.REMOVE_UNIT),
            exhaustMap((action: TrainingActions.RemoveUnitAction) =>
                this.trainingUnitService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.RemoveUnitSuccessAction(action.payload);
                        }),
                        catchError((error) => of(new TrainingActions.RemoveUnitFailAction(error)))
                    )
            )
        )
    );

    loadUnitCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_UNIT_COPY),
            exhaustMap((action: TrainingActions.LoadUnitCopyAction) => {
                return this.trainingUnitService
                    .get(action.payload, action.unitDataId)
                    .pipe(
                        map((res: any) => new TrainingActions.LoadUnitCopySuccessAction(res)),
                        catchError((error) => of(new TrainingActions.LoadUnitCopyFailAction(error)))
                    )
            }
            )
        )
    );
    editUnitCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.EDIT_UNIT_COPY),
            exhaustMap((action: any) =>
                this.trainingUnitService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.EditUnitCopySuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.EditUnitCopyFailAction(error)))
                    )
            )
        )
    );
    removeUnitCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.REMOVE_UNIT_COPY),
            exhaustMap((action: TrainingActions.RemoveUnitCopyAction) =>
                this.trainingUnitService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.RemoveUnitCopySuccessAction(action.payload);
                        }),
                        catchError((error) => of(new TrainingActions.RemoveUnitCopyFailAction(error)))
                    )
            )
        )
    );

    loadCategories$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_CATEGORIES),
            withLatestFrom(this.store$),
            filter(([action, storeState]) => {
                return !storeState?.training?.categories?.paging.currentPage;
            }),
            exhaustMap((action) => {
                return this.trainingCategoryService
                    .getAll()
                    .pipe(
                        map((res: any) => new TrainingActions.LoadCategoriesSuccessAction(res)),
                        catchError((error) => of(new TrainingActions.LoadCategoriesFailAction(error)))
                    )
            }
            )
        )
    );
    loadCategoriesPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_CATEGORIES_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [TrainingActions.LoadCategoriesPageAction, AppState]) => {
                return storeState?.training?.categories?.paging?.currentPage != 0 && (!action.page || storeState?.training?.categories.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [TrainingActions.LoadCategoriesPageAction, AppState]) => {
                let page = !action.page ? storeState.training.categories.paging.currentPage + 1 : action.page;
                return this.trainingCategoryService
                    .getAll(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<ITrainingCategory>) => new TrainingActions.LoadCategoriesPageSuccessAction(pagingResult)),
                        catchError((error) => of(new TrainingActions.LoadCategoriesPageFailAction(error)))
                    )
            })
        )
    );
    addCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.ADD_CATEGORY),
            exhaustMap((action: any) =>
                this.trainingCategoryService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.AddCategorySuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.AddCategoryFailAction(error)))
                    )
            )
        )
    );
    removeCategory$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.REMOVE_CATEGORY),
            exhaustMap((action: TrainingActions.RemoveCategoryAction) =>
                this.trainingCategoryService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.RemoveCategorySuccessAction(action.payload);
                        }),
                        catchError((error) => of(new TrainingActions.RemoveCategoryFailAction(error)))
                    )
            )
        )
    );

    loadTechniques$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_TECHNIQUES),
            withLatestFrom(this.store$),
            filter(([action, storeState]) => {
                return !storeState?.training?.techniques?.paging.currentPage;
            }),
            exhaustMap((action) => {
                return this.techniquesService
                    .getAll()
                    .pipe(
                        map((res: any) => new TrainingActions.LoadTechniquesSuccessAction(res)),
                        catchError((error) => of(new TrainingActions.LoadTechniquesFailAction(error)))
                    )
            }
            )
        )
    );
    loadTechniquesPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_TECHNIQUES_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [TrainingActions.LoadTechniquesPageAction, AppState]) => {
                return storeState?.training?.techniques?.paging?.currentPage != 0 && (!action.page || storeState?.training?.techniques.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [TrainingActions.LoadTechniquesPageAction, AppState]) => {
                let page = !action.page ? storeState.training.techniques.paging.currentPage + 1 : action.page;
                return this.techniquesService
                    .getAll(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<ITechnique>) => new TrainingActions.LoadTechniquesPageSuccessAction(pagingResult)),
                        catchError((error) => of(new TrainingActions.LoadTechniquesPageFailAction(error)))
                    )
            })
        )
    );
    addTechnique$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.ADD_TECHNIQUE),
            exhaustMap((action: any) =>
                this.techniquesService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.AddTechniqueSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.AddTechniqueFailAction(error)))
                    )
            )
        )
    );
    editTechnique$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.EDIT_TECHNIQUE),
            exhaustMap((action: any) =>
                this.techniquesService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.EditTechniqueSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.EditTechniqueFailAction(error)))
                    )
            )
        )
    );
    removeTechnique$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.REMOVE_TECHNIQUE),
            exhaustMap((action: TrainingActions.RemoveTechniqueAction) =>
                this.techniquesService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.RemoveTechniqueSuccessAction(action.payload);
                        }),
                        catchError((error) => of(new TrainingActions.RemoveTechniqueFailAction(error)))
                    )
            )
        )
    );

    loadSheets$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_SHEETS),
            exhaustMap((action) => {
                return this.trainingSheetService
                    .getAll()
                    .pipe(
                        map((res: any) => new TrainingActions.LoadSheetsSuccessAction(res)),
                        catchError((error) => of(new TrainingActions.LoadSheetsFailAction(error)))
                    )
            }
            )
        )
    );
    loadSheet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_SHEET),
            withLatestFrom(this.store$),
            filter((data: any) => {
                return !data[1]?.training?.sheets?.items.some(x => x.id == data[0].id);
            }),
            mergeMap((action: any) => {
                return this.trainingSheetService
                    .get(action[0].id)
                    .pipe(
                        map((res: any) => new TrainingActions.LoadSheetSuccessAction(res)),
                        catchError((error) => of(new TrainingActions.LoadSheetFailAction(error)))
                    )
            }
            )
        )
    );
    addSheet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.ADD_SHEET),
            exhaustMap((action: any) =>
                this.trainingSheetService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.AddSheetSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.AddSheetFailAction(error)))
                    )
            )
        )
    );
    editSheet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.EDIT_SHEET),
            exhaustMap((action: TrainingActions.EditSheetAction) =>
                this.trainingSheetService
                    .edit(action.payload, action.skipLoading)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.EditSheetSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.EditSheetFailAction(error)))
                    )
            )
        )
    );
    removeSheet$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.REMOVE_SHEET),
            exhaustMap((action: TrainingActions.RemoveSheetAction) =>
                this.trainingSheetService
                    .delete(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.RemoveSheetSuccessAction(action.payload);
                        }),
                        catchError((error) => of(new TrainingActions.RemoveSheetFailAction(error)))
                    )
            )
        )
    );
    copySheetAsTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.COPY_SHEET_AS_TEMPLATE),
            exhaustMap((action: TrainingActions.CopySheetAsTemlateAction) =>
                this.trainingSheetService
                    .copyAsTemplate(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.CopySheetAsTemlateSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.CopySheetAsTemlateFailAction(error)))
                    )
            )
        )
    );

    loadTemplates$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_TEMPLATES),
            withLatestFrom(this.store$),
            filter(([action, storeState]) => {
                return !storeState?.training?.templates?.paging.currentPage;
            }),
            exhaustMap((action) => {
                return this.trainingTemplateService
                    .getAll()
                    .pipe(
                        map((res: any) => new TrainingActions.LoadTemplatesSuccessAction(res)),
                        catchError((error) => of(new TrainingActions.LoadTemplatesFailAction(error)))
                    )
            }
            )
        )
    );
    loadTemplatesPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.LOAD_TEMPLATES_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [TrainingActions.LoadTemplatesPageAction, AppState]) => {
                return storeState?.training?.templates?.paging?.currentPage != 0 && (!action.page || storeState?.training?.templates.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [TrainingActions.LoadTemplatesPageAction, AppState]) => {
                let page = !action.page ? storeState.training.templates.paging.currentPage + 1 : action.page;
                return this.trainingTemplateService
                    .getAll(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<ITrainingTemplate>) => new TrainingActions.LoadTemplatesPageSuccessAction(pagingResult)),
                        catchError((error) => of(new TrainingActions.LoadTemplatesPageFailAction(error)))
                    )
            })
        )
    );
    addTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.ADD_TEMPLATE),
            exhaustMap((action: any) =>
                this.trainingTemplateService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.AddTemplateSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.AddTemplateFailAction(error)))
                    )
            )
        )
    );
    editTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.EDIT_TEMPLATE),
            exhaustMap((action: any) =>
                this.trainingTemplateService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.EditTemplateSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.EditTemplateFailAction(error)))
                    )
            )
        )
    );
    removeTemplate$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.REMOVE_TEMPLATE),
            exhaustMap((action: TrainingActions.RemoveTemplateAction) =>
                this.trainingTemplateService
                    .delete(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.RemoveTemplateSuccessAction(action.payload);
                        }),
                        catchError((error) => of(new TrainingActions.RemoveTemplateFailAction(error)))
                    )
            )
        )
    );


    copyPlan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(TrainingActions.COPY_PLAN),
            exhaustMap((action: TrainingActions.CopyTrainingPlanAction) =>
                this.trainingPlanService
                    .copy(action.name, action.planId)
                    .pipe(
                        map((res: any) => {
                            return new TrainingActions.CopyTrainingPlanSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new TrainingActions.CopyTrainingPlanFailAction(error)))
                    )
            )
        )
    );
}


export const environment = {
    production: true,
    API_URL: 'https://api.onspot-app.de/api/v1',
    WEB_URL: 'https://web.onspot-app.de/',
    S3_BASE_URL: 'https://onspot-assets-prod.s3.eu-central-1.amazonaws.com/',
    S3_BUCKET_NAME: 'onspot-assets-prod',
    S3_BUCKET_REGION: 'eu-west-1',
    AWS_ACCESS_KEY_ID: 'AKIAX7E5PIRLAKAW2YBY',
    AWS_SECRET_ACCESS_KEY: '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',
    AWS_SIGNATURE_VERSION: 'v4',
    AWS_API_VERSION: '2006-03-01',
    STRIPE_KEY: 'pk_live_51Hc55sGuZ1CahdjBxYyT75W8xotdnA5UZiCg7QI9JtwJXGMvvOw7sgMsAuVfbd4z2hoPqL9AHtwJEHUqEfl9NUia00kCuh1UfT',
    PAY_PAL_SANDBOX: false,
    PAY_PAL_CLIENT_ID: 'AatNjbN9HWuFmJm2pllbScuVxurMFjooccB8jGrekBKrLWZOwPk0bba-kylrrET8jhVLxti11g-myAUm',
    MAX_SHEETS_AMOUNT: '10'
  };

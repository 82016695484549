import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FilterActiveInvitesPipe } from './filter-active-invites.pipe';
import { FilterBodyDataByTypePipe } from './filter-body-data-by-type.pipe';
import { SafeHtmlPipe } from './safe-html.pipe';
import { SortByPipe } from './sortByPipe';
import { StripHtmlPipe } from './strip-html.pipe';

@NgModule({
    declarations: [
        SortByPipe,
        SafeHtmlPipe,
        StripHtmlPipe,
        FilterBodyDataByTypePipe,
        FilterActiveInvitesPipe
    ],
    imports: [CommonModule],
    exports: [
        SortByPipe,
        SafeHtmlPipe,
        StripHtmlPipe,
        FilterBodyDataByTypePipe,
        FilterActiveInvitesPipe
    ]
})

export class MainPipeModule { }
import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { UserRole } from '@models/authentication.model';
import { INutritionGoal } from '@models/nutrition.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: "root",
})
export class NutritionGoalsService {
    apiPath: string = 'nutrition_goals';

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    getAll(): Observable<INutritionGoal[]> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let authQuery = userRole == UserRole.athlete ? 'filter[athlete]' : 'coach_id';
        authQuery += `=${userId}`;
        return this.http.get(`/${this.apiPath}?${authQuery}`).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data;
                } else {
                    throw response.message;
                }
            })
        );
    }

    mapModel(model: INutritionGoal): INutritionGoal {
        let result = { ...model };
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        if (userRole == UserRole.athlete) {
            result.athlete_id = userId;
            result.coach_id = undefined;
        }
        else {
            result.coach_id = userId;
        }

        return result;
    }

    add(model: INutritionGoal) {
        let nutritionGoal = this.mapModel(model);

        return this.http.post(`/${this.apiPath}`, nutritionGoal);
    }

    edit(model: INutritionGoal) {
        let nutritionGoal = this.mapModel(model);

        return this.http.patch(`/${this.apiPath}/${nutritionGoal.id}`, nutritionGoal);
    }
}
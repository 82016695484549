import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { IAthleteTracking } from '@models/athlete-tracking.model';
import { IAddSetRequest, IAthleteTraining, IEditSetRequest, ITrainingNote } from '@models/athlete-training.model';
import { UserRole } from '@models/authentication.model';
import { ITrackingResetRequestModel } from '@models/tracking-reset-request.model';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { CoachAthleteViewService } from './coach-athlete-view.service';
import { ITrainingUnitNote } from '@models/training.model';

@Injectable({
    providedIn: "root",
})
export class AthleteTrainingTrackingService {
    apiPath: string = 'athlete_training_trackings';
    noteApiPath: string = 'athlete_training_tracking_notes';
    unitNoteApiPath: string = 'athlete_training_unit_notes';
    resetSheetTrackingsPath: string = 'reset_sheet_trackings';

    constructor(private http: HttpClient,
        private coachAthleteViewService: CoachAthleteViewService,
        private authService: AuthService) { }

    get(date: Date = null): Observable<IAthleteTraining> {
        let athleteId = null;

        let userRole = this.authService.getUserRole();
        athleteId = userRole == UserRole.athlete ? +this.authService.getUserId() : this.coachAthleteViewService.athleteId;

        let url = `/users/${athleteId}/training_trackings`;

        let reqDate = date ? moment(date) : moment();

        let formattedDate = reqDate.format('YYYY-MM-DD');
        let formattedCurrentDate = moment().format('YYYY-MM-DD');

        if (formattedDate != formattedCurrentDate) {
            url = url + `?filter[day]=${formattedDate}`;
        }

        return this.http.get(url).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data[0];
                } else {
                    throw response.message;
                }
            })
        );
    }

    addSet(model: IAddSetRequest): Observable<IAthleteTracking> {
        model = { ...model };
        let userId = this.authService.getUserId();
        model.athlete_id = userId;
        model.day = model.day ? model.day : moment().format('YYYY-MM-DD');

        return this.http.post(`/${this.apiPath}`, model)
            .pipe(
                map((response: any) => {
                    if (response?.status === "success") {
                        return response.data[0];
                    } else {
                        throw response.message;
                    }
                })
            );
    }

    editSet(id: number, model: IEditSetRequest): Observable<IAthleteTracking> {
        model = { ...model };
        return this.http.patch(`/${this.apiPath}/${id}`, model)
            .pipe(
                map((response: any) => {
                    if (response?.status === "success") {
                        return response.data[0];
                    } else {
                        throw response.message;
                    }
                })
            );
    }

    addNote(model: ITrainingNote): Observable<ITrainingNote> {
        return this.http.post(`/${this.noteApiPath}`, model)
            .pipe(
                map((response: any) => {
                    if (response?.status === "success") {
                        return response.data[0];
                    } else {
                        throw response.message;
                    }
                })
            );
    }
    editNote(model: ITrainingNote): Observable<ITrainingNote> {
        model = { ...model };
        return this.http.patch(`/${this.noteApiPath}/${model.id}`, model)
            .pipe(
                map((response: any) => {
                    if (response?.status === "success") {
                        return response.data[0];
                    } else {
                        throw response.message;
                    }
                })
            );
    }
    deleteNote(id: number): Observable<any> {
        return this.http.delete(`/${this.noteApiPath}/${id}`)
            .pipe(
                map((response: any) => {
                    if (response?.status === "success") {
                        return response.data[0];
                    } else {
                        throw response.message;
                    }
                })
            );
    }

    addUnitNote(model: ITrainingUnitNote): Observable<ITrainingNote> {
        return this.http.post(`/${this.unitNoteApiPath}`, model)
            .pipe(
                map((response: any) => {
                    if (response?.status === "success") {
                        return response.data[0];
                    } else {
                        throw response.message;
                    }
                })
            );
    }
    editUnitNote(model: ITrainingUnitNote): Observable<ITrainingNote> {
        model = { ...model };
        return this.http.patch(`/${this.unitNoteApiPath}/${model.id}`, model)
            .pipe(
                map((response: any) => {
                    if (response?.status === "success") {
                        return response.data[0];
                    } else {
                        throw response.message;
                    }
                })
            );
    }
    deleteUnitNote(id: number): Observable<any> {
        return this.http.delete(`/${this.unitNoteApiPath}/${id}`)
            .pipe(
                map((response: any) => {
                    if (response?.status === "success") {
                        return response.data[0];
                    } else {
                        throw response.message;
                    }
                })
            );
    }
    
    resetSheetTrackings(model: ITrackingResetRequestModel): Observable<any> {
        return this.http.post(`/${this.resetSheetTrackingsPath}`, model)
            .pipe(
                map((response: any) => {
                    console.log("success");
                    if (response?.status === "success") {
                        return response.data[0];
                    } else {
                        throw response.message;
                    }
                })
            );
    }
}
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { ITrainingPlan } from '@models/training.model';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '@services/ui.service';
import { AppReducers } from 'app/core/store';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-training-plan-overview',
  templateUrl: './training-plan-overview.component.html',
  styleUrls: ['./training-plan-overview.component.scss']
})
export class TrainingPlanOverviewComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  @Input() planId: number;
  @Input() isCopy: boolean = false;
  plan$: Observable<ITrainingPlan>;

  subs: Subscription[] = [];

  constructor(private store: Store<AppState>,
    private modalCtrl: ModalController,
    private formBuilder: UntypedFormBuilder) { }

  ngOnInit() {
    if (this.planId) {
      this.selectTrainingPlan();
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  selectTrainingPlan() {
    if (!this.isCopy) {
      this.store.dispatch(new TrainingActions.LoadPlanAction(this.planId));
      this.plan$ = this.store.select(AppReducers.getTrainingPlan, { id: this.planId });
    }
    else {
      this.store.dispatch(new TrainingActions.LoadPlanCopyAction(this.planId));
      this.plan$ = this.store.select(AppReducers.getTrainingPlanCopy, { id: this.planId });
    }
  }
}

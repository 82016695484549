import { Component, Input, OnInit } from '@angular/core';
import { IAthleteCoach } from '@models/user.model';
import { DEFAULT_AVATAR_URL } from 'app/core/consts/default-avatar.const';

@Component({
  selector: 'app-coach-block',
  templateUrl: './coach-block.component.html',
  styleUrls: ['./coach-block.component.scss'],
})
export class CoachBlockComponent implements OnInit {
  @Input() coach: IAthleteCoach;
  @Input() inTraining: boolean = true;
  
  defaultImage: string = DEFAULT_AVATAR_URL;

  constructor() { }

  ngOnInit() { }

}

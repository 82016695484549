import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserRole } from '@models/authentication.model';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { AppState } from 'app/core/store/app.states';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-more-button',
  templateUrl: './more-button.component.html',
  styleUrls: ['./more-button.component.scss'],
})
export class MoreButtonComponent implements OnInit {

  role$: Observable<UserRole>;
  UserRole = UserRole;
  
  constructor(private store: Store<AppState>, private router: Router) { }

  ngOnInit() {
    this.role$ = this.store.select(AppReducers.getUserRole);
  }

  gotoMorePage(userRole: UserRole) {
    if (userRole === UserRole.athlete) {
      this.router.navigate(['/athlete/more']); 
    } else {
      this.router.navigate(['/coach/more']); 
    }

  }
}

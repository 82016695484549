import { IAssignedMesocycle, IMesocycle } from '@models/mesocycle.model';
import { IPagingResult } from '@models/paging.model';
import { Action } from '@ngrx/store';

export const LOAD_MESOCYCLES = '[Mesocycle] Load Mesocycles';
export const LOAD_MESOCYCLES_SUCCESS = '[Mesocycle] Load Mesocycles Success';
export const LOAD_MESOCYCLES_FAIL = '[Mesocycle] Load Mesocycles Fail';
export const LOAD_MESOCYCLES_PAGE = '[Mesocycle] Load Mesocycles Page';
export const LOAD_MESOCYCLES_PAGE_SUCCESS = '[Mesocycle] Load Mesocycles Page Success';
export const LOAD_MESOCYCLES_PAGE_FAIL = '[Mesocycle] Load Mesocycles Page Fail';

export const LOAD_MESOCYCLE = '[Mesocycle] Load Mesocycle';
export const LOAD_MESOCYCLE_SUCCESS = '[Mesocycle] Load Mesocycle Success';
export const LOAD_MESOCYCLE_FAIL = '[Mesocycle] Load Mesocycle Fail';
export const ADD_MESOCYCLE = '[Mesocycle] Add Mesocycle';
export const ADD_MESOCYCLE_SUCCESS = '[Mesocycle] Add Mesocycle Success';
export const ADD_MESOCYCLE_FAIL = '[Mesocycle] Add Mesocycle Fail';
export const EDIT_MESOCYCLE = '[Mesocycle] Edit Mesocycle';
export const EDIT_MESOCYCLE_SUCCESS = '[Mesocycle] Edit Mesocycle Success';
export const EDIT_MESOCYCLE_FAIL = '[Mesocycle] Edit Mesocycle Fail';
export const REMOVE_MESOCYCLE = '[Mesocycle] Remove Mesocycle';
export const REMOVE_MESOCYCLE_SUCCESS = '[Mesocycle] Remove Mesocycle Success';
export const REMOVE_MESOCYCLE_FAIL = '[Mesocycle] Remove Mesocycle Fail';

export const LOAD_MESOCYCLE_COPY = '[Mesocycle] Load Mesocycle Copy';
export const LOAD_MESOCYCLE_COPY_SUCCESS = '[Mesocycle] Load Mesocycle Copy Success';
export const LOAD_MESOCYCLE_COPY_FAIL = '[Mesocycle] Load Mesocycle Copy Fail';
export const EDIT_MESOCYCLE_COPY = '[Mesocycle] Edit Mesocycle Copy';
export const EDIT_MESOCYCLE_COPY_SUCCESS = '[Mesocycle] Edit Mesocycle Copy Success';
export const EDIT_MESOCYCLE_COPY_FAIL = '[Mesocycle] Edit Mesocycle Copy Fail';

export const LOAD_ASSIGNED_MESOCYCLES = '[Mesocycle] Load Assigned Mesocycles';
export const LOAD_ASSIGNED_MESOCYCLES_SUCCESS = '[Mesocycle] Load Assigned Mesocycles Success';
export const LOAD_ASSIGNED_MESOCYCLES_FAIL = '[Mesocycle] Load Assigned Mesocycles Fail';
export const LOAD_ASSIGNED_MESOCYCLES_PAGE = '[Mesocycle] Load Assigned Mesocycles Page';
export const LOAD_ASSIGNED_MESOCYCLES_PAGE_SUCCESS = '[Mesocycle] Load Assigned Mesocycles Page Success';
export const LOAD_ASSIGNED_MESOCYCLES_PAGE_FAIL = '[Mesocycle] Load Assigned Mesocycles Page Fail';
export const ADD_ASSIGNED_MESOCYCLE = '[Mesocycle] Add Assigned Mesocycle';
export const ADD_ASSIGNED_MESOCYCLE_SUCCESS = '[Mesocycle] Add Assigned Mesocycle Success';
export const ADD_ASSIGNED_MESOCYCLE_FAIL = '[Mesocycle] Add Assigned Mesocycle Fail';
export const REMOVE_ASSIGNED_MESOCYCLE = '[Mesocycle] Remove Assigned Mesocycle';
export const REMOVE_ASSIGNED_MESOCYCLE_SUCCESS = '[Mesocycle] Remove Assigned Mesocycle Success';
export const REMOVE_ASSIGNED_MESOCYCLE_FAIL = '[Mesocycle] Remove Assigned Mesocycle Fail';
export const ACTIVATE_ASSIGNED_MESOCYCLE = '[Mesocycle] Activate Assigned Mesocycle';
export const ACTIVATE_ASSIGNED_MESOCYCLE_SUCCESS = '[Mesocycle] Activate Assigned Mesocycle Success';
export const ACTIVATE_ASSIGNED_MESOCYCLE_FAIL = '[Mesocycle] Activate Assigned Mesocycle Fail';
export const DEACTIVATE_ASSIGNED_MESOCYCLE = '[Mesocycle] Deactivate Assigned Mesocycle';
export const DEACTIVATE_ASSIGNED_MESOCYCLE_SUCCESS = '[Mesocycle] Deactivate Assigned Mesocycle Success';
export const DEACTIVATE_ASSIGNED_MESOCYCLE_FAIL = '[Mesocycle] Deactivate Assigned Mesocycle Fail';

export const SET_INVISIBLE_ASSIGNED_MESOCYCLE = '[Mesocycle] Set invisible Assigned Mesocycle';
export const SET_INVISIBLE_ASSIGNED_MESOCYCLE_SUCCESS = '[Mesocycle] Set invisible Assigned Mesocycle Success';
export const SET_INVISIBLE_ASSIGNED_MESOCYCLE_FAIL = '[Mesocycle] Set invisible Assigned Mesocycle Fail';

export const COPY_TO_BASE_MESOCYCLE = '[Mesocycle] Copy to base Mesocycle';
export const COPY_TO_BASE_MESOCYCLE_SUCCESS = '[Mesocycle] Copy to base Mesocycle Success';
export const COPY_TO_BASE_MESOCYCLE_FAIL = '[Mesocycle] Copy to base Mesocycle Fail';

/**
 * Load Mesocycles Actions
 */
export class LoadMesocyclesAction implements Action {
    readonly type = LOAD_MESOCYCLES;
}
export class LoadMesocyclesSuccessAction implements Action {
    readonly type = LOAD_MESOCYCLES_SUCCESS;
    constructor(public payload: IPagingResult<IMesocycle>) { }
}
export class LoadMesocyclesFailAction implements Action {
    readonly type = LOAD_MESOCYCLES_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Mesocycles Page Actions
 */
export class LoadMesocyclesPageAction implements Action {
    readonly type = LOAD_MESOCYCLES_PAGE;
    constructor(public page: number = null) { }
}
export class LoadMesocyclesPageSuccessAction implements Action {
    readonly type = LOAD_MESOCYCLES_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<IMesocycle>) { }
}
export class LoadMesocyclesPageFailAction implements Action {
    readonly type = LOAD_MESOCYCLES_PAGE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load Mesocycle Actions
 */
export class LoadMesocycleAction implements Action {
    readonly type = LOAD_MESOCYCLE;
    constructor(public id: number) { }
}
export class LoadMesocycleSuccessAction implements Action {
    readonly type = LOAD_MESOCYCLE_SUCCESS;
    constructor(public payload: IMesocycle) { }
}
export class LoadMesocycleFailAction implements Action {
    readonly type = LOAD_MESOCYCLE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Add Mesocycle Actions
 */
export class AddMesocycleAction implements Action {
    readonly type = ADD_MESOCYCLE;
    constructor(public payload: IMesocycle) { }
}
export class AddMesocycleSuccessAction implements Action {
    readonly type = ADD_MESOCYCLE_SUCCESS;
    constructor(public payload: IMesocycle) { }
}
export class AddMesocycleFailAction implements Action {
    readonly type = ADD_MESOCYCLE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Edit Mesocycle Actions
 */
export class EditMesocycleAction implements Action {
    readonly type = EDIT_MESOCYCLE;
    constructor(public payload: IMesocycle) { }
}
export class EditMesocycleSuccessAction implements Action {
    readonly type = EDIT_MESOCYCLE_SUCCESS;
    constructor(public payload: IMesocycle) { }
}
export class EditMesocycleFailAction implements Action {
    readonly type = EDIT_MESOCYCLE_FAIL;
    constructor(public payload: any) { }
}


/**
 * Remove Mesocycle Actions
 */
export class RemoveMesocycleAction implements Action {
    readonly type = REMOVE_MESOCYCLE;
    constructor(public payload: IMesocycle) { }
}
export class RemoveMesocycleSuccessAction implements Action {
    readonly type = REMOVE_MESOCYCLE_SUCCESS;
    constructor(public payload: IMesocycle) { }
}
export class RemoveMesocycleFailAction implements Action {
    readonly type = REMOVE_MESOCYCLE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Load Mesocycle Copy Actions
 */
export class LoadMesocycleCopyAction implements Action {
    readonly type = LOAD_MESOCYCLE_COPY;
    constructor(public id: number) { }
}
export class LoadMesocycleCopySuccessAction implements Action {
    readonly type = LOAD_MESOCYCLE_COPY_SUCCESS;
    constructor(public payload: IMesocycle) { }
}
export class LoadMesocycleCopyFailAction implements Action {
    readonly type = LOAD_MESOCYCLE_COPY_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Mesocycle Copy Actions
 */
export class EditMesocycleCopyAction implements Action {
    readonly type = EDIT_MESOCYCLE_COPY;
    constructor(public payload: IMesocycle) { }
}
export class EditMesocycleCopySuccessAction implements Action {
    readonly type = EDIT_MESOCYCLE_COPY_SUCCESS;
    constructor(public payload: IMesocycle) { }
}
export class EditMesocycleCopyFailAction implements Action {
    readonly type = EDIT_MESOCYCLE_COPY_FAIL;
    constructor(public payload: any) { }
}



/**
 * Load Assigned Mesocycles Actions
 */
export class LoadAssignedMesocyclesAction implements Action {
    readonly type = LOAD_ASSIGNED_MESOCYCLES;
    constructor(public athleteId: number, public withoutLoading: boolean = false, public force: boolean = false) { }
}
export class LoadAssignedMesocyclesSuccessAction implements Action {
    readonly type = LOAD_ASSIGNED_MESOCYCLES_SUCCESS;
    constructor(public payload: IPagingResult<IAssignedMesocycle>, public athleteId: number) { }
}
export class LoadAssignedMesocyclesFailAction implements Action {
    readonly type = LOAD_ASSIGNED_MESOCYCLES_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Assigned Mesocycles Page Actions
 */
export class LoadAssignedMesocyclesPageAction implements Action {
    readonly type = LOAD_ASSIGNED_MESOCYCLES_PAGE;
    constructor(public athleteId: number, public page: number = null) { }
}
export class LoadAssignedMesocyclesPageSuccessAction implements Action {
    readonly type = LOAD_ASSIGNED_MESOCYCLES_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<IAssignedMesocycle>, public athleteId: number) { }
}
export class LoadAssignedMesocyclesPageFailAction implements Action {
    readonly type = LOAD_ASSIGNED_MESOCYCLES_PAGE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Add Assigned Mesocycle Actions
 */
export class AddAssignedMesocycleAction implements Action {
    readonly type = ADD_ASSIGNED_MESOCYCLE;
    constructor(public payload: IAssignedMesocycle) { }
}
export class AddAssignedMesocycleSuccessAction implements Action {
    readonly type = ADD_ASSIGNED_MESOCYCLE_SUCCESS;
    constructor(public payload: IAssignedMesocycle) { }
}
export class AddAssignedMesocycleFailAction implements Action {
    readonly type = ADD_ASSIGNED_MESOCYCLE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Remove Assigned Mesocycle Actions
 */
export class RemoveAssignedMesocycleAction implements Action {
    readonly type = REMOVE_ASSIGNED_MESOCYCLE;
    constructor(public payload: IAssignedMesocycle) { }
}
export class RemoveAssignedMesocycleSuccessAction implements Action {
    readonly type = REMOVE_ASSIGNED_MESOCYCLE_SUCCESS;
    constructor(public payload: IAssignedMesocycle) { }
}
export class RemoveAssignedMesocycleFailAction implements Action {
    readonly type = REMOVE_ASSIGNED_MESOCYCLE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Activate Assigned Mesocycle Actions
 */
export class ActivateAssignedMesocycleAction implements Action {
    readonly type = ACTIVATE_ASSIGNED_MESOCYCLE;
    constructor(public id: number, public athleteId: number|boolean = false) { }
}
export class ActivateAssignedMesocycleSuccessAction implements Action {
    readonly type = ACTIVATE_ASSIGNED_MESOCYCLE_SUCCESS;
    constructor(public payload: IAssignedMesocycle) { }
}
export class ActivateAssignedMesocycleFailAction implements Action {
    readonly type = ACTIVATE_ASSIGNED_MESOCYCLE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Deactivate Assigned Mesocycle Actions
 */
export class DeactivateAssignedMesocycleAction implements Action {
    readonly type = DEACTIVATE_ASSIGNED_MESOCYCLE;
    constructor(public id: number) { }
}
export class DeactivateAssignedMesocycleSuccessAction implements Action {
    readonly type = DEACTIVATE_ASSIGNED_MESOCYCLE_SUCCESS;
    constructor(public payload: IAssignedMesocycle) { }
}
export class DeactivateAssignedMesocycleFailAction implements Action {
    readonly type = DEACTIVATE_ASSIGNED_MESOCYCLE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Set invisible Assigned Mesocycle Actions
 */
export class SetInvisibleAssignedMesocycleAction implements Action {
    readonly type = SET_INVISIBLE_ASSIGNED_MESOCYCLE;
    constructor(public id: number, public is_invisible: boolean, public date: moment.Moment) { }
}
export class SetInvisibleAssignedMesocycleSuccessAction implements Action {
    readonly type = SET_INVISIBLE_ASSIGNED_MESOCYCLE_SUCCESS;
    constructor(public payload: IAssignedMesocycle) { }
}
export class SetInvisibleAssignedMesocycleFailAction implements Action {
    readonly type = SET_INVISIBLE_ASSIGNED_MESOCYCLE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Copy to base Mesocycle Actions
 */
export class CopyToBaseMesocycleAction implements Action {
    readonly type = COPY_TO_BASE_MESOCYCLE;
    constructor(public mesocycleId: number, public name: string) { }
}
export class CopyToBaseMesocycleSuccessAction implements Action {
    readonly type = COPY_TO_BASE_MESOCYCLE_SUCCESS;
    constructor(public payload: IMesocycle) { }
}
export class CopyToBaseMesocycleFailAction implements Action {
    readonly type = COPY_TO_BASE_MESOCYCLE_FAIL;
    constructor(public payload: any) { }
}
import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-tplans-infinity-scroll',
  templateUrl: './tplans-infinity-scroll.component.html',
  styleUrls: ['./tplans-infinity-scroll.component.scss'],
})
export class TPlansInfinityScrollComponent {
  selectPaging = AppReducers.getTrainingPlanPaging;
  loadPageSuccessAction: string = TrainingActions.LOAD_PLANS_PAGE_SUCCESS;
  loadPageFailAction: string = TrainingActions.LOAD_PLANS_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  loadPageAction() {
    this.store.dispatch(new TrainingActions.LoadPlansPageAction());
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.load-more-text {
  padding-left: calc(30px + var(--ion-safe-area-left, 0px));
}
.load-more-text span {
  font-family: var(--ons-font-bold);
  cursor: pointer;
  color: var(--ons-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/infinity-button/infinity-button.component.scss"],"names":[],"mappings":"AAAA;EACI,yDAAA;AACJ;AACI;EACI,iCAAA;EACA,eAAA;EACA,+BAAA;AACR","sourcesContent":[".load-more-text {\n    padding-left: calc(30px + var(--ion-safe-area-left, 0px));\n\n    span {\n        font-family: var(--ons-font-bold);\n        cursor: pointer;\n        color: var(--ons-color-primary);\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

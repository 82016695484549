import { INote, ISideNote } from '@models/note.model';
import { INotificationPagingResult, NotificationType } from '@models/notificaton.model';
import { INITIAL_PAGING, IPaging, IPagingResult } from '@models/paging.model';
import { AuthActions, NoteActions, NotificationActions } from "../actions";

export interface State {
    notes: NoteState;
    sideNotes: SideNoteState;
}
export interface NoteState {
    loaded: boolean;
    loading: boolean;
    items: INote[];
    paging: IPaging;
    hasNewData: boolean;
}
export interface SideNoteState {
    loaded: boolean;
    loading: boolean;
    items: ISideNote[];
}

const initialSubState = {
    loaded: false,
    loading: false,
    items: []
}
const initialState: State = {
    notes: {
        ...initialSubState,
        paging: INITIAL_PAGING,
        hasNewData: false
    },
    sideNotes: initialSubState
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
        case NoteActions.SET_INITIAL: {
            return initialState;
        }

        case NoteActions.LOAD_NOTES:
        case NoteActions.LOAD_NOTES_PAGE: {
            return Object.assign({}, state, {
                notes: {
                    ...state.notes,
                    loading: true
                }
            });
        }
        case NoteActions.LOAD_NOTES_FAIL:
        case NoteActions.LOAD_NOTES_PAGE_FAIL: {
            return Object.assign({}, state, {
                notes: {
                    ...state.notes,
                    loading: false
                }
            });
        }
        case NoteActions.LOAD_NOTES_SUCCESS: {
            let resp: IPagingResult<INote> = action.payload;

            return Object.assign({}, state, {
                notes: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: resp.items,
                    hasNewData: false,
                    paging: resp.paging
                }
            });
        }
        case NoteActions.LOAD_NOTES_PAGE_SUCCESS: {
            let resp: IPagingResult<INote> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.notes.items, ...resp.items];
            newItems = newItems.filter(x => !x.frontend_note);

            return Object.assign({}, state, {
                notes: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    hasNewData: state.notes.hasNewData,
                    paging: resp.paging
                }
            });
        }
        case NoteActions.LOAD_NOTE_SUCCESS:
        case NoteActions.ADD_NOTE_SUCCESS: {
            let note = action.payload;
            note = { ...note };

            if (state.notes.items.findIndex(x => x.id == note.id) > -1) {
                return state;
            }

            let items = [...state.notes.items];
            note.frontend_note = true;
            items = [note, ...items];

            return Object.assign({}, state, {
                notes: {
                    ...state.notes,
                    items: items
                }
            });
        }
        case NoteActions.EDIT_NOTE_SUCCESS: {
            const note = action.payload;

            if (!state.notes.items.some(x => x.id == note.id)) {
                return state;
            }

            return Object.assign({}, state, {
                notes: {
                    ...state.notes,
                    items: state.notes.items.map(obj => obj.id == note.id ? note : obj)
                }
            });
        }
        case NoteActions.REMOVE_NOTE_SUCCESS: {
            const removedNote = action.payload;

            return Object.assign({}, state, {
                notes: {
                    ...state.notes,
                    items: state.notes.items.filter(note => note.id !== removedNote.id)
                }
            });
        }


        case NoteActions.LOAD_SIDE_NOTES: {
            return Object.assign({}, state, {
                sideNotes: {
                    ...state.sideNotes,
                    loading: true
                }
            });
        }
        case NoteActions.LOAD_SIDE_NOTES_SUCCESS: {
            const notes = action.payload;

            return Object.assign({}, state, {
                sideNotes: {
                    loaded: true,
                    loading: false,
                    items: notes
                }
            });
        }
        case NoteActions.ADD_SIDE_NOTE_SUCCESS: {
            const note = action.payload;

            if (state.notes.items.findIndex(x => x.id == note.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                sideNotes: {
                    ...state.sideNotes,
                    items: [note, ...state.sideNotes.items]
                }
            });
        }
        case NoteActions.EDIT_SIDE_NOTE_SUCCESS: {
            const note = action.payload;

            if (!state.sideNotes.items.some(x => x.id == note.id)) {
                return state;
            }

            return Object.assign({}, state, {
                sideNotes: {
                    ...state.sideNotes,
                    items: state.sideNotes.items.map(obj => obj.id == note.id ? note : obj)
                }
            });
        }
        case NoteActions.REMOVE_SIDE_NOTE_SUCCESS: {
            const removedNote = action.payload;

            return Object.assign({}, state, {
                sideNotes: {
                    ...state.sideNotes,
                    items: state.sideNotes.items.filter(note => note.id !== removedNote.id)
                }
            });
        }

        case NotificationActions.LOAD_NOTIFICATIONS_SUCCESS:
        case NotificationActions.LOAD_NOTIFICATIONS_PAGE_SUCCESS: {
            let resp: INotificationPagingResult = action.payload;

            let notesUpdate = resp.items.some(x => x.type == NotificationType.note && !x.is_read);

            return Object.assign({}, state, {
                notes: {
                    ...state.notes,
                    hasNewData: notesUpdate && (!!state.notes.items || state.notes.items.length > 0)
                }
            });
        }

        case AuthActions.LOGIN_SUCCESS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}


export const getNotesLoaded = (state: State) => state.notes.loaded;
export const getNotesLoading = (state: State) => state.notes.loading;
export const getNotes = (state: State) => state.notes.items;
export const getNotesPaging = (state: State) => state.notes.paging;
export const getNotesHasNewData = (state: State) => state.notes.hasNewData;
export const getNote = (state: State, props) => state.notes.items.find(x => x.id == props.id);

export const getSideNotesLoaded = (state: State) => state.sideNotes.loaded;
export const getSideNotesLoading = (state: State) => state.sideNotes.loading;
export const getSideNotes = (state: State) => state.sideNotes.items;
export const getSideNote = (state: State, props) => state.sideNotes.items.find(x => x.id == props.id);
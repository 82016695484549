
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ITechnique, ITrainingCategory } from '@models/training.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '@services/ui.service';
import { AppReducers } from 'app/core/store';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';
import { AddTechniqModalComponent } from './add-techniq-modal/add-techniq-modal.component';
import { EditTechniqModalComponent } from './edit-techniq-modal/edit-techniq-modal.component';


@Component({
  selector: 'app-techniques-modal',
  templateUrl: './techniques-modal.component.html',
  styleUrls: ['./techniques-modal.component.scss'],
})
export class TechniquesModalComponent implements OnInit, OnDestroy {
  @Input() techniques: Array<ITechnique>;
  @Input() activeTechniques: Array<ITechnique>;
  techSub: Subscription;
  checkTechLoadedSub: Subscription;
  deletingSub: Subscription;

  constructor(private modalController: ModalController,
    private store: Store<AppState>,
    private uiService: UIService,
    private actions$: Actions,
    private translate: TranslateService) { }

  ngOnInit() {
    this.checkTechLoadedSub = this.store.select(AppReducers.getTrainingTechniquesLoaded)
      .subscribe(hasLoaded => {
        if (!hasLoaded) this.store.dispatch(new TrainingActions.LoadTechniquesAction());
      });

    this.techSub = this.store.select(AppReducers.getTrainingTechniquesCollection)
      .subscribe(data => {
        this.techniques = data;
      });
    this.deletingSub = this.actions$.pipe(ofType(TrainingActions.REMOVE_TECHNIQUE_SUCCESS))
      .subscribe((data: any) => {
        this.activeTechniques = this.activeTechniques.filter(x => x.id != data.payload.id);
      })
  }

  checkIfTechniqueActive(technique) {
    return this.activeTechniques.some(x => x.id == technique.id);
  }

  onSelect($event) {
    let checked = $event.detail.checked;
    let value = $event.detail.value;

    if (checked) {
      this.activeTechniques = [];
      this.activeTechniques.push(value);
    }
    else {
      this.activeTechniques = this.activeTechniques.filter(x => x.id != value.id);
    }
  }

  onClickAdd() {
    this.modalController.create({
      component: AddTechniqModalComponent,
      swipeToClose: true
    })
      .then(modal => {
        modal.present();
      })
  }
  onClickEdit(techniq) {
    this.modalController.create({
      component: EditTechniqModalComponent,
      componentProps: {
        techniq: { ...techniq }
      },
      swipeToClose: true
    })
      .then(modal => {
        modal.present();
      })
  }

  onClickDelete(technique) {
    let name = technique.name;
    this.uiService.confirmModal(this.translate.instant("base.modal.confirm_title"), this.translate.instant("base.modal.delete_text", { name })).then(res => {
      if (res) {
        this.store.dispatch(new TrainingActions.RemoveTechniqueAction(technique))
      }
    })
  }

  async onSave() {
    await this.modalController.dismiss(this.activeTechniques);
  }

  async onClickCloseModal() {
    await this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.checkTechLoadedSub.unsubscribe();
    this.techSub.unsubscribe();
    this.deletingSub.unsubscribe();
  }
}

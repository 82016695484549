// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content.athlete-info-view {
  --background: var(--ons-color-primary-background);
}
ion-content.athlete-info-view main.inner-scroll {
  padding: 10px !important;
}
ion-content.athlete-info-view ion-range {
  padding: 0;
  --bar-background-active: var(--ons-color-primary);
}
ion-content.athlete-info-view .lblTitle {
  margin-top: 20px;
  font-family: var(--ons-font-bold) !important;
}
ion-content.athlete-info-view .lblInfo {
  margin-left: 45%;
}
ion-content.athlete-info-view ion-datetime {
  padding-top: 20px;
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(201, 201, 201);
}

ion-button {
  margin-top: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/pages/athlete-info/athlete-info.page.scss"],"names":[],"mappings":"AAAA;EACE,iDAAA;AACF;AAAE;EACE,wBAAA;AAEJ;AACE;EACE,UAAA;EACA,iDAAA;AACJ;AAEE;EACE,gBAAA;EACA,4CAAA;AAAJ;AAGE;EACE,gBAAA;AADJ;AAIE;EACE,iBAAA;EACA,oBAAA;EACA,2CAAA;AAFJ;;AAMA;EACE,gBAAA;AAHF","sourcesContent":["ion-content.athlete-info-view {\n  --background: var(--ons-color-primary-background);\n  main.inner-scroll {\n    padding: 10px !important;\n  }\n\n  ion-range {\n    padding: 0;\n    --bar-background-active: var(--ons-color-primary);\n  }\n\n  .lblTitle {\n    margin-top: 20px;\n    font-family: var(--ons-font-bold) !important;\n  }\n\n  .lblInfo {\n    margin-left: 45%;\n  }\n\n  ion-datetime {\n    padding-top: 20px;\n    padding-bottom: 10px;\n    border-bottom: 2px solid rgb(201, 201, 201);\n  }\n}\n\nion-button {\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

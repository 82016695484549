import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { UserRole } from '@models/authentication.model';
import { IPagingResult } from '@models/paging.model';
import { ITrainingCategory } from '@models/training.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../helpers/http.helper';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: "root",
})
export class TrainingCategoryService {
    apiPath: string = 'categories';

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    getAll(page: number = 1, withoutLoading = false): Observable<IPagingResult<ITrainingCategory>> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let authQuery = userRole == UserRole.athlete ? 'athlete_id' : 'coach_id';
        authQuery += `=${userId}`;
        let pageQuery = `&page=${page}`;

        let http = withoutLoading ? this.http.disableLoading() : this.http;
        return http.get(`/${this.apiPath}?${authQuery}${pageQuery}`, { observe: 'response' }).pipe(
            map((response: any) => {                
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    add(model: ITrainingCategory) {
        let category = { ...model };
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        if (userRole == UserRole.athlete) {
            category.athlete_id = userId;
        }
        else {
            category.coach_id = userId;
        }

        return this.http.post(`/${this.apiPath}`, category);
    }

    delete(id: number) {
        return this.http.delete(`/${this.apiPath}/${id}`)
    }
}
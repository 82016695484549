import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, withLatestFrom } from "rxjs/operators";
import { NoteActions } from "../actions";
import { AppState } from '../app.states';
import { Store } from '@ngrx/store';
import { NoteService } from "@services/note.service";
import { SideNoteService } from "@services/side-note.service";
import { IPagingResult } from "@models/paging.model";
import { INote, INoteFilter } from "@models/note.model";

@Injectable()
export class NoteEffects {
    constructor(
        private noteService: NoteService,
        private sideNoteService: SideNoteService,
        private actions$: Actions,
        private store$: Store<AppState>
    ) { }

    loadNotes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.LOAD_NOTES),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [NoteActions.LoadNotesAction, AppState]) => {
                let isNewFilter = storeState?.note?.notes.paging?.filter != JSON.stringify(action.payload);
                return action.force || isNewFilter;
            }),
            exhaustMap(([action, storeState]: [NoteActions.LoadNotesAction, AppState]) => {
                return this.noteService
                    .getAll(action.payload)
                    .pipe(
                        map((pagingResult: IPagingResult<INote>) => new NoteActions.LoadNotesSuccessAction(pagingResult)),
                        catchError((error) => of(new NoteActions.LoadNotesFailAction(error)))
                    )
            })
        )
    );
    loadNotesPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.LOAD_NOTES_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [NoteActions.LoadNotesPageAction, AppState]) => {
                return storeState?.note?.notes.paging?.currentPage != 0 && (!action.page || storeState?.note?.notes.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [NoteActions.LoadNotesPageAction, AppState]) => {
                let page = !action.page ? storeState.note.notes.paging.currentPage + 1 : action.page;
                let filter: INoteFilter = JSON.parse(storeState.note.notes.paging.filter)
                return this.noteService
                    .getAll(filter, page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<INote>) => new NoteActions.LoadNotesPageSuccessAction(pagingResult)),
                        catchError((error) => of(new NoteActions.LoadNotesPageFailAction(error)))
                    )
            })
        )
    );
    loadNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.LOAD_NOTE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [NoteActions.LoadNoteAction, AppState]) => {
                return !storeState.note.notes.items.some(x => x.id == action.id);
            }),
            exhaustMap(([action, storeState]: [NoteActions.LoadNoteAction, AppState]) => {
                return this.noteService
                    .get(action.id)
                    .pipe(
                        map((result: INote) => new NoteActions.LoadNoteSuccessAction(result)),
                        catchError((error) => of(new NoteActions.LoadNoteFailAction(error)))
                    )
            })
        )
    );
    addNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.ADD_NOTE),
            exhaustMap((action: NoteActions.AddNoteAction) =>
                this.noteService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new NoteActions.AddNoteSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new NoteActions.AddNoteFailAction(error)))
                    )
            )
        )
    );
    editNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.EDIT_NOTE),
            exhaustMap((action: NoteActions.EditNoteAction) =>
                this.noteService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new NoteActions.EditNoteSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new NoteActions.EditNoteFailAction(error)))
                    )
            )
        )
    );
    removeNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.REMOVE_NOTE),
            exhaustMap((action: NoteActions.RemoveNoteAction) =>
                this.noteService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new NoteActions.RemoveNoteSuccessAction(action.payload);
                        }),
                        catchError((error) => of(new NoteActions.RemoveNoteFailAction(error)))
                    )
            )
        )
    );
    loadNotesAfterRemoving$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.REMOVE_NOTE_SUCCESS),
            withLatestFrom(this.store$),
            exhaustMap(([action, storeState]: [NoteActions.RemoveNoteSuccessAction, AppState]) => {
                let filter: INoteFilter = JSON.parse(storeState.note.notes.paging.filter);
                return this.noteService
                    .getAll(filter)
                    .pipe(
                        map((pagingResult: IPagingResult<INote>) => new NoteActions.LoadNotesSuccessAction(pagingResult)),
                        catchError((error) => of(new NoteActions.LoadNotesFailAction(error)))
                    )
            })
        )
    );

    loadSideNotes$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.LOAD_SIDE_NOTES),
            withLatestFrom(this.store$),
            filter(([action, storeState]) => {
                return !storeState?.note?.sideNotes.loaded;
            }),
            exhaustMap(([action, storeState]: [NoteActions.LoadSideNotesAction, AppState]) =>
                this.sideNoteService
                    .getAll()
                    .pipe(
                        map((res: any) => new NoteActions.LoadSideNotesSuccessAction(res)),
                        catchError((error) => of(new NoteActions.LoadSideNotesFailAction(error)))
                    )
            )
        )
    );
    addSideNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.ADD_SIDE_NOTE),
            exhaustMap((action: NoteActions.AddSideNoteAction) =>
                this.sideNoteService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new NoteActions.AddSideNoteSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new NoteActions.AddSideNoteFailAction(error)))
                    )
            )
        )
    );
    editSideNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.EDIT_SIDE_NOTE),
            exhaustMap((action: NoteActions.EditSideNoteAction) =>
                this.sideNoteService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new NoteActions.EditSideNoteSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new NoteActions.EditSideNoteFailAction(error)))
                    )
            )
        )
    );
    removeSideNote$ = createEffect(() =>
        this.actions$.pipe(
            ofType(NoteActions.REMOVE_SIDE_NOTE),
            exhaustMap((action: NoteActions.RemoveSideNoteAction) =>
                this.sideNoteService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new NoteActions.RemoveSideNoteSuccessAction(action.payload);
                        }),
                        catchError((error) => of(new NoteActions.RemoveSideNoteFailAction(error)))
                    )
            )
        )
    );
}

import { IAthleteTracking } from '@models/athlete-tracking.model';
import { IGetUsersResponse } from '@models/user.model';
import { AthleteActions, AuthActions, InvitationActions, MesocycleActions } from '../actions';
import * as moment from 'moment';
import { IAthleteTraining, ITrainingNote } from '@models/athlete-training.model';
import { IInvitation } from '@models/invitation.model';
import { INITIAL_PAGING, IPaging, IPagingResult } from '@models/paging.model';
import { IAthlete } from '@models/athlete.model';
import { ITrackingMesocycleOverview } from '@models/tracking-overview.model';
import { ITrainingMesocycleOverview } from '@models/training-overview.model';

export interface State {
    athletes: AthleteState;
    trackings: any;
    trainings: any;
    overview: OverviewState;
    trainingOverview: TrainingOverviewState;
}
export interface AthleteState {
    loaded: boolean;
    loading: boolean;
    items: IAthlete[];
    invitations: IInvitation[];
    paging: IPaging;
}
export interface OverviewState {
    loaded: boolean;
    loading: boolean;
    items: ITrackingMesocycleOverview[];
    paging: IPaging;
}

export interface TrainingOverviewState {
    loaded: boolean;
    loading: boolean;
    items: ITrainingMesocycleOverview[];
    paging: IPaging;
}

const initialSubState = {
    loaded: false,
    loading: false,
    items: [],
    invitations: [],
    paging: INITIAL_PAGING
}
const initialOverviewState = {
    loaded: false,
    loading: false,
    items: [],
    paging: INITIAL_PAGING
}
const initialTrainingOverviewState = {
    loaded: false,
    loading: false,
    items: [],
    paging: INITIAL_PAGING
}
const initialState: State = {
    athletes: initialSubState,
    trackings: {},
    trainings: {},
    overview: initialOverviewState,
    trainingOverview : initialTrainingOverviewState
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
        case AthleteActions.SET_INITIAL: {
            return {
                ...initialState,
                athletes: state.athletes
            };
        }

        case AthleteActions.LOAD_ATHLETES:
        case AthleteActions.LOAD_ATHLETES_PAGE: {
            return Object.assign({}, state, {
                athletes: {
                    ...state.athletes,
                    loading: true
                }
            });
        }
        case AthleteActions.LOAD_ATHLETES_FAIL:
        case AthleteActions.LOAD_ATHLETES_PAGE_FAIL: {
            return Object.assign({}, state, {
                athletes: {
                    ...state.athletes,
                    loading: false
                }
            });
        }
        case AthleteActions.LOAD_ATHLETES_SUCCESS: {
            let payload: IGetUsersResponse = action.payload;
            let paging: IPagingResult<any> = action.paging;

            const athletes = payload.users;
            const invitations = payload.invitations;

            return Object.assign({}, state, {
                athletes: {
                    loaded: paging.paging.currentPage == paging.paging.lastPage,
                    loading: false,
                    items: athletes,
                    invitations: invitations,
                    paging: paging.paging
                }
            });
        }
        case AthleteActions.LOAD_ATHLETES_PAGE_SUCCESS: {
            let payload: IGetUsersResponse = action.payload;
            let paging: IPagingResult<any> = action.paging;

            const athletes = payload.users;
            const invitations = payload.invitations;

            let newAthletes = paging.paging.currentPage == 1 ? athletes : [...state.athletes.items, ...athletes];

            return Object.assign({}, state, {
                athletes: {
                    loaded: paging.paging.currentPage == paging.paging.lastPage,
                    loading: false,
                    items: newAthletes,
                    invitations: invitations,
                    paging: paging.paging
                }
            });
        }



        case AthleteActions.UPDATE_ASSIGNED_ATHLETE_SUCCESS: {
            if (action.model.manage_athlete_status) {
                return {
                    ...state,
                    athletes: initialSubState
                }
            }

            let items = [...state.athletes.items];
            if (items.findIndex(x => x.id == action.athleteId) > -1) {
                items = items.map(x => x.id == action.athleteId ? { ...x, ...action.model } : x);
            }

            return Object.assign({}, state, {
                athletes: {
                    ...state.athletes,
                    items: [...items]
                }
            });
        }
        case AthleteActions.LOAD_ATHLETE_SUCCESS: {
            const athlete = action.payload;

            let items = [...state.athletes.items];
            if (items.findIndex(x => x.id == athlete.id) > -1) {
                items = items.map(x => x.id == athlete.id ? athlete : x);
            }
            else {
                items = [...items, athlete];
            }

            return Object.assign({}, state, {
                athletes: {
                    ...state.athletes,
                    items: [...items]
                }
            });
        }
        case AthleteActions.CANCEL_ASSIGNMENT_SUCCESS: {
            const athleteId = action.athleteId;

            if (!athleteId || state.athletes.items.findIndex(x => x.id == athleteId) < 0) {
                return state;
            }

            let items = [...state.athletes.items].filter(x => x.id != athleteId);

            return Object.assign({}, state, {
                athletes: {
                    ...state.athletes,
                    items: items
                }
            });
        }

        case InvitationActions.INVITE_ATHLETE_SUCCESS: {
            const invite = action.payload;

            if (state.athletes.items.findIndex(x => x.id == invite.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                athletes: {
                    ...state.athletes,
                    invitations: [invite, ...state.athletes.invitations]
                }
            });
        }
        case InvitationActions.CANCEL_INVITE_SUCCESS: {
            const inviteId = action.id;

            if (state.athletes.invitations.findIndex(x => x.id == inviteId) < 0) {
                return state;
            }

            let items = [...state.athletes.invitations].map(x => x.id != inviteId ? x : { ...x, has_cancelled: true });

            return Object.assign({}, state, {
                athletes: {
                    ...state.athletes,
                    invitations: items
                }
            });
        }



        case AthleteActions.LOAD_TRACKING_SUCCESS:
        case AthleteActions.ADD_TRACKING_VALUE_SUCCESS:
        case AthleteActions.EDIT_TRACKING_VALUE_SUCCESS: {
            const tracking: IAthleteTracking = action.payload;

            if (tracking) {
                let currentItems = Object.assign({}, state.trackings);
                let formattedDate = moment(action.payload?.day).format('YYYY-MM-DD');
                currentItems[formattedDate] = tracking;

                return Object.assign({}, state, {
                    trackings: currentItems
                });
            }

            return state;
        }

        case AthleteActions.LOAD_TRAINING_SUCCESS:
        case AthleteActions.ADD_TRAINING_SET_SUCCESS:
        case AthleteActions.EDIT_TRAINING_SET_SUCCESS: {
            const training: IAthleteTraining = action.payload;
            if (training) {
                let currentItems = Object.assign({}, state.trainings);
                let formattedDate = moment(action.payload?.day).format('YYYY-MM-DD');
                currentItems[formattedDate] = training;

                return Object.assign({}, state, {
                    trainings: currentItems
                });
            }

            return state;
        }

        case AthleteActions.ADD_TRAINING_NOTE_SUCCESS: {
            const note: ITrainingNote = action.payload;
            const updateStore: boolean = action.updateStore;

            if(!updateStore) {
                return state;
            }

            if (note) {
                let currentItems = Object.assign({}, state.trainings);
                Object.keys(currentItems).forEach(day => {
                    let training: IAthleteTraining = currentItems[day];
                    training = {
                        ...training,
                        notes: [...training.notes, note]
                    };
                    currentItems[day] = training;
                });

                return Object.assign({}, state, {
                    trainings: currentItems
                });
            }

            return state;
        }
        case AthleteActions.EDIT_TRAINING_NOTE_SUCCESS: {
            const note: ITrainingNote = action.payload;
            const updateStore: boolean = action.updateStore;
            
            if(!updateStore) {
                return state;
            }

            if (note) {
                let currentItems = Object.assign({}, state.trainings);
                Object.keys(currentItems).forEach(day => {
                    let training: IAthleteTraining = currentItems[day];
                    training = {
                        ...training,
                        notes: training.notes?.map(x => x.id == note.id ? note : x)
                    };
                    currentItems[day] = training;
                });

                return Object.assign({}, state, {
                    trainings: currentItems
                });
            }

            return state;
        }
        case AthleteActions.DELETE_TRAINING_NOTE_SUCCESS: {
            const noteId: number = action.id;

            if (noteId) {
                let currentItems = Object.assign({}, state.trainings);
                Object.keys(currentItems).forEach(day => {
                    let training: IAthleteTraining = currentItems[day];
                    training = {
                        ...training,
                        notes: training.notes?.filter(x => x.id != noteId)
                    };
                    currentItems[day] = training;
                });

                return Object.assign({}, state, {
                    trainings: currentItems
                });
            }

            return state;
        }

        case MesocycleActions.ACTIVATE_ASSIGNED_MESOCYCLE_SUCCESS: {
            return Object.assign({}, initialState,
                {
                    athletes: state.athletes
                })
        }

        case AthleteActions.MANAGE_ATHLETE_DATE_SUCCESS: {
            return {
                ...state,
                athletes: initialSubState
            }
        }



        case AthleteActions.LOAD_OVERVIEW:
        case AthleteActions.LOAD_OVERVIEW_PAGE: {
            return Object.assign({}, state, {
                overview: {
                    ...state.overview,
                    loading: true
                }
            });
        }
        case AthleteActions.LOAD_OVERVIEW_FAIL:
        case AthleteActions.LOAD_OVERVIEW_PAGE_FAIL: {
            return Object.assign({}, state, {
                overview: {
                    ...state.overview,
                    loading: false
                }
            });
        }
        case AthleteActions.LOAD_OVERVIEW_SUCCESS:
        case AthleteActions.LOAD_OVERVIEW_PAGE_SUCCESS: {
            let resp: IPagingResult<ITrackingMesocycleOverview> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.overview.items, ...resp.items];

            return Object.assign({}, state, {
                overview: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }

        case AthleteActions.LOAD_TRAINING_OVERVIEW:
        case AthleteActions.LOAD_TRAINING_OVERVIEW_PAGE: {
            return Object.assign({}, state, {
                trainingOverview: {
                    ...state.trainingOverview,
                    loading: true
                }
            });
        }
        case AthleteActions.LOAD_TRAINING_OVERVIEW_FAIL:
        case AthleteActions.LOAD_TRAINING_OVERVIEW_PAGE_FAIL: {
            return Object.assign({}, state, {
                trainingOverview: {
                    ...state.trainingOverview,
                    loading: false
                }
            });
        }
        case AthleteActions.LOAD_TRAINING_OVERVIEW_SUCCESS:
        case AthleteActions.LOAD_TRAINING_OVERVIEW_PAGE_SUCCESS: {
            let resp: IPagingResult<ITrainingMesocycleOverview> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.trainingOverview.items, ...resp.items];

            return Object.assign({}, state, {
                trainingOverview: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }

        case AuthActions.LOGIN_SUCCESS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}


export const getAthletesLoaded = (state: State) => state.athletes.loaded;
export const getAthletesLoading = (state: State) => state.athletes.loading;
export const getAthletes = (state: State) => state.athletes.items;
export const getAthletesPaging = (state: State) => state.athletes.paging;
export const getInvitations = (state: State) => state.athletes.invitations;
export const getAthlete = (state: State, props) => state.athletes.items.find(x => x.id == props.id);

export const getAthleteTrackings = (state: State) => state.trackings;
export const getAthleteTrackingsByDate = (state: State, props) => state.trackings[moment(props.date).format('YYYY-MM-DD')];

export const getAthleteTrainings = (state: State) => state.trainings;
export const getAthleteTrainingByDate = (state: State, props): IAthleteTraining => {
    let date = moment(props.date);
    date = moment(date.format('YYYY-MM-DD'));
    let training = Object.values(state.trainings).find((training: IAthleteTraining) => {
        if (training.week_range?.end && training.week_range.start) {
            if (date.isSameOrBefore(moment(training.week_range.end)) && date.isSameOrAfter(moment(training.week_range.start))) {
                return true;
            }
        }
    });
    return (training as IAthleteTraining);
};
export const getAthleteTrainingSheet = (state: State, props) => {
    let training = getAthleteTrainingByDate(state, props);
    if (training) {
        return training['sheet_' + props.sheetNo.toString().padStart(2, "0")]
    }
    return null;
}
export const getAthleteTrainingSheetTitle = (state: State, props) => {
    let training = getAthleteTrainingByDate(state, props);
    if (training) {
        return training['sheet_' + props.sheetNo.toString().padStart(2, "0") + '_title']
    }
    return null;
}


export const getOverviewLoaded = (state: State) => state.overview.loaded;
export const getOverviewLoading = (state: State) => state.overview.loading;
export const getOverview = (state: State) => state.overview.items;
export const getOverviewPaging = (state: State) => state.overview.paging;

export const getTrainingOverviewLoaded = (state: State) => state.trainingOverview.loaded;
export const getTrainingOverviewLoading = (state: State) => state.trainingOverview.loading;
export const getTrainingOverview = (state: State) => state.trainingOverview.items;
export const getTrainingOverviewPaging = (state: State) => state.trainingOverview.paging;
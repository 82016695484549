import { INITIAL_PAGING, IPaging, IPagingResult } from '@models/paging.model';
import { IBodyData, IMacro, ITag } from '@models/progress.model';
import { AuthActions, ProgressActions } from "../actions";

//#region State
export interface State {
    bodyDatas: BodyDataState;
    bodyDataCopies: BodyDataState;
    tags: TagState;
    macros: MacroState;
    macroCopies: MacroState;
}
export interface BodyDataState {
    loaded: boolean;
    loading: boolean;
    items: IBodyData[];
    paging: IPaging;
}
export interface TagState {
    loaded: boolean;
    loading: boolean;
    items: ITag[];
    paging: IPaging;
}
export interface MacroState {
    loaded: boolean;
    loading: boolean;
    items: IMacro[];
    paging: IPaging;
}

const initialSubState = {
    loaded: false,
    loading: false,
    items: [],
    paging: INITIAL_PAGING
}
const initialState: State = {
    bodyDatas: initialSubState,
    bodyDataCopies: initialSubState,
    tags: initialSubState,
    macros: initialSubState,
    macroCopies: initialSubState,
};
//#endregion

export function reducer(state = initialState, action): State {
    switch (action.type) {
        //#region Body Data
        case ProgressActions.LOAD_BODY_DATAS:
        case ProgressActions.LOAD_BODY_DATAS_PAGE: {
            return Object.assign({}, state, {
                bodyDatas: {
                    ...state.bodyDatas,
                    loading: true
                }
            });
        }
        case ProgressActions.LOAD_BODY_DATAS_FAIL:
        case ProgressActions.LOAD_BODY_DATAS_PAGE_FAIL: {
            return Object.assign({}, state, {
                bodyDatas: {
                    ...state.bodyDatas,
                    loading: false
                }
            });
        }
        case ProgressActions.LOAD_BODY_DATAS_SUCCESS:
        case ProgressActions.LOAD_BODY_DATAS_PAGE_SUCCESS: {
            let resp: IPagingResult<IBodyData> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.bodyDatas.items, ...resp.items];

            return Object.assign({}, state, {
                bodyDatas: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }
        case ProgressActions.LOAD_BODY_DATA_SUCCESS: {
            const bodyData = action.payload;

            if (state.bodyDatas.items.findIndex(x => x.id == bodyData.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                bodyDatas: {
                    ...state.bodyDatas,
                    items: [bodyData, ...state.bodyDatas.items]
                }
            });
        }
        case ProgressActions.ADD_BODY_DATA_SUCCESS: {
            const bodyData = action.payload;

            if (state.bodyDatas.items.findIndex(x => x.id == bodyData.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                bodyDatas: {
                    ...state.bodyDatas,
                    items: [bodyData, ...state.bodyDatas.items]
                }
            });
        }
        case ProgressActions.EDIT_BODY_DATA_SUCCESS: {
            const bodyData = action.payload;

            if (!state.bodyDatas.items.some(x => x.id == bodyData.id)) {
                return state;
            }

            return Object.assign({}, state, {
                bodyDatas: {
                    ...state.bodyDatas,
                    items: state.bodyDatas.items.map(obj => obj.id == bodyData.id ? bodyData : obj)
                },
                bodyDataCopies: {
                    ...state.bodyDataCopies,
                    items: state.bodyDataCopies.items.map(obj => obj.id == bodyData.id ? {
                        ...obj,
                        name: bodyData.name,
                        tags: bodyData.tags,
                        unit: bodyData.unit
                    } : obj)
                }
            });
        }
        case ProgressActions.REMOVE_BODY_DATA_SUCCESS: {
            const removedBodyData = action.payload;

            return Object.assign({}, state, {
                bodyDatas: {
                    ...state.bodyDatas,
                    items: state.bodyDatas.items.filter(bodyData => bodyData.id !== removedBodyData.id)
                }
            });
        }
        //#endregion
        //#region Body Data copy
        case ProgressActions.LOAD_BODY_DATA_COPY_SUCCESS: {
            const bodyDataCopy = action.payload;

            if (state.bodyDataCopies.items.findIndex(x => x?.body_data_detail?.id == bodyDataCopy?.body_data_detail?.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                bodyDataCopies: {
                    ...state.bodyDataCopies,
                    items: [bodyDataCopy, ...state.bodyDataCopies.items]
                }
            });
        }
        case ProgressActions.EDIT_BODY_DATA_COPY_SUCCESS: {
            const bodyDataCopy = action.payload;

            if (!state.bodyDataCopies.items.some(x => x?.body_data_detail?.id == bodyDataCopy?.body_data_detail?.id)) {
                return state;
            }

            return Object.assign({}, state, {
                bodyDataCopies: {
                    ...state.bodyDataCopies,
                    items: state.bodyDataCopies.items.map(obj => obj?.body_data_detail?.id == bodyDataCopy?.body_data_detail?.id ? bodyDataCopy : obj)
                }
            });
        }
        case ProgressActions.REMOVE_BODY_DATA_COPY_SUCCESS: {
            const removedBodyDataCopy = action.payload;

            return Object.assign({}, state, {
                bodyDataCopies: {
                    ...state.bodyDataCopies,
                    items: state.bodyDataCopies.items.filter(unit => unit?.body_data_detail?.id !== removedBodyDataCopy?.body_data_detail?.id)
                }
            });
        }
        //#endregion
        //#region Tag
        case ProgressActions.LOAD_TAGS:
        case ProgressActions.LOAD_TAGS_PAGE: {
            return Object.assign({}, state, {
                tags: {
                    ...state.tags,
                    loading: true
                }
            });
        }
        case ProgressActions.LOAD_TAGS_FAIL:
        case ProgressActions.LOAD_TAGS_PAGE_FAIL: {
            return Object.assign({}, state, {
                tags: {
                    ...state.tags,
                    loading: false
                }
            });
        }
        case ProgressActions.LOAD_TAGS_SUCCESS:
        case ProgressActions.LOAD_TAGS_PAGE_SUCCESS: {
            let resp: IPagingResult<ITag> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.tags.items, ...resp.items];

            return Object.assign({}, state, {
                tags: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }
        case ProgressActions.ADD_TAG_SUCCESS: {
            const tag = action.payload;

            if (state.tags.items.findIndex(x => x.id == tag.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                tags: {
                    ...state.tags,
                    items: [tag, ...state.tags.items]
                }
            });
        }
        case ProgressActions.REMOVE_TAG_SUCCESS: {
            const removedTagId = action.payload;

            return Object.assign({}, state, {
                tags: {
                    ...state.tags,
                    items: state.tags.items.filter(tag => tag.id !== removedTagId)
                }
            });
        }
        //#endregion
        //#region Macro
        case ProgressActions.LOAD_MACROS:
        case ProgressActions.LOAD_MACROS_PAGE: {
            return Object.assign({}, state, {
                macros: {
                    ...state.macros,
                    loading: true
                }
            });
        }
        case ProgressActions.LOAD_MACROS_FAIL:
        case ProgressActions.LOAD_MACROS_PAGE_FAIL: {
            return Object.assign({}, state, {
                macros: {
                    ...state.macros,
                    loading: false
                }
            });
        }
        case ProgressActions.LOAD_MACROS_SUCCESS:
        case ProgressActions.LOAD_MACROS_PAGE_SUCCESS: {
            let resp: IPagingResult<IMacro> = action.payload;

            let newItems = resp.paging.currentPage == 1 ? resp.items : [...state.macros.items, ...resp.items];

            return Object.assign({}, state, {
                macros: {
                    loaded: resp.paging.currentPage == resp.paging.lastPage,
                    loading: false,
                    items: newItems,
                    paging: resp.paging
                }
            });
        }
        case ProgressActions.LOAD_MACRO_SUCCESS: {
            const macro = action.payload;

            if (state.macros.items.findIndex(x => x.id == macro.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                macros: {
                    ...state.macros,
                    items: [macro, ...state.macros.items]
                }
            });
        }
        case ProgressActions.ADD_MACRO_SUCCESS: {
            const macro = action.payload;

            if (state.macros.items.findIndex(x => x.id == macro.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                macros: {
                    ...state.macros,
                    items: [macro, ...state.macros.items]
                }
            });
        }
        case ProgressActions.EDIT_MACRO_SUCCESS: {
            const macro = action.payload;

            if (!state.macros.items.some(x => x.id == macro.id)) {
                return state;
            }

            return Object.assign({}, state, {
                macros: {
                    ...state.macros,
                    items: state.macros.items.map(obj => obj.id == macro.id ? macro : obj)
                },
                macroCopies: {
                    ...state.macroCopies,
                    items: state.macroCopies.items.map(obj => obj.id == macro.id ? {
                        ...obj,
                        name: macro.name,
                        unit: macro.unit
                    } : obj)
                }
            });
        }
        case ProgressActions.REMOVE_MACRO_SUCCESS: {
            const removedMacro = action.payload;

            return Object.assign({}, state, {
                macros: {
                    ...state.macros,
                    items: state.macros.items.filter(macro => macro.id !== removedMacro.id)
                }
            });
        }
        //#endregion
        //#region Macro copy
        case ProgressActions.LOAD_MACRO_COPY_SUCCESS: {
            const macroCopy = action.payload;

            if (state.macroCopies.items.findIndex(x => x?.macro_detail?.id == macroCopy?.macro_detail?.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                macroCopies: {
                    ...state.macroCopies,
                    items: [macroCopy, ...state.macroCopies.items]
                }
            });
        }
        case ProgressActions.EDIT_MACRO_COPY_SUCCESS: {
            const macroCopy = action.payload;

            if (!state.macroCopies.items.some(x => x?.macro_detail?.id == macroCopy?.macro_detail?.id)) {
                return state;
            }

            return Object.assign({}, state, {
                macroCopies: {
                    ...state.macroCopies,
                    items: state.macroCopies.items.map(obj => obj?.macro_detail?.id == macroCopy?.macro_detail?.id ? macroCopy : obj)
                }
            });
        }
        case ProgressActions.REMOVE_MACRO_COPY_SUCCESS: {
            const removedMacroCopy = action.payload;

            return Object.assign({}, state, {
                macroCopies: {
                    ...state.macroCopies,
                    items: state.macroCopies.items.filter(obj => obj?.macro_detail?.id !== removedMacroCopy?.macro_detail?.id)
                }
            });
        }
        //#endregion

        case AuthActions.LOGIN_SUCCESS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}

//#region Selectors
export const getBodyDatasLoaded = (state: State) => state.bodyDatas.loaded;
export const getBodyDatasLoading = (state: State) => state.bodyDatas.loading;
export const getBodyDatas = (state: State) => state.bodyDatas.items;
export const getBodyDatasPaging = (state: State) => state.bodyDatas.paging;
export const getBodyData = (state: State, props) => state.bodyDatas.items.find(x => x.id == props.id);

export const getBodyDataCopy = (state: State, props) => state.bodyDataCopies.items.find(x => x.id == props.id);

export const getTagsLoaded = (state: State) => state.tags.loaded;
export const getTagsLoading = (state: State) => state.tags.loading;
export const getTags = (state: State) => state.tags.items;
export const getTagsPaging = (state: State) => state.tags.paging;
export const getTag = (state: State, props) => state.tags.items.find(x => x.id == props.id);

export const getMacrosLoaded = (state: State) => state.macros.loaded;
export const getMacrosLoading = (state: State) => state.macros.loading;
export const getMacros = (state: State) => state.macros.items;
export const getMacrosPaging = (state: State) => state.macros.paging;
export const getMacro = (state: State, props) => state.macros.items.find(x => x.id == props.id);

export const getMacroCopy = (state: State, props) => state.macroCopies.items.find(x => x.id == props.id);
//#endregion
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ITrainingPlanSheet, ITrainingUnit } from '@models/training.model';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { TrainingPlanSheetUnitsComponent } from 'app/modules/coach/pages/training/training-plans/training-plan/training-plan-sheet/training-plan-sheet-units/training-plan-sheet-units.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-training-plan-overview-sheet',
  templateUrl: './training-plan-overview-sheet.component.html',
  styleUrls: ['./training-plan-overview-sheet.component.scss']
})
export class TrainingPlanOverviewSheetComponent implements OnInit, OnDestroy {
  @Input() sheet: ITrainingPlanSheet;
  @Input() sheetNumber: number;

  subs: Subscription[] = [];

  get setsSum(): number {
    let result = 0;
    this.sheet?.units?.forEach(unit => {
      result += unit?.unit_data?.sets || 0;
    });
    return result;
  }

  constructor(private store: Store<AppState>,
    private modalController: ModalController) { }

  ngOnInit() {
    if (this.sheet?.id) {
      this.selectTrainingSheet();
    }
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  doReorder(event) {
    let activeUnits = this.sheet?.units || [];
    activeUnits = this.array_move([...activeUnits], event.detail.from, event.detail.to);
    this.sheet = {
      ...this.sheet,
      units: activeUnits
    };
    event.detail.complete();


    this.store.dispatch(new TrainingActions.EditSheetAction(this.sheet, true));
  }

  array_move(arr, old_index, new_index) {
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  selectTrainingSheet() {
    this.store.dispatch(new TrainingActions.LoadSheetAction(this.sheet?.id))
    this.subs[this.subs.length] = this.store.select(AppReducers.getTrainingSheetsCollection)
      .subscribe(data => {
        if (data && data.length > 0) {
          let sheet = data.find(x => x.id == this.sheet?.id);
          if (sheet) {
            this.sheet = sheet;
          }
        }
      });
  }

  onClickAddUnits() {
    this.modalController.create({
      component: TrainingPlanSheetUnitsComponent,
      swipeToClose: true,
      cssClass: 'shadow-modal'
    })
      .then(modal => {
        modal.present();

        modal.onDidDismiss().then(data => {
          if (data?.data) {
            this.sheet = {
              ...this.sheet,
              units: [...this.sheet.units.concat(data?.data)]
            };
            this.store.dispatch(new TrainingActions.EditSheetAction(this.sheet, true));
          }
        })
      })
  }

  onRemoveUnit(unit: ITrainingUnit) {
    this.sheet = {
      ...this.sheet,
      units: this.sheet.units.filter(x => x.id != unit.id)
    };
    this.store.dispatch(new TrainingActions.EditSheetAction(this.sheet, true));
  }
}

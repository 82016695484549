import { INutritionGoal } from '@models/nutrition.model';
import { AuthActions, NutritionActions } from "../actions";

export interface State {
    goals: GoalState;
}
export interface GoalState {
    loaded: boolean;
    loading: boolean;
    items: INutritionGoal[];
}

const initialSubState = {
    loaded: false,
    loading: false,
    items: []
}
const initialState: State = {
    goals: initialSubState
};

export function reducer(state = initialState, action): State {
    switch (action.type) {
        case NutritionActions.LOAD_GOALS: {
            return Object.assign({}, state, {
                goals: {
                    ...state.goals,
                    loading: true
                }
            });
        }
        case NutritionActions.LOAD_GOALS_SUCCESS: {
            const goals = action.payload;

            return Object.assign({}, state, {
                goals: {
                    loaded: true,
                    loading: false,
                    items: goals
                }
            });
        }
        case NutritionActions.ADD_GOAL_SUCCESS: {
            const goal = action.payload;

            if (state.goals.items.findIndex(x => x.id == goal.id) > -1) {
                return state;
            }

            return Object.assign({}, state, {
                goals: {
                    ...state.goals,
                    items: [goal, ...state.goals.items]
                }
            });
        }
        case NutritionActions.EDIT_GOAL_SUCCESS: {
            const goal = action.payload;

            if (!state.goals.items.some(x => x.id == goal.id)) {
                return state;
            }

            return Object.assign({}, state, {
                goals: {
                    ...state.goals,
                    items: state.goals.items.map(obj => obj.id == goal.id ? goal : obj)
                }
            });
        }


        case AuthActions.LOGIN_SUCCESS: {
            return initialState;
        }

        default: {
            return state;
        }
    }
}


export const getGoalsLoaded = (state: State) => state.goals.loaded;
export const getGoalsLoading = (state: State) => state.goals.loading;
export const getGoals = (state: State) => state.goals.items;
export const getGoal = (state: State, props) => state.goals.items.find(x => x.id == props.id);
export const getGoalByAthleteId = (state: State, props) => state.goals.items.find(x => x.athlete_id == props.id);
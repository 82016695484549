import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { AthleteService } from "@services/athlete.service";
import { CoachInvitationsService } from "@services/coach-invitations.service";
import { of } from "rxjs";
import { exhaustMap, map, catchError } from "rxjs/operators";
import { InvitationActions } from "../actions";

@Injectable()
export class InvitationEffects {
    constructor(
        private athleteService: AthleteService,
        private coachInvitationService: CoachInvitationsService,
        private actions$: Actions
    ) { }

    get$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InvitationActions.GET),
            exhaustMap((action: any) =>
                this.coachInvitationService
                    .get()
                    .pipe(
                        map((res) => new InvitationActions.GetSuccessAction(res)),
                        catchError((error) => of(new InvitationActions.GetFailAction(error)))
                    )
            )
        )
    );

    inviteAthlete$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InvitationActions.INVITE_ATHLETE),
            exhaustMap((action: any) =>
                this.athleteService
                    .add(action.payload)
                    .pipe(
                        map((res) => new InvitationActions.InviteAthleteSuccessAction(res)),
                        catchError((error) => of(new InvitationActions.InviteAthleteFailAction(error)))
                    )
            )
        )
    );

    acceptInvite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InvitationActions.ACCEPT_INVITE),
            exhaustMap((action: InvitationActions.AcceptInviteAction) =>
                this.coachInvitationService
                    .accept(action.coachId, action.code)
                    .pipe(
                        map((res: any) => new InvitationActions.AcceptInviteSuccessAction(action.coachId, action.code)),
                        catchError((error) => of(new InvitationActions.AcceptInviteFailAction(error)))
                    )
            )
        )
    );

    cancelInvite$ = createEffect(() =>
        this.actions$.pipe(
            ofType(InvitationActions.CANCEL_INVITE),
            exhaustMap((action: InvitationActions.CancelInviteAction) =>
                this.coachInvitationService
                    .cancel(action.id)
                    .pipe(
                        map((res: any) => new InvitationActions.CancelInviteSuccessAction(action.id)),
                        catchError((error) => of(new InvitationActions.CancelInviteFailAction(error)))
                    )
            )
        )
    );
}
import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-policy-modal',
  templateUrl: './policy-modal.component.html',
  styleUrls: ['./policy-modal.component.scss'],
})
export class PolicyModalComponent implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() { }

  cancel() {
    this.modalController.dismiss();
  }

  accept() {
    this.modalController.dismiss(true);
  }
}

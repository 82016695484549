import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-tunits-infinity-scroll',
  templateUrl: './tunits-infinity-scroll.component.html',
  styleUrls: ['./tunits-infinity-scroll.component.scss'],
})
export class TUnitsInfinityScrollComponent {
  selectPaging = AppReducers.getTrainingUnitPaging;
  loadPageSuccessAction: string = TrainingActions.LOAD_UNITS_PAGE_SUCCESS;
  loadPageFailAction: string = TrainingActions.LOAD_UNITS_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  loadPageAction() {
    this.store.dispatch(new TrainingActions.LoadUnitsPageAction());
  }
}

import { Injectable } from '@angular/core';
import { ActionSheetController, Platform } from '@ionic/angular';
import { AppSubscription } from '@models/app-subscription.model';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { UIService } from './ui.service';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { StripeService } from './stripe.service';
import { Router } from '@angular/router';
import { addScriptHelper } from '../helpers/add-script.helper';
import "cordova-plugin-purchase";

declare const paypal: any;



@Injectable({
    providedIn: 'root'
})
export class IAPService {
    trainerSubs$: BehaviorSubject<AppSubscription[]> = new BehaviorSubject<AppSubscription[]>([]);
    athleteSubs$: BehaviorSubject<AppSubscription[]> = new BehaviorSubject<AppSubscription[]>([]);

    subs: Subscription[] = [];

    freeSubKey: string = "free";
    private _athleteSubKey: string = "athlete_basic";
    private _trainerSubsKeys: string[] = [
        "trainer_basic",
        "trainer_20",
        "trainer_30",
        "trainer_40",
    ];

    public constructor(public platform: Platform,
        private authService: AuthService,
        private http: HttpClient,
        private stripeService: StripeService,
        private translate: TranslateService,
        private router: Router,
        private actionSheetController: ActionSheetController,
        private uiService: UIService) {

        this.platform.ready().then(() => {
            if (this.isIOS) {
                
                if (!environment.production) {
                    // CdvPurchase.store.verbosity = CdvPurchase.LogLevel.DEBUG;
                }
                this.setupListeners();
                this.registerIAPSubs();
                this.updateIAPSubs();
            } else {
                this.setupListeners();
                this.updateWebSubs();
            }
        });
    }

    refresh() {
        if (this.isIOS) {
            this.updateIAPSubs();

            CdvPurchase.store.update();
            CdvPurchase.store.restorePurchases();

        } else {
            this.updateWebSubs();
        }
    }

    setupListeners() {

        if (this.isIOS) {

            CdvPurchase.Logger.console = {
                error: (message: string | unknown) => {
                    // console.log("Logger error", message)
                    this.uiService.showMessage(`ERROR ${message}`, 'danger');
                },
                warn: (message: string | unknown) => {
                    // console.log("Logger warn", message)
                },
                log: (message: string | unknown) => {
                    // console.log("Logger console", message)
                }
            };

            CdvPurchase.store.when().approved((transaction) => {
                transaction.verify()
            });

            CdvPurchase.store.when().verified((receipt) => {
                receipt.finish();
            });

            CdvPurchase.store.when().unverified((unverified) => {
                console.log(`[Store] Unverified. code: ${unverified.payload.code}, ` +
                    "status: " + unverified.payload.status + ", " + unverified.payload.message);
            });

            CdvPurchase.store.when().finished((transaction: CdvPurchase.Transaction) => {

                const parentReceipt = transaction.parentReceipt as CdvPurchase.AppleAppStore.SKApplicationReceipt;
                if(parentReceipt?.nativeData?.appStoreReceipt) {

                    // TODO: how to reach countryCode
                    this.http.post('/apple_transaction', { receipt: parentReceipt?.nativeData?.appStoreReceipt})
                            .subscribe(() => {
                                this.authService.getUserData(false).subscribe();
                            });
                }
            })

            CdvPurchase.store.when().receiptUpdated((_) => {
                this.updateIAPSubs();
            })

            CdvPurchase.store.update();

        } else {
            this.translate.onLangChange.subscribe(() => {
                this.updateWebSubs();
            })
        }
    }

    /**
     * Registers IAP Subs currently only for IOS
     */
    async registerIAPSubs() {
        this._trainerSubsKeys.forEach(subKey => {
            CdvPurchase.store.register({ id: subKey, type: CdvPurchase.ProductType.PAID_SUBSCRIPTION, platform: CdvPurchase.Platform.APPLE_APPSTORE })
        })

        CdvPurchase.store.register({ id: this._athleteSubKey, type: CdvPurchase.ProductType.PAID_SUBSCRIPTION, platform: CdvPurchase.Platform.APPLE_APPSTORE })

        CdvPurchase.store.update();

        await CdvPurchase.store.initialize();
    }

    updateIAPSubs() {

        CdvPurchase.store.ready(() => {
            let trainerSubs: AppSubscription[] = [this.generateFreeSub(this.freeSubKey)];
            let athleteSubs: AppSubscription[] = [this.generateFreeSub(this.freeSubKey)];

            CdvPurchase.store.products.forEach(product => {
                if (this._trainerSubsKeys.includes(product.id)) {
                    trainerSubs.push(this.purchaseProductToAppSubscription(product));
                }
                else if (product.id == this._athleteSubKey) {
                    athleteSubs.push(this.purchaseProductToAppSubscription(product));
                }
            });

            this.trainerSubs$.next([...trainerSubs]);
            this.athleteSubs$.next([...athleteSubs]);
        });
    }

    updateWebSubs() {
        this.http.get('/subscriptions')
            .subscribe((resp: any) => {
                if (resp.data[0]) {
                    let athleteSubs: AppSubscription[] = resp.data[0].athlete.map(x => this.mapStripeItemToIAP(x));
                    let trainerSubs: AppSubscription[] = resp.data[0].trainer.map(x => this.mapStripeItemToIAP(x));
                    athleteSubs.reverse();
                    trainerSubs.reverse();
                    this.trainerSubs$.next([
                        this.generateFreeSub(this.freeSubKey),
                        ...trainerSubs
                    ]);
                    this.athleteSubs$.next([
                        this.generateFreeSub(this.freeSubKey),
                        ...athleteSubs
                    ]);
                }
            })
    }

    mapStripeItemToIAP(item): AppSubscription {
        return new AppSubscription(item?.metadata?.key, `€${item?.price?.unit_amount / 100}`, item?.price?.id, item?.id, item?.paypal_id);
    }

    purchaseProductToAppSubscription(product: CdvPurchase.Product) {
        return new AppSubscription(product.id, product.pricing?.price);
    }

    generateFreeSub(key: string): AppSubscription {
        let title = this.translate.instant(`auth.sub.list.${key}.title`);
        let desc = this.translate.instant(`auth.sub.list.${key}.desc`);
        let price = this.translate.instant(`auth.sub.list.${key}.price`);

        const freeProduct : AppSubscription = {
            id: key,
            price: price
        }
        
        return freeProduct;
    }

    get isIOS(): boolean {
        return this.platform.is('ios');
    }

    unsubscribe(): Observable<any> {
        if (this.isIOS) {
            CdvPurchase.store.manageSubscriptions();
        } else {
            return new Observable(subscriber => {
                this.authService.currentUser$
                    .pipe(take(1))
                    .subscribe(user => {
                        switch (user.subscription_type) {
                            case 'paypal':
                                window.open(`https://www.${environment.PAY_PAL_SANDBOX ? 'sandbox.' : ''}paypal.com/myaccount/autopay/`, "_blank");
                                subscriber.next(true);
                                subscriber.complete();
                                break;
                            default:
                                this.http.delete('/stripe_subscriptions')
                                    .subscribe(data => {
                                        subscriber.next(data);
                                        subscriber.complete();
                                    });
                        }
                    })
            })
        }
    }

    purchase(product: AppSubscription, successUrl: string = '', cancelUrl: string = ''): Observable<any> {
        let id = product.id;
        switch (id) {
            case this.freeSubKey:
                return this.http.post(`/free_subscription`, null).pipe(
                    map((response: any) => {
                        if (response?.status === "success") {
                            this.authService.setUserData(response.data[0]);

                            this.router.navigateByUrl(successUrl);

                            return response.data[0];
                        } else {
                            throw response.message;
                        }
                    })
                );
            default:
                if (this.isIOS) {

                    const offer = this.findOfferById(id);

                    CdvPurchase.store.order(offer)
                        .then(
                            (data) => {
                                // console.log('Success', `Successfully purchased: ${data}`);
                            },
                            e => {
                                console.error('Failed', `Failed to purchase: ${e}`);
                            });
                    return of(true);
                } else {
                    return this.creditCardPurchase(product, successUrl, cancelUrl);
                }
        }
    }

    private stripePurchase(product: AppSubscription, successUrl: string = '', cancelUrl: string = ''): Observable<any> {
        let productId = product.productId;
        let priceId = product.priceId;

        return this.stripeService.createTransaction(priceId, productId, successUrl, cancelUrl).pipe(
            map((data: any) => {
                return data;
            }));
    }

    private creditCardPurchase(product: AppSubscription, successUrl: string = '', cancelUrl: string = ''): Observable<any> {
        return new Observable(subscriber => {

            this.actionSheetController.create({
                header: this.translate.instant('coach.more.sub.select_method'),
                mode: 'ios',
                buttons: [
                    {
                        text: '',
                        handler: () => {
                            this.stripePurchase(product, successUrl, cancelUrl)
                                .subscribe(data => {
                                    subscriber.next(data);
                                    subscriber.complete();
                                });
                        },
                        cssClass: 'stripe-button'
                    },
                    {
                        text: this.translate.instant('coach.more.sub.or'),
                        cssClass: 'inactive-button',
                        handler: () => false,
                    },
                    {
                        text: '',
                        cssClass: 'paypal-button',
                        handler: () => false,
                    }]
            }).then(actionSheet => {
                this.uiService.showLoading();
                addScriptHelper(`https://www.paypal.com/sdk/js?client-id=${environment.PAY_PAL_CLIENT_ID}&vault=true&intent=subscription`)
                    .subscribe(() => {
                        this.createPayPalButton(product)
                            .subscribe((data) => {
                                if (data) {
                                    actionSheet.present();
                                }
                                else {
                                    actionSheet.dismiss();
                                }
                            });
                    });
            });
        });
    }

    private createPayPalButton(product: AppSubscription): Observable<boolean> {
        return new Observable(subscriber => {
            paypal.Buttons({
                style: {
                    shape: 'pill',
                    color: 'black',
                    layout: 'horizontal',
                    tagline: 'false'
                },
                createSubscription: function (data, actions) {
                    return actions.subscription.create({
                        plan_id: product.paypal_id
                    });
                },
                onInit: (data) => {
                    this.uiService.hideLoading();
                    subscriber.next(true);
                },
                onCancel: () => {
                    subscriber.next(false);
                    subscriber.complete();
                },
                onApprove: (data) => {
                    this.createPayPalTransaction(data, product.paypal_id);

                    subscriber.next(false);
                    subscriber.complete();
                }
            }).render('.paypal-button span');
        });
    }

    private createPayPalTransaction(data: any, product_id: string) {
        this.http.post('/paypal_transaction', {
            billing_token: data.billingToken,
            facilitator_access_token: data.facilitatorAccessToken,
            order_id: data.orderID,
            subscription_id: data.subscriptionID,
            product_id: product_id
        })
            .subscribe(() => {
                this.authService.getUserData(false).subscribe();
            });
    }

    /**
     * Converts an id to an offer
     */
    private findOfferById(id: string) : CdvPurchase.Offer {
        return CdvPurchase.store.get(id).getOffer();
    }
}
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-assign-template,
app-assign-units {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/coach/pages/training/training-plans/training-plan/training-plan-sheet/training-plan-sheet-units/training-plan-sheet-units.component.scss"],"names":[],"mappings":"AAAA;;EAEI,YAAA;AACJ","sourcesContent":["app-assign-template,\napp-assign-units {\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

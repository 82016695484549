import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { ITrainingPlan } from '@models/training.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-copy-plan-modal',
  templateUrl: './copy-plan-modal.component.html',
  styleUrls: ['./copy-plan-modal.component.scss']
})
export class CopyPlanModalComponent implements OnInit, OnDestroy {
  @Input() plan: ITrainingPlan;
  @Input() assigned: boolean = true;
  form: UntypedFormGroup;
  subSuccess: Subscription;

  constructor(private formBuilder: UntypedFormBuilder,
    private store: Store<AppState>,
    private actions$: Actions,
    private modalController: ModalController) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
      value: [this.plan?.name, Validators.required]
    });

    this.subSuccess = this.actions$.pipe(ofType(TrainingActions.COPY_PLAN_SUCCESS))
      .subscribe((data: any) => {
        this.modalController.dismiss();
      })
  }

  closeModal() {
    this.modalController.dismiss();
  }

  submit() {
    this.store.dispatch(new TrainingActions.CopyTrainingPlanAction(this.plan?.id, this.form?.value?.value));
  }

  ngOnDestroy(): void {
    this.subSuccess.unsubscribe();
  }
}

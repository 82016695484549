import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "environments/environment";
import { Platform } from "@ionic/angular";

declare var Stripe: any;

@Injectable({
    providedIn: "root",
})
export class StripeService {

    constructor(private http: HttpClient,
        public platform: Platform) {
    }

    createTransaction(priceId: string, productId: string, successUrl: string, cancelUrl: string): Observable<string> {
        let stripe = Stripe(environment.STRIPE_KEY);

        let base = window.location.protocol + "//" + window.location.host + '/';
        base = this.platform.is('android') ? environment.WEB_URL : base;

        return this.http.post('/stripe_create_transaction', {
            price_id: priceId,
            product_id: productId,
            success_url: base + successUrl + "?type=success",
            cancel_url: base + cancelUrl + "?type=error"
        })
            .pipe(
                map((response: any) => {
                    if (response?.status === "success") {
                        let sessionId = response.data[0].stripe_session_id;
                        if (sessionId) {
                            stripe.redirectToCheckout({ sessionId: sessionId });
                            return sessionId;
                        }
                    } else {
                        throw response.message;
                    }
                })
            );
    }
}
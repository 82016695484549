import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    availableLanguages = ['en', 'de'];
    defaultLang = 'de';

    constructor(private translate: TranslateService) {

    }

    initLocalization() {
        const lang = this.translate.getBrowserLang() in this.availableLanguages ?
            this.translate.getBrowserLang() : this.defaultLang;
        this.translate.setDefaultLang(lang);
        this.translate.use(lang);

        moment.locale(lang);
    }
}

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, mergeMap, withLatestFrom } from "rxjs/operators";
import { MesocycleService } from '@services/mesocycle.service';
import { AthleteActions, MesocycleActions } from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.states';
import { AssignedMesocycleService } from '@services/assigned-mesocycle.service';
import { IPagingResult } from "@models/paging.model";
import { IAssignedMesocycle, IMesocycle } from "@models/mesocycle.model";

@Injectable()
export class MesocycleEffects {
    constructor(
        private mesocycleService: MesocycleService,
        private assignedMesocycleService: AssignedMesocycleService,
        private actions$: Actions,
        private store$: Store<AppState>
    ) { }

    loadMesocycles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.LOAD_MESOCYCLES),
            withLatestFrom(this.store$),
            filter(([action, storeState]) => {
                return !storeState?.mesocycle?.mesocycles?.paging.currentPage;
            }),
            exhaustMap((action) => {
                return this.mesocycleService
                    .getAll()
                    .pipe(
                        map((res: any) => new MesocycleActions.LoadMesocyclesSuccessAction(res)),
                        catchError((error) => of(new MesocycleActions.LoadMesocyclesFailAction(error)))
                    )
            })
        )
    );
    loadMesocyclesPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.LOAD_MESOCYCLES_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [MesocycleActions.LoadMesocyclesPageAction, AppState]) => {
                return storeState?.mesocycle?.mesocycles?.paging?.currentPage != 0 && (!action.page || storeState?.mesocycle?.mesocycles.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [MesocycleActions.LoadMesocyclesPageAction, AppState]) => {
                let page = !action.page ? storeState.mesocycle.mesocycles.paging.currentPage + 1 : action.page;
                return this.mesocycleService
                    .getAll(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<IMesocycle>) => new MesocycleActions.LoadMesocyclesPageSuccessAction(pagingResult)),
                        catchError((error) => of(new MesocycleActions.LoadMesocyclesPageFailAction(error)))
                    )
            })
        )
    );
    loadMesocycle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.LOAD_MESOCYCLE),
            withLatestFrom(this.store$),
            filter((data: any) => {
                return !data[1]?.mesocycle?.mesocycles?.items.some(x => x.id == data[0].id);
            }),
            exhaustMap((action: any) => {
                return this.mesocycleService
                    .get(action[0].id)
                    .pipe(
                        map((res: any) => new MesocycleActions.LoadMesocycleSuccessAction(res)),
                        catchError((error) => of(new MesocycleActions.LoadMesocycleFailAction(error)))
                    )
            })
        )
    );
    addMesocycle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.ADD_MESOCYCLE),
            exhaustMap((action: any) =>
                this.mesocycleService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new MesocycleActions.AddMesocycleSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new MesocycleActions.AddMesocycleFailAction(error)))
                    )
            )
        )
    );
    editMesocycle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.EDIT_MESOCYCLE),
            exhaustMap((action: any) =>
                this.mesocycleService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new MesocycleActions.EditMesocycleSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new MesocycleActions.EditMesocycleFailAction(error)))
                    )
            )
        )
    );
    removeMesocycle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.REMOVE_MESOCYCLE),
            exhaustMap((action: MesocycleActions.RemoveMesocycleAction) =>
                this.mesocycleService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new MesocycleActions.RemoveMesocycleSuccessAction(action.payload);
                        }),
                        catchError((error) => of(new MesocycleActions.RemoveMesocycleFailAction(error)))
                    )
            )
        )
    );

    loadMesocycleCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.LOAD_MESOCYCLE_COPY),
            withLatestFrom(this.store$),
            filter((data: any) => {
                return !data[1]?.mesocycle?.mesocycleCopies?.items.some(x => x.id == data[0].id);
            }),
            exhaustMap((action: any) => {
                return this.mesocycleService
                    .get(action[0].id)
                    .pipe(
                        map((res: any) => new MesocycleActions.LoadMesocycleCopySuccessAction(res)),
                        catchError((error) => of(new MesocycleActions.LoadMesocycleCopyFailAction(error)))
                    )
            })
        )
    );
    editMesocycleCopy$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.EDIT_MESOCYCLE_COPY),
            exhaustMap((action: any) =>
                this.mesocycleService
                    .edit(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new MesocycleActions.EditMesocycleCopySuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new MesocycleActions.EditMesocycleCopyFailAction(error)))
                    )
            )
        )
    );

    loadAssignedMesocycles$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.LOAD_ASSIGNED_MESOCYCLES),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [MesocycleActions.LoadAssignedMesocyclesAction, AppState]) => {
                return action.force || !Array.isArray(storeState?.mesocycle?.assigned[action.athleteId]?.items);
            }),
            exhaustMap((action: any) => {
                return this.assignedMesocycleService
                    .getAthleteMesocycles(action[0].athleteId, action[0].withoutLoading)
                    .pipe(
                        map((res: any) => new MesocycleActions.LoadAssignedMesocyclesSuccessAction(res, action[0].athleteId)),
                        catchError((error) => of(new MesocycleActions.LoadAssignedMesocyclesFailAction(error)))
                    )
            })
        )
    );

    loadAssignedMesocyclesPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.LOAD_ASSIGNED_MESOCYCLES_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [MesocycleActions.LoadAssignedMesocyclesPageAction, AppState]) => {
                return storeState?.mesocycle?.assigned[action.athleteId]?.paging?.currentPage != 0 && (!action.page || storeState?.mesocycle?.assigned[action.athleteId].paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [MesocycleActions.LoadAssignedMesocyclesPageAction, AppState]) => {
                let page = !action.page ? storeState.mesocycle.assigned[action.athleteId]?.paging?.currentPage + 1 : action.page;
                return this.assignedMesocycleService
                    .getAthleteMesocycles(action.athleteId, true, page)
                    .pipe(
                        map((pagingResult: IPagingResult<IAssignedMesocycle>) => new MesocycleActions.LoadAssignedMesocyclesPageSuccessAction(pagingResult, action.athleteId)),
                        catchError((error) => of(new MesocycleActions.LoadAssignedMesocyclesPageFailAction(error)))
                    )
            })
        )
    );
    addAssignedMesocycle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.ADD_ASSIGNED_MESOCYCLE),
            mergeMap((action: any) =>
                this.assignedMesocycleService
                    .add(action.payload)
                    .pipe(
                        map((res: any) => {
                            return new MesocycleActions.AddAssignedMesocycleSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new MesocycleActions.AddAssignedMesocycleFailAction(error)))
                    )
            )
        )
    );
    removeAssignedMesocycle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.REMOVE_ASSIGNED_MESOCYCLE),
            exhaustMap((action: MesocycleActions.RemoveAssignedMesocycleAction) =>
                this.assignedMesocycleService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new MesocycleActions.RemoveAssignedMesocycleSuccessAction(action.payload);
                        }),
                        catchError((error) => of(new MesocycleActions.RemoveAssignedMesocycleFailAction(error)))
                    )
            )
        )
    );

    activateMesocycle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.ACTIVATE_ASSIGNED_MESOCYCLE),
            exhaustMap((action: MesocycleActions.ActivateAssignedMesocycleAction) =>
                this.assignedMesocycleService
                    .activate(action.id, action.athleteId, true)
                    .pipe(
                        map((res: any) => {
                            this.store$.dispatch(new AthleteActions.LoadTrackingAction(null, true, true));
                            this.store$.dispatch(new AthleteActions.LoadTrainingAction(null, true));
                            return new MesocycleActions.ActivateAssignedMesocycleSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new MesocycleActions.ActivateAssignedMesocycleFailAction(error)))
                    )
            )
        )
    );
    deactivateMesocycle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.DEACTIVATE_ASSIGNED_MESOCYCLE),
            exhaustMap((action: MesocycleActions.DeactivateAssignedMesocycleAction) =>
                this.assignedMesocycleService
                    .activate(action.id, false)
                    .pipe(
                        map((res: any) => {
                            return new MesocycleActions.DeactivateAssignedMesocycleSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new MesocycleActions.DeactivateAssignedMesocycleFailAction(error)))
                    )
            )
        )
    );


    setinvisibleMesocycle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.SET_INVISIBLE_ASSIGNED_MESOCYCLE),
            exhaustMap((action: MesocycleActions.SetInvisibleAssignedMesocycleAction) =>
                this.assignedMesocycleService
                    .setInvisible(action.id, action.is_invisible, action.date)
                    .pipe(
                        map((res: any) => {
                            return new MesocycleActions.SetInvisibleAssignedMesocycleSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new MesocycleActions.SetInvisibleAssignedMesocycleFailAction(error)))
                    )
            )
        )
    );


    copyToBaseMesocycle$ = createEffect(() =>
        this.actions$.pipe(
            ofType(MesocycleActions.COPY_TO_BASE_MESOCYCLE),
            exhaustMap((action: MesocycleActions.CopyToBaseMesocycleAction) =>
                this.assignedMesocycleService
                    .copyToBaseMesocycle(action.name, action.mesocycleId)
                    .pipe(
                        map((res: any) => {
                            return new MesocycleActions.CopyToBaseMesocycleSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new MesocycleActions.CopyToBaseMesocycleFailAction(error)))
                    )
            )
        )
    );
}

import { Component, OnInit } from '@angular/core';
import { IInvitation } from '@models/invitation.model';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { InvitationActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-athlete-invites',
  templateUrl: './athlete-invites.component.html',
  styleUrls: ['./athlete-invites.component.scss']
})
export class AthleteInvitesComponent implements OnInit {
  invitations$: Observable<IInvitation[]>;

  constructor(private store: Store<AppState>) { }

  ngOnInit() {
    this.invitations$ = this.store.select(AppReducers.getPendingAthleteInvitationsCollection);
    this.store.dispatch(new InvitationActions.GetAction());
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { S3FileUploadService } from "@services/s3.upload.service";
import { UIService } from "@services/ui.service";
import { AuthService } from "@services/auth.service";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: 'app-upload-avatar',
  templateUrl: './upload-avatar.page.html',
  styleUrls: ['./upload-avatar.page.scss'],
})

export class UploadAvatarPage implements OnInit {

  croppedImagePath: string = "";
  croppedImageBase64: string = "";

  constructor(
    private router: Router,
    private translate: TranslateService,
    private s3upload: S3FileUploadService,
    private uiService: UIService,
    private auth: AuthService
  ) { }

  ngOnInit() {
  }

  imageUpdated($event) {
    this.croppedImageBase64 = $event;
  }

  submitAvatar() {
    this.s3upload.uploadAvatar(this.croppedImageBase64).then((path: string) => {

      this.croppedImagePath = path;

      this.storeUserAvatar(path);

    }).catch((err) => {
      this.uiService.showMessage(err, 'danger', 5000);
    })
  }

  storeUserAvatar(imageURL: string) {

    this.auth.updateAvatar(imageURL)
      .then(() => {
        this.uiService.showMessage(this.translate.instant('auth.upload.image_upload_success'), 'success', 2000);
        this.router.navigate(['/color-schema']);
      })
      .catch(error => {
        console.log('Error update avatar ', error);
      });
  }

  async nextPage() {
    this.router.navigate(['/color-schema']);
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IUser } from '@models/user.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AthleteService } from '@services/athlete.service';
import { AuthService } from '@services/auth.service';
import { CoachInvitationsService } from '@services/coach-invitations.service';
import { InvitationActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'app-invite-code',
  templateUrl: './invite-code.page.html',
  styleUrls: ['./invite-code.page.scss'],
})
export class InviteCodePage implements OnInit, OnDestroy {
  code: string = '';
  coach$: Observable<IUser>;
  subs: Subscription[] = [];

  constructor(private router: Router,
    private store: Store<AppState>,
    private athleteService: AthleteService,
    private actions$: Actions,
    private coachInvService: CoachInvitationsService,
    private authService: AuthService) { }

  ngOnInit() {
    this.subs[this.subs.length] = this.actions$.pipe(ofType(InvitationActions.ACCEPT_INVITE_SUCCESS))
      .subscribe(data => {
        this.router.navigate(['/athlete-info']);
      })
  }

  submitCode() {
    this.coach$ = this.coachInvService.getByCode(this.code);
  }

  acceptInvite(coachId: number) {
    this.store.dispatch(new InvitationActions.AcceptInviteAction(coachId, this.code));
  }

  async goToSubscription() {
    await this.authService.trainsHimself();
    this.router.navigate(['/subscription']);
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

import { IAddAthlete } from "@models/athlete.model";
import { IInvitation } from "@models/invitation.model";
import { Action } from "@ngrx/store";

export const GET = '[Invite] Get invitations';
export const GET_SUCCESS = '[Invite] Get invitations Success';
export const GET_FAIL = '[Invite] Get invitations Fail';

export const INVITE_ATHLETE = '[Invite] Invite Athlete';
export const INVITE_ATHLETE_SUCCESS = '[Invite] Invite Athlete Success';
export const INVITE_ATHLETE_FAIL = '[Invite] Invite Athlete Fail';

export const CANCEL_INVITE = '[Invite] Cancel Invite';
export const CANCEL_INVITE_SUCCESS = '[Invite] Cancel Invite Success';
export const CANCEL_INVITE_FAIL = '[Invite] Cancel Invite Fail';

export const ACCEPT_INVITE = '[Invite] Accept Invite';
export const ACCEPT_INVITE_SUCCESS = '[Invite] Accept Invite Success';
export const ACCEPT_INVITE_FAIL = '[Invite] Accept Invite Fail';


/**
 * Get Invitations Actions
 */
export class GetAction implements Action {
    readonly type = GET;
}
export class GetSuccessAction implements Action {
    readonly type = GET_SUCCESS;
    constructor(public result: IInvitation[]) { }
}
export class GetFailAction implements Action {
    readonly type = GET_FAIL;
    constructor(public payload: any) { }
}

/**
 * Invite Athlete Actions
 */
export class InviteAthleteAction implements Action {
    readonly type = INVITE_ATHLETE;
    constructor(public payload: IAddAthlete) { }
}
export class InviteAthleteSuccessAction implements Action {
    readonly type = INVITE_ATHLETE_SUCCESS;
    constructor(public payload: IInvitation) { }
}
export class InviteAthleteFailAction implements Action {
    readonly type = INVITE_ATHLETE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Accept Invite Actions
 */
export class AcceptInviteAction implements Action {
    readonly type = ACCEPT_INVITE;
    constructor(public coachId: number, public code: string) { }
}
export class AcceptInviteSuccessAction implements Action {
    readonly type = ACCEPT_INVITE_SUCCESS;
    constructor(public coachId: number, public code: string) { }
}
export class AcceptInviteFailAction implements Action {
    readonly type = ACCEPT_INVITE_FAIL;
    constructor(public payload: any) { }
}

/**
 * Cancel Invite Actions
 */
export class CancelInviteAction implements Action {
    readonly type = CANCEL_INVITE;
    constructor(public id: number) { }
}
export class CancelInviteSuccessAction implements Action {
    readonly type = CANCEL_INVITE_SUCCESS;
    constructor(public id: number) { }
}
export class CancelInviteFailAction implements Action {
    readonly type = CANCEL_INVITE_FAIL;
    constructor(public payload: any) { }
}
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IInvitation } from '@models/invitation.model';
import { IUser } from '@models/user.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { UIService } from '@services/ui.service';
import { DEFAULT_AVATAR_URL } from 'app/core/consts/default-avatar.const';
import { AthleteActions, InvitationActions, NoteActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { FollowupComponent } from './followup/followup.component';
import { InviteDetailsComponent } from './invite-details/invite-details.component';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  @Input() users: IUser[] = [];
  @Input() invitations: IInvitation[] = [];
  @Input() isSingleSelect: boolean = false;
  @Input() isMultiSelect: boolean = false;
  @Input() isUserMultiSelectDisabled: Function;
  @Input() withDelete: boolean = true;
  @Input() withManageDates: boolean = false;


  @Output() onSelect = new EventEmitter<IUser>();
  @Output() onSingleSelect = new EventEmitter<IUser>();
  @Output() onMultiSelect = new EventEmitter<IUser[]>();

  selectedUser: IUser = null;
  selectedUsers: IUser[] = [];

  defaultImage: string = DEFAULT_AVATAR_URL;

  constructor(private modalController: ModalController,
    private translate: TranslateService,
    private uiService: UIService,
    private store: Store<AppState>) { }

  ngOnInit() { }

  onSelectUser(user: IUser) {
    this.onSelect.emit(user);
    this.onSingleSelect.emit(user);
  }

  async openInviteDetails(invitation: IInvitation) {
    if (invitation) {
      let modal = await this.modalController.create({
        component: InviteDetailsComponent,
        componentProps: {
          invitation: invitation
        },
        cssClass: "modal-auto-height modal-shadow"
      });

      modal.present();
    }
  }

  onClickCancelInvitation(invitation: IInvitation) {
    this.uiService.confirmModal(this.translate.instant("base.modal.confirm_title"), this.translate.instant("coach.athletes.inv_details.cancel_confirmation")).then(res => {
      if (res) {
        this.store.dispatch(new InvitationActions.CancelInviteAction(invitation.id));
      }
    })
  }

  onClickCancelAssignment(user: IUser) {
    this.uiService.confirmModal(this.translate.instant("coach.athletes.cancel_assignment"), this.translate.instant("coach.athletes.cancel_assignment_desc")).then(res => {
      if (res) {
        this.store.dispatch(new AthleteActions.CancelAssignmentAction(user.id));
      }
    })
  }

  async onFollowUpUserSelect(user: IUser) {
    if (user) {
      let modal = await this.modalController.create({
        component: FollowupComponent,
        componentProps: {
          athlete: user
        },
        cssClass: "modal-auto-height modal-shadow"
      });

      modal.present();
    }
  }

  onMultiUserSelect($event) {
    let checked = $event.detail.checked;
    let value = $event.detail.value;

    if (checked) {
      this.selectedUsers = this.selectedUsers.concat(value);
    }
    else {
      this.selectedUsers = this.selectedUsers.filter(x => x.id != value.id);
    }
    this.onMultiSelect.emit(this.selectedUsers);
  }
}

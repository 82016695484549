// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.on-inner-content-wrapper {
  height: 100%;
}

.form-wrapper {
  min-height: calc(100% - 136px);
}
.form-wrapper ion-label {
  font-family: var(--ons-font-light) !important;
  font-size: 14px;
}
.form-wrapper ion-input {
  border-bottom: 1px solid var(--ons-color-border);
}
.form-wrapper .label-floating.sc-ion-label-md-h {
  color: var(--ons-color-lighter-grey);
}

.bottom-buttons {
  position: sticky;
  bottom: 0;
  height: 136px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bottom-buttons ion-button {
  margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/training-unit/techniques-modal/add-techniq-modal/add-techniq-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AACA;EACI,8BAAA;AAEJ;AAAI;EACI,6CAAA;EACA,eAAA;AAER;AACI;EACI,gDAAA;AACR;AAEI;EACI,oCAAA;AAAR;;AAIA;EACI,gBAAA;EACA,SAAA;EACA,aAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;AADJ;AAGI;EACI,SAAA;AADR","sourcesContent":[".on-inner-content-wrapper {\n    height: 100%;\n}\n.form-wrapper {\n    min-height: calc(100% - 136px);\n\n    ion-label {\n        font-family: var(--ons-font-light) !important;\n        font-size: 14px;\n    }\n\n    ion-input {\n        border-bottom: 1px solid var(--ons-color-border);\n    }\n    \n    .label-floating.sc-ion-label-md-h {\n        color: var(--ons-color-lighter-grey);\n    }\n}\n\n.bottom-buttons {\n    position: sticky;\n    bottom: 0;\n    height: 136px;\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n\n    ion-button {\n        margin: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { ErrorAction, ErrorActionTypes } from "../actions/errors.actions";

export interface State {
  error: any;
}

const initialState: State = {
  error: null,
};

export const reducer: (state: State, action: ErrorAction) => State = (
  state = initialState,
  action: ErrorAction
) => {
  switch (action.type) {
    case ErrorActionTypes.ADD_ERROR:
      return { ...state, error: action.payload }
    case ErrorActionTypes.REMOVE_ERROR:
      return { ...state, error: null }
    default:
      return state;

  }
};

import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, withLatestFrom } from "rxjs/operators";
import { AnalyticsActions } from '../actions';
import { Store } from '@ngrx/store';
import { AppState } from '../app.states';
import { AnalyticsOptionsService } from '@services/analytics-options.service';
import { AnalyticsService } from '@services/analytics.service';
import { IPagingResult } from "@models/paging.model";
import { IBodyDataGroup, IMacroGroup } from "@models/analytics.model";

@Injectable()
export class AnalyticsEffects {
    constructor(
        private analyticsOptionsService: AnalyticsOptionsService,
        private analyticsService: AnalyticsService,
        private actions$: Actions,
        private store$: Store<AppState>
    ) { }

    loadAnalytics$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AnalyticsActions.LOAD_ANALYTICS),
            exhaustMap((action: AnalyticsActions.LoadAnalyticsAction) => {
                return this.analyticsService
                    .getAnalytics(action.payload)
                    .pipe(
                        map((res: any) => new AnalyticsActions.LoadAnalyticsSuccessAction(res)),
                        catchError((error) => of(new AnalyticsActions.LoadAnalyticsFailAction(error)))
                    )
            })
        )
    );

    loadBodyDataGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AnalyticsActions.LOAD_BODY_DATA_GROUPS),
            withLatestFrom(this.store$),
            filter(([action, storeState]) => {
                return !storeState?.analytics?.bodyDataGroups?.paging.currentPage;
            }),
            exhaustMap(() => {
                return this.analyticsOptionsService
                    .getBodyDatas()
                    .pipe(
                        map((payload: IPagingResult<IBodyDataGroup>) => new AnalyticsActions.LoadBodyDataGroupsSuccessAction(payload)),
                        catchError((error) => of(new AnalyticsActions.LoadBodyDataGroupsFailAction(error)))
                    )
            })
        )
    );
    loadBodyDataGroupsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AnalyticsActions.LOAD_BODY_DATA_GROUPS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [AnalyticsActions.LoadBodyDataGroupsPageAction, AppState]) => {
                return storeState?.analytics?.bodyDataGroups?.paging?.currentPage != 0 &&
                    (!action.page || storeState?.analytics?.bodyDataGroups?.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [AnalyticsActions.LoadBodyDataGroupsPageAction, AppState]) => {
                let page = !action.page ? storeState.analytics?.bodyDataGroups?.paging.currentPage + 1 : action.page;
                return this.analyticsOptionsService
                    .getBodyDatas(page, true)
                    .pipe(
                        map((payload: IPagingResult<IBodyDataGroup>) => new AnalyticsActions.LoadBodyDataGroupsPageSuccessAction(payload)),
                        catchError((error) => of(new AnalyticsActions.LoadBodyDataGroupsPageFailAction(error)))
                    )
            })
        )
    );

    loadMacroGroups$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AnalyticsActions.LOAD_MACRO_GROUPS),
            withLatestFrom(this.store$),
            filter(([action, storeState]) => {
                return !storeState?.analytics?.macroGroups?.paging.currentPage;
            }),
            exhaustMap(() => {
                return this.analyticsOptionsService
                    .getMacros()
                    .pipe(
                        map((res: IPagingResult<IMacroGroup>) => new AnalyticsActions.LoadMacroGroupsSuccessAction(res)),
                        catchError((error) => of(new AnalyticsActions.LoadMacroGroupsFailAction(error)))
                    )
            })
        )
    );
    loadMacroGroupsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AnalyticsActions.LOAD_MACRO_GROUPS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [AnalyticsActions.LoadMacroGroupsPageAction, AppState]) => {
                return storeState?.analytics?.macroGroups?.paging?.currentPage != 0 &&
                    (!action.page || storeState?.analytics?.macroGroups?.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [AnalyticsActions.LoadMacroGroupsPageAction, AppState]) => {
                let page = !action.page ? storeState.analytics?.macroGroups?.paging.currentPage + 1 : action.page;
                return this.analyticsOptionsService
                    .getMacros(page, true)
                    .pipe(
                        map((payload: IPagingResult<IMacroGroup>) => new AnalyticsActions.LoadMacroGroupsPageSuccessAction(payload)),
                        catchError((error) => of(new AnalyticsActions.LoadMacroGroupsPageFailAction(error)))
                    )
            })
        )
    );
}

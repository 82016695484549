import { INote, INoteFilter, INoteRequest, ISideNote } from '@models/note.model';
import { IPagingResult } from '@models/paging.model';
import { Action } from '@ngrx/store';

export const SET_INITIAL = '[Note] Set initial state';

export const LOAD_NOTES = '[Note] Load Notes';
export const LOAD_NOTES_SUCCESS = '[Note] Load Notes Success';
export const LOAD_NOTES_FAIL = '[Note] Load Notes Fail';
export const LOAD_NOTES_PAGE = '[Note] Load Notes Page';
export const LOAD_NOTES_PAGE_SUCCESS = '[Note] Load Notes Page Success';
export const LOAD_NOTES_PAGE_FAIL = '[Note] Load Notes Page Fail';

export const LOAD_NOTE = '[Note] Load Note';
export const LOAD_NOTE_SUCCESS = '[Note] Load Note Success';
export const LOAD_NOTE_FAIL = '[Note] Load Note Fail';
export const ADD_NOTE = '[Note] Add Note';
export const ADD_NOTE_SUCCESS = '[Note] Add Note Success';
export const ADD_NOTE_FAIL = '[Note] Add Note Fail';
export const EDIT_NOTE = '[Note] Edit Note';
export const EDIT_NOTE_SUCCESS = '[Note] Edit Note Success';
export const EDIT_NOTE_FAIL = '[Note] Edit Note Fail';
export const REMOVE_NOTE = '[Note] Remove Note';
export const REMOVE_NOTE_SUCCESS = '[Note] Remove Note Success';
export const REMOVE_NOTE_FAIL = '[Note] Remove Note Fail';

export const LOAD_SIDE_NOTES = '[Note] Load Side Notes';
export const LOAD_SIDE_NOTES_SUCCESS = '[Note] Load Side Notes Success';
export const LOAD_SIDE_NOTES_FAIL = '[Note] Load Side Notes Fail';
export const ADD_SIDE_NOTE = '[Note] Add Side Note';
export const ADD_SIDE_NOTE_SUCCESS = '[Note] Add Side Note Success';
export const ADD_SIDE_NOTE_FAIL = '[Note] Add Side Note Fail';
export const EDIT_SIDE_NOTE = '[Note] Edit Side Note';
export const EDIT_SIDE_NOTE_SUCCESS = '[Note] Edit Side Note Success';
export const EDIT_SIDE_NOTE_FAIL = '[Note] Edit Side Note Fail';
export const REMOVE_SIDE_NOTE = '[Note] Remove Side Note';
export const REMOVE_SIDE_NOTE_SUCCESS = '[Note] Remove Side Note Success';
export const REMOVE_SIDE_NOTE_FAIL = '[Note] Remove Side Note Fail';


/**
 * Set initial
 */
export class SetInitialAction implements Action {
    readonly type = SET_INITIAL;
}

/**
 * Load Notes Actions
 */
export class LoadNotesAction implements Action {
    readonly type = LOAD_NOTES;
    constructor(public payload: INoteFilter, public force: boolean = false) { }
}
export class LoadNotesSuccessAction implements Action {
    readonly type = LOAD_NOTES_SUCCESS;
    constructor(public payload: IPagingResult<INote>) { }
}
export class LoadNotesFailAction implements Action {
    readonly type = LOAD_NOTES_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Notes Page Actions
 */
export class LoadNotesPageAction implements Action {
    readonly type = LOAD_NOTES_PAGE;
    constructor(public page: number = null) { }
}
export class LoadNotesPageSuccessAction implements Action {
    readonly type = LOAD_NOTES_PAGE_SUCCESS;
    constructor(public payload: IPagingResult<INote>) { }
}
export class LoadNotesPageFailAction implements Action {
    readonly type = LOAD_NOTES_PAGE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Load Note Actions
 */
export class LoadNoteAction implements Action {
    readonly type = LOAD_NOTE;
    constructor(public id: number) { }
}
export class LoadNoteSuccessAction implements Action {
    readonly type = LOAD_NOTE_SUCCESS;
    constructor(public payload: INote) { }
}
export class LoadNoteFailAction implements Action {
    readonly type = LOAD_NOTE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Add Note Actions
 */
export class AddNoteAction implements Action {
    readonly type = ADD_NOTE;
    constructor(public payload: INoteRequest) { }
}
export class AddNoteSuccessAction implements Action {
    readonly type = ADD_NOTE_SUCCESS;
    constructor(public payload: INote) { }
}
export class AddNoteFailAction implements Action {
    readonly type = ADD_NOTE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Note Actions
 */
export class EditNoteAction implements Action {
    readonly type = EDIT_NOTE;
    constructor(public payload: INoteRequest) { }
}
export class EditNoteSuccessAction implements Action {
    readonly type = EDIT_NOTE_SUCCESS;
    constructor(public payload: INote) { }
}
export class EditNoteFailAction implements Action {
    readonly type = EDIT_NOTE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Remove Note Actions
 */
export class RemoveNoteAction implements Action {
    readonly type = REMOVE_NOTE;
    constructor(public payload: INote) { }
}
export class RemoveNoteSuccessAction implements Action {
    readonly type = REMOVE_NOTE_SUCCESS;
    constructor(public payload: INote) { }
}
export class RemoveNoteFailAction implements Action {
    readonly type = REMOVE_NOTE_FAIL;
    constructor(public payload: any) { }
}


/**
 * Load Side Notes Actions
 */
export class LoadSideNotesAction implements Action {
    readonly type = LOAD_SIDE_NOTES;
}
export class LoadSideNotesSuccessAction implements Action {
    readonly type = LOAD_SIDE_NOTES_SUCCESS;
    constructor(public payload: ISideNote[]) { }
}
export class LoadSideNotesFailAction implements Action {
    readonly type = LOAD_SIDE_NOTES_FAIL;
    constructor(public payload: any) { }
}
/**
 * Add Side Note Actions
 */
export class AddSideNoteAction implements Action {
    readonly type = ADD_SIDE_NOTE;
    constructor(public payload: ISideNote) { }
}
export class AddSideNoteSuccessAction implements Action {
    readonly type = ADD_SIDE_NOTE_SUCCESS;
    constructor(public payload: ISideNote) { }
}
export class AddSideNoteFailAction implements Action {
    readonly type = ADD_SIDE_NOTE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Side Note Actions
 */
export class EditSideNoteAction implements Action {
    readonly type = EDIT_SIDE_NOTE;
    constructor(public payload: ISideNote) { }
}
export class EditSideNoteSuccessAction implements Action {
    readonly type = EDIT_SIDE_NOTE_SUCCESS;
    constructor(public payload: ISideNote) { }
}
export class EditSideNoteFailAction implements Action {
    readonly type = EDIT_SIDE_NOTE_FAIL;
    constructor(public payload: any) { }
}
/**
 * Remove Side Note Actions
 */
export class RemoveSideNoteAction implements Action {
    readonly type = REMOVE_SIDE_NOTE;
    constructor(public payload: ISideNote) { }
}
export class RemoveSideNoteSuccessAction implements Action {
    readonly type = REMOVE_SIDE_NOTE_SUCCESS;
    constructor(public payload: ISideNote) { }
}
export class RemoveSideNoteFailAction implements Action {
    readonly type = REMOVE_SIDE_NOTE_FAIL;
    constructor(public payload: any) { }
}
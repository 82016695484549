import { Injectable, OnDestroy } from "@angular/core";
import { IAthleteTraining } from '@models/athlete-training.model';
import { BehaviorSubject, Subscription } from 'rxjs';
import * as moment from 'moment';
import { LocalStorageService } from './local-storage.service';
import { AppState } from '../store/app.states';
import { Store } from '@ngrx/store';
import { AppReducers } from '../store';

export const LOCAL_STORAGE_KEYS = {
    activeTraining: 'athlete_active_training'
};

export interface IAthleteActiveTrainingInfo {
    training: IAthleteTraining,
    sheetNo: number,
    startTime?: moment.Moment,
    completed?: boolean
}

@Injectable({
    providedIn: "root",
})
export class AthleteActiveTrainingService implements OnDestroy {
    private _active: IAthleteActiveTrainingInfo = null;
    active$: BehaviorSubject<IAthleteActiveTrainingInfo> = new BehaviorSubject<IAthleteActiveTrainingInfo>(null);
    subs: Subscription[] = [];

    get active() {
        return this._active;
    }
    set active(val: IAthleteActiveTrainingInfo) {
        this.unsubscribe();

        this._active = val;
        this.propagateActive();

        if (val) {
            this.subs[this.subs.length] = this.store.select(AppReducers.getAthleteTrainingByDate, { date: val.training.day })
                .subscribe(data => {
                    if (data) {
                        this._active.training = data;
                        this.propagateActive();
                    }
                });
        }
    }

    constructor(private localStorageService: LocalStorageService,
        private store: Store<AppState>) { }

    propagateActive() {
        this.active$.next(this._active);
        this.localStorageService.set(LOCAL_STORAGE_KEYS.activeTraining, this._active);
    }

    init() {
        this.active = this.localStorageService.get(LOCAL_STORAGE_KEYS.activeTraining);
    }

    clear() {
        this.unsubscribe();
        this._active = null;
        this.propagateActive();
    }

    start(val: IAthleteActiveTrainingInfo) {
        val.startTime = val.startTime ? val.startTime : moment();
        this.active = val;
    }

    unsubscribe() {
        this.subs.forEach(sub => sub.unsubscribe());
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }
}
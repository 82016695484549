import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { of } from "rxjs";
import { catchError, exhaustMap, filter, map, withLatestFrom } from "rxjs/operators";
import { DocumentActions } from "../actions";
import { S3FileUploadService } from '@services/s3.upload.service';
import { DocumentService } from '@services/document.service';
import { AppState } from '../app.states';
import { Store } from '@ngrx/store';
import { IPagingResult } from "@models/paging.model";
import { IDocument } from "@models/document.model";

@Injectable()
export class DocumentEffects {
    constructor(
        private documentService: DocumentService,
        private s3Service: S3FileUploadService,
        private actions$: Actions,
        private store$: Store<AppState>
    ) { }

    loadDocuments$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.LOAD_DOCUMENTS),
            withLatestFrom(this.store$),
            filter(([action, storeState]) => {
                return !storeState?.document?.paging.currentPage;
            }),
            exhaustMap((action) =>
                this.documentService
                    .getAll()
                    .pipe(
                        map((res: any) => new DocumentActions.LoadDocumentsSuccessAction(res)),
                        catchError((error) => of(new DocumentActions.LoadDocumentsFailAction(error)))
                    )
            )
        )
    );
    loadDocumentsPage$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.LOAD_DOCUMENTS_PAGE),
            withLatestFrom(this.store$),
            filter(([action, storeState]: [DocumentActions.LoadDocumentsPageAction, AppState]) => {
                return storeState?.document?.paging?.currentPage != 0 && (!action.page || storeState?.document.paging?.currentPage < action.page);
            }),
            exhaustMap(([action, storeState]: [DocumentActions.LoadDocumentsPageAction, AppState]) => {
                let page = !action.page ? storeState.document.paging.currentPage + 1 : action.page;
                return this.documentService
                    .getAll(page, true)
                    .pipe(
                        map((pagingResult: IPagingResult<IDocument>) => new DocumentActions.LoadDocumentsPageSuccessAction(pagingResult)),
                        catchError((error) => of(new DocumentActions.LoadDocumentsPageFailAction(error)))
                    )
            })
        )
    );

    s3UploadDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.S3_UPLOAD_DOCUMENT),
            exhaustMap((action: DocumentActions.S3UploadDocumentAction) =>
                this.s3Service
                    .uploadDocument(action.payload.dataUrl, action.payload.name)
                    .pipe(
                        map((res: any) => new DocumentActions.S3UploadDocumentSuccessAction({ url: res.uri, name: res.filename })),
                        catchError((error) => of(new DocumentActions.S3UploadDocumentFailAction(error)))
                    )
            )
        )
    );

    addDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.S3_UPLOAD_DOCUMENT_SUCCESS),
            exhaustMap((action: any) =>
                this.documentService
                    .add(action.payload.name, action.payload.url)
                    .pipe(
                        map((res: any) => {
                            return new DocumentActions.AddDocumentSuccessAction(res.data[0]);
                        }),
                        catchError((error) => of(new DocumentActions.AddDocumentFailAction(error)))
                    )
            )
        )
    );

    removeDocument$ = createEffect(() =>
        this.actions$.pipe(
            ofType(DocumentActions.REMOVE_DOCUMENT),
            exhaustMap((action: DocumentActions.RemoveDocumentAction) =>
                this.documentService
                    .delete(action.payload.id)
                    .pipe(
                        map((res: any) => {
                            return new DocumentActions.RemoveDocumentSuccessAction(action.payload);
                        }),
                        catchError((error) => of(new DocumentActions.RemoveDocumentFailAction(error)))
                    )
            )
        )
    );
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.slider-container {
  position: relative;
  width: 100%;
  padding-top: 30px;
}
.slider-container .custom-pin {
  font-size: 15px;
  top: 7px;
  position: absolute;
  text-align: center;
  height: 25px;
  z-index: 3;
  border-bottom: 2px solid var(--ons-color-lighter-grey);
  padding: 0 5px;
  display: flex;
  align-items: center;
}
.slider-container .custom-pin input {
  width: 45px;
  text-align: center;
  border: none;
  background-color: transparent;
}
.slider-container ion-range {
  padding-bottom: 0;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/slider/slider.component.scss"],"names":[],"mappings":"AAAA,gCAAA;AACA;;EAEE,wBAAA;EACA,SAAA;AACF;;AAEA,YAAA;AACA;EACE,0BAAA;AACF;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,iBAAA;AACJ;AACI;EACI,eAAA;EACA,QAAA;EACA,kBAAA;EACA,kBAAA;EACA,YAAA;EACA,UAAA;EACA,sDAAA;EACA,cAAA;EACA,aAAA;EACA,mBAAA;AACR;AACQ;EACI,WAAA;EACA,kBAAA;EACA,YAAA;EACA,6BAAA;AACZ;AAGI;EACI,iBAAA;AADR","sourcesContent":["/* Chrome, Safari, Edge, Opera */\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n\n/* Firefox */\ninput[type=number] {\n  -moz-appearance: textfield;\n}\n\n.slider-container {\n    position: relative;\n    width: 100%;\n    padding-top: 30px;\n\n    .custom-pin {\n        font-size: 15px;\n        top: 7px;\n        position: absolute;\n        text-align: center;\n        height: 25px;\n        z-index: 3;\n        border-bottom: 2px solid var(--ons-color-lighter-grey);\n        padding: 0 5px;\n        display: flex;\n        align-items: center;\n\n        input {\n            width: 45px;\n            text-align: center;\n            border: none;\n            background-color: transparent;\n        }\n    }\n\n    ion-range {\n        padding-bottom: 0;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { UserRole } from '@models/authentication.model';
import { IPagingResult } from '@models/paging.model';
import { IMacro } from '@models/progress.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../helpers/http.helper';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: "root",
})
export class MacroService {
    apiPath: string = 'macros';

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    getAll(page: number = 1, withoutLoading = false): Observable<IPagingResult<IMacro>> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let authQuery = userRole == UserRole.athlete ? 'athlete_id' : 'coach_id';
        authQuery += `=${userId}`;
        let pageQuery = `&page=${page}`;

        let http = withoutLoading ? this.http.disableLoading() : this.http;
        return http.get(`/${this.apiPath}?${authQuery}${pageQuery}&with_shared=1`, { observe: 'response' }).pipe(
            map((response: any) => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    get(id: number, detailId: number | null): Observable<IMacro> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let query = userRole == UserRole.coach ?
            `coach_id=${userId}` :
            `athlete_id=${userId}`;
        if (detailId) {
            query += `&detail_id=${detailId}`;
        }
        return this.http.get(`/${this.apiPath}/${id}?${query}&with_shared=1`).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data[0];
                } else {
                    throw response.message;
                }
            })
        );
    }

    add(model: IMacro) {
        let macro = {
            ...model,
            deviation: JSON.stringify(model?.macro_detail?.deviation),
        };
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        if (userRole == UserRole.athlete) {
            macro.athlete_id = userId;
        }
        else {
            macro.coach_id = userId;
        }

        return this.http.post(`/${this.apiPath}`, macro);
    }

    edit(model: IMacro) {
        let macro = {
            ...model,
            deviation: JSON.stringify(model?.macro_detail?.deviation),
            macro_detail: undefined
        };
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        if (userRole == UserRole.athlete) {
            macro.athlete_id = userId;
        }
        else {
            macro.coach_id = userId;
        }

        return this.http.patch(`/${this.apiPath}/${macro.id}?with_shared=1`, macro);
    }

    delete(id: number) {
        return this.http.delete(`/${this.apiPath}/${id}`)
    }
}
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHelper } from '../helpers/http.helper';
import { INotificationPagingResult } from '@models/notificaton.model';


@Injectable({
    providedIn: "root",
})
export class NotificationService {
    apiPath: string = 'notifications';

    constructor(private http: HttpClient) { }

    get(page: number = 1, withoutLoading = false): Observable<INotificationPagingResult> {
        let query = `?filter[sort]=desc`;

        let pageQuery = (query ? '&' : '') + `page=${page}`;

        let http = this.http;
        if (withoutLoading) {
            http = http.disableLoading();
        }

        return http.disableErrorHandler().get(`/${this.apiPath}${query}${pageQuery}`, { observe: 'response' }).pipe(
            map((response: HttpResponse<any>): INotificationPagingResult => {
                if (response?.body?.status === "success" && response?.body?.data[0]) {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data[0].notifications,
                        unread_count: response.body.data[0].unread_count,
                        paging: { ...paging }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    markAsRead(ids: number[]): Observable<any> {
        return this.http.patch(`/${this.apiPath}`, { ids: ids });
    }
}

export interface IFreeSubItem {
    id: string;
    title: string;
    desc: string;
    price: string;
}

/**
 * Covers CdvPurchase.Product, a free subscription and stripe subscription
 */
export class AppSubscription {
    id: string;
    price: string;

    // stripe related fields
    productId?: string;
    priceId?: string;

    // paypal related fields
    paypal_id?: string;
    

    constructor(id: string, price: string, priceId: string = '', productId: string = '', payPalId: string = '') {
        this.id = id;
        this.price = price;
        this.priceId = priceId;
        this.productId = productId;
        this.paypal_id = payPalId;
    }
}
// export class AppSubscription extends CdvPurchase.Product {
//     constructor(id: string, title: string, description: string, price: string, priceId: string = '', productId: string = '', payPalId: string = '') {
//         this.id = id;
//         this.title = title;
//         this.description = description;
//         this.price = price;
//         this.priceId = priceId;
//         this.productId = productId;
//         this.paypal_id = payPalId;
//     }

//     priceId: string;
//     productId: string;
//     paypal_id: string;
//     price: string;

//     className: 'Product';
//     /** Platform this product is available from */
//     platform: CdvPurchase.Platform;
//     /** Type of product (subscription, consumable, etc.) */
//     type: CdvPurchase.ProductType;
//     /** Product identifier on the store (unique per platform) */
//     id: string;
//     /** List of offers available for this product */
//     offers: CdvPurchase.Offer[];
//     /** Product title from the store. */
//     title: string;
//     /** Product full description from the store. */
//     description: string;
    
//     pricing: CdvPurchase.PricingPhase | undefined;

//     canPurchase: boolean;

//     owned: boolean;

//     getOffer(id?: string): CdvPurchase.Offer | undefined {

//     }

//     addOffer(offer: CdvPurchase.Offer): this;

//     // pricing(action: string, args: any): void {
//     //     throw new Error('Method not implemented.');
//     // }

//     // priceId: string;
//     // productId: string;

//     // paypal_id: string;

//     // id: string;
//     // alias?: string;
//     // // type: string;
//     // state: string;
//     // title: string;
//     // description: string;
//     // priceMicros: number;
//     // price: string;
//     // currency: string;
//     // loaded: boolean;
//     // valid: boolean;
//     // canPurchase: boolean;
//     // owned: boolean;
//     // downloading?: boolean;
//     // downloaded?: boolean;
//     // lastRenewalDate?: Date;
//     // expiryDate?: Date;
//     // introPrice?: string;
//     // introPriceMicros?: number;
//     // introPriceNumberOfPeriods?: number;
//     // introPriceSubscriptionPeriod?: string;
//     // introPricePaymentMode?: string;
//     // ineligibleForIntroPrice?: boolean;
//     // billingPeriod?: number;
//     // billingPeriodUnit?: string;
//     // trialPeriod?: number;
//     // trialPeriodUnit?: string;
//     // additionalData?: any;
//     // transaction?: any;

//     finish(): void {
//         throw new Error('Method not implemented.');
//     }
//     verify() {
//         throw new Error('Method not implemented.');
//     }
//     set(key: string, value: any): void {
//         throw new Error('Method not implemented.');
//     }
//     stateChanged(): void {
//         throw new Error('Method not implemented.');
//     }
//     on(event: string, callback: Function): void {
//         throw new Error('Method not implemented.');
//     }
//     once(event: string, callback: Function): void {
//         throw new Error('Method not implemented.');
//     }
//     off(callback: Function): void {
//         throw new Error('Method not implemented.');
//     }
//     trigger(action: string, args: any): void {
//         throw new Error('Method not implemented.');
//     }

// }
// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.unit {
  padding: 10px 15px;
  color: var(--ons-color-black-2);
}
.unit .unit-header {
  font-size: 22px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.unit .unit-header .unit-name {
  font-family: var(--ons-font-bold);
}
.unit .unit-set {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.unit .unit-set-header {
  font-family: var(--ons-font-bold);
}
.unit .unit-set .unit-set-col {
  width: 100%;
  font-size: 12px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 4px;
  flex-wrap: wrap;
  line-height: 1;
}
.unit .unit-set .unit-set-col:first-of-type {
  width: 50%;
  padding-left: 0;
}
.unit .unit-set .unit-set-col:last-of-type {
  max-width: 20px;
  font-size: 16px;
  padding-right: 0;
  color: var(--ons-color-green);
}
.unit .unit-set .unit-set-col small {
  color: var(--ons-color-grey);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/training-plan-overview/training-plan-overview-sheet/training-plan-overview-unit/training-plan-overview-unit-change-modal/training-plan-overview-unit-change-modal.component.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,+BAAA;AADJ;AAII;EACI,eAAA;EACA,mBAAA;EACA,aAAA;EACA,8BAAA;EACA,mBAAA;AAFR;AAIQ;EACI,iCAAA;AAFZ;AAMI;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;AAJR;AAMQ;EACI,iCAAA;AAJZ;AAOQ;EACI,WAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,cAAA;EACA,eAAA;EACA,cAAA;AALZ;AAOY;EACI,UAAA;EACA,eAAA;AALhB;AAQY;EACI,eAAA;EACA,eAAA;EACA,gBAAA;EACA,6BAAA;AANhB;AASY;EACI,4BAAA;AAPhB","sourcesContent":["\n\n.unit {\n    padding: 10px 15px;\n    color: var(--ons-color-black-2);\n    \n\n    .unit-header {\n        font-size: 22px;\n        margin-bottom: 20px;\n        display: flex;\n        justify-content: space-between;\n        align-items: center;\n\n        .unit-name {\n            font-family: var(--ons-font-bold);\n        }\n    }\n\n    .unit-set {\n        display: flex;\n        justify-content: space-between;\n        margin-bottom: 15px;\n\n        &-header {\n            font-family: var(--ons-font-bold);\n        }\n\n        .unit-set-col {\n            width: 100%;\n            font-size: 12px;\n            text-align: center;\n            display: flex;\n            align-items: center;\n            justify-content: center;\n            padding: 0 4px;\n            flex-wrap: wrap;\n            line-height: 1;\n\n            &:first-of-type {\n                width: 50%;\n                padding-left: 0;\n            }\n\n            &:last-of-type {\n                max-width: 20px;\n                font-size: 16px;\n                padding-right: 0;\n                color: var(--ons-color-green);\n            }\n\n            small {\n                color: var(--ons-color-grey);\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

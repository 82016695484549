import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ITrainingCategory, IUnitFilter } from '@models/training.model';
import { Store } from '@ngrx/store';
import { DEFAULT_UNIT_FILTER } from 'app/core/consts/default-unit-filter.const';
import { AppReducers } from 'app/core/store';
import { TrainingActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-filter-units',
  templateUrl: './filter-units.component.html',
  styleUrls: ['./filter-units.component.scss'],
})
export class FilterUnitsComponent implements OnInit, OnDestroy {
  @Input() filter: IUnitFilter = DEFAULT_UNIT_FILTER;
  allCategories: ITrainingCategory[];
  subs: Subscription[] = [];

  constructor(private modalController: ModalController,
    private store: Store<AppState>) { }

  ngOnInit() {
    this.filter = { ...this.filter };
    this.subs[this.subs.length] = this.store.select(AppReducers.getTrainingCategoriesLoaded)
      .subscribe(hasLoaded => {
        if (!hasLoaded) this.store.dispatch(new TrainingActions.LoadCategoriesAction());
      });
    this.subs[this.subs.length] = this.store.select(AppReducers.getTrainingCategoriesCollection)
      .subscribe(data => {
        this.allCategories = data;
      });
  }
  onSelectCategory($event) {
    let checked = $event.detail.checked;
    let value = $event.detail.value;

    if (checked) {
      this.filter = {
        ...this.filter,
        categories: [...this.filter.categories].concat(value)
      };
    }
    else {
      this.filter = {
        ...this.filter,
        categories: [...this.filter.categories].filter(x => x != value)
      };
    }
  }

  checkIfCategoryActive(category) {
    return this.filter.categories?.some(x => x == category);
  }

  onSave() {
    this.modalController.dismiss(this.filter);
  }
  close() {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

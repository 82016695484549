import { Observable } from 'rxjs';

export function addScriptHelper(url: string): Observable<void> {
  return new Observable(subscriber => {
    const scripts = document.querySelectorAll('script');
    let alreadyExist: boolean = false;
    scripts.forEach(script => {
      if (script.src == url) {
        alreadyExist = true;
      }
    });

    if (!alreadyExist) {
      const script = document.createElement('script');
      const body = document.querySelector('body');
      script.src = url;
      script.type = 'text/javascript';

      script.onload = () => {
        subscriber.next();
        subscriber.complete();
      };

      body?.appendChild(script);
    }
    else {
      subscriber.next();
      subscriber.complete();
    }
  });
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { UserRole } from '@models/authentication.model';
import { IPagingResult } from '@models/paging.model';
import { ITrainingUnit, IUnitFilter } from '@models/training.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DEFAULT_UNIT_FILTER } from '../consts/default-unit-filter.const';
import { SortDirection } from '../enums/sort-direction.enum';
import { HttpHelper } from '../helpers/http.helper';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: "root",
})
export class TrainingUnitService {
    apiPath: string = 'training_units';

    constructor(private http: HttpClient,
        private authService: AuthService) { }

    getAll(filter: IUnitFilter = DEFAULT_UNIT_FILTER, page: number = 1, withoutLoading = false): Observable<IPagingResult<ITrainingUnit>> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let authQuery = userRole == UserRole.athlete ? 'athlete_id' : 'coach_id';
        authQuery += `=${userId}`;

        let filterQuery = ``;
        if (filter?.categories?.length > 0) {
            filterQuery += `&filter[category]=${filter.categories}`;
        }
        if (filter?.search) {
            filterQuery += `&filter[name]=${filter.search}`;
        }

        let pageQuery = `&page=${page}`;

        let http = this.http;
        if (withoutLoading) {
            http = http.disableLoading();
        }

        let sortQuery = `&sort=${filter.sortOrder == SortDirection.DESC ? '-' : ''}`;

        return http.get(`/${this.apiPath}?${authQuery}${filterQuery}${sortQuery}name&with_categories=1${pageQuery}`, { observe: 'response' }).pipe(
            map((response: HttpResponse<any>): IPagingResult<ITrainingUnit> => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: {
                            ...paging,
                            filter: JSON.stringify(filter)
                        }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    get(id: number, unitDataId: number): Observable<ITrainingUnit> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let query = userRole == UserRole.coach ?
            `coach_id=${userId}` :
            `athlete_id=${userId}`;
        if (unitDataId) {
            query += `&unit_data_id=${unitDataId}`;
        }
        return this.http.get(`/${this.apiPath}/${id}?${query}&with_categories=1`).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data[0];
                } else {
                    throw response.message;
                }
            })
        );
    }

    add(model: ITrainingUnit) {
        let unit = { ...model };
        let userId = this.authService.getUserId();

        let userRole = this.authService.getUserRole();
        if (userRole == UserRole.athlete) {
            unit.athlete_id = userId;
        }
        else {
            unit.coach_id = userId;
        }

        return this.http.post(`/${this.apiPath}`, unit);
    }

    edit(model: ITrainingUnit) {
        let unit = { ...model };
        return this.http.patch(`/${this.apiPath}/${unit.id}?&with_categories=1`, unit);
    }

    delete(id: number) {
        return this.http.delete(`/${this.apiPath}/${id}`)
    }
}
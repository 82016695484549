import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { ProgressActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-body-data-infinity-scroll',
  templateUrl: './body-data-infinity-scroll.component.html',
  styleUrls: ['./body-data-infinity-scroll.component.scss'],
})
export class BodyDataInfinityScrollComponent {
  selectPaging = AppReducers.getBodyDatasPaging;
  loadPageSuccessAction: string = ProgressActions.LOAD_BODY_DATAS_PAGE_SUCCESS;
  loadPageFailAction: string = ProgressActions.LOAD_BODY_DATAS_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  loadPageAction() {
    this.store.dispatch(new ProgressActions.LoadBodyDatasPageAction());
  }
}

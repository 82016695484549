// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input-item {
  color: white;
  position: relative;
  background: transparent;
  border-width: 0 0 2px 0;
  border-color: var(--ons-color-lighter-grey);
  font-family: var(--ons-font-medium);
  font-weight: 500;
  --padding-start: 0;
  font-size: 16px;
  height: 84px;
}
.input-item.textarea-item {
  height: auto;
}
.input-item.ion-touched.ion-invalid {
  border-color: var(--ons-color-red);
}
.input-item.ion-touched.ion-valid {
  border-color: var(--ons-color-primary);
}
.input-item.ion-touched.ion-valid .show-password {
  color: var(--ons-color-primary);
}
.input-item ion-icon {
  margin-top: 34px;
  margin-right: 0;
  position: absolute;
  bottom: 38px;
  right: 15px;
  font-size: 20px;
  z-index: 100;
  color: var(--ons-color-primary);
}
.input-item ion-icon.checkmark {
  color: var(--ons-color-primary);
}
.input-item ion-label {
  font-size: 14px;
  opacity: 0.4;
  font-family: var(--ons-font-light);
  --color: #fff;
  color: #fff !important;
}
.input-item ion-input {
  border-bottom: 2px solid #fff;
}
.input-item.ion-valid.ion-dirty ion-input {
  border-bottom: 2px solid var(--ons-color-green);
}
.input-item.ion-invalid.ion-dirty ion-input {
  border-bottom: 2px solid var(--ons-color-red);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/auth/components/login-form/login-form.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EAEA,uBAAA;EACA,uBAAA;EACA,2CAAA;EACA,mCAAA;EACA,gBAAA;EAiBA,kBAAA;EAEA,eAAA;EACA,YAAA;AAjBF;AAFE;EACE,YAAA;AAIJ;AADI;EACE,kCAAA;AAGN;AADI;EACE,sCAAA;AAGN;AADM;EACE,+BAAA;AAGR;AAOE;EACE,gBAAA;EACA,eAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,eAAA;EACA,YAAA;EAEA,+BAAA;AANJ;AAOI;EACE,+BAAA;AALN;AASE;EACE,eAAA;EACA,YAAA;EACA,kCAAA;EACA,aAAA;EACA,sBAAA;AAPJ;AAUE;EACE,6BAAA;AARJ;AAYI;EACE,+CAAA;AAVN;AAcI;EACE,6CAAA;AAZN","sourcesContent":[".input-item {\n  color: white;\n  position: relative;\n\n  background: transparent;\n  border-width: 0 0 2px 0;\n  border-color: var(--ons-color-lighter-grey);\n  font-family: var(--ons-font-medium);\n  font-weight: 500;\n  &.textarea-item {\n    height: auto;\n  }\n  &.ion-touched {\n    &.ion-invalid {\n      border-color: var(--ons-color-red);\n    }\n    &.ion-valid {\n      border-color: var(--ons-color-primary);\n\n      .show-password {\n        color: var(--ons-color-primary);\n      }\n    }\n  }\n\n  --padding-start: 0;\n  // --color: white;\n  font-size: 16px;\n  height: 84px;\n\n  ion-icon {\n    margin-top: 34px;\n    margin-right: 0;\n    position: absolute;\n    bottom: 38px;\n    right: 15px;\n    font-size: 20px;\n    z-index: 100;\n\n    color: var(--ons-color-primary);\n    &.checkmark {\n      color: var(--ons-color-primary);\n    }\n  }\n\n  ion-label {\n    font-size: 14px;\n    opacity: 0.4;\n    font-family: var(--ons-font-light);\n    --color: #fff;\n    color: #fff !important;\n  }\n\n  ion-input {\n    border-bottom: 2px solid #fff;\n  }\n\n  &.ion-valid.ion-dirty {\n    ion-input {\n      border-bottom: 2px solid var(--ons-color-green);\n    }\n  }\n  &.ion-invalid.ion-dirty {\n    ion-input {\n      border-bottom: 2px solid var(--ons-color-red);\n    }\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

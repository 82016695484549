import { HttpClient } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { NavController } from '@ionic/angular';
import { IUpdateCoachUser, IUpdateAthleteUser } from "@models/user.model";
import { UIService } from "@services/ui.service";
import { map } from "rxjs/operators";
import { AuthService } from './auth.service';
import { ThemeService } from './theme.service';

@Injectable({
  providedIn: "root",
})
export class UserService {
  apiPath: string = 'users';

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private uiService: UIService,
    private navCtrl: NavController,
    private themeService: ThemeService
  ) { }

  updateUser(user: IUpdateCoachUser) {

    return this.http.patch(`/${this.apiPath}/${user.id}`, user).pipe(
      map((response: any) => {
        if (response?.status === "success") {
          this.uiService.showMessage("Profil aktualisiert", 'success', 2000);
          this.themeService.toggleTheme(response.data[0]?.color_schema);
          return response.data[0];
        } else {
          throw response.message;
        }
      })
    );
  }

  updateAthleteUser(user: IUpdateAthleteUser) {

    return this.http.patch(`/${this.apiPath}/${user.id}`, user).pipe(
      map((response: any) => {
        if (response?.status === "success") {
          this.uiService.showMessage("Profil aktualisiert", 'success', 2000);
          return response.data[0];
        } else {
          throw response.message;
        }
      })
    );
  }

  deleteUser() {
    let userId = this.authService.getUserId();
    return this.http.delete(`/${this.apiPath}/${userId}`).pipe(
      map((response: any) => {
        if (response?.status === "success") {
          this.authService.logout();
          this.navCtrl.navigateRoot('/login', { animated: true, animationDirection: 'forward' });
          return response.data[0];
        } else {
          throw response.message;
        }
      })
    );
  }
}

import { Injectable } from '@angular/core';
import { THEME_LIST } from '../consts/theme-list.const';

@Injectable({
    providedIn: 'root'
})

export class ThemeService {
    themeList = THEME_LIST;

    toggleTheme(themeName: string): void {
        let theme = this.themeList.find(x => x.name == themeName);
        theme = theme ? theme : THEME_LIST[2];
        document.documentElement.style.setProperty(`--ons-color-primary`, theme.color);
    }
}

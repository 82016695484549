import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocalStorageService } from '@services/local-storage.service';
import { EulaModalComponent } from '../eula-modal/eula-modal.component';
import { PolicyModalComponent } from '../policy-modal/policy-modal.component';

@Component({
  selector: 'app-overview-policies-modal',
  templateUrl: './overview-policies-modal.component.html',
  styleUrls: ['./overview-policies-modal.component.scss'],
})
export class OverviewPoliciesModalComponent implements OnInit {
  isEULAAccepted: boolean = false;
  isPolicyAccepted: boolean = false;

  constructor(private lsService: LocalStorageService,
    private modalController: ModalController) { }

  ngOnInit() {
    this.isEULAAccepted = this.lsService.getEULA();
    this.isPolicyAccepted = this.lsService.getPolicy();
  }

  openEULA() {
    this.modalController.dismiss();
    this.modalController.create({
      component: EulaModalComponent,
      cssClass: "modal-shadow"
    }).then(modal => {
      modal.present();

      modal.onWillDismiss().then(data => {
        this.lsService.setEULA(!!data?.data);
        this.reOpen();
      })
    });
  }

  openPolicy() {
    this.modalController.dismiss();
    this.modalController.create({
      component: PolicyModalComponent,
      cssClass: "modal-shadow"
    }).then(modal => {
      modal.present();

      modal.onWillDismiss().then(data => {
        this.lsService.setPolicy(!!data?.data);
        this.reOpen();
      })
    });
  }

  reOpen() {
    let isEULAAccepted = this.lsService.getEULA();
    let isPolicyAccepted = this.lsService.getPolicy();
    if (!isEULAAccepted || !isPolicyAccepted) {
      this.modalController.create({
        component: OverviewPoliciesModalComponent,
        cssClass: "modal-shadow"
      }).then(overviewModal => {
        overviewModal.present();
      });
    }
  }
}

import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { LoadingController } from '@ionic/angular';
import { UserRole } from '@models/authentication.model';
import { IPagingResult } from '@models/paging.model';
import { ITrainingPlan } from '@models/training.model';
import { TranslateService } from '@ngx-translate/core';
import { from, interval, Observable, Subject } from 'rxjs';
import { finalize, map, switchMap, takeUntil, tap } from 'rxjs/operators';
import { HttpHelper } from '../helpers/http.helper';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: "root",
})
export class TrainingPlanService {
    apiPath: string = 'training_plans';

    constructor(private http: HttpClient,
        private translate: TranslateService,
        private loadingController: LoadingController,
        private authService: AuthService) { }

    getAll(page: number = 1, withoutLoading = false): Observable<IPagingResult<ITrainingPlan>> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let authQuery = userRole == UserRole.athlete ? 'athlete_id' : 'coach_id';
        authQuery += `=${userId}`;

        let pageQuery = `&page=${page}`;

        let http = this.http;
        if (withoutLoading) {
            http = http.disableLoading();
        }

        return http.get(`/${this.apiPath}?${authQuery}${pageQuery}`, { observe: 'response' }).pipe(
            map((response: HttpResponse<any>): IPagingResult<ITrainingPlan> => {
                if (response?.body?.status === "success") {
                    let paging = HttpHelper.getPaging(response);
                    return {
                        items: response.body.data,
                        paging: { ...paging }
                    };
                } else {
                    throw response?.body?.message;
                }
            })
        );
    }

    get(id: number): Observable<ITrainingPlan> {
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        let query = userRole == UserRole.coach ?
            `coach_id=${userId}` :
            `athlete_id=${userId}`;
        return this.http.get(`/${this.apiPath}/${id}?${query}`).pipe(
            map((response: any) => {
                if (response?.status === "success") {
                    return response.data[0];
                } else {
                    throw response.message;
                }
            })
        );
    }

    add(model: ITrainingPlan) {
        let plan: any = { ...model };
        let userId = this.authService.getUserId();
        let userRole = this.authService.getUserRole();
        if (userRole == UserRole.athlete) {
            plan.athlete_id = userId;
        }
        else {
            plan.coach_id = userId;
        }

        plan.plan_sheets = plan.plan_sheets?.map(x => x.id);

        return this.http.post(`/${this.apiPath}`, plan);
    }

    edit(model: ITrainingPlan, withLoading = true) {
        let plan: any = { ...model };

        plan.plan_sheets = plan.plan_sheets?.map(x => x.id);

        let http = this.http;
        if (withLoading) {
            http = this.http.disableLoading();
        }

        return http.patch(`/${this.apiPath}/${plan.id}`, plan);
    }

    delete(id: number) {
        return this.http.delete(`/${this.apiPath}/${id}`)
    }

    copy(name: string, planId: number) {
        const timer = interval(2000);
        const event = new Subject();

        let messagePath = 'base.loading';
        let message = this.translate.instant(messagePath);
        message = message == messagePath ? 'Bitte warten...' : message;

        return from(this.loadingController.create({
            message: message
        }))
            .pipe(
                tap((loading) => {
                    timer.pipe(takeUntil(event))
                        .subscribe(index => {
                            let textPath = 'fake_loading.training_plan.msg' + index;
                            let text = this.translate.instant(textPath);
                            if (textPath != text) {
                                loading.message = `<div class="loading-icon-text"><ion-icon name="checkmark-circle-outline" class="loading-icon"></ion-icon>${text}</div>`;
                            }
                        });
                    return loading.present();
                }),
                switchMap((loading) => {
                    return this.http.disableLoading().post(`/copy_training_plan`, { id: planId, name: name })
                        .pipe(finalize(() => {
                            event.next({});
                            loading.dismiss();
                        }));
                })
            );
    }
}
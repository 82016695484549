// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-policy {
  height: calc(100% - 51px);
  padding-bottom: 20px;
}

.buttons {
  display: flex;
  border-top: 1px solid var(--ons-grey-border);
  z-index: 1;
  background: var(--ons-color-primary-background);
}
.buttons .button {
  width: 100%;
  line-height: 50px;
  text-align: center;
  cursor: pointer;
  color: var(--ons-color-black-2);
  padding-bottom: calc(0px + var(--ion-safe-area-bottom, 0));
}
.buttons .button:last-of-type {
  border-left: 1px solid var(--ons-grey-border);
  color: var(--ons-color-primary);
  font-weight: bold;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/policy-modal/policy-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,oBAAA;AACJ;;AAEA;EACI,aAAA;EACA,4CAAA;EACA,UAAA;EACA,+CAAA;AACJ;AACI;EACI,WAAA;EACA,iBAAA;EACA,kBAAA;EACA,eAAA;EACA,+BAAA;EACA,0DAAA;AACR;AACQ;EACI,6CAAA;EACA,+BAAA;EACA,iBAAA;AACZ","sourcesContent":["app-policy {\n    height: calc(100% - 51px);\n    padding-bottom: 20px;\n}\n\n.buttons {\n    display: flex;\n    border-top: 1px solid var(--ons-grey-border);\n    z-index: 1;\n    background: var(--ons-color-primary-background);\n\n    .button {\n        width: 100%;\n        line-height: 50px;\n        text-align: center;\n        cursor: pointer;\n        color: var(--ons-color-black-2);\n        padding-bottom: calc(00px + var(--ion-safe-area-bottom, 0));\n\n        &:last-of-type {\n            border-left: 1px solid var(--ons-grey-border);\n            color: var(--ons-color-primary);\n            font-weight: bold;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { IPagingResult } from "./paging.model";

export interface INotification {
    id: number,
    message: string,
    is_read: boolean,
    type: NotificationType,
    created_at: string,
    updated_at: string,
}

export enum NotificationType {
    common = 'common',
    document = 'document',
    mesocycle = 'mesocycle',
    note = 'note',
    relation = 'relation',
    plan = 'training_plan',
    invite = 'invite',
    assignment = 'assignment'
}

export interface INotificationPagingResult extends IPagingResult<INotification> {
    unread_count: number
}
import { Component, OnInit } from '@angular/core';
import { UIService } from "@services/ui.service";
import { AuthService } from "@services/auth.service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from '@angular/router';
import { ACTIVITY_LEVELS } from 'app/core/consts/activity-level.const';

@Component({
	selector: 'app-athlete-level',
	templateUrl: './athlete-level.page.html',
	styleUrls: ['./athlete-level.page.scss'],
})

export class AthleteLevelPage implements OnInit {
	activity_level: number = 1;

	levelList = ACTIVITY_LEVELS;

	constructor(
		private translate: TranslateService,
		private uiService: UIService,
		private auth: AuthService,
		private router: Router
	) { }

	ngOnInit() {
	}

	levelChange(event) {
		this.activity_level = event.detail.value;
	}

	nextPage() {

		this.auth.updateActivityLevel(this.activity_level)
			.then((res) => {
				this.uiService.showMessage("Your activity level has been saved!", 'success', 2000);
				this.router.navigate(['/athlete/dashboard']);
			})
			.catch(error => {
				console.log('Error Athlete Update ', error);
			});
	}

}

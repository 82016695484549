import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AthleteMoreButtonComponent } from './athlete-more-button/athlete-more-button.component';
import { MoreButtonComponent } from './more-button/more-button.component';
import { BodyDataFormComponent } from './body-data-form/body-data-form.component';
import { TagsModalComponent } from './body-data-form/tags-modal/tags-modal.component';
import { CoachAnalyticsButtonComponent } from './coach-analytics-button/coach-analytics-button.component';
import { CoachBlockComponent } from './coach-block/coach-block.component';
import { CropAvatarComponent } from './crop-avatar/crop-avatar.component';
import { FilterUnitsComponent } from './filter-units/filter-units.component';
import { ImprintComponent } from './imprint/imprint.component';
import { MacroFormComponent } from './macro-form/macro-form.component';
import { NoDataComponent } from './no-data/no-data.component';
import { NotesInfinityScrollComponent } from './infinity-scrolls/notes-infinity-scroll/notes-infinity-scroll.component';
import { PlusMinusInputComponent } from './plus-minus-input/plus-minus-input.component';
import { PolicyComponent } from './policy/policy.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { SliderComponent } from './slider/slider.component';
import { TrackingCalendarComponent } from './tracking-calendar/tracking-calendar.component';
import { UpdateAvatarComponent } from './update-avatar/update-avatar.component';
import { UserListComponent } from './user-list/user-list.component';
import { InfinityScrollComponent } from './infinity-scrolls/infinity-scroll/infinity-scroll.component';
import { MesocyclesInfinityScrollComponent } from './infinity-scrolls/mesocycles-infinity-scroll/mesocycles-infinity-scroll.component';
import { NotesListComponent } from './notes-list/notes-list.component';
import { MainPipeModule } from 'app/core/pipes/main-pipe.module';
import { InviteDetailsComponent } from './user-list/invite-details/invite-details.component';
import { AthleteNotificationsButtonComponent } from './athlete-notifications-button/athlete-notifications-button.component';
import { NotificationsInfinityScrollComponent } from './infinity-scrolls/notifications-infinity-scroll/notifications-infinity-scroll.component';
import { SubscriptionSelectComponent } from './subscription-select/subscription-select.component';
import { AthletesInfinityScrollComponent } from './infinity-scrolls/athletes-infinity-scroll/athletes-infinity-scroll.component';
import { TPlansInfinityScrollComponent } from './infinity-scrolls/tplans-infinity-scroll/tplans-infinity-scroll.component';
import { TUnitsInfinityScrollComponent } from './infinity-scrolls/tunits-infinity-scroll/tunits-infinity-scroll.component';
import { CategoriesInfinityScrollComponent } from './infinity-scrolls/categories-infinity-scroll/categories-infinity-scroll.component';
import { TechniquesInfinityScrollComponent } from './infinity-scrolls/techniques-infinity-scroll/techniques-infinity-scroll.component';
import { TemplatesInfinityScrollComponent } from './infinity-scrolls/templates-infinity-scroll/templates-infinity-scroll.component';
import { InfinityButtonComponent } from './infinity-button/infinity-button.component';
import { BodyDataInfinityScrollComponent } from './infinity-scrolls/body-data-infinity-scroll/body-data-infinity-scroll.component';
import { MacroInfinityScrollComponent } from './infinity-scrolls/macro-infinity-scroll/macro-infinity-scroll.component';
import { TagInfinityScrollComponent } from './infinity-scrolls/tag-infinity-scroll/tag-infinity-scroll.component';
import { AssignedMesocyclesInfinityScrollComponent } from './infinity-scrolls/assigned-mesocycles-infinity-scroll/assigned-mesocycles-infinity-scroll.component';
import { DocumentsInfinityScrollComponent } from './infinity-scrolls/documents-infinity-scroll/documents-infinity-scroll.component';
import { EditNutritionComponent } from './edit-nutrition/edit-nutrition.component';
import { TrainingUnitComponent } from './training-unit/training-unit.component';
import { CategoriesModalComponent } from './training-unit/categories-modal/categories-modal.component';
import { AddCategoryModalComponent } from './training-unit/categories-modal/add-category-modal/add-category-modal.component';
import { TechniquesModalComponent } from './training-unit/techniques-modal/techniques-modal.component';
import { AddTechniqModalComponent } from './training-unit/techniques-modal/add-techniq-modal/add-techniq-modal.component';
import { EulaModalComponent } from './eula-modal/eula-modal.component';
import { PolicyModalComponent } from './policy-modal/policy-modal.component';
import { OverviewPoliciesModalComponent } from './overview-policies-modal/overview-policies-modal.component';
import { FollowupComponent } from './user-list/followup/followup.component';
import { InviteBlockComponent } from './invite-block/invite-block.component';
import { AthleteInvitesComponent } from './athlete-invites/athlete-invites.component';
import { CopyMesocycleModalComponent } from './copy-mesocycle-modal/copy-mesocycle-modal.component';
import { CopyPlanModalComponent } from './copy-plan-modal/copy-plan-modal.component';
import { AthleteOverviewButtonComponent } from './athlete-overview-button/athlete-overview-button.component';
import { HeaderLogoComponent } from './header-logo/header-logo.component';
import { TrainingPlanOverviewComponent } from './training-plan-overview/training-plan-overview.component';
import { TrainingPlanOverviewButtonComponent } from './training-plan-overview/training-plan-overview-button/training-plan-overview-button.component';
import { TrainingPlanOverviewSheetComponent } from './training-plan-overview/training-plan-overview-sheet/training-plan-overview-sheet.component';
import { TrainingPlanOverviewUnitComponent } from './training-plan-overview/training-plan-overview-sheet/training-plan-overview-unit/training-plan-overview-unit.component';
import { ContentPopoverComponent } from './content-popover/content-popover.component';
import { TrainingPlanOverviewUnitChangeModalComponent } from './training-plan-overview/training-plan-overview-sheet/training-plan-overview-unit/training-plan-overview-unit-change-modal/training-plan-overview-unit-change-modal.component';

@NgModule({
    imports: [
        IonicModule,
        CommonModule,
        TranslateModule,
        ImageCropperModule,
        FormsModule,
        ReactiveFormsModule,
        MainPipeModule
    ],
    declarations: [
        CropAvatarComponent,
        UpdateAvatarComponent,
        PlusMinusInputComponent,
        FilterUnitsComponent,
        BodyDataFormComponent,
        TagsModalComponent,
        MacroFormComponent,
        UserListComponent,
        AthleteMoreButtonComponent,
        MoreButtonComponent,
        SliderComponent,
        TrackingCalendarComponent,
        CoachAnalyticsButtonComponent,
        PolicyComponent,
        PrivacyComponent,
        ImprintComponent,
        CoachBlockComponent,
        NoDataComponent,
        InfinityScrollComponent,
        NotesInfinityScrollComponent,
        MesocyclesInfinityScrollComponent,
        NotesListComponent,
        InviteDetailsComponent,
        AthleteNotificationsButtonComponent,
        AthleteOverviewButtonComponent,
        NotificationsInfinityScrollComponent,
        SubscriptionSelectComponent,
        AthletesInfinityScrollComponent,
        TPlansInfinityScrollComponent,
        TUnitsInfinityScrollComponent,
        CategoriesInfinityScrollComponent,
        TechniquesInfinityScrollComponent,
        TemplatesInfinityScrollComponent,
        InfinityButtonComponent,
        BodyDataInfinityScrollComponent,
        MacroInfinityScrollComponent,
        TagInfinityScrollComponent,
        AssignedMesocyclesInfinityScrollComponent,
        DocumentsInfinityScrollComponent,
        EditNutritionComponent,
        TrainingUnitComponent,
        CategoriesModalComponent,
        AddCategoryModalComponent,
        TechniquesModalComponent,
        AddTechniqModalComponent,
        EulaModalComponent,
        PolicyModalComponent,
        OverviewPoliciesModalComponent,
        FollowupComponent,
        InviteBlockComponent,
        AthleteInvitesComponent,
        CopyMesocycleModalComponent,
        CopyPlanModalComponent,
        HeaderLogoComponent,
        TrainingPlanOverviewComponent,
        TrainingPlanOverviewButtonComponent,
        TrainingPlanOverviewSheetComponent,
        TrainingPlanOverviewUnitComponent,
        TrainingPlanOverviewUnitChangeModalComponent,
        ContentPopoverComponent,
    ],
    exports: [
        CommonModule,
        CropAvatarComponent,
        UpdateAvatarComponent,
        PlusMinusInputComponent,
        FilterUnitsComponent,
        BodyDataFormComponent,
        TagsModalComponent,
        MacroFormComponent,
        UserListComponent,
        AthleteMoreButtonComponent,
        MoreButtonComponent,
        SliderComponent,
        TrackingCalendarComponent,
        CoachAnalyticsButtonComponent,
        PolicyComponent,
        PrivacyComponent,
        ImprintComponent,
        CoachBlockComponent,
        NoDataComponent,
        InfinityScrollComponent,
        NotesInfinityScrollComponent,
        MesocyclesInfinityScrollComponent,
        NotesListComponent,
        InviteDetailsComponent,
        AthleteNotificationsButtonComponent,
        AthleteOverviewButtonComponent,
        NotificationsInfinityScrollComponent,
        SubscriptionSelectComponent,
        AthletesInfinityScrollComponent,
        TPlansInfinityScrollComponent,
        TUnitsInfinityScrollComponent,
        CategoriesInfinityScrollComponent,
        TechniquesInfinityScrollComponent,
        TemplatesInfinityScrollComponent,
        InfinityButtonComponent,
        BodyDataInfinityScrollComponent,
        MacroInfinityScrollComponent,
        TagInfinityScrollComponent,
        AssignedMesocyclesInfinityScrollComponent,
        DocumentsInfinityScrollComponent,
        EditNutritionComponent,
        TrainingUnitComponent,
        CategoriesModalComponent,
        AddCategoryModalComponent,
        TechniquesModalComponent,
        AddTechniqModalComponent,
        EulaModalComponent,
        PolicyModalComponent,
        OverviewPoliciesModalComponent,
        FollowupComponent,
        InviteBlockComponent,
        AthleteInvitesComponent,
        CopyMesocycleModalComponent,
        CopyPlanModalComponent,
        HeaderLogoComponent,
        TrainingPlanOverviewButtonComponent,
        TrainingPlanOverviewUnitChangeModalComponent,
        ContentPopoverComponent
    ],
    providers: [TranslatePipe]
})
export class SharedModule { }

import { Pipe, PipeTransform } from "@angular/core";


@Pipe({ name: 'filterBodyDataByType' })
export class FilterBodyDataByTypePipe implements PipeTransform {
    transform(list: any[], type: 'weight' | 'avg_weight' | 'custom'): any[] {
        if (list) {
            return list.filter((item: any) => item.type === type);
        }
    }
}
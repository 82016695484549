import { Injectable } from '@angular/core';
import { CameraResultType, CameraSource, Camera } from '@capacitor/camera';


@Injectable({
	providedIn: 'root'
})

export class CameraService {

	constructor() { }

	pickImage(sourceType: CameraSource) {
		return new Promise((resolve, reject) => {
			Camera.getPhoto({
				quality: 100,
				allowEditing: false,
				resultType: CameraResultType.DataUrl,
				source: sourceType,
				correctOrientation: false
			})
				.then((imageData) => {
					resolve(imageData);
				}, (err) => {
					// Handle error
					reject(err);
				});
		});

	}
}

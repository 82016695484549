import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { IAthlete } from "@models/athlete.model";
import { IDocument } from "@models/document.model";
import { IUser } from "@models/user.model";
import { Actions, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { BehaviorSubject } from 'rxjs';
import { AppReducers } from "../store";
import { AthleteActions } from "../store/actions";
import { AppState } from "../store/app.states";
import { AuthService } from "./auth.service";

@Injectable({
    providedIn: "root",
})
export class CoachAthleteViewService {
    athleteId$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    athlete$: BehaviorSubject<IAthlete> = new BehaviorSubject<IAthlete>(null);
    documents$: BehaviorSubject<IDocument[]> = new BehaviorSubject<IDocument[]>(null);
    athleteId: number;

    constructor(private http: HttpClient,
        private authService: AuthService,
        private actions$: Actions,
        private store: Store<AppState>) {
        this.athleteId$.subscribe(data => {
            if (data) {
                this.athleteId = data;
                this.getAthlete();
                this.getAthleteDocuments();
            }
        });

        this.actions$.pipe(ofType(AthleteActions.ASSIGN_DOCUMENT_SUCCESS))
            .subscribe((data: any) => {
                this.getAthleteDocuments();
            });
        this.actions$.pipe(ofType(AthleteActions.UNASSIGN_DOCUMENT_SUCCESS))
            .subscribe((data: any) => {
                this.getAthleteDocuments();
            });
    }

    clear() {
        this.athleteId = undefined;
        this.athleteId$.next(undefined);
        this.athlete$.next(undefined);
        this.documents$.next(undefined);
    }

    getAthlete() {
        this.store.select(AppReducers.getAthlete, { id: this.athleteId })
            .subscribe(athlete => {
                this.athlete$.next(athlete);
            });
        this.store.dispatch(new AthleteActions.LoadAthleteAction(this.athleteId));
    }

    getAthleteDocuments() {
        let coachId = this.authService.getUserId();
        this.http.get(`/documents?athlete_id=${this.athleteId}&coach_id=${coachId}`)
            .subscribe((response: any) => {
                if (response?.status === "success" && response?.data) {
                    this.documents$.next(response.data);
                } else {
                    throw response.message;
                }
            });
    }
}
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { map, shareReplay, tap } from "rxjs/operators";
import { NavController } from "@ionic/angular";
import { ICoachRegistrationForm, UserRole } from "@models/authentication.model";
import { IUser } from "@models/user.model";
import { BehaviorSubject, Observable } from "rxjs";
import { LocalStorageService } from './local-storage.service';
import * as moment from 'moment';
import { AppState } from '../store/app.states';
import { Store } from '@ngrx/store';
import { AuthActions } from '../store/actions';
import { Router } from '@angular/router';
// @ts-ignore  
import jwt_decode from "jwt-decode";
import { AthleteActiveTrainingService } from './athlete-active-training.service';
import { ThemeService } from "./theme.service";

export interface ILoginHttpAnswer {
  status: string;
  message: string;
  data: {
    token_type: string;
    expires_in: number;
    access_token: string;
    refresh_token: string;
    user: IUser;
  }[];
  error: [];
}

export interface IRegisterHttpAnswer {
  status: string;
  message: string;
  data: IUser;
  error: [];
}

export interface IResetPassword {
  email: string;
  password: string;
  password_confirmation: string;
  token: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  currentUser$: BehaviorSubject<IUser> = new BehaviorSubject(null);
  private userData: IUser;

  private resetPassword: IResetPassword;

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store<AppState>,
    private navCtrl: NavController,
    private localStorageService: LocalStorageService,
    private athleteActiveTrainingService: AthleteActiveTrainingService,
    private themeService: ThemeService
  ) {
    console.log("check logged in")
    if (this.isLoggedIn()) {
      console.log("ist eingeloggt")
      this.getUserData(false)
        .subscribe(data => {
          console.log(this.router.url)
          if (this.router.url == '/login' || this.router.url == '/') {

            console.log("route to different route")
            this.navigateUserBasedOnHisRole();
          }
        });
    }
    else {
      this.router.navigate(['/login']);
    }
  }

  public getUserId() {
    let accessToken = this.localStorageService.getAccessToken();
    if (!accessToken) {
      return null;
    }
    const decodedJWT: any = jwt_decode(accessToken);
    return decodedJWT?.sub;
  }

  public getUserRole(): UserRole {
    return this.userData?.role;
  }

  public isLoggedIn(): boolean {
    let token = this.localStorageService.getAccessToken();
    return !!token;
  }

  removeMyCoach() {
    if (this.userData?.coach || this.userData?.assigned_coach) {
      let user: IUser = {
        ...this.userData,
        coach: undefined,
        assigned_coach: undefined
      }
      this.setUserData(user);
    }
  }

  setUserData(userData: IUser) {
    this.userData = userData;
    this.currentUser$.next(this.userData);

    this.store.dispatch(
      AuthActions.loadUserSuccess({ user: userData })
    );

    this.themeService.toggleTheme(this.userData.color_schema);

    if (this.userData.role == UserRole.coach ||
      (this.userData.role == UserRole.athlete && !this.userData.coach?.email && !this.userData.assigned_coach?.coach?.email)) {
    }

    this.athleteActiveTrainingService.init();
  }


  getUserData(skipLoading: boolean = true): Observable<IUser> {
    let http = skipLoading ? this.http.disableLoading() : this.http;
    return http.get('/user')
      .pipe(
        tap((res: any) => {
          this.setUserData(res.data[0]);
        }),
        shareReplay()
      );
  }

  login(email: string, password: string): Observable<IUser> {
    this.athleteActiveTrainingService.clear();
    return this.http.post<any>('/login', { email, password }).pipe(
      tap(res => {
        this.setSession(res);
        let user = res.data[0].user;
        this.setUserData(user);

        this.navigateUserBasedOnHisRole();
      }),
      shareReplay()
    );
  }

  navigateUserBasedOnHisRole() {
    let user = this.userData;

    let userRole = user?.role;
    if (userRole == UserRole.coach) {
      this.navCtrl.navigateRoot('coach/athletes', { animated: true, animationDirection: 'forward' });
    } else if (userRole == UserRole.athlete) {
      this.navCtrl.navigateRoot('/athlete/dashboard', { animated: true, animationDirection: 'forward' });
    }
    else if (!userRole) {
      this.navCtrl.navigateRoot('/login', { animated: true, animationDirection: 'forward' });
    }
  }

  setSession(res) {
    let accessToken = res.data[0].access_token;
    this.localStorageService.setAccessToken(accessToken);

    let refreshToken = res.data[0].refresh_token;
    this.localStorageService.setRefreshToken(refreshToken);

    let expDateSpan = res.data[0].expires_in;
    let expDate = moment().add(expDateSpan, 'seconds').format();
    this.localStorageService.setExpiryDate(expDate);
  }

  logout() {
    localStorage.removeItem('onspot_access_token');
    localStorage.removeItem('onspot_refresh_token');
    localStorage.removeItem('onspot_expires_date');

    this.store.dispatch(
      AuthActions.loadUserSuccess({ user: null })
    );
  }

  refreshToken() {
    let req = {
      token: this.localStorageService.getRefreshToken(),
      user_id: null
    }
    if(this.userData !== undefined) {
      req.user_id = this.userData['id'] ?? null;
    }
    
    return this.http.post('/refresh', req).pipe(
      map((res) => {
        this.setSession(res);
        return res;
      })
    );
  }

  register(userData: ICoachRegistrationForm, uRole) {
    return this.http.post("/users", {
      ...userData,
      role: uRole,
    })
      .pipe(
        map((response: IRegisterHttpAnswer) => {
          if (response?.status === "success") {
            this.setUserData(response.data[0]);
          } else {
            throw response.message;
          }
        })
      )
      .toPromise();
  }

  getPasswordToken(email: string) {
    return this.http
      .post("/password/create", {
        email: email,
      })
      .pipe(
        map((response: any) => {
          if (response?.status === "success") {
            this.resetPassword = {
              email: email,
              password: "",
              password_confirmation: "",
              token: "",
            };
          } else {
            throw response.message;
          }
        })
      )
      .toPromise();
  }

  checkPasswordToken(token: string) {
    return this.http
      .get("/password/reset/" + token)
      .pipe(
        map((response: any) => {
          if (response?.status === "success") {
            this.resetPassword.token = token;
          } else {
            throw response.message;
          }
        })
      )
      .toPromise();
  }

  setNewPassword(password: string) {
    this.resetPassword.password = password;
    this.resetPassword.password_confirmation = password;

    return this.http
      .post("/password/reset", this.resetPassword)
      .pipe(
        map((response: any) => {
          if (response?.status === "success") {
          } else {
            throw response.message;
          }
        })
      )
      .toPromise();
  }

  changePassword(oldPassword: string, newPassword: string) {
    return this.http
      .post("/password/change", {
        old_password: oldPassword,
        new_password: newPassword
      })
      .pipe(
        map((response: any) => {
          if (response?.status === "success") {
          } else {
            throw response.message;
          }
        })
      )
      .toPromise();
  }

  updateAvatar(path: string) {
    let url = "/users/" + this.userData["id"];

    const body = {
      image: path,
    };

    return this.http
      .patch(url, body)
      .pipe(
        map((response: any) => {
          if (response?.status === "success") {
            this.setUserData(response.data[0]);
          } else {
            throw response.message;
          }
        })
      )
      .toPromise();
  }

  updateColorSchema(theme: string) {
    let url = "/users/" + this.userData["id"];

    const body = {
      color_schema: theme,
    };

    return this.http
      .patch(url, body)
      .pipe(
        map((response: any) => {
          if (response?.status === "success") {
            this.setUserData(response.data[0]);
          } else {
            throw response.message;
          }
        })
      )
      .toPromise();
  }

  updateAthleteInfo(size: number, dob: Date, weight: number) {
    let url = "/users/" + this.userData["id"];

    const body = {
      size: size,
      dob: moment(dob).format('YYYY-MM-DD'),
      weight: weight
    };

    return this.http
      .patch(url, body)
      .pipe(
        map((response: any) => {
          if (response?.status === "success") {
            this.setUserData(response.data[0]);
          } else {
            throw response.message;
          }
        })
      )
      .toPromise();
  }

  updateActivityLevel(level: number) {
    let url = "/users/" + this.userData["id"];

    const body = {
      activity_level: level
    };

    return this.http
      .patch(url, body)
      .pipe(
        map((response: any) => {
          if (response?.status === "success") {
            this.setUserData(response.data[0]);
          } else {
            throw response.message;
          }
        })
      )
      .toPromise();
  }

  trainsHimself() {
    return this.http.post<any>('/athlete_manage_self', {}).pipe(
      map((response: any) => {
        // console.log(response)
      }),
      shareReplay()
    ).toPromise();;
    
  }
}

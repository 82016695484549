// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon {
  color: var(--ons-color-primary);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/coach-analytics-button/coach-analytics-button.component.scss"],"names":[],"mappings":"AAAA;EACI,+BAAA;AACJ","sourcesContent":["ion-icon {\n    color: var(--ons-color-primary);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppReducers } from 'app/core/store';
import { AthleteActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';

@Component({
  selector: 'app-athletes-infinity-scroll',
  templateUrl: './athletes-infinity-scroll.component.html',
  styleUrls: ['./athletes-infinity-scroll.component.scss'],
})
export class AthletesInfinityScrollComponent {
  selectPaging = AppReducers.getAthletePaging;
  loadPageSuccessAction: string = AthleteActions.LOAD_ATHLETES_PAGE_SUCCESS;
  loadPageFailAction: string = AthleteActions.LOAD_ATHLETES_PAGE_FAIL;

  constructor(private store: Store<AppState>) { }

  loadPageAction() {
    this.store.dispatch(new AthleteActions.LoadAthletesPageAction());
  }
}

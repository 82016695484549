import { ActionReducerMap, createSelector } from '@ngrx/store';
import {
    AuthReducers,
    DocumentReducers,
    ErrorsReducers,
    MesocycleReducers,
    ProgressReducers,
    TrainingReducers,
    AthleteReducers,
    NutritionReducers,
    AnalyticsReducers,
    NoteReducers,
    NotificationReducers,
    AthleteInvitationsReducers,
    BaseObjectReducers
} from './reducers';

export interface AppState {
    error: ErrorsReducers.State,
    auth: AuthReducers.State,
    document: DocumentReducers.State,
    training: TrainingReducers.State,
    mesocycle: MesocycleReducers.State,
    progress: ProgressReducers.State,
    athlete: AthleteReducers.State,
    nutrition: NutritionReducers.State,
    analytics: AnalyticsReducers.State,
    note: NoteReducers.State,
    notification: NotificationReducers.State,
    athleteInvitations: AthleteInvitationsReducers.State,
    baseObjects: BaseObjectReducers.State,
}

export const reducers: ActionReducerMap<any> = {
    error: ErrorsReducers.reducer,
    auth: AuthReducers.reducer,
    document: DocumentReducers.reducer,
    training: TrainingReducers.reducer,
    mesocycle: MesocycleReducers.reducer,
    progress: ProgressReducers.reducer,
    athlete: AthleteReducers.reducer,
    nutrition: NutritionReducers.reducer,
    analytics: AnalyticsReducers.reducer,
    note: NoteReducers.reducer,
    notification: NotificationReducers.reducer,
    athleteInvitations: AthleteInvitationsReducers.reducer,
    baseObjects: BaseObjectReducers.reducer,
};

//#region Auth
export const getAuthState = (state: AppState) => state.auth;
export const getUser = createSelector(getAuthState, AuthReducers.selectUser);
export const getUserRole = createSelector(getAuthState, AuthReducers.selectUserRole);
//#endregion

//#region Document
export const getDocumentState = (state: AppState) => state.document;
export const getDocumentLoaded = createSelector(getDocumentState, DocumentReducers.getLoaded);
export const getDocumentLoading = createSelector(getDocumentState, DocumentReducers.getLoading);
export const getDocumentCollection = createSelector(getDocumentState, DocumentReducers.getDocuments);
export const getDocumentPaging = createSelector(getDocumentState, DocumentReducers.getDocumentsPaging);
//#endregion

//#region Training
export const getTrainingState = (state: AppState) => state.training;
export const getTrainingPlansLoaded = createSelector(getTrainingState, TrainingReducers.getPlansLoaded);
export const getTrainingPlansLoading = createSelector(getTrainingState, TrainingReducers.getPlansLoading);
export const getTrainingPlansCollection = createSelector(getTrainingState, TrainingReducers.getPlans);
export const getTrainingPlanPaging = createSelector(getTrainingState, TrainingReducers.getPlansPaging);
export const getTrainingPlan = createSelector(getTrainingState, TrainingReducers.getPlan);

export const getTrainingPlanCopy = createSelector(getTrainingState, TrainingReducers.getPlanCopy);

export const getTrainingUnitsLoaded = createSelector(getTrainingState, TrainingReducers.getUnitsLoaded);
export const getTrainingUnitsLoading = createSelector(getTrainingState, TrainingReducers.getUnitsLoading);
export const getTrainingUnitsCollection = createSelector(getTrainingState, TrainingReducers.getUnits);
export const getTrainingUnitPaging = createSelector(getTrainingState, TrainingReducers.getUnitsPaging);
export const getTrainingUnit = createSelector(getTrainingState, TrainingReducers.getUnit);

export const getTrainingUnitCopy = createSelector(getTrainingState, TrainingReducers.getUnitCopy);

export const getTrainingCategoriesLoaded = createSelector(getTrainingState, TrainingReducers.getCategoriesLoaded);
export const getTrainingCategoriesLoading = createSelector(getTrainingState, TrainingReducers.getCategoriesLoading);
export const getTrainingCategoriesCollection = createSelector(getTrainingState, TrainingReducers.getCategories);
export const getTrainingCategoriesPaging = createSelector(getTrainingState, TrainingReducers.getCategoriesPaging);

export const getTrainingTechniquesLoaded = createSelector(getTrainingState, TrainingReducers.getTechniquesLoaded);
export const getTrainingTechniquesLoading = createSelector(getTrainingState, TrainingReducers.getTechniquesLoading);
export const getTrainingTechniquesCollection = createSelector(getTrainingState, TrainingReducers.getTechniques);
export const getTrainingTechniquesPaging = createSelector(getTrainingState, TrainingReducers.getTechniquesPaging);

export const getTrainingSheetsLoaded = createSelector(getTrainingState, TrainingReducers.getSheetsLoaded);
export const getTrainingSheetsLoading = createSelector(getTrainingState, TrainingReducers.getSheetsLoading);
export const getTrainingSheetsCollection = createSelector(getTrainingState, TrainingReducers.getSheets);

export const getTrainingTemplatesLoaded = createSelector(getTrainingState, TrainingReducers.getTemplatesLoaded);
export const getTrainingTemplatesLoading = createSelector(getTrainingState, TrainingReducers.getTemplatesLoading);
export const getTrainingTemplatesCollection = createSelector(getTrainingState, TrainingReducers.getTemplates);
export const getTrainingTemplatesPaging = createSelector(getTrainingState, TrainingReducers.getTemplatesPaging);
export const getTrainingTemplate = createSelector(getTrainingState, TrainingReducers.getTemplate);
//#endregion

//#region Mesocycle
export const getMesocycleState = (state: AppState) => state.mesocycle;
export const getMesocyclesLoaded = createSelector(getMesocycleState, MesocycleReducers.getMesocyclesLoaded);
export const getMesocyclesLoading = createSelector(getMesocycleState, MesocycleReducers.getMesocyclesLoading);
export const getMesocyclesCollection = createSelector(getMesocycleState, MesocycleReducers.getMesocycles);
export const getMesocyclePaging = createSelector(getMesocycleState, MesocycleReducers.getMesocyclesPaging);
export const getMesocycle = createSelector(getMesocycleState, MesocycleReducers.getMesocycle);

export const getMesocycleCopy = createSelector(getMesocycleState, MesocycleReducers.getMesocycleCopy);

export const getAssignedMesocycles = createSelector(getMesocycleState, MesocycleReducers.getAssignedMesocycles);
export const getAssignedMesocyclesPaging = createSelector(getMesocycleState, MesocycleReducers.getAssignedMesocyclesPaging);
export const getActiveAssignedMesocycle = createSelector(getMesocycleState, MesocycleReducers.getActiveAssignedMesocycle);
//#endregion

//#region Nutrition
export const getNutritionState = (state: AppState) => state.nutrition;
export const getNutritionGoalsLoaded = createSelector(getNutritionState, NutritionReducers.getGoalsLoaded);
export const getNutritionGoalsLoading = createSelector(getNutritionState, NutritionReducers.getGoalsLoading);
export const getNutritionGoalsCollection = createSelector(getNutritionState, NutritionReducers.getGoals);
export const getNutritionGoal = createSelector(getNutritionState, NutritionReducers.getGoal);
export const getNutritionGoalByAthleteId = createSelector(getNutritionState, NutritionReducers.getGoalByAthleteId);
//#endregion

//#region Progress
export const getProgressState = (state: AppState) => state.progress;
export const getBodyDatasLoaded = createSelector(getProgressState, ProgressReducers.getBodyDatasLoaded);
export const getBodyDatasLoading = createSelector(getProgressState, ProgressReducers.getBodyDatasLoading);
export const getBodyDatasCollection = createSelector(getProgressState, ProgressReducers.getBodyDatas);
export const getBodyDatasPaging = createSelector(getProgressState, ProgressReducers.getBodyDatasPaging);
export const getBodyData = createSelector(getProgressState, ProgressReducers.getBodyData);

export const getBodyDataCopy = createSelector(getProgressState, ProgressReducers.getBodyDataCopy);

export const getTagsLoaded = createSelector(getProgressState, ProgressReducers.getTagsLoaded);
export const getTagsLoading = createSelector(getProgressState, ProgressReducers.getTagsLoading);
export const getTagsCollection = createSelector(getProgressState, ProgressReducers.getTags);
export const getTagsPaging = createSelector(getProgressState, ProgressReducers.getTagsPaging);
export const getTag = createSelector(getProgressState, ProgressReducers.getTag);

export const getMacrosLoaded = createSelector(getProgressState, ProgressReducers.getMacrosLoaded);
export const getMacrosLoading = createSelector(getProgressState, ProgressReducers.getMacrosLoading);
export const getMacrosCollection = createSelector(getProgressState, ProgressReducers.getMacros);
export const getMacrosPaging = createSelector(getProgressState, ProgressReducers.getMacrosPaging);
export const getMacro = createSelector(getProgressState, ProgressReducers.getMacro);

export const getMacroCopy = createSelector(getProgressState, ProgressReducers.getMacroCopy);
//#endregion

//#region Athlete
export const getAthleteState = (state: AppState) => state.athlete;
export const getAthletesLoaded = createSelector(getAthleteState, AthleteReducers.getAthletesLoaded);
export const getAthletesLoading = createSelector(getAthleteState, AthleteReducers.getAthletesLoading);
export const getAthletesCollection = createSelector(getAthleteState, AthleteReducers.getAthletes);
export const getAthletePaging = createSelector(getAthleteState, AthleteReducers.getAthletesPaging);
export const getAthlete = createSelector(getAthleteState, AthleteReducers.getAthlete);
export const getInvitationsCollection = createSelector(getAthleteState, AthleteReducers.getInvitations);

export const getAthleteTrackings = createSelector(getAthleteState, AthleteReducers.getAthleteTrackings);
export const getAthleteTrackingsByDate = createSelector(getAthleteState, AthleteReducers.getAthleteTrackingsByDate);

export const getAthleteTrainings = createSelector(getAthleteState, AthleteReducers.getAthleteTrainings);
export const getAthleteTrainingByDate = createSelector(getAthleteState, AthleteReducers.getAthleteTrainingByDate);
export const getAthleteTrainingSheet = createSelector(getAthleteState, AthleteReducers.getAthleteTrainingSheet);
export const getAthleteTrainingSheetTitle = createSelector(getAthleteState, AthleteReducers.getAthleteTrainingSheetTitle);


export const getOverviewLoaded = createSelector(getAthleteState, AthleteReducers.getOverviewLoaded);
export const getOverviewLoading = createSelector(getAthleteState, AthleteReducers.getOverviewLoading);
export const getOverviewCollection = createSelector(getAthleteState, AthleteReducers.getOverview);
export const getOverviewPaging = createSelector(getAthleteState, AthleteReducers.getOverviewPaging);

export const getTrainingOverviewLoaded = createSelector(getAthleteState, AthleteReducers.getTrainingOverviewLoaded);
export const getTrainingOverviewLoading = createSelector(getAthleteState, AthleteReducers.getTrainingOverviewLoading);
export const getTrainingOverviewCollection = createSelector(getAthleteState, AthleteReducers.getTrainingOverview);
export const getTrainingOverviewPaging = createSelector(getAthleteState, AthleteReducers.getTrainingOverviewPaging);
//#endregion


//#region Analytics
export const getAnalyticsState = (state: AppState) => state.analytics;
export const getAnalyticsBodyDataGroupsLoaded = createSelector(getAnalyticsState, AnalyticsReducers.getBodyDataGroupsLoaded);
export const getAnalyticsBodyDataGroupsLoading = createSelector(getAnalyticsState, AnalyticsReducers.getBodyDataGroupsLoading);
export const getAnalyticsBodyDataGroupsCollection = createSelector(getAnalyticsState, AnalyticsReducers.getBodyDataGroups);
export const getAnalyticsBodyDataGroupsPaging = createSelector(getAnalyticsState, AnalyticsReducers.getBodyDataGroupsPaging);
export const getAnalyticsBodyDataGroup = createSelector(getAnalyticsState, AnalyticsReducers.getBodyDataGroup);
export const getAnalyticsMacroGroupsCollection = createSelector(getAnalyticsState, AnalyticsReducers.getMacroGroups);
export const getAnalyticsMacroGroupsPaging = createSelector(getAnalyticsState, AnalyticsReducers.getMacroGroupsPaging);
export const getAnalyticsMacroGroup = createSelector(getAnalyticsState, AnalyticsReducers.getMacroGroup);
//#endregion


//#region Note
export const getNoteState = (state: AppState) => state.note;
export const getNoteLoaded = createSelector(getNoteState, NoteReducers.getNotesLoaded);
export const getNoteLoading = createSelector(getNoteState, NoteReducers.getNotesLoading);
export const getNoteCollection = createSelector(getNoteState, NoteReducers.getNotes);
export const getNotePaging = createSelector(getNoteState, NoteReducers.getNotesPaging);
export const getNoteHasNewData = createSelector(getNoteState, NoteReducers.getNotesHasNewData);
export const getNote = createSelector(getNoteState, NoteReducers.getNote);

export const getSideNoteLoaded = createSelector(getNoteState, NoteReducers.getSideNotesLoaded);
export const getSideNoteLoading = createSelector(getNoteState, NoteReducers.getSideNotesLoading);
export const getSideNoteCollection = createSelector(getNoteState, NoteReducers.getSideNotes);
export const getSideNote = createSelector(getNoteState, NoteReducers.getSideNote);
//#endregion


//#region Notification
export const getNotificationState = (state: AppState) => state.notification;
export const getNotificationLoaded = createSelector(getNotificationState, NotificationReducers.getNotificationsLoaded);
export const getNotificationLoading = createSelector(getNotificationState, NotificationReducers.getNotificationsLoading);
export const getNotificationCollection = createSelector(getNotificationState, NotificationReducers.getNotifications);
export const getNotificationPaging = createSelector(getNotificationState, NotificationReducers.getNotificationsPaging);
export const getNotification = createSelector(getNotificationState, NotificationReducers.getNotification);
export const getNotificationUnreadCount = createSelector(getNotificationState, NotificationReducers.getNotificationsUnreadCount);
//#endregion


//#region Athlete Invitations
export const getAthleteInvitationsState = (state: AppState) => state.athleteInvitations;
export const getAthleteInvitationsCollection = createSelector(getAthleteInvitationsState, AthleteInvitationsReducers.getItems);
export const getPendingAthleteInvitationsCollection = createSelector(getAthleteInvitationsState, AthleteInvitationsReducers.getPendingItems);
//#endregion


//#region Base objects
export const getBaseObjectsState = (state: AppState) => state.baseObjects;
export const getBaseTrainingUnitsLoaded = createSelector(getBaseObjectsState, BaseObjectReducers.getUnitsLoaded);
export const getBaseTrainingUnitsLoading = createSelector(getBaseObjectsState, BaseObjectReducers.getUnitsLoading);
export const getBaseTrainingUnitsCollection = createSelector(getBaseObjectsState, BaseObjectReducers.getUnits);
export const getBaseTrainingUnitPaging = createSelector(getBaseObjectsState, BaseObjectReducers.getUnitsPaging);
export const getBaseTrainingUnit = createSelector(getBaseObjectsState, BaseObjectReducers.getUnit);

export const getBaseTrainingPlansLoaded = createSelector(getBaseObjectsState, BaseObjectReducers.getPlansLoaded);
export const getBaseTrainingPlansLoading = createSelector(getBaseObjectsState, BaseObjectReducers.getPlansLoading);
export const getBaseTrainingPlansCollection = createSelector(getBaseObjectsState, BaseObjectReducers.getPlans);
export const getBaseTrainingPlanPaging = createSelector(getBaseObjectsState, BaseObjectReducers.getPlansPaging);
export const getBaseTrainingPlan = createSelector(getBaseObjectsState, BaseObjectReducers.getPlan);

export const getBaseMacrosLoaded = createSelector(getBaseObjectsState, BaseObjectReducers.getMacrosLoaded);
export const getBaseMacrosLoading = createSelector(getBaseObjectsState, BaseObjectReducers.getMacrosLoading);
export const getBaseMacrosCollection = createSelector(getBaseObjectsState, BaseObjectReducers.getMacros);
export const getBaseMacroPaging = createSelector(getBaseObjectsState, BaseObjectReducers.getMacrosPaging);
export const getBaseMacro = createSelector(getBaseObjectsState, BaseObjectReducers.getMacro);

export const getBaseBodyDatasLoaded = createSelector(getBaseObjectsState, BaseObjectReducers.getBodyDatasLoaded);
export const getBaseBodyDatasLoading = createSelector(getBaseObjectsState, BaseObjectReducers.getBodyDatasLoading);
export const getBaseBodyDatasCollection = createSelector(getBaseObjectsState, BaseObjectReducers.getBodyDatas);
export const getBaseBodyDataPaging = createSelector(getBaseObjectsState, BaseObjectReducers.getBodyDatasPaging);
export const getBaseBodyData = createSelector(getBaseObjectsState, BaseObjectReducers.getBodyData);
//#endregion
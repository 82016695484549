import { INutritionGoal } from '@models/nutrition.model';
import { Action } from '@ngrx/store';

export const LOAD_GOALS = '[Nutrition] Load Goals';
export const LOAD_GOALS_SUCCESS = '[Nutrition] Load Goals Success';
export const LOAD_GOALS_FAIL = '[Nutrition] Load Goals Fail';
export const ADD_GOAL = '[Nutrition] Add Goal';
export const ADD_GOAL_SUCCESS = '[Nutrition] Add Goal Success';
export const ADD_GOAL_FAIL = '[Nutrition] Add Goal Fail';
export const EDIT_GOAL = '[Nutrition] Edit Goal';
export const EDIT_GOAL_SUCCESS = '[Nutrition] Edit Goal Success';
export const EDIT_GOAL_FAIL = '[Nutrition] Edit Goal Fail';


/**
 * Load Goals Actions
 */
export class LoadGoalsAction implements Action {
    readonly type = LOAD_GOALS;
}
export class LoadGoalsSuccessAction implements Action {
    readonly type = LOAD_GOALS_SUCCESS;
    constructor(public payload: INutritionGoal[]) { }
}
export class LoadGoalsFailAction implements Action {
    readonly type = LOAD_GOALS_FAIL;
    constructor(public payload: any) { }
}
/**
 * Add Goal Actions
 */
export class AddGoalAction implements Action {
    readonly type = ADD_GOAL;
    constructor(public payload: INutritionGoal) { }
}
export class AddGoalSuccessAction implements Action {
    readonly type = ADD_GOAL_SUCCESS;
    constructor(public payload: INutritionGoal) { }
}
export class AddGoalFailAction implements Action {
    readonly type = ADD_GOAL_FAIL;
    constructor(public payload: any) { }
}
/**
 * Edit Goal Actions
 */
export class EditGoalAction implements Action {
    readonly type = EDIT_GOAL;
    constructor(public payload: INutritionGoal) { }
}
export class EditGoalSuccessAction implements Action {
    readonly type = EDIT_GOAL_SUCCESS;
    constructor(public payload: INutritionGoal) { }
}
export class EditGoalFailAction implements Action {
    readonly type = EDIT_GOAL_FAIL;
    constructor(public payload: any) { }
}
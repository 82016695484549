import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IAthlete } from '@models/athlete.model';
import { IAssignedMesocycle } from '@models/mesocycle.model';
import { INutritionGoal } from '@models/nutrition.model';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NutritionService } from '@services/nutrition.service';
import { UIService } from '@services/ui.service';
import { NutrientUnit } from 'app/core/enums/nutrient-unit.enum';
import { AppReducers } from 'app/core/store';
import { MesocycleActions, NutritionActions } from 'app/core/store/actions';
import { AppState } from 'app/core/store/app.states';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-edit-nutrition',
  templateUrl: './edit-nutrition.component.html',
  styleUrls: ['./edit-nutrition.component.scss'],
})
export class EditNutritionComponent implements OnInit, OnDestroy {
  @Input() athlete: IAthlete;
  isCalculated: boolean = false;
  subs: Subscription[] = [];
  availableUnits = NutrientUnit;
  unit = NutrientUnit.percent;

  fatsMaxGram = 200;
  protMaxGram = 350;
  carbMaxGram = 1000;

  fatsMaxGKG = 4;
  protMaxGKG = 4;
  carbMaxGKG = 9;

  autoValue: string = null;
  nutrient: any = {
    calories: 0,
    totalPercent: 0,
    prot: { gkg: 0, g: 0, percent: 0 },
    fats: { gkg: 0, g: 0, percent: 0 },
    carb: { gkg: 0, g: 0, percent: 0 }
  };
  calories: number;
  tolerance = {
    min: -100,
    max: 100
  };

  activeMesocycle: IAssignedMesocycle;
  goal: INutritionGoal;

  constructor(private store: Store<AppState>,
    private nutritionService: NutritionService,
    private actions$: Actions,
    private translate: TranslateService,
    private uiService: UIService) { }

  ngOnInit() {
    this.autoCalcNutrition();
    this.loadGoal();

    this.loadActiveMesocycle();
  }

  loadActiveMesocycle() {
    this.subs[this.subs.length] = this.store.select(AppReducers.getActiveAssignedMesocycle, { id: this.athlete.id })
      .subscribe(msc => {
        this.activeMesocycle = msc;
      });
    this.store.dispatch(new MesocycleActions.LoadAssignedMesocyclesAction(this.athlete.id));
  }

  loadGoal() {
    this.store.dispatch(new NutritionActions.LoadGoalsAction());
    this.subs[this.subs.length] = this.store.select(AppReducers.getNutritionGoalByAthleteId, { id: this.athlete.id })
      .subscribe(goal => {
        if (goal) {
          this.isCalculated = false;

          this.goal = goal;

          this.calories = goal.value;
          this.tolerance.min = goal.deviation_from > 0 ? goal.deviation_from - (2 * goal.deviation_from) : goal.deviation_from;
          this.tolerance.max = goal.deviation_to;

          this.nutrient = this.nutritionService.calcNutritionByGrams(
            this.athlete.weight,
            this.calories,
            goal.proteins,
            goal.fats,
            goal.carbohydrates);
        }
      });
  }

  onNutritionChange(unitType) {
    switch (unitType) {
      case 'percent':
        this.nutrient = this.nutritionService.calcNutritionByPercent(
          this.athlete.weight,
          this.calories,
          this.autoValue == 'prot' ? null : this.nutrient.prot.percent,
          this.autoValue == 'fats' ? null : this.nutrient.fats.percent,
          this.autoValue == 'carb' ? null : this.nutrient.carb.percent);
        break;
      case 'g':
        this.nutrient = this.nutritionService.calcNutritionByGrams(
          this.athlete.weight,
          this.calories,
          this.autoValue == 'prot' ? null : this.nutrient.prot.g,
          this.autoValue == 'fats' ? null : this.nutrient.fats.g,
          this.autoValue == 'carb' ? null : this.nutrient.carb.g);
        break;
      case 'gkg':
        this.nutrient = this.nutritionService.calcNutritionByGKG(
          this.athlete.weight,
          this.calories,
          this.autoValue == 'prot' ? null : this.nutrient.prot.gkg,
          this.autoValue == 'fats' ? null : this.nutrient.fats.gkg,
          this.autoValue == 'carb' ? null : this.nutrient.carb.gkg);
        break;
    }
  }



  calcSliderPercent(value, max) {
    let percent = (100 * value) / max;
    percent = percent < 0 ? 0 : percent;
    percent = percent > 100 ? 100 : percent;
    return percent + '%';
  }

  onAutoValueChange($event, unitType) {
    switch ($event.detail.value) {
      case undefined:
      case 'prot':
      case 'fats':
      case 'carb':
        this.onNutritionChange(unitType);
        break;
    }
  }

  onSubmit() {
    let goal = {
      ...this.goal,
      athlete_id: this.athlete.id,
      value: this.calories,
      deviation_from: this.tolerance.min,
      deviation_to: this.tolerance.max,
      proteins: this.nutrient.prot.g,
      fats: this.nutrient.fats.g,
      carbohydrates: this.nutrient.carb.g
    };

    this.subscribetoAddUpdate();

    if (this.goal) {
      this.store.dispatch(new NutritionActions.EditGoalAction(goal));
    }
    else {
      this.store.dispatch(new NutritionActions.AddGoalAction(goal));
    }
  }

  subscribetoAddUpdate() {
    let successAction = this.goal ? NutritionActions.EDIT_GOAL_SUCCESS : NutritionActions.ADD_GOAL_SUCCESS;
    let failAction = this.goal ? NutritionActions.EDIT_GOAL_FAIL : NutritionActions.ADD_GOAL_FAIL;

    let subSuccess = this.actions$.pipe(ofType(successAction))
      .subscribe((data: any) => {
        subSuccess.unsubscribe();
        subFail.unsubscribe();

        this.uiService.showMessage(this.translate.instant('coach.nutrition.' + (this.goal ? 'update_success' : 'add_success')), 'success', 2000);
      })
    let subFail = this.actions$.pipe(ofType(failAction))
      .subscribe((data: any) => {
        subSuccess.unsubscribe();
        subFail.unsubscribe();
      })
  }

  totalCaloriesChanged(calories) {
    this.isCalculated = false;
  }

  autoCalcNutrition() {
    this.calories = this.nutritionService.calcBasicCalories(this.athlete);
    this.isCalculated = true;
  }

  ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item ion-label:last-of-type {
  text-align: right;
}

.cancel-invite-btn {
  display: flex;
  align-items: center;
  color: var(--ons-color-red);
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/user-list/invite-details/invite-details.component.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;AAAR;;AAGA;EACI,aAAA;EACA,mBAAA;EACA,2BAAA;AAAJ","sourcesContent":["ion-item {\n    ion-label:last-of-type {\n        text-align: right;\n    }\n}\n.cancel-invite-btn {\n    display: flex;\n    align-items: center;\n    color: var(--ons-color-red);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
